import Api from '../helpers/Api';
import { showToast } from './snackBar';
import { updateCursorStatus } from './index';

export const GET_SUBJECTS = 'BriefsDictionary/GET_SUBJECTS';
export const UPDATE_BRIFES_DICTIONARY = 'BriefsDictionary/UPDATE_BRIFES_DICTIONARY';
export const RESET_DICTIONARY_ITEM = 'BriefsDictionary/RESET_DICTIONARY_ITEM';

export function updateBriefsDictionary(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BRIFES_DICTIONARY, payload });
  };
}

export function resetDictionaryItem(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: RESET_DICTIONARY_ITEM, payload });
  };
}

export function getBriefsDictionary({ bmSubjectId = null }) {
  return async (dispatch, getState) => {
    const { bmSubjectId: currentBmSubjectId } = getState().briefsDictionary;
    const response = await Api({
      method: 'get',
      url: `/dictionary_terms?bm_subject_id=${bmSubjectId || currentBmSubjectId}`,
    });
    if (response.success) {
      const payload = {};
      payload.dictionary = response.dictionary;
      payload.hasModifyBriefAccess = response.has_modify_brief_access;
      payload.bmSubjectId = bmSubjectId;
      dispatch({ type: UPDATE_BRIFES_DICTIONARY, payload });
    }
    return response;
  };
}

export function addItemToDictionary() {
  return async (dispatch, getState) => {
    const { dictionary, bmSubjectId, term, description } = getState().briefsDictionary;
    dispatch(updateBriefsDictionary({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: '/dictionary_terms',
      data: {
        term,
        description,
        bm_subject_id: bmSubjectId,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.term = '';
        payload.description = '';
        payload.isDictionaryItemSelected = false;
        payload.isChanged = false;
        payload.dictionary = ([...dictionary, response.dictionary_item]).sort((a, b) => {
          const first = a.term.toLowerCase();
          const second = b.term.toLowerCase();
          if (first < second) {
            return -1;
          } if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      payload.isUpdating = false;
      dispatch(updateBriefsDictionary(payload));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateItemInDictionary({ isDictionarySearch = false }) {
  return (dispatch, getState) => {
    const { bmSubjectId, term, description, selectedDictionaryItem } = getState().briefsDictionary;
    const data = {
      term,
      description,
      bm_subject_id: bmSubjectId,
    };
    dispatch(updateBriefsDictionary({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: `/dictionary_terms/${selectedDictionaryItem.toString()}/update`,
      data,
    }).then((response) => {
      let payload = {};
      payload = response;
      payload.id = selectedDictionaryItem;
      payload.isDictionarySearch = isDictionarySearch;
      if (response.success) {
        payload.isDictionaryItemSelected = false;
        payload.selectedDictionaryItem = null;
        payload.selectedIndex = null;
      }
      dispatch(showToast(response.message));
      payload.isUpdating = false;
      dispatch(updateBriefsDictionary(payload));
      dispatch(getBriefsDictionary({ bmSubjectId }));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function deleteItemInDictionary(id) {
  return (dispatch, getState) => {
    const { selectedDictionaryItem, bmSubjectId } = getState().briefsDictionary;
    return Api({
      method: 'post',
      url: `/dictionary_terms/${selectedDictionaryItem}/destroy`,
    }).then((response) => {
      if (response.success) {
        dispatch(resetDictionaryItem({}));
        dispatch(getBriefsDictionary({ bmSubjectId }));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}