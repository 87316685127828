import React from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { Text, QuestionView } from './index';
import { removeQuestionFromComprehension, showToast } from '../../actions';

const history = createBrowserHistory({
  forceRefresh: true,
});

class ComprehensiveQuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removedQuestions: {},
      showQuestions: false,
    };
    this.removeQuestion = this.removeQuestion.bind(this);
  }

  removeQuestion(comprehensionId, questionId) {
    const { removedQuestions } = this.state;
    if (removedQuestions[`${comprehensionId}-${questionId}`]) {
      this.props.showToast('Once Question Is Removed, It Cannot Be Reverted');
    } else {
      removedQuestions[`${comprehensionId}-${questionId}`] = true;
      this.props.removeQuestionFromComprehension(comprehensionId, questionId).then(() => {
        this.setState({ removedQuestions });
      });
    }
  }

  renderMathJax() {
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  renderShowQuestion() {
    const { showQuestions } = this.state;
    return (
      <div
        style={{ width: 'max-content' }}
        onClick={() => this.setState({ showQuestions: !showQuestions }, () => { this.renderMathJax(); })}
      >
        <Text
          fontWeight={StyleConstants.textWeight.bold}
          text={`${showQuestions ? 'Hide' : 'Show'} Questions`}
          color={StyleConstants.color.error}
          style={{
            textDecoration: 'underline', cursor: 'pointer', marginLeft: 38,
          }}
        />
      </div>
    );
  }

  renderQuestionView(questionList, comprehensionId, removedQuestions) {
    const style = {
      backgroundColor: StyleConstants.backgroundColor.gray, marginBottom: 8, flexDirection: 'row', alignItems: 'center',
    };
    return (
      <>
        {questionList.map((questionData) => {
          const removed = removedQuestions[`${comprehensionId}-${questionData.id}`];
          return (
            <div className="Card-View" style={{ ...style, margin: '20px 0px 0px 25px' }}>
              <QuestionView
                currentQuestion={questionData}
                style={{ backgroundColor: 'inherit', boxShadow: '', marginRight: 20 }}
              />
              <div
                className="Button Error-Button"
                style={{
                  padding: 10, marginLeft: 'auto', cursor: removed && 'not-allowed', backgroundColor: removed && StyleConstants.color.errorHover,
                }}
                onClick={() => this.removeQuestion(comprehensionId, questionData.id)}
              >
                {removed ? 'Removed' : 'Remove'}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  renderQuestionText(questionText, style) {
    return (
      <Text
        color={StyleConstants.contentSearch.primary}
        fontWeight={StyleConstants.textWeight.bold}
        fontSize={StyleConstants.textSize.subHeader}
        style={style}
        text={questionText}
      />
    );
  }

  renderSupportiveImage() {
    const { question } = this.props;
    return (
      <img height="200px" width="250px" src={question.supporting_picture.url} alt="" />
    );
  }

  renderEdit(question) {
    return (
      <div
        className="Button Secondary-Button"
        style={{ width: 100, marginLeft: 'auto', margin: '5px 0' }}
        role="presentation"
        onClick={() => history.push(`/comprehension_questions/edit/${question.id}`)}
      >
        Edit
      </div>
    );
  }

  renderComprehensionData(question, canEdit) {
    const isSupportingPicture = question.supporting_picture && question.supporting_picture.url;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {this.renderQuestionText(question.comprehension_text, { marginRight: canEdit && 20, marginBottom: isSupportingPicture ? 20 : 0 })}
        {isSupportingPicture && this.renderSupportiveImage()}
      </div>
    );
  }

  render() {
    const { question, currentIndex, canEdit } = this.props;
    const { removedQuestions, showQuestions } = this.state;
    const questionList = question.question_data.filter((el) => {
      return el != null;
    });
    return (
      <div id={question.id} style={{ margin: 10, width: '100%' }}>
        <div style={{ display: 'flex', width: '100%' }}>
          {currentIndex && this.renderQuestionText(`${currentIndex}.`, { marginRight: 10 })}
          <div style={{ display: 'flex', width: '100%' }}>
            {this.renderComprehensionData(question, canEdit)}
            {canEdit && this.renderEdit(question)}
          </div>
        </div>
        {questionList.length > 0 && this.renderShowQuestion()}
        {showQuestions && this.renderQuestionView(questionList, question.id, removedQuestions)}
      </div>
    );
  }
}

export default connect(null, { removeQuestionFromComprehension, showToast })(ComprehensiveQuestionView);
