import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from './text';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import '../../shared/constants/styleConstants/loader.css';

class Loader extends PureComponent {
  state = {
    screenHeight: 0,
  };

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }
  render() {
    const { height } = this.props;
    return (
      <div style={{ height: height ? height : this.state.screenHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'  }}>
        <div className="loader" />
        <Text
          text={"LOADING"}
          style={{ fontWeight: StyleConstants.textWeight.bold, paddingTop: 20 }}
        />
      </div>
    )
  }
}

Loader.propTypes = {
  height: PropTypes.number,
}

export default Loader;
