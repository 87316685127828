import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import { createBrowserHistory } from 'history';
import { isMobileDevice, getUniqObjectsFromArrayofObjects } from '../../helpers/Utils';
import Text from '../common/text';
import Layout from '../layout';
import { searchQuestions, updateSearchString, getAllFilterCategories, updateFilters, updateQuestionComplexity, 
  updateQuestionTagging, handlePagination, FILTER_CATEGORIES, DIFFICULTY_VALUE_MAPPING } from '../../actions';
import QuestionView from '../common/questionView';
import DropDown from '../common/dropDown';
import Loader from '../common/loader';

const IS_MOBILE = isMobileDevice();
const history = createBrowserHistory({
  forceRefresh: true,
});


class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sampleSetState: false,
    }
    this.renderContents = this.renderContents.bind(this);
    this.renderFilterBody = this.renderFilterBody.bind(this);
    this.renderDropDowns = this.renderDropDowns.bind(this);
    this.renderTagging = this.renderTagging.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  componentDidMount() {
    this.props.getAllFilterCategories();
  }

  renderSearchNotFound() {
    const { appliedFilters, questions, fetching, searchApplied, searchText } = this.props;
    let notFound = (((appliedFilters[FILTER_CATEGORIES.COURSE] && appliedFilters[FILTER_CATEGORIES.COURSE].id) || (appliedFilters[FILTER_CATEGORIES.TEST] && appliedFilters[FILTER_CATEGORIES.TEST].id)) && questions.length === 0) || searchText;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, backgroundColor: StyleConstants.color.white, border: '1px solid #CDCFD6', borderRadius: 4, marginTop : 60 }}>
       <Text 
          text={(notFound && searchApplied) ? fetching ? 'Loading...' : "No Questions found"  : "Search Questions"}
          color={notFound && searchApplied ?  StyleConstants.color.dark :  StyleConstants.color.inactive}
          fontSize={StyleConstants.textSize.tertiaryHeader} 
       />
      </div>
    );
  }

  renderSelectedTopics(selectedCheckTopic) {
    return (
      <div>
        {
         !!selectedCheckTopic.length && selectedCheckTopic.map((topic) => {
            return (
              <div>
                <Text 
                  text={topic.name}
                  color={StyleConstants.color.dark}
                  fontSize={StyleConstants.textSize.text} 
                />
              </div>
            );
          })
        }
      </div>
    );
  }

  renderTagging(question) {
    const { checkpoints, appliedFilters } = this.props;

    let selectedCheckTopic = {
      id: '',
      name: '',
    };
    let showAllTopics = false;
    let allSelectedTopics = [];
    let topicList = [];
    let selectedChapter = appliedFilters[FILTER_CATEGORIES.CHAPTER] ? appliedFilters[FILTER_CATEGORIES.CHAPTER].id : '';
    let selectedCourse = appliedFilters[FILTER_CATEGORIES.COURSE] ? appliedFilters[FILTER_CATEGORIES.COURSE].id : '';

    if (checkpoints && Object.keys(checkpoints).length) {
      Object.values(checkpoints).map((chapter_checkpoint) => {
        topicList = [ ...topicList, ...Object.values(chapter_checkpoint)];
      })
      if (question.question_checkpoint_mappings && question.question_checkpoint_mappings.length) {
        if (!selectedChapter && !selectedCourse) {

          let allTopicsHash = {}
          showAllTopics = true;
  
          topicList.map((item) => {
            allTopicsHash[item.id] = item;
          })
  
          question.question_checkpoint_mappings.map((qcm) => {
            allSelectedTopics.push({
              id: allTopicsHash[qcm.checkpoint_id] ? allTopicsHash[qcm.checkpoint_id].id : '',
              name: allTopicsHash[qcm.checkpoint_id] ? allTopicsHash[qcm.checkpoint_id].name : '',
            })
          })
        }
        
        if (selectedChapter) {
          topicList = Object.values(checkpoints[selectedChapter]);
          question.question_checkpoint_mappings.map((qcm) => {
            if (selectedChapter === qcm.chapter_id) {
              selectedCheckTopic = {
                id: checkpoints[selectedChapter][qcm.checkpoint_id] ? checkpoints[selectedChapter][qcm.checkpoint_id].id : '',
                name: checkpoints[selectedChapter][qcm.checkpoint_id] ? checkpoints[selectedChapter][qcm.checkpoint_id].name : '',
              }
            }
          })
        } else {
            if (selectedCourse) {
              question.question_checkpoint_mappings.map((qcm) => {
                if (selectedCourse === qcm.test_type_id) {
                  selectedCheckTopic = {
                    id: checkpoints[qcm.checkpoint_id] ? checkpoints[qcm.checkpoint_id].id : '',
                    name: checkpoints[qcm.checkpoint_id] ? checkpoints[qcm.checkpoint_id].name : '',
                  }
                }
              })
            } else {
              selectedCheckTopic = {
                id: checkpoints[question.question_checkpoint_mappings[0].checkpoint_id] ? checkpoints[question.question_checkpoint_mappings[0].checkpoint_id].id : '',
                name: checkpoints[question.question_checkpoint_mappings[0].checkpoint_id] ? checkpoints[question.question_checkpoint_mappings[0].checkpoint_id].name : '',
              }
            }
         }
      }
    }
  
    let subjectFilteredTopics = [];
    subjectFilteredTopics = topicList && topicList.filter((checkpoint) => checkpoint.subject_id === question.subject_id)
    return (
      <>
      {showAllTopics && this.renderSelectedTopics(allSelectedTopics)}
       <DropDown key={`${Object.keys(this.props.checkpoints).length}-${selectedCheckTopic.name}`}  defaultText='-' selectedItem={showAllTopics ? [] : selectedCheckTopic} optionsList={subjectFilteredTopics} optionType={'topics'} width={210} color={StyleConstants.color.dark} onValueChange={(value, text) => this.props.updateQuestionTagging(question.id, value)} />
      </>
    );
  } 

  renderContentBody(question, index) {
    const { difficulty, activePage, itemsCountPerPage } = this.props;
    let selectedDifficulty = (question.complexity ? DIFFICULTY_VALUE_MAPPING[question.complexity] : {});
    let cellStyle = { verticalAlign: 'top',  border: '1px solid #CDCFD6', padding: 10 } 
    let currentIndex = (activePage - 1) ? ((activePage - 1) * itemsCountPerPage) + (index + 1) : (index + 1);
    return (
      <tr style={{ fontSize: StyleConstants.textSize.text, height: 240, margin: 20, border: '1px solid #CDCFD6' }}>
        <td style={cellStyle}>
          <div style={{ paddingLeft: 20, cursor: 'pointer' }} onClick={() => history.push(`/questions/edit/${question.id}`)}>
            <Text
            text={currentIndex}
            color={StyleConstants.contentSearch.answerColor}
            fontSize={StyleConstants.textSize.subHeader}
          />
          </div>
        </td>
        <td style={{ ...cellStyle, padding: 0 }}>
          <QuestionView
            style={{ boxShadow: '', margin: 0 }}
            currentQuestion={question}
            canReport
            canEdit
            videoSolutionWidth = "420px"
            videoSolutionHeight = "180px"
          />
        </td>
        <td style={cellStyle}>
          {this.renderTagging(question)}
        </td>
        <td style={cellStyle}>
          <DropDown key={"Difficulty"} defaultText='-' selectedItem={selectedDifficulty} optionsList={difficulty} optionType={'difficulty'} width={210} color={StyleConstants.color.dark} onValueChange={(value, text) => value && this.props.updateQuestionComplexity(question.id, value)} />
        </td>
      </tr>
    );
  }

  renderDropDowns(text, list, type, defaultText = '') {
    let selectedItem = this.props.appliedFilters[type];
    return (
      <div key={`${text}-${list ? list.length : ''}`}>
        <Text
          text={text}
          fontSize={StyleConstants.textSize.headerSmall}
        />
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          {<DropDown selectedItem={selectedItem} key={`${text}-${list ? list.length : ''}`} defaultText={defaultText} optionsList={list} optionType={text} width={210} color={StyleConstants.color.dark} onValueChange={(value, text) => this.props.updateFilters(type, value, text)} />}
        </div>
      </div>
    );
  }

  renderFilterBody() {
    const { courses, subjects, chapters, checkpoints, tests, appliedFilters } = this.props;
    let selectedCourse = appliedFilters[FILTER_CATEGORIES.COURSE] ? appliedFilters[FILTER_CATEGORIES.COURSE].id : '';
    let selectedChapter = appliedFilters[FILTER_CATEGORIES.CHAPTER] ? appliedFilters[FILTER_CATEGORIES.CHAPTER].id : '';
    let selectedSubject = appliedFilters[FILTER_CATEGORIES.SUBJECT] ? appliedFilters[FILTER_CATEGORIES.SUBJECT].id : '';
    let subjectsList = selectedCourse ? subjects[selectedCourse] : getUniqObjectsFromArrayofObjects(Object.values(subjects).flat());
    let chaptersList = chapters[`${selectedCourse}_${selectedSubject}`];
    let topicsList = selectedChapter ? Object.values(checkpoints[`${selectedChapter}`]) : [];
    return (
     <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {this.renderDropDowns("Course", courses, FILTER_CATEGORIES.COURSE)}
        {this.renderDropDowns("Subject", subjectsList, FILTER_CATEGORIES.SUBJECT)}
        {this.renderDropDowns("Chapter", chaptersList, FILTER_CATEGORIES.CHAPTER)}
        {this.renderDropDowns("Topic", topicsList, FILTER_CATEGORIES.TOPIC)}
      </div>
      <div>
        {this.renderDropDowns("Test", tests, FILTER_CATEGORIES.TEST, "-")}
      </div>
      </>
    );
  }

  handlePageChange(pageNumber) {
    this.props.handlePagination({ activePage: pageNumber });
    window.scrollTo(0, 0);
  }

  renderPagination() {
    if (this.props.questions.length > 0) {
      return (
        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%' }}>
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.itemsCountPerPage}
            totalItemsCount={this.props.totalQuestionsCount}
            pageRangeDisplayed={5}
            onChange={(event) => this.handlePageChange(event)}
          />
       </div>
    );
    } else {
      return <div />;
    }
    
  }
  renderContents() {
    const { questions, fetching, appliedFilters, totalQuestionsCount, searchText, searchApplied } = this.props;
    let showCount = (searchApplied || questions.length) && ((appliedFilters[FILTER_CATEGORIES.COURSE] && appliedFilters[FILTER_CATEGORIES.COURSE].id) || (appliedFilters[FILTER_CATEGORIES.TEST] && appliedFilters[FILTER_CATEGORIES.TEST].id) || (appliedFilters[FILTER_CATEGORIES.SUBJECT] && appliedFilters[FILTER_CATEGORIES.SUBJECT].id) || (searchText.length && searchApplied));
    if (fetching) {
      return (
        <Loader height={300} />
      );
    }
    const tableHeader = { paddingLeft: 10 };
    return (
      <div>
        <div style={{ padding: '10px 0px' }}>
          <Text 
            text={showCount ? `Total Questions : ${totalQuestionsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ''}
            fontSize={StyleConstants.textSize.subHeader} 
            color={StyleConstants.contentSearch.answerColor}
         />
        </div>
        {this.renderPagination()}
        {(questions.length > 0) && 
          <table className="table table-bordered" style={{ boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4,  backgroundColor: StyleConstants.color.white, border: '1px solid #CDCFD6', width: '100%', borderSpacing: 0, borderCollapse: 'collapse', tableLayout: 'fixed' }}>
            <thead >
              <tr style={{ fontSize: "18px", height: 60, color: StyleConstants.color.dark, fontFamily: StyleConstants.textFont.normal ,borderBottom: '2px solid #CDCFD6'  }}>
                <th scope="col" style={{ ...tableHeader, width: "7%" }}>
                  <Text 
                    text={"No"}
                    color={StyleConstants.color.dark}
                    fontSize={StyleConstants.textSize.title} 
                  />
                </th>
                <th scope="col" style={{ ...tableHeader, width: "50%", textAlign: 'left' }}>
                  <Text 
                    text={"QUESTION"}
                    color={StyleConstants.color.dark}
                    fontSize={StyleConstants.textSize.title} 
                  />
                </th>
                <th scope="col" style={{ ...tableHeader, width: "22%" }}>
                  <Text 
                    text={"TOPICS"}
                    color={StyleConstants.color.dark}
                    fontSize={StyleConstants.textSize.title} 
                  />
                </th>
                <th scope="col" style={{ ...tableHeader, width: "22%" }}>
                  <Text
                    text={"DIFFICULTY"}
                    color={StyleConstants.color.dark}
                    fontSize={StyleConstants.textSize.title} 
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {window &&  window.MathJax &&  window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]) }
              {questions.map((question, index) => this.renderContentBody(question, index))}
            </tbody>
          </table>
        }
        {(questions.length === 0) && this.renderSearchNotFound()}
        {this.renderPagination()}
      </div>
    );
  }

  renderSearchBar = () => {
    const { searchText } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '10px 0px', display: 'flex', border: '1px solid #4C5E6F', borderRadius: 4, width: '100%', height: 40 }}>
          <img src="/images/common/search.svg" alt="search" style={{ padding: 10 }} />
          <div style={{ width: '100%', display: 'flex' }}>
            <input
              id="searchText"
              type="text"
              autoComplete="off"
              placeholder="Search questions by text"
              style={{ border: 0, marginBottom: 0,padding: 0, height: 40, width: '100%', backgroundColor: "inherit", margin: 0, outline: 'none', fontSize: StyleConstants.textSize.text }}
              onChange={(e) => {
                this.props.updateSearchString(e.target.value);
                if (e.target.value === '') {
                  this.setState({ urlText: '' })
                  return;
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.props.searchQuestions();
                }}}
              value={searchText}
              autoFocus={!IS_MOBILE}
            />
            <div style={{ width: 25, display: (searchText.length > 0) ? "flex" : "none", alignItems: "center", cursor: "pointer" }}  onClick={() => this.props.updateSearchString('')}>
              <img src="/images/common/x-mark.svg" width="16" height="16" alt="x" />
            </div>
          </div>
        </div>
        {!IS_MOBILE && <div className="Primary-Button" style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, margin: '10px 0px 10px 10px', cursor: 'pointer', padding: 10 }} onClick={() => {this.props.searchQuestions()}}>Search</div>}
      </div>
    );
  }

  render() {
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.SEARCH.label} currentPage={fileConstants.contentsMenuOrder.SEARCH.position}>
        <div className="Simple-Card-View" style={{ padding: 30 }}>
          {this.renderSearchBar()}
          {this.renderFilterBody()}
          {this.renderContents()}
        </div>
      </Layout>
    );
  }
}

Search.propTypes = {
  searchText: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  difficulty: PropTypes.array.isRequired,
  tests: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  checkpoints: PropTypes.object.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ home, search }) => ({
  searchText: search.searchText,
  questions: search.questions,
  difficulty: search.difficulty,
  checkpoints: search.checkpoints,
  subjects: search.subjects,
  chapters: search.chapters,
  courses: search.courses,
  tests: search.tests,
  appliedFilters: search.appliedFilters,
  totalQuestionsCount: search.totalQuestionsCount,
  activePage: search.activePage,
  itemsCountPerPage: search.itemsCountPerPage,
  fetching: search.fetching,
  searchApplied: search.searchApplied,
});

export default connect(mapStateToProps, { 
  updateSearchString,
  getAllFilterCategories,
  updateFilters,
  updateQuestionComplexity,
  updateQuestionTagging,
  handlePagination,
  searchQuestions,
 })(Search);
