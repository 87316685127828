import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateBriefQuestion, removeBriefQuestion, showToast,
} from '../actions';
import reset from '../shared/images/common/reset.svg';
import BriefQuestionView from './briefQuestionView';
import queryString from 'query-string';

class NewBriefQuestion extends PureComponent {
  constructor(props) {
    super(props);
    const { question } = props;
    this.state = {
      textChanges: {
        id: question.id,
        question_text: question.question_text,
        option_1: question.option_1,
        option_2: question.option_2,
        option_3: question.option_3,
        option_4: question.option_4,
        answer: question.answer,
      },
      imageChanges: {
        image_option_1: question.image_option_1,
        image_option_2: question.image_option_2,
        image_option_3: question.image_option_3,
        image_option_4: question.image_option_4,
        supporting_picture: question.supporting_picture,
      },
      removedImages: {},
      isNewImage: {
        image_option_0: false,
        image_option_1: false,
        image_option_2: false,
        image_option_3: false,
        image_option_4: false,
        supporting_picture: false,
      },
      processing: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleChangeOnImages = this.handleChangeOnImages.bind(this);
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges: { ...textChanges } });
  }

  handleChangeOnImages(type, value) {
    const { imageChanges, isNewImage, removedImages } = this.state;
    imageChanges[type] = value;
    removedImages[type] = false;
    isNewImage[type] = true;
    this.setState({
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
      removedImages: { ...removedImages },
    });
  }

  updateQuestion() {
    const { textChanges, imageChanges, removedImages } = this.state;
    const imageFiles = [];
    const { id, question, selectedBrief } = this.props;
    if (this.isDataPresent(textChanges, imageChanges)) {
      Object.keys(imageChanges).map((file) => {
        if ((imageChanges[file]
          && imageChanges[file].url !== (question[file]
            && question[file].url)) || (!question[file] && imageChanges[file])) {
          imageFiles.push(new File([imageChanges[file]], `${file}.${imageChanges[file].type && imageChanges[file].type.split('/')[1]}`,
            { type: imageChanges[file].type }));
        }
        return imageChanges;
      });
      this.handleCloseModal();
      this.setState({ processing: true });
      this.props.updateBriefQuestion(id, textChanges, !!selectedBrief, imageFiles, removedImages).then(() => {
        this.setState({ processing: false });
        this.props.resetEdit();
      });
    }
  }

  isDataPresent(textChanges, imageChanges) {
    let optionArray = Array.from(new Set([textChanges.option_1 || imageChanges.image_option_1,
      textChanges.option_2 || imageChanges.image_option_2,
      textChanges.option_3 || imageChanges.image_option_3,
      textChanges.option_4 || imageChanges.image_option_4]));
    optionArray = optionArray.filter((e) => e);
    if (!textChanges.question_text) {
      this.props.showToast('Question Text Should Be Present');
      return false;
    }
    if (optionArray.length !== 4) {
      this.props.showToast('All four options should be present and it should be unique');
      return false;
    }
    if (!textChanges.answer) {
      this.props.showToast('Atleast one option should be marked as correct');
      return false;
    }
    return true;
  }

  refresh() {
    const { question } = this.props;
    const textChanges = {
      id: question.id,
      question_text: question.question_text,
      option_1: question.option_1,
      option_2: question.option_2,
      option_3: question.option_3,
      option_4: question.option_4,
      answer: question.answer,
    };
    const imageChanges = {
      image_option_1: question.image_option_1,
      image_option_2: question.image_option_2,
      image_option_3: question.image_option_3,
      image_option_4: question.image_option_4,
      supporting_picture: question.supporting_picture,
    };
    const isNewImage = {
      image_option_0: false,
      image_option_1: false,
      image_option_2: false,
      image_option_3: false,
      image_option_4: false,
    };
    this.setState({
      textChanges: { ...textChanges },
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
    });
  }

  removeImage(option) {
    const { imageChanges, isNewImage, removedImages } = this.state;
    const type = option > 0 ? `image_option_${option}` : 'supporting_picture';
    imageChanges[type] = null;
    removedImages[type] = true;
    isNewImage[type] = false;
    this.setState({
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
      removedImages: { ...removedImages },
    });
  }

  handleCloseModal() {
    const { isNewBriefQuestion } = this.props;
    this.setState({ showQuestionModal: false });
    isNewBriefQuestion && this.props.removeNewBriefQuestion();
  }

  renderBriefQuestion(briefQuestion, index) {
    const {
      textChanges, imageChanges, isNewImage, processing,
    } = this.state;
    const {
      id, isNewBriefQuestion, removeNewBriefQuestion, isEditable, selectedBrief, isPreviousYearQuestionView, economyMode
    } = this.props;
    const { print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          marginBottom: 20,
          borderLeft: isEditable ? '10px solid #3994AE' : '1px solid #454545',
          borderRadius: isEditable && 10,
          boxShadow: '0px 3px 15px rgba(103, 58, 183, 0.1)',
          width: printChapterBriefQuestions && (window.innerWidth-100)/2,
        }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontFamily: 'Nunito', border: '1px solid #454545', borderBottom: 0, padding: '5px 20px', alignItems: 'center', borderLeft: 0,
        }}
        >
          {!isNewBriefQuestion && (
          <div
            style={{
              width: 'fit-content', fontWeight: 600, color: '#454545',
            }}
          >
            {isPreviousYearQuestionView ? `Question ID: ${briefQuestion.id}` : `Question: ${index}`}
          </div>
          )}
          <div style={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginLeft: 'auto',
          }}
          >
            {isEditable && !isNewBriefQuestion && (
            <img
              onClick={() => this.refresh()}
              role="presentation"
              src={reset}
              alt="refresh"
              style={{
                marginRight: 20, height: 30, width: 30, padding: 5, borderRadius: '50%', cursor: 'pointer',
              }}
            />
            )}
            {isEditable && (
              processing
                ? (
                  <div className="Button Primary-Button" style={{ padding: '5px 10px', border: 'none', marginRight: 20 }}>
                    Updating &nbsp;
                    <i style={{ fontSize: 18 }} className="fa fa-spinner fa-spin" />
                  </div>
                )
                : (
                  <div
                    className="Button Primary-Button"
                    onClick={() => this.updateQuestion()}
                    role="presentation"
                    style={{
                      marginRight: 20, padding: '5px 10px', cursor: 'pointer', height: 30, border: 'none',
                    }}
                  >
                    SAVE
                  </div>
                )
            )}
            <div
              role="presentation"
              className="New-Button-Hover"
              onClick={isNewBriefQuestion ? () => this.props.removeNewBriefQuestion() : () => this.props.removeBriefQuestion(id, briefQuestion.id, isPreviousYearQuestionView, !!selectedBrief)}
              style={{
                padding: 10, borderRadius: 20, cursor: 'pointer', marginRight: 20,
              }}
            >
             {!printChapterBriefQuestions && (<img alt="Delete Question" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmaxcdn.icons8.com%2FShare%2Ficon%2FEditing%2Fdelete1600.png&f=1&nofb=1" width={20} height={20} />)}
            </div>
          </div>
        </div>
        {printChapterBriefQuestions && (
        <div
          role="presentation"
          style={{ border: '1px solid #343737', padding: 20, borderLeft: 0, }}
        >
          <BriefQuestionView
            briefQuestion={{ ...briefQuestion }}
            textChanges={{ ...textChanges }}
            imageChanges={{ ...imageChanges }}
            economyMode={economyMode}
          />
        </div>)}
        {!printChapterBriefQuestions && (
        <div
          role="presentation"
          style={{ border: '1px solid #343737', padding: 20, borderLeft: 0 }}
          onClick={() => this.props.edit()}
        >
          <BriefQuestionView
            briefQuestion={{ ...briefQuestion }}
            textChanges={{ ...textChanges }}
            imageChanges={{ ...imageChanges }}
            isNewImage={{ ...isNewImage }}
            refresh={this.refresh}
            removeImage={this.removeImage}
            handleChangeOnImages={this.handleChangeOnImages}
            handleChange={this.handleChange}
            removeNewBriefQuestion={removeNewBriefQuestion}
            isEditable={isEditable}
            isNewBriefQuestion={isNewBriefQuestion}
          />
        </div>
        )}
      </div>
    );
  }

  render() {
    const { question, index } = this.props;
    const { print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);
    return (
      <div style={{ fontFamily: 'Nunito', margin: printChapterBriefQuestions && 'auto'  }}>
        <style>
          {`
            input[type=checkbox]:after {
              background-color: #3994AE;
            }

            .Simple-Card-View {
                border-radius: 4px;
                border: 1px solid #FFFFFF;
            }
        `}
        </style>
        {this.renderBriefQuestion(question, index)}
      </div>
    );
  }
}

NewBriefQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  removeNewBriefQuestion: PropTypes.bool,
  isEditable: PropTypes.bool,
  isNewBriefQuestion: PropTypes.bool,
};

NewBriefQuestion.defaultProps = {
  question: {},
  removeNewBriefQuestion: false,
  isEditable: false,
  index: 0,
  isNewBriefQuestion: false,
};

export default connect(null, {
  updateBriefQuestion,
  removeBriefQuestion,
  showToast,
})(NewBriefQuestion);
