import Api from '../helpers/Api';
import { showToast } from './snackBar';

export const GET_LIST = 'CheatsheetList/GET_LIST';
export const CHECKPOINT_CHAPTER_LIST = 'CheatsheetList/CHECKPOINT_CHAPTER_LIST';
export const GET_QUICKPOINT_FILTER_DATA = 'CheatsheetList/GET_QUICKPOINT_FILTER_DATA';
export const UPDATE_QUICKPOINT_FILTER_DATA = 'CheatsheetList/UPDATE_QUICKPOINT_FILTER_DATA';
export const ADD_QUICKPOINTS = 'CheatsheetList/ADD_QUICKPOINTS';
export const UPDATE_QUICKPOINTS = 'CheatsheetList/UPDATE_QUICKPOINTS';
export const ORDER_QUICKPOINTS = 'CheatsheetList/ORDER_QUICKPOINTS';
export const DELETE_QUICKPOINTS = 'CheatsheetList/DELETE_QUICKPOINTS';

export const IMAGE_UPDATE_QUICKPOINT = {
  NO_CHANGE: 0,
  UPDATED: 1,
  CHANGED: 2,
  DELETED: 3,
};

export const getQuickpointList = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload: { isLoading: true } });
  const { cheatsheetList: { appliedFilters } } = getState();
  let url_params = '';
  if (appliedFilters.chapter.id) {
    url_params += `&chapter_id=${appliedFilters.chapter.id}`;
  }
  const response = await Api({
    method: 'get',
    url: `/quickpoints?get_all=true${url_params}`,
  });
  const payload = {};
  if (response.success) {
    payload.quickpoints = response.data.quickpoints;
    dispatch({
      type: GET_LIST,
      payload,
    });
    dispatch(getChapterCheckpointList());
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload });
};

export const getChapterCheckpointList = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload: { isLoading: true } });
  const { cheatsheetList: { appliedFilters } } = getState();
  let url_params = '';
  if (appliedFilters.chapter.id) {
    url_params += `?chapter_id=${appliedFilters.chapter.id}`;
  }
  return Api({
    method: 'get',
    url: `/checkpoints/get_list${url_params}`,
  }).then((response) => {
    const payload = {};
    payload.checkpoints = response.data.checkpoints;
    payload.selectedChapter = response.data.chapter;
    if (response.success) {
      dispatch({ type: CHECKPOINT_CHAPTER_LIST, payload });
    }
    payload.isLoading = false;
    dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload });
  });
};

export const getQuickpointFilterData = (courseID, subjectID, chapterID) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_QUICKPOINT_FILTER_DATA,
    payload: { isLoading: true },
  });
  const response = await Api({
    method: 'get',
    url: '/content_engine/get_all_filter_categories',
  });
  const payload = {};
  if (response.success) {
    payload.courses = response.courses || [];
    payload.subjects = response.subjects || [];
    payload.chapters = response.chapters || [];
    payload.checkpoints = [];
    if (courseID && subjectID && chapterID) {
      payload.appliedFilters = {
        course: response.courses ? response.courses.filter((course) => { return course.id === courseID; })[0] : {},
        subject: response.subjects[courseID] ? response.subjects[courseID].filter((subject) => { return subject.id === subjectID; })[0] : {},
        chapter: response.chapters[`${courseID}_${subjectID}`] ? response.chapters[`${courseID}_${subjectID}`].filter((chapter) => { return chapter.id === chapterID; }).map(({ id, name }) => { return { id, name: name.substring(name.indexOf('-') + 1) }; })[0] : {},
        checkpoints: [],
      };
    }
    dispatch({
      type: GET_QUICKPOINT_FILTER_DATA,
      payload,
    });
  }
  payload.isLoading = false;
  dispatch({
    type: UPDATE_QUICKPOINT_FILTER_DATA,
    payload,
  });
  return response;
};

export function addQuickpoint(title, text, checkpoints, supportingPicture, selectedIndex, chapterId, configuration) {
  const checkpointIds = checkpoints.map(({ id }) => { return id; });
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/quickpoints',
      attachment: supportingPicture,
      type: 'supporting_picture',
      data: {
        title,
        text,
        checkpoints: JSON.stringify(checkpointIds),
        selected_index: selectedIndex,
        chapter_id: chapterId,
        configuration: JSON.stringify(configuration),
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.selectedIndex = selectedIndex;
        dispatch({
          type: ADD_QUICKPOINTS,
          payload,
        });
        dispatch(showToast(response.message));
        payload.selectedIndex = '';
        payload.isQuickpointSelected = false;
        payload.configuration = {};
      }
      dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload });
      return response;
    });
  };
}

export function updateQuickpoint(id, title, text, supportingPicture, checkpoints, imageUpdate, configuration) {
  const checkpointIds = checkpoints.map(({ id }) => { return id; });
  return (dispatch) => {
    let attachment = {};
    const data = {
      title,
      text,
      checkpoints: JSON.stringify(checkpointIds),
      image_update: imageUpdate,
      configuration: JSON.stringify(configuration),
    };
    if (imageUpdate === IMAGE_UPDATE_QUICKPOINT.UPDATED) {
      attachment = {
        attachment: supportingPicture,
        type: 'supporting_picture',
      };
    } else {
      data.supporting_picture = supportingPicture;
    }
    return Api({
      method: 'post',
      url: `/quickpoints/${id}/update`,
      ...attachment,
      data,
    }).then((response) => {
      const payload = {};
      if (response.success) {
        dispatch({ type: UPDATE_QUICKPOINTS, payload: response });
        payload.selectedIndex = '';
        payload.isQuickpointSelected = false;
        payload.configuration = {};
      }
      dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload });
      return response;
    });
  };
}

export function deprecateQuickpoint(id) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/quickpoints/${id}/deprecate`,
      data: {},
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = { id };
        dispatch({
          type: DELETE_QUICKPOINTS,
          payload,
        });
        payload.selectedIndex = '';
        payload.isQuickpointSelected = false;
        payload.configuration = {};
      }
      dispatch({
        type: UPDATE_QUICKPOINT_FILTER_DATA,
        payload,
      });
      return response;
    });
  };
}

export function orderQuickpoints(fromIndex, toIndex, selectedChapterID, quickpoints) {
  const quickpointIds = quickpoints.map(({ id }) => { return id; });
  if (toIndex === quickpointIds.length) {
    quickpointIds.push(quickpointIds[fromIndex]);
    quickpointIds.splice(fromIndex, 1);
  } else {
    quickpointIds[fromIndex] = quickpointIds.splice(toIndex, 1, quickpointIds[fromIndex])[0];
  }
  return (dispatch) => {
    const data = {
      chapter_id: selectedChapterID,
      from_index: fromIndex + 1,
      to_index: toIndex + 1,
      quickpoint_ids: JSON.stringify(quickpointIds),
    };
    dispatch({
      type: UPDATE_QUICKPOINT_FILTER_DATA,
      payload: { isOrderBeingUpdated: false },
    });
    return Api({
      method: 'post',
      url: '/quickpoints/order',
      data,
    }).then((response) => {
      const payload = {};
      payload.fromIndex = fromIndex;
      payload.toIndex = toIndex;
      if (response.success) {
        dispatch({ type: ORDER_QUICKPOINTS, payload });
        dispatch(showToast(response.message));
        payload.selectedIndex = '';
        payload.isQuickpointSelected = false;
        payload.configuration = {};
        payload.isOrderBeingUpdated = true;
        dispatch({ type: UPDATE_QUICKPOINT_FILTER_DATA, payload });
      }
      return response;
    });
  };
}

export const updateQuickpointReducer = (payload) => async (dispatch) => {
  const { addParams } = payload;
  if (addParams) {
    const { appliedFilters: { course, subject, chapter } } = payload;
    window.location.hash = `course_id=${course.id}&subject_id=${subject.id}&chapter_id=${chapter.id}`;
  }
  return dispatch({
    type: UPDATE_QUICKPOINT_FILTER_DATA,
    payload,
  });
};
