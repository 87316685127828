import {
  UPDATE_DIAGRAM, RESET_DIAGRAM,
} from '../actions';

const initialState = {
  appliedFilters: {
    subject: {},
    chapter: {},
    briefOperator: {},
    briefHealth: null,
    briefHealthSecondary: null,
  },
  subjects: [],
  diagrams: [],
  selectedIndex: 0,
  selectedDiagramId: null,
  isDiagramSelected: false,
  name: '',
  description: '',
  image: '',
  isDiagramSearch: false,
  displayEditView: false,
  isChanged: false,
  bmSubjectId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DIAGRAM:
      return { ...state, ...action.payload };
    case RESET_DIAGRAM:
      const resetItem = {
        selectedIndex: 0,
        selectedDiagramId: null,
        isDiagramSelected: false,
        name: '',
        description: '',
        image: '',
      };
      return { ...state, ...resetItem, ...action.payload };
    default:
      return state;
  }
}
