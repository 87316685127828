import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Constants from '../shared/constants/environmentConfig';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import {
  REPORT_ISSUE_TOAST_MESSAGES,
} from '../shared/constants/fieldTypes';
import sampleSheet from '../shared/asserts/Sample_Question_Spreadsheet.xlsx';
import Upload from '../shared/images/common/uploadarrow.svg';
import { uploadQuestions, getInstituteUploadStatus, showToast } from '../actions';
import { UUID } from '../helpers/Utils';
import { Modal, Text } from './common';

const cookies = new Cookies();
const USER = cookies.get('user');
const INSTITUTE_QUESTION_UPLOAD_CHANNEL = 'InstituteQuestionUploadChannel';
const SUCCESS = {
  fontWeight: StyleConstants.textWeight.bold, fontSize: StyleConstants.textSize.subHeader, color: StyleConstants.color.success, textAlign: 'center',
};
const ERROR = { color: StyleConstants.color.error };
const UPLOAD_STATUS = {
  Error: 0,
  Progressing: 1,
  Complete: 2,
  UnUpload: 3,
  Verified: 4,
  NotVerified: 5,

};

const COMPLETE_PROGRESS = 100;

class ImportQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showTestModal: false,
      cable: Object.create(null),
      subscription: Object.create(null),
      progress: 0,
      status: UPLOAD_STATUS.UnUpload,
      errors: [],
    };
    this.uploadTestQuestion = this.uploadTestQuestion.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
  }

  componentDidMount() {
    this.props.getInstituteUploadStatus().then((data) => {
      this.setState({ showLoader: false, status: data.status });
    });
    if (typeof window !== 'undefined') {
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants().api.ws_url);
      this.setState({ cable });
      const subscription = cable.subscriptions.create({ channel: INSTITUTE_QUESTION_UPLOAD_CHANNEL, institute_id: USER && USER.institute_id }, {
        received: (data) => {
          this.setState({ status: data.status, progress: data.progress });
          if (data.progress === COMPLETE_PROGRESS && data.status === UPLOAD_STATUS.Complete) {
            this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATED);
            this.setState({ showTestModal: true });
          }
        },
      });
      this.setState({ subscription });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  showProgress() {
    const { status, progress } = this.state;
    const isProgressing = UPLOAD_STATUS.Progressing === status;
    const isVerified = status === UPLOAD_STATUS.Verified;
    const isNotVerified = status === UPLOAD_STATUS.NotVerified;
    const text = isProgressing ? `${Object.keys(UPLOAD_STATUS)[status]} - ${progress}%` : 'No Processing Files';
    return (
      <div className="d-flex justify-content-center" style={{ margin: '10%', textAlignLast: 'center' }}>
        {isProgressing && <div style={SUCCESS}>{text}</div>}
        {isVerified && <div style={SUCCESS}>{REPORT_ISSUE_TOAST_MESSAGES.VERIFIED}</div>}
        {isNotVerified && <div style={SUCCESS}>{REPORT_ISSUE_TOAST_MESSAGES.NOTVERIFIED}</div>}
      </div>
    );
  }

  showUploadedErrors() {
    const { errors } = this.state;
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ overflowY: 'scroll', maxHeight: 400, padding: 20 }}>
          {errors.map((error, index) => (
            <div style={{ color: StyleConstants.color.error, paddingTop: 10, fontSize: StyleConstants.textSize.text }}>
              {index + 1}
              .&nbsp;&nbsp;&nbsp;
              {error}
            </div>
          ))}
        </div>
        <div className="Button Primary-Button" style={{ width: '50%', marginTop: 25 }} onClick={() => this.getTestQuestionFile(true)}>Upload Again</div>
      </div>
    );
  }

  uploadTestQuestion(event) {
    const fileList = event.target.files;
    const files = Object.values(fileList);
    if (files.length > 0) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.NOTVERIFIED);
      this.setState({ fileList: files, showTestModal: true });
      const sendFiles = files.map((file) => {
        const extension = file.name && file.name.split('.');
        return new File([file], `${UUID()}.${extension[extension.length - 1]}`, { type: file.type });
      });
      this.setState({
        status: UPLOAD_STATUS.NotVerified, errors: [], progress: 0, showTestModal: true,
      }, () => this.props.uploadQuestions(sendFiles).then((data) => {
        const toastMessage = data.errors && data.errors.length === 0 ? REPORT_ISSUE_TOAST_MESSAGES.VERIFIED
          : REPORT_ISSUE_TOAST_MESSAGES.ERRORS;
        if (data.success) {
          this.setState({ errors: data.errors, status: data.status, progress: 0 }, () => {
            this.props.showToast(toastMessage);
          });
        } else {
          this.setState({ status: UPLOAD_STATUS.UnUpload }, () => {
            this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPLOAD_ERROR);
          });
        }
      }));
    }
  }

  getTestQuestionFile(canUpload) {
    if (canUpload) {
      document.getElementById('uploadTestQuestionFile').click();
    } else {
      this.setState({ showTestModal: true });
      this.props.showToast('Check File Status. Your Questions Are Being Uploaded. You Cant Upload New File Now.');
    }
  }

  renderTestModal() {
    const { errors, status } = this.state;
    const canUpload = !([UPLOAD_STATUS.Progressing, UPLOAD_STATUS.Verified, UPLOAD_STATUS.NotVerified].includes(status)) && errors.length === 0;
    return (
      <div style={{
        padding: 65, pointerEvents: !canUpload, cursor: canUpload ? 'pointer' : 'not-allowed', margin: '60px 0px 30px', textAlign: 'center', border: '1px solid black', height: 460, display: 'flex', alignItems: 'center', flexDirection: 'column',
      }}
      >
        <div style={{ fontSize: StyleConstants.textSize.primaryHeader }}>Upload Your Files</div>
        <div style={{ display: 'flex', marginTop: 50 }}>
          <div>Download the &nbsp;</div>
          <a href={sampleSheet} download>template</a>
        </div>
        <div
          role="presentation"
          style={{
            textAlign: 'center', display: 'flex', padding: 20, margin: '20px 0', border: '2px dashed #527192', background: '#F6F8FB', width: '100%', borderRadius: 4,
          }}
          onClick={() => this.getTestQuestionFile(canUpload)}
        >
          <img src={Upload} alt="" width="100px" />
          <div style={{ color: '#95ABC4', fontSize: StyleConstants.textSize.tertiaryHeader, width: '100%' }}>
            <p>Drag and Drop or Choose the file to upload your data.</p>
            <p>All .csv, .xlsx, .xls, .docx file types are supported</p>
          </div>
        </div>
        <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
          <input id="uploadTestQuestionFile" type="file" name="result" accept=".xlsx,.docx" multiple="true" onChange={(event) => this.uploadTestQuestion(event)} />
        </div>
      </div>
    );
  }

  showSuccess(isCompleted) {
    const text = isCompleted ? 'Questions Imported Successfuly' : 'Failed to Upload Questions, Due to Errors In the File. Try Again';
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ margin: '10%', textAlign: 'center' }}><Text style={isCompleted ? SUCCESS : ERROR} text={text} /></div>
        <div className="Button Primary-Button" style={{ width: '50%' }} onClick={() => this.getTestQuestionFile(true)}>Upload Again</div>
      </div>
    );
  }

  render() {
    const { errors, status, showTestModal } = this.state;
    const canUpload = !([UPLOAD_STATUS.Progressing, UPLOAD_STATUS.Verified, UPLOAD_STATUS.NotVerified].includes(status)) && errors.length === 0;
    const isCompleted = status === UPLOAD_STATUS.Complete;
    const header = status === UPLOAD_STATUS.Error ? 'Error Report' : 'Processing Result';
    return (
      <div>
        <div style={{ margin: '0px 115px' }}>
          {this.renderTestModal()}
          <div className="Button Primary-Button" style={{ width: 'fit-content', padding: '0px 10px', marginLeft: 'auto' }} onClick={() => this.setState({ showTestModal: true })}>Check File status</div>
          <Modal center isModalVisible={showTestModal} handleCloseModal={() => this.setState({ showTestModal: false })} header={header} top="25%" headerHeight="50px">
            {canUpload ? this.showSuccess(isCompleted)
              : errors.length === 0 ? this.showProgress() : this.showUploadedErrors()}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});
export default connect(mapStateToProps, { uploadQuestions, showToast, getInstituteUploadStatus })(ImportQuestions);
