import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Close from '../../shared/images/common/close_black.svg';

class ModalView extends PureComponent {
  render() {
    const {
      isModalVisible, children, header, maxWidth, headerStyle, headerHeight, height, width, iconHeight, iconWidth, handleCloseModal,
    } = this.props;
    return (
      <Modal
        center
        open={isModalVisible}
        onClose={handleCloseModal}
        styles={{
          closeIcon: {
            opacity: 0.5,
            cursor: 'pointer',
            width: iconHeight,
            height: iconWidth,
            position: 'absolute',
            right: 0,
            top: 10,
          },

          modal: {
            padding: '20px 30px 30px 30px',
            height,
            width,
            minWidth: 300,
            maxWidth,
            borderRadius: 4,
          },
          closeButton: {
            display: 'none',
          },
        }}
        classNames={{ closeButton: false }}
      >
        {header && (
          <div
            className="d-flex justify-content-between"
            style={{
              borderBottom: '1px solid #efefef', alignItems: 'center', display: 'flex', height: headerHeight || '', justifyContent: 'space-between',
            }}
          >
            <b style={{ fontSize: 18, ...headerStyle, whiteSpace: 'pre-wrap' }}>
              {' '}
              {header}
              {' '}
            </b>
            <img
              src={Close}
              alt=""
              style={{
                height: '10.5px', width: '10.5px', color: '#43464B', float: 'right', cursor: 'pointer',
              }}
              onClick={() => handleCloseModal()}
            />
          </div>
        )}
        {children}
      </Modal>
    );
  }
}

ModalView.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  image: PropTypes.string,
  isModalVisible: PropTypes.bool,
};

ModalView.defaultProps = {
  isModalVisible: false,
  maxWidth: 600,
  iconHeight: 20,
  iconWidth: 20,
  image: '',
};

export default ModalView;
