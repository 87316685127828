import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loader from './common/loader';
import Text from './common/text';
import Header from './common/header';
import StyleConstants from '../shared/constants/styleConstants/styles';
import DashBoard from './contents/dashBoard';
import Browse from './contents/browse';
import Search from './contents/search';
import ComprehensionQuestion from './comprehensionQuestion';
import Tools from './contents/tools';
import NewQuestion from './newQuestion';
import { SnackBar } from './common';
import Feedback from './contents/feedback';
import NotifyServerMaintenance from './notifyServerMaintenance';
import BriefMe from './contents/briefMe';
import BriefMeBrowse from './contents/briefMeBrowse';

const history = createBrowserHistory({
  forceRefresh: true,
});

class RouteNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: '',
    };
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    this.forceUpdate();
  }

  renderToast() {
    // Do customize toast before use - This is just a sample Toast
    return (
      <div style={{
        bottom: 40, position: 'fixed', display: 'flex', justifyContent: 'center', width: window.innerWidth * 0.8, zIndex: 1001, padding: '5px 10px 5px 10px',
      }}
      >
        <Text text={this.props.toastMessage} style={{ color: StyleConstants.color.white }} />
      </div>
    );
  }

  renderServerError() {
    return (
      <div style={{ paddingTop: 100, display: 'flex', justifyContent: 'center' }}>
        <Text text="Sorry something went wrong, please check your internet connectivity or try again after sometime." style={{ fontSize: StyleConstants.textSize.headerSmall }} />
      </div>
    );
  }

  renderRouterContent() {
    const { userProfile, pointerType } = this.props;
    const briefPath = 'brief_me';
    const isBriefPath = briefPath === window.location.pathname.split('/')[1];
    if (userProfile.has_only_brief_me_access && !isBriefPath) {
      return history.push(`/${briefPath}`);
    }
    return (
      <div className="App" style={{ cursor: pointerType }}>
        <SnackBar />
        <Switch>
          <Route exact path="/" render={(props) => <DashBoard {...props} userProfile={userProfile} />} />
          <Route path="/dashboard" component={DashBoard} />
          <Route path="/browse" component={Browse} />
          <Route path="/search" component={Search} />
          <Route path="/brief_me/browse" component={BriefMeBrowse} />
          <Route path="/brief_me/:briefType" render={(props) => <BriefMe {...props} userProfile={userProfile} />} />
          <Route path="/brief_me" component={BriefMeBrowse} />
          <Route path="/comprehension_questions/edit/:id" render={(props) => <ComprehensionQuestion {...props} userProfile={userProfile} />} />
          <Route path="/questions/edit/:id" render={(props) => <NewQuestion {...props} userProfile={userProfile} />} />
          <Route path="/tools/:toolType/:type" render={(props) => <Tools {...props} userProfile={userProfile} />} />
          <Route path="/tools/:toolType" render={(props) => <Tools {...props} userProfile={userProfile} />} />
          <Route path="/feedback/:type" render={(props) => <Feedback {...props} userProfile={userProfile} />} />
          <Route path="/tools" component={Tools} />
          <Route path="/track_comparision/:id" />
        </Switch>
      </div>
    );
  }

  renderBody(isServerUnderMaintenance, isServerError, loaderState) {
    if (isServerUnderMaintenance) {
      return <NotifyServerMaintenance />;
    }
    if (isServerError) {
      return this.renderServerError();
    }
    if (loaderState) {
      return <Loader />;
    }
    return this.renderRouterContent();
  }

  render() {
    const { screenHeight } = this.state;
    const {
      showToast, isServerError, isServerUnderMaintenance, loaderState,
    } = this.props;
    const showHeader = isServerError || loaderState;
    return (
      <Router>
        <div className="App" style={{ minHeight: screenHeight, backgroundColor: StyleConstants.backgroundColor.default }}>
          {showHeader && <Header />}
          {showToast && this.renderToast()}
          {this.renderBody(isServerUnderMaintenance, isServerError, loaderState)}
        </div>
      </Router>
    );
  }
}

RouteNavigation.propTypes = {
  isServerError: PropTypes.bool,
  isServerUnderMaintenance: PropTypes.bool,
  loaderState: PropTypes.bool,
  showToast: PropTypes.bool,
  toastMessage: PropTypes.string,
  toastMessageType: PropTypes.string,
  userProfile: PropTypes.object,
  notifications: PropTypes.array,
};

RouteNavigation.defaultProps = {
  isServerError: false,
  isServerUnderMaintenance: false,
  loaderState: false,
  showToast: false,
  toastMessage: '',
  toastMessageType: '',
  userProfile: {},
  notifications: [],
};

const mapStateToProps = ({ global }) => ({
  isServerError: global.isServerError,
  isServerUnderMaintenance: global.isServerUnderMaintenance,
  loaderState: global.loaderState,
  toastMessage: global.toastMessage,
  showToast: global.showToast,
  toastMessageType: global.toastMessageType,
  pointerType: global.pointerType,
});

export default connect(mapStateToProps)(RouteNavigation);
