import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StyleConstants from '../../shared/constants/styleConstants/styles';
import { DropDown, Text } from './index';
import { updateQuestionReducer, showToast } from '../../actions';
import { toastMessages } from '../../shared/constants/fieldTypes';

class QuestionFilter extends PureComponent {
  renderDropdown(dropDownOptions) {
    return (
      <div style={{ display: 'flex' }}>
        <Text fontSize={14} style={{ alignSelf: 'center', marginRight: 20 }} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text={`${dropDownOptions.optionType}:`} />
        <DropDown
          key={dropDownOptions.optionList.length}
          selectedItem={dropDownOptions.selectedItem}
          optionsList={dropDownOptions.optionList}
          optionType={dropDownOptions.optionType}
          width={dropDownOptions.width || 150}
          style={{ marginTop: 10 }}
          color={StyleConstants.color.dark}
          onValueChange={dropDownOptions.onValueChange}
        />
      </div>
    );
  }

  renderSearchButton() {
    const { question: { appliedFilters, enableFilterSearch } } = this.props;
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        style={{
				  width: '200px', margin: '10px 0px', cursor: 'pointer', opacity: enableFilterSearch ? 1 : 0.5,
        }}
        onClick={() => {
				  if (enableFilterSearch) {
				    this.props.updateQuestionReducer({ enableFilterSearch: false });
				    this.props.filterFunction();
				  } else if (appliedFilters.course.id) {
            this.props.showToast(toastMessages.alreadyFiltered);
          } else {
            this.props.showToast(toastMessages.noFilterSelected);
          }
        }}
      >
        Search
      </div>
    );
  }

  renderFilters() {
    const {
      question: {
        appliedFilters, courses, subjects, chapters,
      },
    } = this.props;
    const courseDropDownOptions = {
      selectedItem: appliedFilters.course,
      optionList: courses,
      optionType: 'Courses',
      onValueChange: (id, name) => {
        this.props.updateQuestionReducer({
          appliedFilters: {
            course: { id: id || '', name: name || '' },
            subject: { id: '' },
            chapter: { id: '' },
          },
          enableFilterSearch: true,
        });
      },
    };
    const subjectDropDownOptions = {
      selectedItem: appliedFilters.subject,
      optionList: appliedFilters.course.id ? subjects[appliedFilters.course.id] : [],
      optionType: 'Subjects',
      onValueChange: (id, name) => {
        this.props.updateQuestionReducer({
          appliedFilters: {
            course: appliedFilters.course,
            subject: { id: id || '', name: name || '' },
            chapter: { id: '' },
          },
          enableFilterSearch: true,
        });
      },
    };
    const chapterDropDownOptions = {
      selectedItem: appliedFilters.chapter,
      optionList: (!!appliedFilters.course.id && !!appliedFilters.subject.id) ? chapters[`${appliedFilters.course.id}_${appliedFilters.subject.id}`] : {},
      optionType: 'Chapters',
      width: 300,
      onValueChange: (id, name) => {
        this.props.updateQuestionReducer({
          appliedFilters: {
            course: appliedFilters.course,
            subject: appliedFilters.subject,
            chapter: { id: id || '', name: name || '' },
          },
          enableFilterSearch: true,
        });
      },
    };
    return (
      <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
        {this.renderDropdown(courseDropDownOptions)}
        {this.renderDropdown(subjectDropDownOptions)}
        {this.renderDropdown(chapterDropDownOptions)}
        {this.renderSearchButton()}
      </div>
    );
  }

  render() {
    return (
      this.renderFilters()
    );
  }
}

QuestionFilter.propTypes = {
  filterFunction: PropTypes.func.isRequired,
};

QuestionFilter.defaultProps = {
};

const mapStateToProps = ({ question }) => ({
  question,
});

export default connect(mapStateToProps, { updateQuestionReducer, showToast })(QuestionFilter);
