import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { showToast, updateBriefReducer } from '../actions';
import NewBriefQuestion from './newBriefQuestion';
import { Text } from './common';

class BriefQuestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textChanges: {
        id: '',
        question_text: '',
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        answer: '',
      },
      editableQuestionId: 0,
    };
    this.createNewQuestion = this.createNewQuestion.bind(this);
  }

  createNewQuestion() {
    return (
      <div
        className="edit-button"
        role="presentation"
        style={{
          height: 35, marginBottom: 0, width: 150, backgroundColor: '#3994AE',
        }}
        onClick={() => { this.props.updateBriefReducer({ isAddBriefQuestion: true }); }}
      >
        New Question
      </div>
    );
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges });
  }

  renderTextArea(textType) {
    const type = textType.toLowerCase().split(' ').join('_');
    const { textChanges } = this.state;
    return (
      <textarea
        type="text"
        placeholder={type}
        value={textChanges[type]}
        id={type}
        style={{
          fontWeight: '400', display: 'flex', fontSize: '14px', width: (type === 'answer') ? '20%' : '100%', minHeight: (type === 'answer') && 'fit-content', padding: (type !== 'answer') && 10, overflow: 'auto', resize: 'none',
        }}
        onChange={(event) => this.handleChange(type, event.target)}
      />
    );
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  render() {
    const {
      briefQuestionList, briefPreviousYearQuestionList, isAddBriefQuestion, updatedCount, shownBriefIds, removedIds, removedPreviousYearQIds, id, style, selectedIndex, selectedBrief, isPreviousYearQuestionView,
    } = this.props;
    const { editableQuestionId } = this.state;

    const questionList = isPreviousYearQuestionView ? [...briefPreviousYearQuestionList] : [...briefQuestionList];
    const idsRemoved = isPreviousYearQuestionView ? [...removedPreviousYearQIds] : [...removedIds];
    let changesAfterClick = { selectedIndex: null, selectedBriefId: null, isBriefSelected: false };
    changesAfterClick = isPreviousYearQuestionView ? { ...changesAfterClick, showBriefPreviousYearQuestionView: false } : { ...changesAfterClick, showBriefQuestionView: false };

    return (
      <div
        role="presentation"
        className="Card-View"
        style={{
          fontSize: 16, position: 'fixed', top: 0, bottom: 0, right: 0, ...style, overflow: 'auto',
        }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15,
        }}
        >
          <div
            role="presentation"
            style={{
              height: 20, width: 20, border: '1px solid #000', borderRadius: 50, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            onClick={() => this.props.updateBriefReducer(changesAfterClick)}
          >
            <img src="/images/common/x-mark.svg" alt="close" style={{ height: 10 }} />
          </div>
          {this.renderTitle(`Position: ${selectedIndex + 1}`)}
          {!isPreviousYearQuestionView && this.createNewQuestion()}
        </div>
        {isAddBriefQuestion && !isPreviousYearQuestionView && (
        <div
          role="presentation"
          onClick={() => this.setState({ editableQuestionId: 0 })}
        >
          <NewBriefQuestion
            id={id}
            updatedCount={updatedCount}
            selectedBrief={selectedBrief}
            edit={() => this.setState({ editableQuestionId: 0 })}
            renderPublishTestModal={this.renderPublishTestModal}
            removeNewBriefQuestion={this.props.removeNewBriefQuestion}
            isEditable={editableQuestionId === 0}
            resetEdit={() => this.setState({ editableQuestionId: 0 })}
            isNewBriefQuestion
          />
        </div>
        )}
        {(questionList.map((BriefQuestion, index) => (
          !idsRemoved.includes(BriefQuestion.id) && (
          <NewBriefQuestion
            id={id}
            question={BriefQuestion}
            updatedCount={updatedCount}
            selectedBrief={selectedBrief}
            resetEdit={() => this.setState({ editableQuestionId: 0 })}
            key={`${BriefQuestion.id} - ${updatedCount}`}
            index={index + 1}
            isEditable={isPreviousYearQuestionView ? false : editableQuestionId === BriefQuestion.id}
            shownBriefIds={shownBriefIds}
            edit={() => {
              this.setState({ editableQuestionId: BriefQuestion.id });
            }}
            renderPublishTestModal={this.renderPublishTestModal}
            isPreviousYearQuestionView={isPreviousYearQuestionView}
          />
          )
        )))}
        {isPreviousYearQuestionView && briefPreviousYearQuestionList.length === 0 && (
          <div style={{ margin: 'auto' }}>
            No Previous Year Questions are tagged to this Brief
          </div>
        )}
      </div>
    );
  }
}

BriefQuestion.propTypes = {
  briefs: PropTypes.object,
  briefQuestionList: PropTypes.array,
  isAddBriefQuestion: PropTypes.bool,
  removedIds: PropTypes.array,
  shownBriefIds: PropTypes.array,
  updatedCount: PropTypes.number,
  id: PropTypes.number,
  style: PropTypes.object,
  selectedBriefId: PropTypes.number,
};

BriefQuestion.defaultProps = {
  briefs: {},
  briefQuestionList: [],
  shownBriefIds: [],
  isAddBriefQuestion: false,
  removedIds: [],
  updatedCount: 0,
  style: {},
  id: 0,
  selectedBriefId: 0,
};

const mapStateToProps = ({ briefMe }) => ({
  briefs: briefMe.briefs,
  briefQuestionList: briefMe.briefQuestionList,
  briefPreviousYearQuestionList: briefMe.briefPreviousYearQuestionList,
  shownBriefIds: briefMe.shownBriefIds,
  removedIds: briefMe.removedIds,
  removedPreviousYearQIds: briefMe.removedPreviousYearQIds,
  updatedCount: briefMe.updatedCount,
  selectedBriefId: briefMe.selectedBriefId,
  selectedIndex: briefMe.selectedIndex,
  selectedBrief: briefMe.selectedBrief,
});

export default connect(mapStateToProps, {
  showToast,
  updateBriefReducer,
})(BriefQuestion);
