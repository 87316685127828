import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import { DropDown, Text } from './index';
import { updateQuickpointReducer, showToast } from '../../actions';
import { toastMessages } from '../../shared/constants/fieldTypes';

class QuickpointFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  renderDropdown(dropDownOptions) {
    return (
      <div style={{ display: 'flex' }}>
        <Text fontSize={14} style={{ alignSelf: 'center', marginRight: 20 }} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text={`${dropDownOptions.optionType}:`} />
        <DropDown
          key={dropDownOptions.optionList.length}
          selectedItem={dropDownOptions.selectedItem}
          optionsList={dropDownOptions.optionList}
          optionType={dropDownOptions.optionType}
          width={dropDownOptions.width || 150}
          style={{ marginTop: 10 }}
          color={StyleConstants.color.dark}
          onValueChange={dropDownOptions.onValueChange}
        />
      </div>
    );
  }

  handleClick() {
    const { cheatsheetList: { appliedFilters, enableFilterSearch } } = this.props;
    if (enableFilterSearch) {
      this.props.updateQuickpointReducer({ enableFilterSearch: false, appliedFilters, addParams: true });
      this.props.filterFunction();
    } else if (!!appliedFilters.course.id && !!appliedFilters.subject.id && !!appliedFilters.chapter.id) {
      this.props.showToast(toastMessages.alreadyFiltered);
    } else {
      this.props.showToast(toastMessages.noQuickpointFilterSelected);
    }
  }

  renderSearchButton() {
    const { cheatsheetList: { enableFilterSearch } } = this.props;
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        style={{
				  width: '200px', margin: '10px 0px', height: 40, opacity: enableFilterSearch ? 1 : 0.5,
        }}
        onClick={this.handleClick}
      >
        Search
      </div>
    );
  }

  render() {
    const {
      cheatsheetList: {
        appliedFilters, courses, subjects, chapters,
      },
    } = this.props;
    const courseDropDownOptions = {
      selectedItem: appliedFilters.course,
      optionList: courses,
      optionType: 'Course',
      onValueChange: (id, name) => {
        this.props.updateQuickpointReducer({
          appliedFilters: {
            course: { id: id || '', name: name || '' },
            subject: {},
            chapter: {},
          },
          enableFilterSearch: false,
          selectedIndex: '',
          isQuickpointSelected: false,
        });
      },
    };
    const subjectDropDownOptions = {
      selectedItem: appliedFilters.subject,
      optionList: appliedFilters.course.id ? subjects[appliedFilters.course.id] : [],
      optionType: 'Subject',
      onValueChange: (id, name) => {
        this.props.updateQuickpointReducer({
          appliedFilters: {
            course: appliedFilters.course,
            subject: { id: id || '', name: name || '' },
            chapter: {},
          },
          enableFilterSearch: false,
          selectedIndex: '',
          isQuickpointSelected: false,
        });
      },
    };
    const chapterDropDownOptions = {
      selectedItem: appliedFilters.chapter,
      optionList: (!!appliedFilters.course.id && !!appliedFilters.subject.id) ? chapters[`${appliedFilters.course.id}_${appliedFilters.subject.id}`].sort((chapterA, chapterB) => (chapterA.id > chapterB.id ? 1 : -1)).map(({ id, name }) => { return { id, name: name.substring(name.indexOf('-') + 1) }; }) : [],
      optionType: 'Chapter',
      width: 300,
      onValueChange: (id, name) => {
        this.props.updateQuickpointReducer({
          appliedFilters: {
            course: appliedFilters.course,
            subject: appliedFilters.subject,
            chapter: { id: id || '', name: name || '' },
          },
          enableFilterSearch: true,
          selectedIndex: '',
          isQuickpointSelected: false,
        });
      },
    };
    return (
      <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
        {this.renderDropdown(courseDropDownOptions)}
        {this.renderDropdown(subjectDropDownOptions)}
        {this.renderDropdown(chapterDropDownOptions)}
        {this.renderSearchButton()}
      </div>
    );
  }
}

QuickpointFilter.propTypes = {
  filterFunction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cheatsheetList }) => ({
  cheatsheetList,
});

export default connect(mapStateToProps, { updateQuickpointReducer, showToast })(QuickpointFilter);
