import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BriefQuestion from '../briefQuestions';
import {
  BriefMeTabs, EditBrief, ShowBrief,
} from './index';
import {
  updateBriefReducer,
} from '../../actions';

const MOBILE_VIEW_WIDTH = 325;
const QUESTION_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);

class BriefView extends PureComponent {
  constructor(props) {
    super(props);
    this.removeNewBriefQuestion = this.removeNewBriefQuestion.bind(this);
  }

  removeNewBriefQuestion() {
    this.props.updateBriefReducer({ isAddBriefQuestion: false });
  }

  render() {
    const {
      briefMe: {
        hasCreateSubjectAccess, selectedBrief, updatedCount, isAddBriefQuestion, selectedBriefId, showBriefQuestionView, showBriefPreviousYearQuestionView,
      }, selectedType,
    } = this.props;

    const {
      id, title, content, time_to_read: timeToRead, audio_url: audioURL, deprecated, polly_text: pollyText, message_to_improve_consistency: messageToImproveConsistency, brief_questions_count: briefQuestionsCount, brief_previous_year_questions_count: briefPreviousYearQuestionsCount, tags, meta_data: metaData, 
    } = selectedBrief;

    const briefDetails = {
      id, title, content, timeToRead, audioURL, deprecated, pollyText, index: 0, width: MOBILE_VIEW_WIDTH, briefQuestionsCount, briefPreviousYearQuestionsCount, messageToImproveConsistency, briefTags: tags, printBriefs: false, displayAddButton: false, isAudioModal: false, showBorder: false, isVersionComparison: false, isBriefSearch: true, metaData,
    };

    const style = { width: QUESTION_VIEW_WIDTH };

    return (
      <>
        {hasCreateSubjectAccess && <BriefMeTabs selectedType={selectedType} />}
        <div style={{ display: 'flex' }}>
          <ShowBrief briefDetails={briefDetails} bmChapterId={selectedBrief.bm_chapter_id} />
          <EditBrief bmChapterId={selectedBrief.bm_chapter_id} isBriefSearch />
          {selectedBriefId && (showBriefQuestionView || showBriefPreviousYearQuestionView) && (
            <BriefQuestion
              id={selectedBriefId}
              key={`${updatedCount}-${isAddBriefQuestion}`}
              style={style}
              isAddBriefQuestion={isAddBriefQuestion}
              updatedCount={updatedCount}
              removeNewBriefQuestion={this.removeNewBriefQuestion}
              isPreviousYearQuestionView={showBriefPreviousYearQuestionView}
            />
          )}
        </div>
      </>
    );
  }
}

BriefView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  updateBriefReducer,
})(BriefView);
