import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles';

class Text extends PureComponent {
  render() {
    const {
      style, text, vertical, fontSize, fontFamily, color, fontWeight,
    } = this.props;
    const defualtStyle = {
      color,
      fontSize,
      fontFamily,
      fontWeight,
      margin: 0,
      padding: 2,
      display: 'inline',
      writingMode: vertical && 'tb-rl',
    };
    if (!text) {
      return <div />;
    }
    return (
      <p style={{ ...defualtStyle, ...style }}>
        {' '}
        {text}
        {' '}
      </p>
    );
  }
}

Text.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  color: PropTypes.string,
  vertical: PropTypes.bool,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
};

Text.defaultProps = {
  style: {},
  color: StyleConstants.color.mediumDark,
  fontSize: StyleConstants.textSize.text,
  fontFamily: StyleConstants.textFont.normal,
  className: '',
  fontWeight: '0',
  vertical: false,
};

export default Text;
