import React, { PureComponent } from 'react';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import PropTypes from 'prop-types';
import Text from '../common/text';
import { logout } from '../../actions';

const cookies = new Cookies();
const userProfile = cookies.get('user');
const history = createBrowserHistory({
  forceRefresh: true,
});

class Menubar extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      hoverMenuIndex: false,
    };
  }

  static propTypes = {
    menuList: PropTypes.array,
    selectedOption: PropTypes.number,
  };

  static defaultProps = {
    menuList: [],
    selectedOption: 0,
  };

  routePage(link) {
   history.push(link);
  }

  showMenu(menu, index) {
    const onHover = this.state.hoverMenuIndex === index;
    const isSelected = this.props.selectedOption === index;

    if((menu.label === "BriefMe") && !userProfile.has_brief_me_access) {
      return <div />
    }
    
    return(
      <div 
        onMouseEnter={() => this.setState({ hoverMenuIndex: index })} 
        onMouseLeave={() => this.setState({ hoverMenuIndex: false })} 
        onClick={() => {this.routePage(menu.route)}} 
        key={index} 
        style={{ backgroundColor: (onHover || isSelected) ? StyleConstants.backgroundColor.menuLight : '',  display: 'flex', alignItems: 'center', height: 50, cursor: 'pointer', borderRadius: 2, marginTop: 5, marginBottom: 5 }}
      >
        <div style={{ backgroundColor: isSelected ? StyleConstants.color.selected : (onHover ? StyleConstants.backgroundColor.menuLight : ''), width: 5, height: 30 }} />
        <Text
          color={(onHover || isSelected) ? StyleConstants.color.white : StyleConstants.color.more}
          fontWeight={isSelected ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold}
          fontSize={StyleConstants.textSize.subHeader}
          style={{ paddingLeft: 30 }} 
          text={menu.label} />
      </div>
    );
  }

  render() {
    const { menuList, headerText } = this.props;
    return(
      <div style={{ width: 250, backgroundColor: StyleConstants.backgroundColor.menu, minHeight: '100vh', position: 'fixed', padding: 0 }}>
        <div style={{ userSelect: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60, backgroundColor: StyleConstants.backgroundColor.menuDark, marginBottom: 25 }} onClick={() => this.routePage("/")}>
          <Text 
            text='IB' 
            color={StyleConstants.color.error}
            fontWeight={StyleConstants.textWeight.bold}
            fontSize={StyleConstants.textSize.secondaryHeader}
          />
          <Text 
            text={headerText} 
            color={StyleConstants.color.white}
            fontWeight={StyleConstants.textWeight.bold}
            fontSize={StyleConstants.textSize.secondaryHeader}
            />
        </div>
        {menuList.map((menu, index) => this.showMenu(menu, index))}
        <div onClick={() => this.props.dispatch(logout())} style={{ padding: 20,  position: 'fixed', bottom: 40, fontSize: StyleConstants.textSize.subHeader, marginLeft: 50 }}><Text style={{ color: StyleConstants.color.mediumDark, fontSize: StyleConstants.textSize.subHeader, cursor: 'pointer', letterSpacing: 0.2 }} text="Log out" /></div>
      </div>
    );
  }
}

export default Menubar;