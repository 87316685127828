import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ImageToText, Text } from './common';
import blueCross from '../shared/images/common/blue-cross.svg';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import queryString from 'query-string';
import OptionView from './optionView';


const OPTION_TYPES = ['OPTION 1', 'OPTION 2', 'OPTION 3', 'OPTION 4'];
const BRIEF_QUESTION_VIEW_CONFIG = {
  MAX_IMAGE_WIDTH: 300,
  BASE_FONT_SIZE: 10,
  LENGTH_FACTOR: 7,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_1: 80,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_2: 160,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_4: 300,
  MAX_WIDTH_OPTION_LAYOUT_1: 100,
  MAX_WIDTH_OPTION_LAYOUT_2: 200,
  MAX_WIDTH_OPTION_LAYOUT_4: 451,
};

class BriefQuestionView extends PureComponent {
  constructor(props) {
    super(props);
    this.setText = this.setText.bind(this);
    this.renderTextArea = this.renderTextArea.bind(this);
    this.addImageOption = this.addImageOption.bind(this);
    this.renderText = this.renderText.bind(this);
    this.optionsLayout = this.optionsLayout.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.state = {
      options: {},
    };
  }

  setText(text, type) {
    const { textChanges } = this.props;
    const textData = `${textChanges[type]}${text}`;
    this.props.handleChange(type, textData);
  }

  renderOptions(question, index, imageWidth){
    return (
        <td
          style={{ paddingRight: 20, whiteSpace: 'nowrap' }}
          key={`option_${index}_${question.id}`}
        >
          <OptionView
          index={question['id']}
          text={question[`option_${index}`]}
          image={question[`image_option_${index}`].url}
          image_width={
            imageWidth[`image_option_width_${index}`]
              ? imageWidth[`image_option_width_${index}`]
              : 140
          }
          optionIndex={index}
          highlightAnswer={question.answer.includes(index)}
          maxWidth={
            BRIEF_QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_2
          }
         />
        </td>
    );
  }

  optionsLayout(question) {
    const { mobileView, economyMode } = this.props;
    const { options } = this.state;
    const options_array = [1,2,3,4];
    const imageWidth = (question.image_widths && !mobileView) ? question.image_widths : {};
    const effectiveOptionWidth = (12 - BRIEF_QUESTION_VIEW_CONFIG.BASE_FONT_SIZE) * BRIEF_QUESTION_VIEW_CONFIG.LENGTH_FACTOR;
    let sumOfAll = 0;
    let sumOfOneTwo = 0;
    let sumOfThreeFour = 0;
    let optionLayout = 4;

    if ( economyMode ) {
      options_array.forEach(index => {
        const currentWidth = Math.floor(
          options[index].getBoundingClientRect().width
        );
        sumOfAll += currentWidth;
        if (index == 1 || index == 2) {
          sumOfOneTwo += currentWidth;
        } else {
          sumOfThreeFour += currentWidth;
        }
      });
      if (sumOfAll <= (BRIEF_QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_1 - effectiveOptionWidth) * 4) {
        optionLayout = 1;
      } else if (sumOfOneTwo <= (BRIEF_QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_2 - effectiveOptionWidth) * 2 && sumOfThreeFour <= (BRIEF_QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_2 - effectiveOptionWidth) *  2) {
        optionLayout = 2;
      } else {
        optionLayout = 4;
      }
      if (optionLayout === 1) {
        return (
          <>
            <tr>
              <td>
                  {options_array.map(index => (
                    <div
                      key={`option_${index}_${question.id}`}
                      style={{ display: 'inline-block', marginRight: 15 }}
                    >
                      <OptionView
                        index={question['id']}
                        text={question[`option_${index}`]}
                        image={question[`image_option_${index}`].url}
                        image_width={
                          imageWidth[`image_option_width_${index}`]
                            ? imageWidth[`image_option_width_${index}`]
                            : 140
                        }
                        optionIndex={index}
                        highlightAnswer={question.answer.includes(index)}
                        maxWidth={
                          BRIEF_QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_1
                        }
                      />
                    </div>
                  ))}
              </td>
            </tr>
          </>
        );
      } else if (optionLayout === 2) {
        return (
          <>
            <tr style={{ pageBreakInside: 'auto' }}>
              <td>
                <table>
                  <tbody>
                    <tr>
                      {options_array.map(index => {
                        if (index <= 2) {
                          return(
                            this.renderOptions(question, index, imageWidth)
                          );
                        }
                      })}
                    </tr>
                    <tr>
                      {options_array.map(index => {
                        if (index > 2) {
                          return(
                            this.renderOptions(question, index, imageWidth)
                          );
                        }
                      })}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        );
      } else if (optionLayout === 4) {
        return options_array.map(index => (
          <tr key={`option_${index}_${question.id}`}>
            <td>
              <OptionView
                index={question['id']}
                text={question[`option_${index}`]}
                image={question[`image_option_${index}`].url}
                image_width={
                  imageWidth[`image_option_width_${index}`]
                    ? imageWidth[`image_option_width_${index}`]
                    : 140
                }
                optionIndex={index}
                highlightAnswer={question.answer.includes(index)}
                maxWidth={BRIEF_QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
              />
            </td>
          </tr>
        ));
      }
    } else {
      return options_array.map(index => (
        <tr key={`option_${index}_${question.id}`}>
          <td>
            <OptionView
              getRef={optionElement => {
                const options = this.state.options;
                options[index] = optionElement;
                this.setState({ options });
              }}
              index={question['id']}
              text={question[`option_${index}`]}
              image={question[`image_option_${index}`].url}
              image_width={
                imageWidth[`image_option_width_${index}`]
                  ? imageWidth[`image_option_width_${index}`]
                  : 140
              }
              optionIndex={index}
              highlightAnswer={question.answer.includes(index)}
              maxWidth={BRIEF_QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
            />
          </td>
        </tr>
      ));
    }   
  }

  getData(optionNumber, type, textType, isCorrectOption) {
    const {
      imageChanges, textChanges, isNewImage,
    } = this.props;
    const ref = `${textChanges.id}-${type}`;
    const imageOptionType = `image_option_${optionNumber + 1}`;
    let image = imageChanges[imageOptionType];
    image = isNewImage[imageOptionType] ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    if (image) {
      return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <img
            alt={`image_${optionNumber + 1}`}
            src={image}
            style={{
              height: '150px', marginLeft: '0', width: '350px', border: `5px solid ${isCorrectOption ? '#0080004A' : '#3994AE4D'}`,
            }}
          />
          <img
            role="presentation"
            onClick={() => { this.props.removeImage(optionNumber + 1); }}
            style={{
              margin: -8, cursor: 'pointer',
            }}
            alt="blue_cross"
            height="15px"
            width="15px"
            src={blueCross}
          />
        </div>
      );
    }
    return (
      <textarea
        className="New-Input"
        value={textChanges[type]}
        ref={ref}
        type="text"
        placeholder={textType}
        style={{
          fontSize: 16, width: optionNumber > -1 ? '90%' : '95%', lineHeight: '1em', minHeight: 'fit-content', overflow: 'hidden', marginBottom: 10,
        }}
        onChange={(event) => this.props.handleChange(type, event.target.value)}
      />
    );
  }

  addImageOption(image, number) {
    const type = number > 0 ? `image_option_${number}` : 'supporting_picture';
    this.props.handleChangeOnImages(type, image);
    number > 0 && this.props.handleChange(`option_${number}`, '');
  }

  renderData(optionNumber, type, isCorrectOption, optionText) {
    const { imageChanges, isNewImage, textChanges } = this.props;
    const imageOptionType = `image_option_${optionNumber + 1}`;
    const { print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);
    let image = imageChanges[imageOptionType];
    image = isNewImage[imageOptionType] ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);

    if (image) {
      return (
        <img
          alt={image}
          src={image}
          style={{
            height: '150px', marginLeft: 20, marginBottom: 20, width: '350px', border: isCorrectOption && '5px solid #0080004A',
          }}
        />
      );
    }
    return (
      <div style={{
        marginBottom: type !== 'answer' && 20,
        marginRight: optionText && 40,
        marginLeft: 10,
        overflowWrap: 'anywhere',
        color: optionText && !isCorrectOption ? '#514d4dc2' : '#454545',
        fontWeight: isCorrectOption && 'bold',
        fontFamily: isCorrectOption && 'Nunito',
        display: printChapterBriefQuestions ? 'inline-block' : 'flex',
      }}
      >
        {textChanges[type]}
      </div>
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  renderTextArea(textType, optionNumber = -1) {
    const type = textType.toLowerCase().split(' ').join('_');
    const { textChanges, isEditable } = this.props;
    const { print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);
    const optionText = optionNumber > -1 && `${optionNumber + 1})`;
    const isCorrectOption = optionText && optionNumber + 1 === parseInt(textChanges.answer, 10);
    if (!isEditable) {
      return (
        <div style={{ display: printChapterBriefQuestions ? 'inline-block' : 'flex' }}>
          {optionNumber > -1 && (
          <div style={{ whiteSpace: 'nowrap' }}>
            {optionNumber + 1}
            .
          </div>
          )}
          {this.renderData(optionNumber, type, isCorrectOption, optionText)}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {optionNumber > -1 && (
        <input
          type="checkbox"
          style={{ transform: 'scale(1.0)', marginRight: 15, cursor: 'pointer' }}
          onClick={() => this.props.handleChange('answer', optionNumber + 1)}
          checked={isCorrectOption ? 'checked' : ''}
        />
        )}
        {this.getData(optionNumber, type, textType, isCorrectOption)}
        <ImageToText
          option={type}
          number={optionNumber + 1}
          addImageOption={this.addImageOption}
          image={optionNumber > -1 && textChanges[`image_option_${optionNumber + 1}`]}
          isImageOption={optionNumber > -1}
          status="Text"
          setText={this.setText}
          clear=""
        />
      </div>
    );
  }

  renderSupportingPicture() {
    const {
      imageChanges, isNewImage, isEditable,
    } = this.props;
    let image = imageChanges.supporting_picture;
    image = isNewImage.supporting_picture ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    if (!image) {
      return <div />;
    }
    if (isEditable) {
      return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <img
            alt={image}
            src={image}
            style={{
              height: '150px', marginLeft: '0', width: '350px', border: '5px solid #3994AE4D',
            }}
          />
          <img
            role="presentation"
            onClick={() => { this.props.removeImage(0); }}
            style={{
              margin: -8, cursor: 'pointer',
            }}
            alt=""
            height="15px"
            width="15px"
            src={blueCross}
          />
        </div>
      );
    }
    return (
      <img
        alt={image}
        src={image}
        style={{
          height: '150px', marginLeft: 20, marginBottom: 30, width: '350px',
        }}
      />
    );
  }

  renderBriefQuestion(index) {
    const { briefQuestion } = this.props;
    const { print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);

    return (
      <>
        {index > 0 && (
        <div style={{ fontWeight: 'bold' }}>
          Question: &nbsp;
            {index}
        </div>
        )}
        {this.renderTextArea('QUESTION TEXT')}
        {this.renderSupportingPicture()}
        <div style={{ marginLeft: 25 }}>
         {!printChapterBriefQuestions && OPTION_TYPES.map((type, index) => {
            return this.renderTextArea(type, index);
          })}
         {printChapterBriefQuestions && this.optionsLayout(briefQuestion)}   
        </div>
        {printChapterBriefQuestions && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            {this.renderText(`ID: ${briefQuestion.id}`)}
            {this.renderText(`Brief ID: ${briefQuestion.brief_id}`)}
        </div>
        )}
      </>
    );
  }

  render() {
    const { index } = this.props;
    return this.renderBriefQuestion(index);
  }
}

BriefQuestionView.propTypes = {
  textChanges: PropTypes.object,
  index: PropTypes.number,
  isEditable: PropTypes.bool,
  imageChanges: PropTypes.object,
  briefQuestion: PropTypes.object,
  isNewImage: PropTypes.object,
};

BriefQuestionView.defaultProps = {
  textChanges: {},
  index: 0,
  isEditable: false,
  briefQuestion: {},
  isNewImage: {},
  imageChanges: {},
};

export default BriefQuestionView;
