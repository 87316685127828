import { GET_QUESTION_DATA, SET_AUTO_DIFFICULTIES_DATA, SET_QUESTION_FILTER_DATA } from '../actions';

const initialState = {
  questionData: {},
  redirectReportPage: false,
  isUserSuggestionAllowed: true,
  appliedFilters: {
    course: { id: '' },
    subject: { id: '' },
    chapter: { id: '' },
  },
  courses: [],
  subjects: {},
  chapters: {},
  questionTypes: {},
  comprehensionIds: {},
  enableFilterSearch: false,
  questionsWithMismatchedPredictions: [],
  totalMismatchedPredictions: 0,
  isLoading: false,
  comprehensiveQuestions: [],
  autoTagPageIndex: 1,
  pageIndex: 1,
  questions: {},
  totalQuestionCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_AUTO_DIFFICULTIES_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_QUESTION_FILTER_DATA: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
