import {
  UPDATE_BRIFES_DICTIONARY, RESET_DICTIONARY_ITEM,
} from '../actions';

const initialState = {
  appliedFilters: {
    subject: {},
    chapter: {},
    briefOperator: {},
    briefHealth: null,
    briefHealthSecondary: null,
  },
  subjects: [],
  dictionary: [],
  selectedIndex: 0,
  selectedDictionaryItem: null,
  isDictionaryItemSelected: false,
  term: '',
  description: '',
  isDictionarySearch: false,
  displayEditView: false,
  isChanged: false,
  bmSubjectId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_BRIFES_DICTIONARY:
      return { ...state, ...action.payload };
    case RESET_DICTIONARY_ITEM:
      const resetItem = {
        selectedIndex: 0,
        selectedDictionaryItem: null,
        isDictionaryItemSelected: false,
        term: '',
        description: '',
      };
      return { ...state, ...resetItem, ...action.payload };
    default:
      return state;
  }
}
