import {
  ADD_BRIEFS, UPDATE_BRIEFS, ORDER_BRIEFS, GET_BRIEFS, EDIT_DETAILS, DELETE_DETAILS, UPDATE_BRIEF_FILTER_DATA, DELETE_BRIEF, TOGGLE_DEPRECATE, UPDATE_BRIEF_ME_HEALTH_FILTER, UPDATE_BRIEF_ME_FILTER_LIST,
  UPDATE_BRIEFS_OPERATOR_PRODUCTIVITY_FILTER, UPDATE_FILTER_TAGS, UPDATE_ACTIVE_FILTER, MAIN_FILTER_TYPES, UPDATE_AUDIO_DETAILS, UPDATE_AUDIO_URL, REPLACE_BRIEF_TEXT, APPEND_NEW_QUESTION, REMOVE_QUESTIONS, UPDATE_BRIEF_META_DATA, CLEAR_IMAGE, 
} from '../actions';

const initialState = {
  appliedFilters: {
    subject: {},
    chapter: {},
    briefOperator: {},
    briefHealth: null,
    briefHealthSecondary: null,
  },
  briefMeHealth: {},
  filtersList: {
    briefHealth: [{ id: MAIN_FILTER_TYPES.OVERALL, name: 'Overall' }, { id: MAIN_FILTER_TYPES.COURSE, name: 'Course' }, { id: MAIN_FILTER_TYPES.SUBJECT, name: 'Subject' }],
    briefHealthSecondary: [],
    briefOperator: [{ id: 1, name: 'Daily' }, { id: 2, name: 'Weekly' }, { id: 3, name: 'Monthly' }],
  },
  feedback: {},
  subjects: [],
  chapters: {},
  bmCourses: [],
  semesters: {},
  bmSubjects: [],
  bmChapters: [],
  selectedSubjectStatus: '',
  selectedChapterStatus: '',
  overallDetails: {},
  briefsHealthData: {},
  courseNameForIssueTypeCheck: '',
  formattedBriefsHealthData: [],
  briefsOperatorProductivityData: {},
  briefs: {},
  formulas: {},
  diagrams: {},
  briefVersions: [],
  versionDifferences: {},
  newerBrief: '',
  olderBrief: '',
  newerVersionCreator: '',
  olderVersionCreator: '',
  membershipsWithPermission: [],
  isAdmin: false,
  hasCreateSubjectAccess: false,
  hasModifyBriefAccess: false,
  hasBriefMeAccess: null,
  selectedChapterToDelete: { id: '', name: '' },
  briefOrder: [],
  title: '',
  content: '',
  audioURL: '',
  imageURL: '',
  timeToRead: '',
  pollyText: '<speak> Enter the text here </speak>',
  deprecated: false,
  selectedBriefId: null,
  isChanged: false,
  isLoading: false,
  selectedIndex: null,
  isOrderBeingUpdated: true,
  isBriefSelected: false,
  displayEditView: false,
  isUpdating: false,
  isPlayButtonClicked: false,
  changedSubjectName: '',
  changedChapterName: '',
  searchedBriefs: [],
  selectedBrief: '',
  showInconsistentBriefs: false,
  rating: {},
  updatedCount: 0,
  removedIds: [],
  removedPreviousYearQIds: [],
  briefQuestionList: [],
  briefQuestionListForChapter: [],
  briefPreviousYearQuestionList: [],
  isAddBriefQuestion: false,
  showBriefQuestionView: false,
  showBriefPreviousYearQuestionView: false,
  briefTags: [],
  briefOrderWithIndex: [],
  briefCursorIndex: null,
  parentIds: [],
  parentId: null,
  selectedTags: [],
  previousYearQuestionId: '',
  autoFormat: {
    isLaoding: false,
    content: '',
  },
  metaData: {},
};

function addBrief(response) {
  return { briefs: response.briefs, briefOrder: response.brief_order };
}

function updateBriefData(oldBriefs, response) {
  if (response.isBriefSearched) {
    const selectedBrief = response.brief;
    return { selectedBrief };
  }
  oldBriefs[response.id] = response.brief;
  return { briefs: oldBriefs };
}

function orderBriefs(response) {
  return { briefs: response.briefs, briefOrder: response.brief_order };
}

function toggleDeprecate(oldBriefs, selectedBrief, response) {
  if (response.isBriefSearched) {
    selectedBrief.deprecated = response.deprecated;
    return { selectedBrief };
  }
  oldBriefs[response.id].deprecated = response.deprecated;
  return { briefs: oldBriefs };
}

function deleteBrief({ briefs, briefOrder }, response) {
  if (response.isBriefSearched) {
    const selectedBrief = '';
    return { selectedBrief };
  }
  delete briefs[response.id];
  const index = briefOrder.indexOf(response.id);
  briefOrder.splice(index, 1);
  return { briefs: { ...briefs }, briefOrder: [...briefOrder] };
}

function editName(overallDetails, response) {
  if (response.type === 'Subject') {
    overallDetails.selectedBmSubject.name = response.name;
  } else {
    overallDetails.selectedBmChapter.name = response.name;
  }
  return { overallDetails: { ...overallDetails } };
}

function updateAudioDetails({ briefs }, response) {
  briefs[response.id] = response.data;
  const selectedBriefId = response.id;
  const timeToRead = response.data.time_to_read;
  const audioURL = response.data.audio_url;
  return {
    briefs: { ...briefs }, selectedBriefId, isBriefSelected: true, timeToRead, audioURL,
  };
}

export default function (state = initialState, action) {
  const newState = { ...state };
  newState.updatedCount += 1;
  switch (action.type) {
    case GET_BRIEFS: {
      return { ...state, ...action.payload };
    }
    case UPDATE_FILTER_TAGS: {
      return { ...state, filtersList: { ...state.filtersList, ...action.payload } };
    }
    case UPDATE_ACTIVE_FILTER: {
      return { ...state, appliedFilters: { ...state.appliedFilters, ...action.payload } };
    }
    case UPDATE_BRIEF_FILTER_DATA: {
      return { ...state, ...action.payload };
    }
    case EDIT_DETAILS: {
      return { ...state, ...action.payload, ...editName(state.overallDetails, action.payload) };
    }
    case DELETE_DETAILS: {
      return { ...state, ...action.payload };
    }
    case UPDATE_BRIEF_ME_FILTER_LIST: {
      return { ...state, filtersList: { ...state.filtersList, ...action.payload } };
    }
    case UPDATE_BRIEFS_OPERATOR_PRODUCTIVITY_FILTER: {
      return { ...state, appliedFilters: { ...state.appliedFilters, briefOperator: action.payload } };
    }
    case UPDATE_BRIEF_ME_HEALTH_FILTER: {
      return { ...state, appliedFilters: { ...state.appliedFilters, briefHealth: action.payload } };
    }
    case ADD_BRIEFS: {
      return { ...state, ...addBrief(action.payload) };
    }
    case UPDATE_BRIEFS: {
      return { ...state, ...updateBriefData(state.briefs, action.payload) };
    }
    case ORDER_BRIEFS: {
      return { ...state, ...orderBriefs(action.payload) };
    }
    case TOGGLE_DEPRECATE: {
      return { ...state, ...toggleDeprecate(state.briefs, state.selectedBrief, action.payload) };
    }
    case DELETE_BRIEF: {
      return { ...state, ...deleteBrief(state, action.payload) };
    }
    case REPLACE_BRIEF_TEXT: {
      return { ...state, searchedBriefs: [...state.searchedBriefs.filter((brief) => { return brief.id !== action.payload.id; })] };
    }
    case UPDATE_AUDIO_DETAILS: {
      return { ...state, ...updateAudioDetails(state, action.payload) };
    }
    case UPDATE_AUDIO_URL: {
      const audioURL = action.payload.url;
      const isPlayButtonClicked = true;
      return { ...state, audioURL, isPlayButtonClicked };
    }
    case APPEND_NEW_QUESTION: {
      if (action.payload.textChanges) {
        if (action.payload.isBriefSearched) {
          newState.selectedBrief.brief_questions_count += 1;
        } else {
          newState.briefs[action.payload.id].brief_questions_count += 1;
        }
      }
      return {
        ...newState,
      };
    }
    case REMOVE_QUESTIONS: {
      if (action.payload.isPreviousYearQuestionView) {
        newState.removedPreviousYearQIds = [...newState.removedPreviousYearQIds, action.payload.questionId];
        newState.removedPreviousYearQIds = [...newState.removedPreviousYearQIds];
      } else {
        newState.removedIds = [...newState.removedIds, action.payload.questionId];
        newState.removedIds = [...newState.removedIds];
      }
      if (action.payload.success) {
        if (action.payload.isBriefSearched) {
          if (action.payload.isPreviousYearQuestionView) {
            newState.selectedBrief.brief_previous_year_questions_count -= 1;
          } else {
            newState.selectedBrief.brief_questions_count -= 1;
          }
        } else if (action.payload.isPreviousYearQuestionView) {
          newState.briefs[action.payload.briefId].brief_previous_year_questions_count -= 1;
        } else {
          newState.briefs[action.payload.briefId].brief_questions_count -= 1;
        }
      }
      return {
        ...newState,
      };
    }
    case UPDATE_BRIEF_META_DATA: {
      const {
        briefId, metaData, isChanged, content,
      } = action.payload;
      newState.briefs[briefId].meta_data = metaData;
      newState.briefs[briefId].content = content;
      newState.metaData = metaData;
      newState.isChanged = isChanged;
      newState.content = content;
      return { ...newState };
    }
    case CLEAR_IMAGE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
