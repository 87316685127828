export const TOAST_MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const SHOW_TOAST = '@SnackBar/SHOW_TOAST';
export const HIDE_TOAST = '@SnackBar/HIDE_TOAST';

export const REPORT_ISSUE_TOAST_MESSAGES = {
  NOT_SELECTED: 'Atleast one change should be done before updating and Question data should not be empty',
  UPDATED: 'Question Updated Successfully',
  PROGRESS: 'Questions are getting Uploaded',
  SELECT_SUBJECT: 'Subject Should Be Selected',
  QUESTION_TYPE: 'Question Type Should Be Selected',
  OPTION_FILLED: 'All Four Options Should Be Filled and Should Be Unique',
  QUESTION_TEXT: 'Question Text Should Not Be Empty',
  ANSWERS: 'Atleast One Option Should Be Selected',
  NOT_UPDATED: 'Failed To Update A Question',
  SELECTED: 'Issues will be resolved soon !',
  UPLOAD_ERROR: 'Failed To Upload Questions',
  ERRORS: 'Solve all errors mentioned before uploading',
  RICH_RESPONSE: 'Numerical Answer Should Be Present For Numerical Question',
  NOTES: 'Notes Should Be Present For Numerical Question',
  MULTIPLE_CHOICE: 'Atleast Two Options Should Be Selected For Multiple Choice Question',
  RICH_RESPONSE_VALID: 'Numerical Answer Should be a Number',
  COMPREHENSION_PRESENT: 'Comprehension Id should be present',
  COMPREHENSION_INVALID: 'Comprehsion Id Provided Is Invalid',
  UPDATE_COMPLETED: 'Changes Already Updated',
  VERIFIED: 'Questions Are Verified, It Will Be Uploaded Soon',
  NOTVERIFIED: 'Uploaded Data is Being Verified',
};

export const REMOVED_STATUS = {
  SUCCESS: 'Question Remove Successfully',
  ERROR: 'Failed To Remove A Question',
};

export const DIFFICULTY_VALUE_MAPPING = [null, 'EASY', 'EASY', 'MEDIUM', 'HARD', 'HARD'];

export const DIFFICULTY = {
  EASY: 'E',
  MEDIUM: 'M',
  HARD: 'H',
};

export const DIFFICULTY_COLOR = {
  EASY: '#49BEB7',
  MEDIUM: '#FFB038',
  HARD: '#FF5F5F',
};

export const QUESTION_TEXT_TYPES = ['question_text', 'option_1', 'option_2', 'option_3', 'option_4', 'solution_text', 'rich_response', 'notes', 'comprehension_question_id'];

export const TEXT_CHANGES_TYPE = {
  NO_ISSUE: 0,
  QUESTION_TEXT: 5,
  SOLUTION_TEXT: 6,
  IMAGE: 7,
  ANSWER: 8,
  MULTIPLE: 9,
  RICH_RESPONSE: 10,
  NOTES: 11,
  COMPREHENSION_QUESTION_ID: 12,
  SUBJECT: 13,
  QUESTION_TYPE: 14,
  ERROR: 15,
  UPDATED: 16,
};

export const REPORT_ISSUE_TYPES = {
  1: 'Spelling or grammar is incorrect',
  2: 'Mistake in Diagram',
  6: 'Incorrect Answer Key',
  7: 'Mistake in question or options',
  8: 'Question does not belong to the chapter or topic',
  4: 'Any other issues',
};

export const MATHJAX_SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML';

export const MODAL_TEXT = {
  SUBMIT: 'Are you sure you want to Submit?',
  GO_BACK: 'Are you sure you need to go back?\n Your changes will be lost',
};

export const toastMessages = {
  noFilterSelected: 'Please selecte any filter!',
  alreadyFiltered: 'The result is already filtered!',
  noQuickpointFilterSelected: 'Please select all the filters',
  mandatoryFieldsNotFilled: 'Either Title or Content is not present',
  invalidSyntax: 'Invalid SSML syntax',
  textNotChanged: 'Change the default SSML and play the audio',
  requestFailureMessage: 'Something went wrong, Please try after sometime',
};
