import Cookies from 'universal-cookie';
import Api from '../helpers/Api';
import { updateLoaderState } from './global';
import constants from '../shared/constants/environmentConfig';
import { updateCursorStatus } from './index';
import { getAccountsDomain, percentageCalulator } from '../helpers/Utils';

const cookies = new Cookies();
const Constants = constants();

export const UPDATE_TEST_DETAILS = 'Home/UPDATE_TEST_DETAILS';
export const UPDATE_DASHBOARD_DETAILS = 'Home/UPDATE_DASHBOARD_DETAILS';
export const UPDATE_COURSE_SUBJECT_LIST = 'Home/UPDATE_COURSE_SUBJECT_LIST';
export const UPDATE_SUBJECT_CHAPTER_LIST = 'Home/UPDATE_SUBJECT_CHAPTER_LIST';
export const UPDATE_CHAPTER_TOPIC_LIST = 'Home/UPDATE_CHAPTER_TOPIC_LIST';
export const UPDATE_TOPIC_QUESTION_LIST = 'Home/UPDATE_TOPIC_QUESTION_LIST';

const updateDashboardDetails = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_DASHBOARD_DETAILS,
    payload,
  });
};

export const getInstituteCourseSubjects = (selectData) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const url_params = selectData && JSON.stringify(selectData);
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_institute_course_subjects?select_data=${url_params}`,
  });
  if (response.success && response.institute_data) {
    const payload = {
      courses: response.institute_data.courses,
      subjects: response.institute_data.subjects,
      courseSubjectMapping: response.institute_data.test_type_subject_mappings,
    };
    dispatch({
      type: UPDATE_COURSE_SUBJECT_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const getInstituteSubjectChapterList = (courseId, subjectId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_institute_course_subject_chapters?test_type_id=${courseId}&subject_id=${subjectId}`,
  });
  if (response.success) {
    const payload = {
      overallDetails: response.overall_details,
      chapters: response.chapter_question_distribution,
    };
    dispatch({
      type: UPDATE_SUBJECT_CHAPTER_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const getInstituteChapterTopicList = (chapterId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_chapter_topic_question_distribution?chapter_id=${chapterId}`,
  });
  if (response.success) {
    const payload = {
      topics: response.topic_question_distribution,
      overallDetails: response.overall_details,
    };
    dispatch({
      type: UPDATE_CHAPTER_TOPIC_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const getInstituteTopicQuestions = (topicId, page = 1) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_institute_topic_questions?checkpoint_id=${topicId}&page=${page}`,
  });
  if (response.success) {
    const payload = {
      questions: response.questions,
      overallDetails: response.overall_details,
    };
    dispatch({
      type: UPDATE_TOPIC_QUESTION_LIST,
      payload,
    });
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }
  dispatch(updateLoaderState(false));
};

export const formatQuestionHealth = (questionHeatlth) => {
  const formattedData = [];
  const totalData = {
    name: 'Total', totalQuestions: 0, withoutTopicTagging: 0, withoutDifficultyTagging: 0, withoutProofReading: 0, unresolvedErrors: 0,
  };
  questionHeatlth.map((data) => {
    const eachData = {};
    eachData.name = data.subject_name;
    totalData.totalQuestions += data.question_count;
    eachData.totalQuestions = data.question_count;
    totalData.withoutTopicTagging += data.without_topic_tagging;
    eachData.withoutTopicTagging = `${data.without_topic_tagging} (${percentageCalulator(data.without_topic_tagging, data.question_count)})`;
    totalData.withoutDifficultyTagging += data.without_difficulty_tagging;
    eachData.withoutDifficultyTagging = `${data.without_difficulty_tagging} (${percentageCalulator(data.without_difficulty_tagging, data.question_count)})`;
    totalData.withoutProofReading += data.without_proof_reading;
    eachData.withoutProofReading = `${data.without_proof_reading} (${percentageCalulator(data.without_proof_reading, data.question_count)})`;
    totalData.unresolvedErrors += data.unresolved_errors;
    eachData.unresolvedErrors = data.unresolved_errors;
    formattedData.push(eachData);
  });
  totalData.withoutTopicTagging = `${totalData.withoutTopicTagging} (${percentageCalulator(totalData.withoutTopicTagging, totalData.totalQuestions)})`;
  totalData.withoutProofReading = `${totalData.withoutProofReading} (${percentageCalulator(totalData.withoutProofReading, totalData.totalQuestions)})`;
  totalData.withoutDifficultyTagging = `${totalData.withoutDifficultyTagging} (${percentageCalulator(totalData.withoutDifficultyTagging, totalData.totalQuestions)})`;
  formattedData.push(totalData);
  return formattedData;
};

export const getDashBoardDetails = (course) => async (dispatch) => {
  if (!course) {
    dispatch(updateDashboardDetails({ isLoading: true }));
  } else {
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    dispatch(updateDashboardDetails({ courseSelected: course }));
  }
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_dashboard_details?test_type_id=${course ? course.id : ''}`,
  });
  if (response.success) {
    const payload = {
      questionHealthData: formatQuestionHealth(response.question_health),
      subjects: response.subjects,
      courses: response.courses,
      dtpOpertorProductivity: response.dtp_operators_productivity,
    };
    dispatch(updateDashboardDetails(payload));
  }
  dispatch(updateDashboardDetails({ isLoading: false }));
  dispatch(updateCursorStatus({ pointerType: 'default' }));
};

export const getDtpOperatorsProductivityDetails = (id) => async (dispatch) => {
  dispatch(updateDashboardDetails({ dtpOperatorProductivityLoader: true, durationFilterSelected: id }));
  dispatch(updateDashboardDetails({ dtpOperatorProductivityLoader: false }));
  dispatch(updateCursorStatus({ pointerType: 'default' }));
};

export function logout() {
  Object.keys(cookies.getAll()).forEach((cookieName) => {
    cookies.remove(cookieName,
      { secure: Constants.cookie.secure, domain: Constants.cookie.domainRegex });
    cookies.remove(cookieName, { path: '/', secure: Constants.cookie.secure });
  });
  window.location.replace(`${getAccountsDomain()}/logout`);
}
