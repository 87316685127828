import React from 'react';
import { Text } from '.';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

class PreviewHeader extends React.Component {
  renderSubHeader() {
    const { isContentEditable } = this.props;
    if (isContentEditable) {
      return (
        <Text
          style={{ textAlign: 'center' }}
          text="Make corrections to the question , options or solution here"
        />
      );
    }
    const { newQuestion, questionId } = this.props;
    return (
      <Text
        style={{ fontFamily: StyleConstants.textFont.header, color: StyleConstants.color.dark, opacity: newQuestion && 0 }}
        text={`Question Id : ${questionId}`}
      />
    );
  }

  renderHeader() {
    const { isContentEditable } = this.props;
    return (
      <Text
        style={{
          fontSize: StyleConstants.textSize.secondaryHeader,
          textAlign: 'center',
          fontFamily: StyleConstants.textFont.header,
          color: StyleConstants.color.dark,
        }}
        text={isContentEditable ? 'Edit' : 'Preview'}
      />
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: 80,
          fontWeight: StyleConstants.textWeight.bold,
          marginBottom: 40,
        }}
      >
        {this.renderHeader()}
        {this.renderSubHeader()}
      </div>
    );
  }
}

export default PreviewHeader;
