import React from 'react';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { TEXT_CHANGES_TYPE, QUESTION_TEXT_TYPES } from '../../shared/constants/fieldTypes';
import { isValidNumber } from '../../helpers/Utils';

class EditDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.mathjaxDiv = null;
  }

  renderScrollHeight(inputType = '') {
    QUESTION_TEXT_TYPES.map((type) => {
      const selected = (type === inputType);
      return this.props.scrollheight(type, selected);
    });
  }

  editDisplay(key, type, value, data, placeholder = '') {
    const { divHeight } = this.props;
    const isOption = isValidNumber(key);
    let isNumerical = false;
    if ([TEXT_CHANGES_TYPE.NOTES, TEXT_CHANGES_TYPE.RICH_RESPONSE, TEXT_CHANGES_TYPE.COMPREHENSION_QUESTION_ID].includes(value)) {
      isNumerical = true;
    }
    return (
      <textarea
        type="text"
        placeholder={placeholder}
        id={key}
        value={data[type]}
        style={{
          fontWeight: '400',
          display: 'inline-block',
          fontSize: '14px',
          marginBottom: isOption ? 5 : 10,
          padding: 15,
          overflow: 'auto',
          width: '85%',
          resize: 'none',
          height: divHeight && divHeight[type],
          color: StyleConstants.color.dark,
        }}
        onChange={(event) => {
          this.props.handleChange(type, event.target.value, value, key, isNumerical);
          this.renderScrollHeight();
        }}
        onKeyPress={(event) => event.key === 'Enter' && this.renderScrollHeight(type)}
      />
    );
  }

  render() {
    const {
      key, type, value, data, placeholder,
    } = this.props;
    return this.editDisplay(key, type, value, data, placeholder);
  }
}

export default EditDisplay;
