import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';

import { renderMathjax, numberFormatterWithCommas } from '../../helpers/Utils';
import QuestionView from '../common/questionView';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  DropDown, Text, BriefMeTabs,
} from '../common/index';
import {
  updateQuestionTaggingData, getSubjectsAndChapters, getBriefsOfChapter, getQuestions, tagBriefsToQuestions,
} from '../../actions';

class QuestionTagging extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: '',
      selectedChapter: '',
      selectedBriefs: [],
      selectedType: { id: 2, name: 'Tab' },
      selectedTaggingType: { id: 2, name: 'Untagged' },
      filterType: '',
      questionIndex: 1,
      currentQuestion: '',
      filtersChanged: false,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.props.getSubjectsAndChapters();
  }

  getCheckpointIds(currentQuestion) {
    return currentQuestion.question_checkpoint_mappings.map((questionCheckpointMapping) => questionCheckpointMapping.checkpoint_id);
  }

  getChapterIds(currentQuestion) {
    return currentQuestion.question_checkpoint_mappings.map((questionCheckpointMapping) => questionCheckpointMapping.chapter_id);
  }

  getBriefs(response) {
    if (response.success) {
      if (this.props.questions.length > 0) {
        const currentQuestion = this.props.questions[(this.state.questionIndex - 1) % 20];
        this.setState({ selectedBriefs: currentQuestion.briefs.map(({ id, title }) => { return { id, name: title.substring(0, 20) }; }) }, () => {
          this.props.updateQuestionTaggingData({ currentQuestion });
        });
        this.props.getBriefsOfChapter(this.getChapterIds(currentQuestion), this.getCheckpointIds(currentQuestion));
      }
    }
  }

  getSelectedBriefs(id, name, briefs) {
    if (briefs && briefs.filter((option) => { return option.id === id; }).length === 1) {
      return briefs.filter((option) => { return option.id !== id; });
    } if (briefs) {
      return [...briefs, { id: id || '', name: name || '' }];
    }
    return [{ id: id || '', name: name || '' }];
  }

  updateFilterType(type) {
    let filterType = '';
    if (this.state.selectedChapter.id) {
      filterType = 'chapters';
    } else if (this.state.selectedSubject.id) {
      filterType = 'subjects';
    } else {
      filterType = '';
    }
    let changes = { filterType };
    if (type === 'selectedSubject') {
      changes = { ...changes, selectedChapter: '' };
    }
    this.setState(changes);
  }

  handleSearchButtonClick() {
    const {
      selectedSubject, selectedChapter, selectedType, selectedTaggingType, filterType, filtersChanged, questionIndex,
    } = this.state;
    const enableSearchButton = (filterType !== '') && filtersChanged;
    const searchObjectId = selectedChapter.id || selectedSubject.id;

    if (enableSearchButton) {
      this.setState({ filtersChanged: false, questionIndex: 1 }, () => this.props.getQuestions({
        filterType, searchObjectId, selectedTypeName: selectedType.name || '', selectedTaggingTypeName: selectedTaggingType.name || '', page: 1, size: 20,
      }).then((response) => this.getBriefs(response)));
    }
  }

  handlePaginationChange(questionIndex) {
    const {
      selectedSubject, selectedChapter, selectedType, selectedTaggingType, filterType, currentPage,
    } = this.state;
    const { questions } = this.props;
    const searchObjectId = selectedChapter.id || selectedSubject.id;
    const currentQuestion = questions[(questionIndex - 1) % 20];

    if (currentPage === Math.ceil(questionIndex / 20)) {
      this.setState({ questionIndex, selectedBriefs: currentQuestion.briefs.map(({ id, title }) => { return { id, name: title.substring(0, 20) }; }) }, () => {
        this.props.updateQuestionTaggingData({ currentQuestion });
        this.props.getBriefsOfChapter(this.getChapterIds(currentQuestion), this.getCheckpointIds(currentQuestion));
      });
    } else {
      this.setState({ questionIndex, currentPage: Math.ceil(questionIndex / 20) }, () => this.props.getQuestions({
        filterType, searchObjectId, selectedTypeName: selectedType.name || '', selectedTaggingTypeName: selectedTaggingType.name || '', page: this.state.currentPage, size: 20,
      }).then((response) => this.getBriefs(response)));
    }
  }

  renderLabel(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.subHeader} color={StyleConstants.color.dark} text={text} />;
  }

  renderBriefsDropDown(dropdownOptions) {
    const { questionIndex, selectedBriefs } = this.state;
    const enableTagBriefButton = dropdownOptions.selectedItems.length > 0;
    const { currentQuestion, briefs } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
        <DropDown
          key={dropdownOptions.key}
          selectedItems={dropdownOptions.selectedItems}
          optionsList={dropdownOptions.optionsList}
          optionType={dropdownOptions.optionType}
          defaultText={dropdownOptions.defaultText}
          width={dropdownOptions.width}
          height={dropdownOptions.height}
          onValueChange={(id, name) => this.setState({ selectedBriefs: this.getSelectedBriefs(id, name, dropdownOptions.selectedItems) })}
        />
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{
            width: 100, height: 40, opacity: enableTagBriefButton ? 1 : 0.5, cursor: enableTagBriefButton ? 'pointer' : 'not-allowed',
          }}
          onClick={enableTagBriefButton ? () => this.props.tagBriefsToQuestions(currentQuestion, questionIndex - 1, selectedBriefs.map((brief) => brief.id), briefs) : () => {}}
        >
          Tag Briefs
        </div>
      </div>
    );
  }

  renderDropdown(dropdownOptions, type) {
    return (
      <DropDown
        key={dropdownOptions.key}
        selectedItem={dropdownOptions.selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        dropDownContainerStyle={{ marginRight: 30, marginBottom: 30 }}
        onValueChange={(id, name) => {
          if (type === 'selectedSubject') {
            this.setState({ selectedSubject: { id, name }, filtersChanged: true }, () => this.updateFilterType(type));
          } else if (type === 'selectedChapter') {
            this.setState({ selectedChapter: { id, name }, filtersChanged: true }, () => this.updateFilterType(type));
          } else {
            this.setState({ [type]: { id, name }, filtersChanged: true });
          }
        }}
      />
    );
  }

  renderFilters({
    subjectDropdownOptions, chapterDropdownOptions, typeDropdownOptions, taggingTypeDropdownOptions,
  }) {
    const {
      filterType, filtersChanged,
    } = this.state;
    const enableSearchButton = (filterType !== '') && filtersChanged;
    const styles = { display: 'flex', justifyContent: 'space-evenly', margin: '0px 40px' };

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ ...styles, width: 1000 }}>
          {this.renderDropdown(subjectDropdownOptions, 'selectedSubject')}
          {this.renderDropdown(chapterDropdownOptions, 'selectedChapter')}
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{
              width: 100, height: 40, opacity: enableSearchButton ? 1 : 0.5, cursor: enableSearchButton ? 'pointer' : 'not-allowed',
            }}
            onClick={() => this.handleSearchButtonClick()}
          >
            Search
          </div>
        </div>
        <div style={{ ...styles, width: 815 }}>
          {this.renderDropdown(typeDropdownOptions, 'selectedType')}
          {this.renderDropdown(taggingTypeDropdownOptions, 'selectedTaggingType')}
        </div>
      </div>
    );
  }

  renderPagination() {
    const {
      questions, totalQuestionCount,
    } = this.props;
    const { questionIndex } = this.state;

    if (questions.length > 0) {
      return (
        <div style={{
          display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
        }}
        >
          <Pagination
            activePage={parseInt(questionIndex, 10)}
            itemsCountPerPage={1}
            totalItemsCount={totalQuestionCount}
            pageRangeDisplayed={10}
            onChange={(event) => this.handlePaginationChange(event)}
          />
        </div>
      );
    }
    return null;
  }

  renderOverViewElement(text, value) {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10,
      }}
      >
        <Text
          text={text}
          style={{ textAlign: 'center' }}
          color={StyleConstants.textColor.secondary}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.subHeader}
        />
        <Text
          text={value}
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          fontSize={StyleConstants.textSize.subHeader}
        />
      </div>
    );
  }

  renderSuggestedBriefs(suggestedBriefs) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 10 }}>
          {this.renderLabel('Recommended Briefs To Tag:')}
        </div>
        <div style={{ display: 'flex', width: 300, overflow: 'auto' }}>
          {suggestedBriefs.map(({ title }) => {
            return (
              <div style={{
                backgroundColor: StyleConstants.backgroundColor.error, padding: 10, marginRight: 5, whiteSpace: 'nowrap', wordBreak: 'unset', borderRadius: 5,
              }}
              >
                {title.substring(0, 20)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderQuestionView(briefDropdownOptions) {
    const {
      questions, totalQuestionCount, currentQuestion, suggestedBriefs,
    } = this.props;
    const { questionIndex } = this.state;

    return (
      <div style={{ padding: 20 }}>
        {(totalQuestionCount || totalQuestionCount === 0) && this.renderOverViewElement('TOTAL QUESTIONS', numberFormatterWithCommas(totalQuestionCount))}
        {this.renderPagination()}
        {questions.length > 0
        && (
        <div style={{ display: 'flex', justifyContent: suggestedBriefs.length > 0 ? 'space-between' : 'flex-end', margin: '10px 0px 30px 0px' }}>
          {suggestedBriefs.length > 0 && this.renderSuggestedBriefs(suggestedBriefs)}
          {this.renderBriefsDropDown(briefDropdownOptions)}
        </div>
        )}
        {questions.length > 0
        && (
        <div
          style={{
            marginBottom: 10, border: '1px solid #ddd', borderRadius: 4, cursor: 'pointer', padding: 10,
          }}
        >
          <div>
            <QuestionView
              currentIndex={questionIndex}
              style={{ backgroundColor: 'inherit', margin: 0, boxShadow: '' }}
              currentQuestion={currentQuestion}
              dontHideSolution
              canReport
              canEdit
              videoSolutionWidth="460px"
              videoSolutionHeight="200px"
            />
          </div>
        </div>
        )}
      </div>
    );
  }

  render() {
    const {
      selectedSubject, selectedChapter, selectedType, selectedTaggingType, selectedBriefs,
    } = this.state;
    const {
      subjects, chapters, briefs,
    } = this.props;
    const subjectDropdownOptions = {
      key: subjects.length,
      optionType: 'Subject',
      defaultText: 'Subject',
      optionsList: subjects,
      selectedItem: selectedSubject,
      width: 250,
      height: 35,
    };
    const chapterDropdownOptions = {
      key: `${selectedSubject.id} - chapters`,
      optionType: 'Chapter',
      defaultText: 'Chapter',
      optionsList: chapters[selectedSubject.id],
      selectedItem: selectedChapter,
      width: 250,
      height: 35,
    };
    const types = [{ id: 1, name: 'All' }, { id: 2, name: 'Tab' }, { id: 3, name: 'Non-Tab' }];
    const typeDropdownOptions = {
      key: `${selectedSubject.id} - types`,
      optionType: 'Type',
      defaultText: 'Type',
      optionsList: types,
      selectedItem: selectedType,
      width: 250,
      height: 35,
    };
    const taggingTypes = [{ id: 1, name: 'All' }, { id: 2, name: 'Untagged' }, { id: 3, name: 'Tagged' }];
    const taggingTypeDropdownOptions = {
      key: `${selectedSubject.id} - tagTypes`,
      optionType: 'Tagging Type',
      defaultText: 'Tagging Type',
      optionsList: taggingTypes,
      selectedItem: selectedTaggingType,
      width: 250,
      height: 35,
    };
    const briefDropdownOptions = {
      key: `${selectedSubject.id} - ${briefs.length} - briefs`,
      optionType: 'Briefs',
      defaultText: 'Briefs',
      optionsList: briefs.map(({ id, title }) => { return { id, name: title.substring(0, 20) }; }),
      selectedItems: selectedBriefs,
      width: 250,
      height: 35,
    };

    return (
      <div style={{ minHeight: 300 }}>
        <BriefMeTabs selectedType="Question Tagging" />
        {this.renderFilters({
          subjectDropdownOptions, chapterDropdownOptions, typeDropdownOptions, taggingTypeDropdownOptions,
        })}
        {renderMathjax()}
        {this.renderQuestionView(briefDropdownOptions)}
      </div>
    );
  }
}

QuestionTagging.propTypes = {
  briefs: PropTypes.array,
  suggestedBriefs: PropTypes.array,
  subjects: PropTypes.array,
  chapters: PropTypes.object,
  questions: PropTypes.array,
  totalQuestionCount: PropTypes.number,
  getSubjectsAndChapters: PropTypes.func.isRequired,
  currentQuestion: PropTypes.object,
  getQuestions: PropTypes.func.isRequired,
};

QuestionTagging.defaultProps = {
  briefs: [],
  suggestedBriefs: [],
  subjects: [],
  chapters: {},
  questions: [],
  currentQuestion: {},
  totalQuestionCount: 0,
};

const mapStateToProps = ({ questionTagging }) => ({
  briefs: questionTagging.briefs,
  suggestedBriefs: questionTagging.suggestedBriefs,
  subjects: questionTagging.subjects,
  chapters: questionTagging.chapters,
  questions: questionTagging.questions,
  currentQuestion: questionTagging.currentQuestion,
  totalQuestionCount: questionTagging.totalQuestionCount,
});

export default connect(mapStateToProps, {
  updateQuestionTaggingData, getSubjectsAndChapters, getBriefsOfChapter, getQuestions, tagBriefsToQuestions,
})(QuestionTagging);
