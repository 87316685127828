import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import imageIcon from '../../shared/images/common/image-icon.svg';
import { convertImage, showToast } from '../../actions';
import uploadIssues from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';
import Modal from './baseModal';

const TEXT_DESIGN = {
  border: '1px solid #4545456B', height: '50%', overflow: 'auto', padding: 20, whiteSpace: 'pre-wrap',
};

const IMAGE = 'Image';
const TEXT = 'Text';
class ImageToText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageModal: false,
      imageFile: '',
      status: IMAGE,
      imageUploaded: false,
      displayImage: false,
      processing: false,
      text: '',
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.updateText = this.updateText.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadImageFile = this.uploadImageFile.bind(this);
    this.changeImageOption = this.changeImageOption.bind(this);
  }

  updateText(image) {
    if (image) {
      this.props.showToast('Processing Image ...');
      this.setState({ processing: true });
      this.props.convertImage(image).then((response) => {
        if (response.success && response.data && response.data.length > 0) {
          this.props.showToast('Image is Processed');
        } else {
          this.props.showToast('Image is Invalid');
        }
        this.setState({ text: response.data, processing: false });
      });
    }
  }

  uploadImageFile(event) {
    const { imageUploaded, displayImage } = this.state;
    this.setState({
      imageUploaded: !imageUploaded,
      imageFile: imageUploaded ? '' : event.target.files[0],
      displayImage: !displayImage,
    },
    () => {
      const { imageFile } = this.state;
      if (!imageFile) {
        this.setState({ text: '' });
      }
    });
  }

  toggleModal() {
    const { option, number } = this.props;
    const {
      showImageModal, text, status, imageFile,
    } = this.state;
    const isImage = status === IMAGE;
    if (isImage && imageFile) {
      this.props.addImageOption(imageFile, number);
    } else if (text) {
      this.props.setText(text, option);
    }
    this.setState({
      showImageModal: !showImageModal,
      imageUploaded: false,
      imageFile: '',
      displayImage: false,
      text: '',
    });
  }

  handleCloseModal() {
    this.setState({ showImageModal: false });
  }

  uploadImage() {
    const { clear } = this.props;
    const { displayImage, imageFile, processing } = this.state;
    if (displayImage && !clear) {
      return (
        <div style={{
          display: 'flex', height: '80%', flexDirection: 'column', justifyContent: 'center',
        }}
        >
          {imageFile
              && (
              <div style={{
                display: 'inline-block', height: 'auto', width: 'fit-content', textAlign: 'center',
              }}
              >
                <img
                  alt={imageFile}
                  src={URL.createObjectURL(imageFile)}
                  style={{
                    height: '150px', padding: '5px', marginLeft: '0', width: '500px',
                  }}
                />
                {!processing && (
                <div
                  className="Button Secondary-Button"
                  role="presentation"
                  style={{ margin: '20px 30%' }}
                  onClick={this.uploadImageFile}
                >
                  Clear
                </div>
                )}
              </div>
              )}
        </div>
      );
    }
    return (
      <div style={{
        display: 'flex', flexDirection: 'column',
      }}
      >
        <div style={{
          borderRadius: '5px', height: '220px', textAlign: 'center', border: '1px solid #B1B1B1', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', textAlign: 'center',
        }}
        >
          <input
            style={{
              position: 'relative', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', cursor: 'pointer',
            }}
            type="file"
            accept="image/x-png,image/jpeg"
            capture="user"
            onChange={this.uploadImageFile}
          />
          <p style={{
            height: '26px', position: 'absolute', margin: '70px 0 0 250px', textAlign: 'center', width: '26px', background: `url(${uploadIssues})`, opacity: 1, textAlign: 'center',
          }}
          />
          <p style={{
            color: '#898989', fontSize: '14', margin: '120px 0 0 110px', position: 'absolute', textAlign: 'center',
          }}
          >
            Drag or browse the Diagram file to upload
          </p>
        </div>
        <div style={{ display: 'flex', justifyItems: 'left', marginTop: 20 }}>
          <div style={{
            marginTop: '2%', marginBottom: '4%', height: '14px', width: '14px', background: `url(${info})`, opacity: 1,
          }}
          />
          <div style={{ fontSize: 12, color: '#B1B1B1', padding: 8 }}>Upload only .Jpg or .Png files</div>
        </div>
      </div>
    );
  }

  changeImageOption(type) {
    this.setState({ status: type });
  }

  showPreviewText() {
    const {
      text, processing, imageFile, status,
    } = this.state;
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);

    if (processing && status === TEXT) {
      return (
        <div style={{ marginTop: 100, textAlign: 'center' }}>Processing Result....</div>
      );
    }
    if (imageFile && !text && status === TEXT) {
      return (
        <div style={{ display: 'flex', placeContent: 'center' }}>
          <div
            className="Button Primary-Button"
            role="presentation"
            style={{ width: 150, marginTop: 50, textAlign: 'center' }}
            onClick={() => { this.updateText(imageFile); }}
          >
            Process the result
          </div>
        </div>
      );
    }
    if (!imageFile) {
      return (
        <div style={{ marginTop: 100, textAlign: 'center' }}>Upload an image to get preview</div>
      );
    }
    if (status === IMAGE) {
      return (
        <img
          alt={imageFile}
          src={URL.createObjectURL(imageFile)}
          style={{
            height: '230px', padding: '30px', marginLeft: '0', width: '450px',
          }}
        />
      );
    }
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', height: 200, width: 450,
      }}
      >
        <textarea
          style={{
            border: 0, ...TEXT_DESIGN, marginBottom: 20, resize: 'none',
          }}
          value={text}
          onChange={(event) => this.setState({ text: event.target.value })}
        />
        <div style={{
          ...TEXT_DESIGN,
        }}
        >
          {text}
        </div>
      </div>
    );
  }

  render() {
    const {
      showImageModal, text, status, imageFile,
    } = this.state;
    const { isImageOption } = this.props;
    const isImage = status === IMAGE;
    const typeText = `${status} Preview`;

    return (
      <>
        <div
          role="presentation"
          onClick={() => this.toggleModal()}
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
        >
          <img
            src={imageIcon}
            alt=""
            width="20px"
            height="20px"
          />
        </div>
        <Modal isModalVisible={showImageModal} handleCloseModal={this.handleCloseModal} header="Image Changes" height="500px" width="1000px" maxWidth="1000px" headerHeight="55px">
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', width: 1000,
          }}
          >
            <div style={{ display: 'flex', height: '80%', marginTop: 30 }}>
              <div style={{ width: 500, marginLeft: 20 }}>
                <div style={{
                  marginBottom: 20, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito',
                }}
                >
                  Upload Image
                </div>
                {this.uploadImage()}
              </div>
              <div style={{ marginLeft: '40px 0', width: 500, height: '80%' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{
                    marginBottom: 20, marginLeft: 10, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito',
                  }}
                  >
                    {typeText}
                  </div>
                </div>
                {this.showPreviewText()}
              </div>
            </div>
            {(text || (isImage && imageFile)) && (
            <div
              role="presentation"
              className="Button Primary-Button"
              style={{
                width: 200, marginTop: 30, display: 'flex', justifyContent: 'center',
              }}
              onClick={() => this.toggleModal()}
            >
              {isImage ? (isImageOption ? 'Set As Image Option' : 'Set As Supporting Picture') : 'Add Text'}
            </div>
            )}
          </div>
        </Modal>
      </>
    );
  }
}
ImageToText.propTypes = {
  option: PropTypes.string,
  clear: PropTypes.bool,
  number: PropTypes.number,
  isImageOption: PropTypes.bool,
};

ImageToText.defaultProps = {
  option: '',
  isImageOption: false,
  number: 0,
  clear: false,
};
export default connect(null, { convertImage, showToast })(ImageToText);
