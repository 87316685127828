import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { Loader, Text } from './index';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

class ShowDictionaryItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  renderBriefBasicDetails(detailDescription, detailContent) {
    const { isItemSelected } = this.props;
    // eslint-disable-next-line no-unused-expressions
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    if (detailDescription !== 'Title' && (!isItemSelected)) return null;
    const allowTitleStyles = (detailDescription === 'Title' && isItemSelected);
    return (
      <div
        className={allowTitleStyles ? 'brief-title' : 'brief-basic-details'}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: markdownToHTML.render(detailContent) }}
        style={{
          fontSize: allowTitleStyles ? StyleConstants.textSize.primaryTitle : StyleConstants.textSize.text,
          color: StyleConstants.textColor.primary,
          fontWeight: allowTitleStyles ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold,
        }}
      />
    );
  }

  renderBrief() {
    const {
      briefsDictionary: {
        selectedDictionaryItem,
        term,
        description,
      },
    } = this.props;
    const {
      id,
      dictionaryTerm,
      dictionaryDescription,
      index,
      width,
      showBorder = false,
      isVersionComparison = false,
      printDictionary = false,
      isItemSelected,
    } = this.props;

    const briefBasicDetails = { Title: [dictionaryTerm, term], Description: [dictionaryDescription, description] };
    const displayBorder = (id && selectedDictionaryItem === id) || showBorder;
    const imageWidth = (window.innerWidth / 3) - 20;
    const briefData = {
      id,
      term: dictionaryTerm,
      description: dictionaryDescription,
      selectedIndex: index,
    };

    return (
      <>
        <style jsx>
          {`
          .brief-basic-details img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printDictionary ? `${imageWidth}px` : '275px'};
          }

          .brief-basic-details ul > img, .brief-basic-details ol > img {
            max-width: ${printDictionary ? `${imageWidth - 20}px` : '255px'};
          }

          .brief-basic-details td>img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printDictionary ? `${imageWidth / 2}px` : '75px'};
            max-height: ${printDictionary ? `${imageWidth / 2}px` : '75px'};
          }

          .brief-basic-details td, .brief-basic-details th {
            border: 1px solid #7F7F7F;
            padding: 0.35rem;
            word-break: normal;
            vertical-align: top;
          }

          .brief-title .katex {
            font-size: 20px;
          }

          .brief-basic-details .katex {
            font-size: 18px;
          }

          .brief-basic-details th {
            background-color: #E3E3E3;
            font-weight: bold;
            text-align: center;
          }

          .brief-basic-details td {
            background-color: #F7F7F7;
          }

          table {
            display: block;
            overflow-x: scroll;
            box-sizing: border-box;
            border-collapse: collapse;
            margin-bottom: 20px;
            position: relative;
          }

          table-small {
            font-size: 12px;
          }

          table-small .katex {
            font-size: 12px;
          }

          table-medium {
            font-size: 14px;
          }

          table-medium .katex {
            font-size: 14px;
          }

          freeze-column td:first-child, freeze-column th:first-child {
            position: sticky;
            z-index: 2;
            left: 0;
            box-shadow: 0.1px 0.1px 0.3px 0.3px #7F7F7F;
            background-color: #E3E3E3;
          }

          formula {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            background-color: #F7F7F7;
          }

          formula-medium {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 15px;
            background-color: #F7F7F7;
          }

          formula-medium .katex {
            font-size: 15px;
          }

          formula-small {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 13px;
            background-color: #F7F7F7;
          }

          formula-small .katex {
            font-size: 13px;
          }

          li {
            margin: 0 0 14px 0;
          }

          ::-webkit-scrollbar {
            width: 0px;
            background: transparent;
          }

        `}
        </style>
        <div
          role="presentation"
          style={{
            width: !printDictionary && width,
            margin: !printDictionary ? '0px 0px' : 'auto auto 20px',
            cursor: !isVersionComparison && !printDictionary && 'pointer',
            border: printDictionary ? `1px solid ${StyleConstants.color.mediumDark}` : 'none',
            wordBreak: 'break-word',
            overflow: 'auto',
          }}
        >
          <div role="presentation">
            {Object.entries(briefBasicDetails).map(([label, content]) => {
              const value = (isVersionComparison || !((id && selectedDictionaryItem === id)) ? content[0] : content[1]);
              return this.renderBriefBasicDetails(label, value);
            })}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
              {isItemSelected && id && this.renderText(`Id: ${id}`)}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      briefMe: {
        isLoading,
      },
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }
    return this.renderBrief();
  }
}

ShowDictionaryItem.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  briefsDictionary: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  dictionaryTerm: PropTypes.string.isRequired,
  dictionaryDescription: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  width: PropTypes.number,
  showBorder: PropTypes.bool,
  isVersionComparison: PropTypes.bool,
  isDictionarySearch: PropTypes.bool,
  printDictionary: PropTypes.bool,
  isItemSelected: PropTypes.bool,
};

ShowDictionaryItem.defaultProps = {
  width: 400,
  showBorder: false,
  isVersionComparison: false,
  isDictionarySearch: false,
  printDictionary: false,
  isItemSelected: false,
};
const mapStateToProps = ({ briefsDictionary, briefMe }) => ({
  briefsDictionary,
  briefMe,
});

export default connect(mapStateToProps, {})(ShowDictionaryItem);
