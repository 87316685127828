import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { createBrowserHistory } from 'history';
import {
  getReportedQuestionList, handleStatusForReportedQuestion, showToast, getFilterData, updateQuestionReducer,
} from '../actions';
import QuestionView from './common/questionView';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { Loader, Text, QuestionFilter } from './common';
import { REPORT_ISSUE_TYPES } from '../shared/constants/fieldTypes';

const ITEMS_COUNT_PER_PAGE = 20;
const STATUS = { 0: 'Resolved', 1: 'Ignored' };
const textStyle = { fontSize: StyleConstants.textSize.text, marginTop: 20 };
const history = createBrowserHistory({
  forceRefresh: true,
});

const BORDER_RIGHT = '1px solid #DBDBDB';

class ReportError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: {},
      statusChanged: {},
    };
    this.renderShowMessage = this.renderShowMessage.bind(this);
  }

  componentDidMount() {
    const { pageIndex } = this.props;
    this.props.getFilterData();
    this.props.getReportedQuestionList(pageIndex).then(() => {
      this.renderMathJax();
    });
  }



  toogleShowMessage(id) {
    const { showMessage } = this.state;
    showMessage[id] = !showMessage[id];
    this.setState({ showMessage });
  }



  handleStatusForReportedQuestion(question, status) {
    const { statusChanged } = this.state;
    statusChanged[question.id] = STATUS[status ? 0 : 1];
    this.props.handleStatusForReportedQuestion(question.id, question.report_issue_ids, status).then(() => {
      this.setState({ statusChanged });
    });
  }

  renderMathJax() {
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  renderShowMessage(id) {
    const { showMessage } = this.state;
    return (
      <div
        role="presentation"
        style={{ width: 'max-content' }}
        onClick={() => this.toogleShowMessage(id)}
      >
        <Text
          fontWeight={StyleConstants.textWeight.bold}
          text={`${showMessage[id] ? 'Hide' : 'Show'} Reported Message`}
          color={StyleConstants.color.error}
          style={{
            textDecoration: 'underline', cursor: 'pointer',
          }}
        />
      </div>
    );
  }

  renderReportHeader(text) {
    const headerStyle = {
      fontWeight: StyleConstants.textWeight.bold, fontFamily: StyleConstants.textFont.header, fontSize: StyleConstants.textSize.subHeader, marginBottom: 10,
    };
    return (
      <Text
        style={headerStyle}
        text={text}
      />
    );
  }

  renderReportStatusButton(question) {
    const { statusChanged } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <div
          role="presentation"
          className="Button Secondary-Button"
          style={{ width: 125, marginRight: 10, borderColor: StyleConstants.color.primary }}
          onClick={() => history.push(`/questions/edit/${question.id}`)}
        >
          Edit
        </div>
        {statusChanged[question.id]
          ? (
            <div
              role="presentation"
              className={`Button ${statusChanged[question.id] === STATUS[0] ? 'Success' : 'Error'}-Button`}
              style={{ width: 125, marginRight: 10 }}
              onClick={() => this.props.showToast('Change Cannot Be Reverted Once Done')}
            >
              {statusChanged[question.id]}
            </div>
          )
          : (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                role="presentation"
                className="Button Accept-Button"
                style={{ width: 125, marginRight: 10 }}
                onClick={() => this.handleStatusForReportedQuestion(question, true)}
              >
                Resolved
              </div>
              <div
                role="presentation"
                className="Button Reject-Button"
                style={{ width: 125 }}
                onClick={() => this.handleStatusForReportedQuestion(question, false)}
              >
                Ignore
              </div>
            </div>
          )}
      </div>
    );
  }

  renderErrorsReported(question) {
    const problems = Array.from(new Set(question.problem_types)).filter(Boolean);
    const messages = Array.from(new Set(question.message)).filter(Boolean);
    const { showMessage } = this.state;
    return (
      <div
        className="Card-View"
        style={{
          display: 'flex', backgroundColor: StyleConstants.backgroundColor.lightGray, flexDirection: 'row', marginTop: '-18px',
        }}
      >
        <div style={{ width: '100%', marginRight: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 15 }}>
            <div style={{ width: '100%', borderRight: BORDER_RIGHT }}>
              {this.renderReportHeader('Problem Types')}
              <div style={{ marginLeft: 25, marginRight: 10 }}>
                {problems.map((type) => REPORT_ISSUE_TYPES[type] && (
                <div style={{ display: 'flex', alignItems: 'center', ...textStyle }}>
                  <li />
                  <div>{REPORT_ISSUE_TYPES[type]}</div>
                </div>
                ))}
              </div>
            </div>
            <div style={{ textAlign: 'center', borderRight: BORDER_RIGHT, width: '100%' }}>
              {this.renderReportHeader('No Of Students Reported')}
              <div style={textStyle}>{question.question_issue_count}</div>
            </div>
            <div style={{ textAlign: 'center', width: '100%' }}>
              {this.renderReportHeader('Last Reported Date')}
              <div style={textStyle}>
                {question.last_issue_created_at_date}
                &#8216;
                {question.last_issue_created_at_year}
              </div>
            </div>
          </div>
          <div style={{
            marginTop: 40, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            {messages.length > 0
              && (
              <>
                <div style={{ marginLeft: 20 }}>
                  {messages.length > 0 && this.renderShowMessage(question.id)}
                  {showMessage[question.id] && (
                  <div style={{ marginTop: 10 }}>
                    {this.renderReportHeader('Message')}
                    <div style={{ marginLeft: 10 }}>
                      {messages.map((message) => (
                        <div style={{ display: 'flex', marginRight: 20 }}>
                          <li style={{ ...textStyle, marginTop: 2 }} />
                          <Text text={message} />
                        </div>
                      ))}
                    </div>
                  </div>
                  )}
                </div>
              </>
              )}
            {this.renderReportStatusButton(question)}
          </div>
        </div>
      </div>
    );
  }

  renderPagination() {
    const { pageIndex, questions, totalQuestionCount } = this.props;
    return (
      <>
        {questions.length > 0
          && (
          <div style={{
            display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
          }}
          >
            <Pagination
              activePage={pageIndex}
              itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
              totalItemsCount={totalQuestionCount}
              pageRangeDisplayed={5}
              onChange={(index) => {
                window.scroll(0, 0);
                this.props.updateQuestionReducer({ pageIndex: index });
                this.props.getReportedQuestionList(parseInt(index)).then(() => {
                  this.renderMathJax();
                });
              }}
            />
          </div>
          )}
      </>
    );
  }

  renderReportIssues() {
    const { isLoading } = this.props;
    const { questions, pageIndex } = this.props;
    if (isLoading) {
      return (
        <Loader />
      );
    }
    if (questions && Object.keys(questions).length > 0) {
      return (
        <div style={{ marginTop: 40 }}>
          {Object.keys(questions).map((question, index) => (
            <>
              <QuestionView
                currentIndex={((pageIndex - 1) * ITEMS_COUNT_PER_PAGE) + (index + 1)}
                currentQuestion={questions[question]}
                videoSolutionWidth="460px"
                videoSolutionHeight="200px"
              />
              {this.renderErrorsReported(questions[question])}
            </>
          ))}
          {this.renderPagination()}
        </div>
      );
    }
    return (
      <div style={{
        marginTop: 150, display: 'flex', alignSelf: 'center', alignItems: 'center',
      }}
      >
        No Errors Reported For This Filter
      </div>
    );
  }

  render() {
    return (
      <>
        <QuestionFilter
          filterFunction={() => {
            this.props.updateQuestionReducer({ pageIndex: 1 });
            this.props.getReportedQuestionList(1).then(() => {
              this.renderMathJax();
            });
          }}
        />
        {this.renderReportIssues()}
      </>
    );
  }
}

ReportError.propTypes = {
  questions: PropTypes.object,
  isLoading: PropTypes.bool,
  totalQuestionCount: PropTypes.number,
  pageIndex: PropTypes.number,
};

ReportError.defaultProps = {
  questions: {},
  isLoading: true,
  totalQuestionCount: 0,
  pageIndex: 1,
};

const mapStateToProps = ({ question }) => ({
  questions: question.questions,
  isLoading: question.isLoading,
  totalQuestionCount: question.totalQuestionCount,
  pageIndex: question.pageIndex,
});

export default connect(mapStateToProps, {
  getReportedQuestionList, handleStatusForReportedQuestion, showToast, getFilterData, updateQuestionReducer,
})(ReportError);
