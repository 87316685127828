export const SERVER_ERROR = 'Global/SERVER_ERROR';
export const UPDATE_TOAST_STATE = 'Global/SHOW_TOAST';
export const UPDATE_LOADER_STATE = 'Global/updateLoaderState';
export const UPDATE_CURSOR_STATUS = 'GLobal/UPDATE_CURSOR_STATUS';
export const SERVER_UNDER_MAINTENANCE = 'Global/SERVER_UNDER_MAINTENANCE';

export const serverUnderMaintenance = () => async (dispatch) => {
  dispatch({ type: SERVER_UNDER_MAINTENANCE });
};

export const serverError = () => async (dispatch) => {
  dispatch({ type: SERVER_ERROR });
};

export const updateLoaderState = (state) => {
  const payload = { loaderState: state };
  return {
    type: UPDATE_LOADER_STATE,
    payload,
  };
};

export const updateCursorStatus = (payload) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_CURSOR_STATUS, payload,
  });
};
