import Api from '../helpers/Api';
import { showToast } from './snackBar';

export const UPDATE_QUESTION_TAGGING_DATA = 'QuestionTagging/UPDATE_QUESTION_TAGGING_DATA';
export const TAG_BRIEFS_TO_QUESTION = 'QuestionTagging/TAG_BRIEFS_TO_QUESTION';

export function updateQuestionTaggingData(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_QUESTION_TAGGING_DATA, payload });
  };
}

export function getSubjectsAndChapters() {
  return async (dispatch) => {
    const response = await Api({
      method: 'get',
      url: '/subjects/get_subject_and_chapters_data?question_tagging=true',
    });
    if (response.success) {
      const payload = {};
      payload.subjects = response.subjects;
      payload.chapters = response.chapters;
      dispatch({ type: UPDATE_QUESTION_TAGGING_DATA, payload });
    }
    return response;
  };
}

export function getBriefsOfChapter(chapterIds = [], checkpointIds = []) {
  return async (dispatch) => {
    const response = await Api({
      method: 'get',
      url: `/briefs/get_briefs_for_question_tagging?chapter_ids=${JSON.stringify(chapterIds)}&checkpoint_ids=${JSON.stringify(checkpointIds)}`,
    });
    if (response.success) {
      const payload = {};
      payload.briefs = response.briefs;
      payload.suggestedBriefs = response.suggested_briefs;
      dispatch({ type: UPDATE_QUESTION_TAGGING_DATA, payload });
    }
    return response;
  };
}

export function getQuestions({
  filterType, searchObjectId, selectedTypeName, selectedTaggingTypeName, page, size,
}) {
  return async (dispatch) => {
    let url = `/questions/search_new?question_tagging=true&filter_type=${filterType}&search_object_id=${searchObjectId}&page=${page}&size=${size}`;
    if (selectedTypeName === 'All') {
      url = `${url}&available_offline=All`;
    } else if (selectedTypeName !== '') {
      url = `${url}&available_offline=${selectedTypeName === 'Tab'}`;
    }
    if (selectedTaggingTypeName === 'Untagged') {
      url = `${url}&untagged=on`;
    } else if (selectedTaggingTypeName === 'Tagged') {
      url = `${url}&untagged=off&tagged=on`;
    } else {
      url = `${url}&untagged=off`;
    }
    const response = await Api({
      method: 'get',
      url,
    });
    if (response.success) {
      const payload = {};
      payload.questions = response.questions;
      payload.totalQuestionCount = response.total_question_count;
      dispatch({ type: UPDATE_QUESTION_TAGGING_DATA, payload });
    }
    return response;
  };
}

export function tagBriefsToQuestions(currentQuestion, questionIndex, briefIds = [], briefs) {
  return async (dispatch) => {
    const data = { question_id: currentQuestion.id, brief_ids: JSON.stringify(briefIds) };
    const response = await Api({
      method: 'post',
      url: '/briefs/tag_briefs_to_questions',
      data,
    });
    if (response.success) {
      const payload = {
        currentQuestion,
        questionIndex,
        briefs: briefs.filter((brief) => {
          if (briefIds.indexOf(brief.id) >= 0) {
            return brief;
          }
        }),
      };
      dispatch({ type: TAG_BRIEFS_TO_QUESTION, payload });
      dispatch(showToast(response.message));
    }
    return response;
  };
}
