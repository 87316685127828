import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { Loader, Text } from './index';
import {
  getBrief, orderBriefs, updateBriefReducer, getBriefPreviousYearQuestions, getBriefQuestions,
} from '../../actions';
import { getFormattedBriefContent, } from '../../helpers/Utils';
import AudioIcon from '../../shared/images/common/audio.svg';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

class ShowBrief extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: {},
      searchText: '',
      replacingText: '',
      displayEditView: false,
    };
  }

  componentDidMount() {
    const { brief_id: briefId } = queryString.parse(window.location.search);
    if (briefId) {
      this.props.getBrief(briefId);
    }
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  handleClick({ id, title, content, metaData, parentId, audioURL, timeToRead, deprecated, pollyText, selectedIndex, briefTags, isBriefQuestion = false, isBriefPreviousYearQuestion = false }) {
    const { show_versions: showVersions } = queryString.parse(window.location.search);
    const selectedTags = briefTags.filter((tag) => { return tag !== null; });

    this.props.updateBriefReducer({ displayEditView: false }).then(() => {
      if (!showVersions) {
        this.setState({ selectedNewerVersion: {}, selectedOlderVersion: {} }, () => (isBriefQuestion ? this.props.getBriefQuestions(id) : isBriefPreviousYearQuestion && this.props.getBriefPreviousYearQuestions(id)));
        this.props.updateBriefReducer({
          title, content, metaData, audioURL, timeToRead, deprecated, pollyText, isChanged: false, enableFilterSearch: false, isBriefSelected: true, selectedIndex, previousYearQuestionId: '',
          selectedBriefId: id, newerBrief: '', olderBrief: '', displayEditView: true, showBriefQuestionView: isBriefQuestion, isAddBriefQuestion: isBriefQuestion, showBriefPreviousYearQuestionView: isBriefPreviousYearQuestion, selectedTags, parentId, parentIdChanged: false,
        });
      }
    });
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  handleAddButtonClick(index) {
    this.props.updateBriefReducer({ displayEditView: false }).then(() => {
      const { briefMe: { briefs, briefOrder }} = this.props;
      this.props.updateBriefReducer({
        title: '', content: '', audioURL: '',
        timeToRead: '', deprecated: false,
        pollyText: '<speak> Enter the text here </speak>',
        selectedBriefId: null,
        metaData: {},
        enableFilterSearch: false, isBriefSelected: false,
        selectedIndex: index, displayEditView: true,
        showBriefQuestionView: false, isAddBriefQuestion: false,
        selectedTags: [], previousYearQuestionId: '',
        parentId: briefs[briefOrder[index]] ? briefs[briefOrder[index]].parent_id : '',
      });
    });
  }

  renderButtonToAddBrief(index) {
    return (
      <div
        role="presentation"
        className="fa fa-plus"
        style={{ margin: '10px 0px', cursor: 'pointer' }}
        onClick={() => this.handleAddButtonClick(index)}
      />
    );
  }

  renderButtonToMoveBrief(fromIndex, toIndex) {
    const { briefMe: { isOrderBeingUpdated, briefOrder, briefs }, bmChapterId } = this.props;
    const isLoading = (!isOrderBeingUpdated && this.state.toIndex === toIndex);
    const parentIdOfToIndex = briefs[briefOrder[toIndex]].parent_id;
    const parentIdOfFromIndex = briefs[briefOrder[fromIndex]].parent_id;
    let message = `Move from ${fromIndex + 1} to ${toIndex + 1}`
    let disableMoveBrief = false;
    if (parentIdOfToIndex && parentIdOfFromIndex !== parentIdOfToIndex) {
      message = 'Cannot Move into another parent - child!'
      disableMoveBrief = true;
    } else if(parentIdOfFromIndex && parentIdOfFromIndex !== parentIdOfToIndex) {
      message = 'Cannot Move outside from parent!'
      disableMoveBrief = true;
    } else if(parentIdOfFromIndex === briefOrder[toIndex]) {
      message = 'Cannot Move to parent position!'
      disableMoveBrief = true;
    }
    if (isLoading) {
      return this.showLoader({ marginLeft: 130 });
    }
    return (
      <div
        role="presentation"
        className="Move-Button"
        style={{ marginLeft: disableMoveBrief ? 15 : 90, opacity: disableMoveBrief ? 0.5 : 1, cursor: disableMoveBrief ? 'not-allowed' : 'pointer' }}
        onClick={() => !disableMoveBrief && this.setState({ toIndex }, () => this.props.orderBriefs(fromIndex, toIndex, bmChapterId))}
      >
        {message}
      </div>
    );
  }

  renderButtonsToAddOrMove(isBriefSelected, selectedIndex, index) {
    const { briefMe: { briefs, briefOrder } } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {this.renderButtonToAddBrief(index)}
        {(isBriefSelected && selectedIndex !== index) && (
        <div style={{ margin: 10 }}>
          {(Object.keys(briefs).length > 0) && (index !== briefOrder.length) && this.renderButtonToMoveBrief(selectedIndex, index)}
        </div>
        )}
      </div>
    );
  }

  renderBriefBasicDetails(detailDescription, detailContent) {
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    return (
      <div
        className={(detailDescription === 'Title') ? 'brief-title brief-basic-details' : 'brief-basic-details'}
        dangerouslySetInnerHTML={{ __html: markdownToHTML.render(detailContent) }}
        style={{
          fontSize: detailDescription === 'Title' ? StyleConstants.textSize.primaryTitle : StyleConstants.textSize.text,
          color: StyleConstants.textColor.primary,
          fontWeight: detailDescription === 'Title' ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold,
        }}
      />
    );
  }

  renderQuestionCount({ id, title, content, metaData, parentId, audioURL, timeToRead, deprecated, pollyText, selectedIndex, briefTags, questionsCount, isBriefQuestion, isBriefPreviousYearQuestion = false }) {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => id && this.handleClick({ id, title, content, metaData, parentId, audioURL, timeToRead, deprecated, pollyText, selectedIndex, briefTags, isBriefQuestion, isBriefPreviousYearQuestion })}>
        {`${questionsCount} ${isBriefQuestion ? 'Quiz' : 'PP'} Qn${questionsCount === 1 ? '' : 's'}`}
      </div>
    );
  }

  renderBrief(id, briefTitle, brieftitleWithIndex, briefContent, briefMetaData, parentId, briefReadTime, briefAudioURL, briefDeprecated, briefPollyText, index, width, briefQuestionsCount = '', briefPreviousYearQuestionsCount = '', briefMessageToImproveConsistency = '', briefTags = [], printBriefs = false, displayAddButton = true, isAudioModal = false, showBorder = false, isVersionComparison = false, isBriefSearch = false) {
    const {
      briefMe: { 
        selectedIndex, selectedBriefId, title, content, timeToRead, isBriefSelected, briefOrder, showInconsistentBriefs, rating, metaData, briefs
      }
    } = this.props;
    const briefBasicDetails = { Title: [briefTitle, title], Description: [briefContent, content, briefMetaData, metaData]};
    const displayBorder = (id && selectedBriefId === id) || showBorder;
    const imageWidth = (window.innerWidth / 3) - 20;
    const selectedTags = briefTags.filter(tag => { return tag !== null });
    const briefData = { id, title: briefTitle, content: briefContent, metaData: briefMetaData, audioURL: briefAudioURL, timeToRead: briefReadTime, deprecated: briefDeprecated, pollyText: briefPollyText, selectedIndex: index, briefTags, parentId, };
    return (
      <>
        <style jsx>
          {`
          .brief-basic-details img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printBriefs ? `${imageWidth}px` : '275px'};
          }

          .brief-basic-details ul > img, .brief-basic-details ol > img {
            max-width: ${printBriefs ? `${imageWidth - 20}px` : '255px'};
          }

          .brief-basic-details td>img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printBriefs ? `${imageWidth / 2}px` : '75px'};
            max-height: ${printBriefs ? `${imageWidth / 2}px` : '75px'};
          }

          .brief-basic-details td, .brief-basic-details th {
            border: 1px solid #7F7F7F;
            padding: 0.35rem;
            word-break: normal;
            vertical-align: top;
          }

          .brief-title .katex {
            font-size: 22px;
          }

          .brief-basic-details .katex {
            font-size: 17px;
          }

          .brief-basic-details th {
            background-color: #E3E3E3;
            font-weight: bold;
            text-align: center;
          }

          .brief-basic-details td {
            background-color: #F7F7F7;
          }

          .brief-diagram-description {
            text-align: center;
          }
          
          table {
            display: block;
            overflow-x: scroll;
            box-sizing: border-box;
            border-collapse: collapse;
            margin-bottom: 20px;
            position: relative;
          }

          table-small {
            font-size: 12px;
          }

          table-small .katex {
            font-size: 12px;
          }

          table-medium {
            font-size: 14px;
          }

          table-medium .katex {
            font-size: 14px;
          }

          freeze-column td:first-child, freeze-column th:first-child {
            position: sticky;
            z-index: 2;
            left: 0;
            box-shadow: 0.1px 0.1px 0.3px 0.3px #7F7F7F;
            background-color: #E3E3E3;
          }

          formula {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            background-color: #F7F7F7;
          }

          formula-medium {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 15px;
            background-color: #F7F7F7;
          }

          formula-medium .katex {
            font-size: 15px;
          }

          formula-small {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 13px;
            background-color: #F7F7F7;
          }

          formula-small .katex {
            font-size: 13px;
          }

          formulae {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            background-color: #F7F7F7;
          }

          formulae-medium {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 15px;
            background-color: #F7F7F7;
          }

          formulae-medium .katex {
            font-size: 15px;
          }

          formulae-small {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 13px;
            background-color: #F7F7F7;
          }

          formulae-small .katex {
            font-size: 13px;
          }

          li {
            margin: 0 0 14px 0;
          }

          ::-webkit-scrollbar {
            width: 0px;
            background: transparent;
          }

        `}
        </style>
        <div
          role="presentation"
          className="Card-View"
          style={{
            width: !printBriefs && width,
            margin: isBriefSearch ? '0px 20px 30px 40px' : (printBriefs && 'auto auto 20px'),
            cursor: !isVersionComparison && !isAudioModal && !printBriefs && 'pointer',
            border: printBriefs ? `1px solid ${StyleConstants.color.mediumDark}` : displayBorder && `2px solid ${StyleConstants.color.primary}`,
            backgroundColor: briefDeprecated && StyleConstants.backgroundColor.error,
            height: isAudioModal && 450,
            wordBreak: 'break-word',
            overflow: 'auto',
          }}
        >
          <div onClick={() => id && this.handleClick(briefData)}>
            {!isVersionComparison && !isAudioModal && !printBriefs && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {!isBriefSearch && this.renderText(`${index + 1}/${briefOrder.length}`)}
              {selectedTags.length > 0 && (
              <div style={{
                display: 'flex', justifyContent: selectedTags.length === 1 ? 'flex-end' : 'flex-start', width: isBriefSearch ? 300 : 200, overflow: 'auto',
              }}
              >
                {selectedTags.map((tag) => {
                  return (
                    <div style={{
                      backgroundColor: StyleConstants.backgroundColor.error, padding: 10, marginRight: 5, whiteSpace: 'nowrap', wordBreak: 'unset', borderRadius: 5,
                    }}
                    >
                      {tag.name}
                    </div>
                  );
                })}
              </div>
              )}
            </div>
            )}
            {Object.entries(briefBasicDetails).map(([detailDescription, detailContent]) => {
              let value = '';
              if (!isVersionComparison && !isAudioModal && !printBriefs && !isBriefSearch && detailDescription === 'Title') {
                value = brieftitleWithIndex;
              } else {
                value = isVersionComparison ? detailContent[0] : ((id && selectedBriefId === id) ? detailContent[1] : detailContent[0]);
                if ( detailDescription === 'Description') {
                  const formulas = isVersionComparison ? (detailContent[2] && detailContent[2].formulas) : ((id && selectedBriefId === id) ? (detailContent[3] && detailContent[3].formulas) : (detailContent[2] && detailContent[2].formulas));
                  const diagrams = isVersionComparison ? (detailContent[2] && detailContent[2].diagrams) : ((id && selectedBriefId === id) ? (detailContent[3] && detailContent[3].diagrams) : (detailContent[2] && detailContent[2].diagrams));
                  value = getFormattedBriefContent(value, formulas, diagrams)
                }
              }
              return this.renderBriefBasicDetails(detailDescription, value);
            })}
            {!isAudioModal && !isVersionComparison && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
              {id && this.renderText(id)}
              {(timeToRead > 0 || briefReadTime > 0) && this.renderText((id && selectedBriefId === id) ? `${Math.round(timeToRead)} min to read` : `${Math.round(briefReadTime)} min to read`)}
              {briefAudioURL && <img src={AudioIcon} alt="audio_icon" />}
              {rating && this.renderText(`${rating.overall_rating} (${rating.overall_count} review${rating.overall_count === 1 ? '' : 's'})`)}
            </div>
            )}
          </div>
          {!isVersionComparison && !isAudioModal && !printBriefs && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
              {this.renderQuestionCount({ ...briefData, questionsCount: briefQuestionsCount, isBriefQuestion: true })}
              {this.renderQuestionCount({
                ...briefData, questionsCount: briefPreviousYearQuestionsCount, isBriefQuestion: false, isBriefPreviousYearQuestion: true,
              })}
            </div>
          )}
          {(briefMessageToImproveConsistency?.length > 0) && (
          <div
            style={{ backgroundColor: StyleConstants.backgroundColor.error, padding: 10, marginTop: 10 }}
            onClick={() => id && this.handleClick(briefData)}
          >
            {briefMessageToImproveConsistency.map((message) => { return <div>{`- ${message}`}</div>; })}
          </div>
          )}
        </div>
        {displayAddButton && !printBriefs && (showInconsistentBriefs ? <div style={{ height: 35 }} /> : this.renderButtonsToAddOrMove(isBriefSelected, selectedIndex, index + 1))}
      </>
    );
  }

  render() {
    const {
      briefMe: {
        isLoading,
      }, briefDetails,
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    const {
      id, title, content, timeToRead, audioURL, deprecated, pollyText, index, width, briefQuestionsCount, briefPreviousYearQuestionsCount,
      messageToImproveConsistency, briefTags, printBriefs, displayAddButton, isAudioModal, showBorder, isVersionComparison, isBriefSearch, metaData, parentId, titleWithIndex
    } = briefDetails;

    return this.renderBrief(id, title, titleWithIndex, content, metaData, parentId, timeToRead, audioURL, deprecated, pollyText, index, width, briefQuestionsCount, briefPreviousYearQuestionsCount, messageToImproveConsistency, briefTags, printBriefs, displayAddButton, isAudioModal, showBorder, isVersionComparison, isBriefSearch);
  }
}

ShowBrief.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  getBrief, orderBriefs, updateBriefReducer, getBriefPreviousYearQuestions, getBriefQuestions,
})(ShowBrief);
