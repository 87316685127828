import Api from '../helpers/Api';
import { showToast } from './snackBar';
import { REPORT_ISSUE_TOAST_MESSAGES, REMOVED_STATUS } from '../shared/constants/fieldTypes';
import { updateCursorStatus } from '.';

export const GET_QUESTION_DATA = 'Question/GET_QUESTION_DATA';
export const SET_AUTO_DIFFICULTIES_DATA = 'Question/SET_AUTO_DIFFICULTIES_DATA';
export const SET_QUESTION_FILTER_DATA = 'Question/SET_QUESTION_FILTER_DATA';

export const TOTAL_QUESTIONS_PER_PAGE = 20;

export function GetComprehensionQuestionToEdit(id) {
  const url_params = JSON.stringify({ subjects: true });
  return (dispatch) => Api({
    method: 'get',
    url: `/content_engine/${id}/get_comprehension_question_data?select_data=${url_params}`,
  }).then((response) => {
    const payload = { questionData: response.questions, subjects: response.institute_data.subjects };
    if (response.success) {
      dispatch({
        type: GET_QUESTION_DATA,
        payload,
      });
    }
    return response;
  });
}

export const getInstituteSubjects = (selectData) => async (dispatch) => {
  const url_params = selectData && JSON.stringify(selectData);
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_institute_course_subjects?select_data=${url_params}`,
  });
  if (response.success) {
    const payload = {
      questionData: {},
      subjects: response.institute_data.subjects,
      questionTypes: response.institute_data.question_types,
      comprehensionIds: response.institute_data.comprehension_ids,
    };
    dispatch({
      type: GET_QUESTION_DATA,
      payload,
    });
  }
  return response;
};

export function getInstituteUploadStatus() {
  return () => Api({
    method: 'post',
    url: '/content_engine/get_institute_question_upload_status',
  }).then((response) => {
    return response;
  });
}

export const getQuestionsForEdit = (id) => async (dispatch) => {
  const url_params = JSON.stringify({ subjects: true, question_types: true, comprehension_ids: true });
  const response = await Api({
    method: 'get',
    url: `/content_engine/${id}/get_question_data_to_edit?select_data=${url_params}`,
  });
  if (response.success) {
    const payload = {
      questionData: response.questions,
      subjects: response.institute_data.subjects,
      questionTypes: response.institute_data.question_types,
      comprehensionIds: response.institute_data.comprehension_ids,
    };
    dispatch({
      type: GET_QUESTION_DATA,
      payload,
    });
  }
  return response;
};

export const getReportedQuestionList = (pageIndex) => async (dispatch, getState) => {
  dispatch({ type: SET_AUTO_DIFFICULTIES_DATA, payload: { isLoading: true } });
  const { question: { appliedFilters } } = getState();
  const url_params = `?per_page=${TOTAL_QUESTIONS_PER_PAGE}&page=${pageIndex}&test_type_id=${appliedFilters.course.id}&subject=${appliedFilters.subject.id}&chapter=${appliedFilters.chapter.id}`;
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_report_issue_questions${url_params}`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      questions: response.questions,
      totalQuestionCount: response.total_question_count,
      isLoading: false,
    };
  }
  dispatch({
    type: GET_QUESTION_DATA,
    payload,
  });
  return response;
};

export function handleStatusForReportedQuestion(questionId, reportedIds, isResolved) {
  return () => Api({
    method: 'post',
    url: '/report_issue/change_report_issue_status',
    data: { question_id: questionId, report_issue_ids: JSON.stringify(reportedIds), status: isResolved ? 'RESOLVED' : 'NOT_A_ISSUE' },
  }).then((response) => {
    return response;
  });
}

export function sendErrorReported(questionId, problemType, message) {
  return () => Api({
    method: 'post',
    url: '/report_issue/create',
    data: { question_id: questionId, problem_type: problemType, message },
  }).then((response) => {
    return response;
  });
}
export function removeQuestionFromComprehension(comprehensionQuestionId, questionId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/content_engine/remove_question_from_comprehension_question',
    data: { question_id: questionId, comprehension_question_id: comprehensionQuestionId },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast(REMOVED_STATUS.SUCCESS));
    } else {
      dispatch(showToast(REMOVED_STATUS.ERROR));
    }
    return response;
  });
}

export const getComprehensionQuestions = (pageIndex = 1) => async (dispatch, getState) => {
  dispatch({ type: GET_QUESTION_DATA, payload: { isLoading: true } });
  const selectData = JSON.stringify({ subjects: true });
  const { question: { appliedFilters } } = getState();
  let payload = {};
  const url_params = `?per_page=${TOTAL_QUESTIONS_PER_PAGE}&page=${pageIndex}`;
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_comprehension_questions${url_params}&subject=${appliedFilters.subject.id}&select_data=${selectData}`,
  });
  if (response.success) {
    payload = { comprehensiveQuestions: response.questions, totalQuestionCount: response.question_count, subjects: response.institute_data.subjects };
    dispatch({
      type: GET_QUESTION_DATA,
      payload,
    });
  }
  payload.isLoading = false;
  dispatch({ type: GET_QUESTION_DATA, payload });
  return response;
};

export function updateComprehensionQuestion(questionId, questionData, imageFile, subjectId) {
  return (dispatch) => Api({
    method: 'post',
    url: questionId === 0 ? '/content_engine/create_comprehension_question' : '/content_engine/update_comprehension_question',
    attachment: imageFile[0],
    type: 'supporting_picture',
    data: {
      question_id: questionId,
      question_text: questionData,
      subject_id: subjectId,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATED));
    } else {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_UPDATED));
    }
    return response;
  });
}

export function updateInstituteQuestion(textChanges, imageFile, questionTypeCorrections, questionId) {
  return (dispatch) => Api({
    method: 'post',
    url: questionId === 0 ? '/content_engine/create_institute_questions' : '/content_engine/update_institute_questions',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'reported_image_file',
    data: {
      question_id: questionId,
      question_params: textChanges,
      question_type_changes: questionTypeCorrections,
    },
  }).then((response) => {
    if (response.is_comprehension_invalid) {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.COMPREHENSION_INVALID));
    } else if (response.success) {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATED));
    } else {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_UPDATED));
    }
    return response;
  });
}

export function uploadQuestions(file) {
  return () => Api({
    method: 'post',
    url: '/content_engine/upload_questions',
    attachment: file,
    type: 'spreadsheet',
  }).then((response) => {
    return response;
  });
}

export const getFilterData = () => async (dispatch, getState) => {
  dispatch({
    type: SET_QUESTION_FILTER_DATA,
    payload: { isLoading: true },
  });
  const response = await Api({
    method: 'get',
    url: '/content_engine/get_all_filter_categories',
  });
  if (response.success) {
    const payload = {
      courses: response.courses || [],
      subjects: response.subjects || [],
      chapters: response.chapters || [],
      isLoading: false,
    };
    dispatch({
      type: SET_QUESTION_FILTER_DATA,
      payload,
    });
  }
};

export const getAutoTagDifficulties = (pageIndex = 1) => async (dispatch, getState) => {
  dispatch({ type: SET_AUTO_DIFFICULTIES_DATA, payload: { isLoading: true } });
  const { question: { appliedFilters } } = getState();
  let url_params = `?per_page=${TOTAL_QUESTIONS_PER_PAGE}&page_index=${pageIndex}`;
  if (appliedFilters.course.id) {
    url_params += `&test_type_id=${appliedFilters.course.id}`;
  }
  if (appliedFilters.subject.id) {
    url_params += `&subject_id=${appliedFilters.subject.id}`;
  }
  if (appliedFilters.chapter.id) {
    url_params += `&chapter_id=${appliedFilters.chapter.id}`;
  }
  const response = await Api({
    method: 'get',
    url: `/content_engine/get_auto_computed_question_difficulties${url_params}`,
  });
  const payload = {};
  if (response.success) {
    payload.questionsWithMismatchedPredictions = response.questions_with_mismatched_predictions;
    payload.totalMismatchedPredictions = response.total_mismatched_predictions;
  }
  payload.isLoading = false;
  dispatch({ type: SET_AUTO_DIFFICULTIES_DATA, payload });
};

export const updateDifficulty = (data) => async (dispatch, getState) => {
  dispatch(updateCursorStatus({ pointerType: 'progress' }));
  const { question: { questionsWithMismatchedPredictions } } = getState();
  const response = await Api({
    method: 'post',
    url: '/content_engine/update_auto_computed_question_difficulty',
    data: {
      complexity: data.complexity,
      question_id: data.questionId,
      status: data.status,
      current_difficulty: data.currentDifficulty,
      suggested_difficulty: data.suggestedDifficulty,
      difficulty_score: data.difficultyScore,
    },
  });
  if (response.success) {
    questionsWithMismatchedPredictions[data.index].status = data.status;
    dispatch({
      type: SET_AUTO_DIFFICULTIES_DATA,
      payload: { questionsWithMismatchedPredictions, totalPredictionCount: response.total_prediction_count },
    });
  }
  dispatch(updateCursorStatus({ pointerType: 'default' }));
};

export const updateQuestionReducer = (payload) => async (dispatch) => {
  return (
    dispatch({
      type: SET_AUTO_DIFFICULTIES_DATA,
      payload,
    })
  );
};
