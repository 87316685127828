import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { createBrowserHistory } from 'history';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import {
  Modal, Text, BriefMeTabs, Loader,
} from '../common';
import Layout from '../layout';
import {
  getBriefMeCourseSemesterDetails, getBriefMeSemesterSubjectList, getBriefMeSubjectChapterList,
  updateBriefReducer, addSubject, addChapter, toggleSubjectStatus, toggleChapterStatus, editSubjectName, updateBmChapterOrder, showToast
} from '../../actions/index';
import BriefMe from '../briefMe';
import DownArrow from '../../shared/images/common/down-arrow.svg';
import UpArrow from '../../shared/images/common/up-arrow.svg';

const cookies = new Cookies();
const userProfile = cookies.get('user');
const history = createBrowserHistory({
  forceRefresh: true,
});

class BriefMeBrowse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedBmCourse: '',
      selectedSemester: '',
      selectedSubjectToAdd: '',
      selectedChapterToAdd: '',
      showEditModal: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const {
      briefMe: {
        bmChapters, bmSubjects, semesters,
      },
    } = this.props;
    const {
      bmCourseId, semesterId, bmSubjectId, bmChapterId,
    } = queryString.parse(this.props.location.search);

    if (bmSubjectId && bmChapters.length === 0) {
      this.props.getBriefMeSubjectChapterList(bmSubjectId);
      return;
    }
    if (semesterId && bmSubjects.length === 0) {
      this.props.getBriefMeSemesterSubjectList(semesterId);
    }
    if (Object.values(semesters).length === 0 && !bmChapterId && !bmCourseId && !bmSubjectId && !semesterId) {
      this.props.getBriefMeCourseSemesterDetails();
    }
  }

  renderPageTableTitle(text) {
    return (
      <Text
        text={text}
        style={{ margin: '10px 0px' }}
        fontSize={StyleConstants.textSize.tertiaryHeader}
        color={StyleConstants.textColor.secondary}
        fontWeight={StyleConstants.textWeight.semiBold}
      />
    );
  }

  handleCloseModal() {
    this.setState({ showEditModal: false });
  }

  editSubjectName() {
    const {
      briefMe: {
        changedSubjectName,
      },
    } = this.props;
    const {
      bmSubjectId,
    } = queryString.parse(this.props.location.search);
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input style={{ width: 180, marginRight: 15, backgroundColor: '#FFF' }} type="text" value={changedSubjectName} placeholder="Subject Name" onChange={(event) => this.props.updateBriefReducer({ changedSubjectName: event.target.value })} />
        <div className="modalButtonView">
          <div className="Button Primary-Button" style={{ width }} onClick={() => { this.props.editSubjectName(changedSubjectName, bmSubjectId); this.handleCloseModal(); }}> Update </div>
        </div>
      </div>
    );
  }

  renderEditModal(showEditModal) {
    return (
      <Modal
        header="Edit Subject Name"
        key={showEditModal}
        isModalVisible={showEditModal}
        handleCloseModal={this.handleCloseModal}
        height={150}
        width={600}
      >
        {this.editSubjectName()}
      </Modal>
    );
  }

  renderDescription(text) {
    return (
      <Text
        text={text}
        fontSize={StyleConstants.textSize.title}
        color={StyleConstants.color.dark}
      />
    );
  }

  renderPageTitle(text) {
    return (
      <Text
        text={text}
        color={StyleConstants.color.primary}
        fontFamily={StyleConstants.textFont.header}
        fontSize={StyleConstants.textSize.secondaryHeader}
        fontWeight={StyleConstants.textWeight.bold}
      />
    );
  }

  renderNavigationText(text, isHyperlink = false) {
    return (
      <Text
        text={text}
        style={{ cursor: isHyperlink && 'pointer', textDecoration: isHyperlink && 'underline' }}
        color={StyleConstants.textColor.secondary}
        fontFamily={StyleConstants.textFont.header}
        fontSize={StyleConstants.textSize.tertiaryHeader}
        fontWeight={StyleConstants.textWeight.bold}
      />
    );
  }

  renderPageTopNavigation(pageHeaderText) {
    const links = this.getPageLinks();
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
        {this.renderPageTitle(pageHeaderText)}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.renderNavigationText('in ')}
          {links.map((link, index) => (
            <>
              <div onClick={() => history.push(link.path)}>
                {this.renderNavigationText(link.name, true)}
              </div>
              {index + 1 !== links.length && this.renderNavigationText(' > ')}
            </>
          ))}
        </div>
      </div>
    );
  }

  getPageLinks() {
    const {
      briefMe: {
        overallDetails,
      },
    } = this.props;
    const links = [];

    if (overallDetails.selectedSemester && overallDetails.selectedSemester.name) {
      links.push({ path: '/brief_me/browse', name: overallDetails.selectedBmCourse.name });
    }
    if (overallDetails.selectedBmSubject && overallDetails.selectedBmSubject.name) {
      links.push({ path: `/brief_me/browse?semesterId=${overallDetails.selectedSemester.id}`, name: overallDetails.selectedSemester.name });
    }
    if (overallDetails.selectedBmChapter && overallDetails.selectedBmChapter.name) {
      links.push({ path: `/brief_me/browse?bmSubjectId=${overallDetails.selectedBmSubject.id}`, name: overallDetails.selectedBmSubject.name });
    }
    return links;
  }

  onDragEnd = result => {
    const {
      briefMe: {
         bmChapters, hasCreateSubjectAccess
      },
    } = this.props;
    if (!hasCreateSubjectAccess) {
      this.props.showToast("You don't have permissions to reorder the chapters");
      return null;
    }
    const {
      bmSubjectId,
    } = queryString.parse(this.props.location.search);
    const {destination, source} = result;
    const droppedChapter = bmChapters[source.index];
    const chapterOrder = [];
    if (!destination) {
      return null;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return null;
    }
    bmChapters.splice(source.index, 1);
    bmChapters.splice(destination.index, 0, droppedChapter);
    bmChapters.map((chapter,index) =>
     chapterOrder.push(chapter.id) 
     )
    this.props.updateBmChapterOrder(bmSubjectId, chapterOrder, source.index, destination.index, droppedChapter.name) 
  }

  renderChapterTableDetails(type) {
    const {
      briefMe: {
       bmChapters
      },
    } = this.props;

    return (
      <>
        {this.renderPageTableTitle(`${type}s`)}
        <table className="table table-striped table-hover" style={{ textAlign: 'center', marginBottom: 0, columnSpan: '10px' }}>
          <thead>
            <tr>
              <th> No </th>
              <th>{type}</th>
              <th style={{ textAlign: 'center' }}> Rating </th>
              <th style={{ textAlign: 'end' }}> Brief Count </th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId= 'dp1'>
              {(provided) => (
                <tbody style={{ textAlign: 'start' }} {...provided.droppableProps} ref={provided.innerRef}>
                  {bmChapters.map((detail, index) => (
                    <Draggable draggableId={index+''} index={index} key={index}>
                      {(provided) => (
                        <tr className="table-row-selection" style={{ backgroundColor: detail.status !== 'ACTIVE' && StyleConstants.backgroundColor.error }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <td role="presentation">
                            {' '}
                            {index + 1}
                            {' '}
                          </td>
                          <td role="presentation" style={{ color: StyleConstants.color.primary, fontWeight: StyleConstants.textWeight.semiBold }} onClick={() => history.push(`/brief_me/browse?bm${type}Id=${detail.id}`)}>
                            {' '}
                            {detail.name}
                            {' '}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {' '}
                            {detail.rating ? detail.rating.overall_rating : 'NA'}
                            {' '}
                          </td>
                          <td style={{ textAlign: 'end' }}>
                            {' '}
                            {detail.brief_count}
                            {' '}
                          </td>
                          {provided.placeholder}
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
              )}
           </Droppable>
         </DragDropContext>
        </table>
      </>
    );
  }

  renderSubjectTableDetails(type) {
    const {
      briefMe: {
        bmSubjects
      },
    } = this.props;

    return (
      <>
        {this.renderPageTableTitle(`${type}s`)}
        <table className="table table-striped table-hover" style={{ textAlign: 'center', marginBottom: 0, columnSpan: '10px' }}>
          <thead>
            <tr>
              <th> No </th>
              <th>{type}</th>
              <th style={{ textAlign: 'end' }}> Chapter Count </th>
              <th style={{ textAlign: 'end' }}> Brief Count </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'start' }}>
            {bmSubjects.map((detail, index) => (
              <tr className="table-row-selection" style={{ backgroundColor: detail.status !== 'ACTIVE' && StyleConstants.backgroundColor.error }}>
                <td role="presentation">
                  {' '}
                  {index + 1}
                  {' '}
                </td>
                <td role="presentation" style={{ color: StyleConstants.color.primary, fontWeight: StyleConstants.textWeight.semiBold }} onClick={() => history.push(`/brief_me/browse?bm${type}Id=${detail.id}`)}>
                  {' '}
                  {detail.name}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {detail.chapter_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {detail.brief_count}
                  {' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  toggleStatus(status) {
    return status === 'ACTIVE' ? 'HIDDEN' : 'ACTIVE';
  }

  renderToggleSubject() {
    const {
      bmSubjectId,
    } = queryString.parse(this.props.location.search);
    const {
      briefMe: {
        selectedSubjectStatus,
      },
    } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
        <input
          type="checkbox"
          checked={selectedSubjectStatus === 'ACTIVE'}
          onChange={() => this.props.updateBriefReducer({ selectedSubjectStatus: this.toggleStatus(selectedSubjectStatus), bmSubjectId })}
          style={{ fontSize: StyleConstants.textSize.tertiaryHeader, margin: '0px 5px', cursor: 'pointer' }}
        />
        {this.renderDescription('Active')}
      </div>
    );
  }

  renderEditButton() {
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        onClick={() => this.setState({ showEditModal: true })}
        style={{
          width: 100,
        }}
      >
        Edit Name
      </div>
    );
  }

  renderSemesterBmSubjectList() {
    const {
      briefMe: {
        overallDetails, hasCreateSubjectAccess,
      },
    } = this.props;

    return (
      <>
        {overallDetails && overallDetails.selectedSemester && this.renderPageTopNavigation(overallDetails.selectedSemester.name)}
        {hasCreateSubjectAccess && this.renderButtonToAddDetail('Subject')}
        <div className="Card-View" style={{ marginTop: 20 }}>
          {this.renderSubjectTableDetails('Subject')}
        </div>
      </>
    );
  }

  renderBmSubjectChapterList() {
    const {
      briefMe: {
        overallDetails, isAdmin,
      },
    } = this.props;

    return (
      <>
        {overallDetails && overallDetails.selectedBmSubject && this.renderPageTopNavigation(overallDetails.selectedBmSubject.name)}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 40 }}>
              {this.renderEditButton()}
            </div>
            {isAdmin && this.renderToggleSubject()}
          </div>
          {this.renderButtonToAddDetail('Chapter')}
        </div>
        <div className="Card-View" style={{ marginTop: 20 }}>
          {this.renderChapterTableDetails('Chapter')}
        </div>
      </>
    );
  }

  renderBmChapterBriefs() {
    const {
      briefMe: {
        overallDetails,
      },
    } = this.props;

    const {
      bmChapterId, print_chapter_briefs: printChapterBriefs, print_chapter_brief_questions: printChapterBriefQuestions
    } = queryString.parse(this.props.location.search);

    return (
      <>
        {!printChapterBriefs && !printChapterBriefQuestions && overallDetails && overallDetails.selectedBmChapter && this.renderPageTopNavigation(overallDetails.selectedBmChapter.name)}
        <BriefMe bmChapterId={parseInt(bmChapterId, 10)} />
      </>
    );
  }

  renderBmCourses() {
    const {
      briefMe: {
        bmCourses,
      },
    } = this.props;
    const { selectedBmCourse } = this.state;

    return (
      <div className="Card-View">
        <Text
          text="Select a Course"
          color={StyleConstants.color.mediumDark}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.secondaryHeader}
        />
        <div className="flexcontainer">
          {bmCourses.map((bmCourse) => (
            <div
              role="presentation"
              className={`Button ${selectedBmCourse === bmCourse.id && 'Primary-Button'}`}
              onClick={() => this.setState({ selectedBmCourse: bmCourse.id, selectedSemester: '' })}
              style={{
                margin: 10, padding: '0px 20px', border: '1px solid #ddd', borderRadius: 4,
              }}
            >
              {bmCourse.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderSemesters() {
    const {
      briefMe: {
        semesters,
      },
    } = this.props;
    const { selectedSemester, selectedBmCourse } = this.state;

    return (
      <div className="Card-View" style={{ marginTop: 20 }}>
        <Text
          text="Select a Semester"
          color={StyleConstants.color.mediumDark}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.secondaryHeader}
        />
        <div className="flexcontainer">
          {semesters[selectedBmCourse].semesters.map((semester, index) => (
            <div
              role="presentation"
              className={`Button ${selectedSemester === semester && 'Primary-Button'}`}
              onClick={() => this.setState({ selectedSemester: semester })}
              style={{
                margin: 10, padding: '0px 20px', border: '1px solid #ddd', borderRadius: 4,
              }}
            >
              {semesters[selectedBmCourse].semesters_name[index]}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderBmCourseSemesterSelection() {
    const { selectedBmCourse, selectedSemester } = this.state;

    return (
      <>
        {this.renderBmCourses()}
        {selectedBmCourse && this.renderSemesters()}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{ width: 250, opacity: !selectedSemester && 0.5 }}
            onClick={() => selectedSemester && history.push(`/brief_me/browse?bmCourseId=${selectedBmCourse}&semesterId=${selectedSemester}`)}
          >
            Next
          </div>
        </div>
      </>
    );
  }

  renderPageContent() {
    const {
      briefMe: {
        semesters, bmCourses,
      },
    } = this.props;
    const {
      semesterId, bmSubjectId, bmChapterId,
    } = queryString.parse(this.props.location.search);

    if (bmChapterId) {
      return this.renderBmChapterBriefs();
    }
    if (bmSubjectId) {
      return this.renderBmSubjectChapterList();
    }
    if (semesterId) {
      return this.renderSemesterBmSubjectList();
    }
    if (Object.values(semesters).length > 0 && bmCourses.length > 0) {
      return this.renderBmCourseSemesterSelection();
    }
  }

  handleOnCreateButtonClick(detailType, selectedDetailName) {
    const {
      semesterId, bmSubjectId,
    } = queryString.parse(this.props.location.search);

    if (detailType === 'Subject') {
      this.props.addSubject(selectedDetailName, semesterId);
    } else {
      this.props.addChapter(selectedDetailName, bmSubjectId);
    }
  }

  renderButtonToAddDetail(detailType) {
    const { selectedSubjectToAdd, selectedChapterToAdd } = this.state;
    const {
      bmSubjectId,
    } = queryString.parse(this.props.location.search);
    const selectedDetailName = detailType === 'Subject' ? selectedSubjectToAdd : selectedChapterToAdd;
    const disableButton = (!bmSubjectId && detailType === 'Chapter') || selectedDetailName === '';

    return (
      <div style={{
        display: 'flex', width: 380, justifyContent: 'space-between',
      }}
      >
        <input
          style={{
            height: 35, width: 260, borderRadius: 0, backgroundColor: '#FFF',
          }}
          type="text"
          value={detailType === 'Subject' ? selectedSubjectToAdd : selectedChapterToAdd}
          placeholder={`New ${detailType}`}
          onChange={(event) => ((detailType === 'Subject' ? this.setState({ selectedSubjectToAdd: event.target.value }) : this.setState({ selectedChapterToAdd: event.target.value })))}
        />
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{ width: 110, opacity: disableButton ? 0.5 : 1, cursor: disableButton ? 'not-allowed' : 'pointer' }}
          onClick={() => !disableButton && this.handleOnCreateButtonClick(detailType, selectedDetailName)}
        >
          Create
          {' '}
          {detailType}
        </div>
      </div>
    );
  }

  renderContent(hasCreateSubjectAccess, showEditModal, printChapterBriefs = false, printChapterBriefQuestions = false) {
    const {
      briefMe: {
        briefOrder,
      },
    } = this.props;

    const { bmChapterId, show_versions: showVersions } = queryString.parse(this.props.location.search);

    return (
      <div>
        {bmChapterId && !showVersions && !printChapterBriefs && !printChapterBriefQuestions
        && (
        <>
          {this.renderButtonToScrollToTopOrBottom()}
          {this.renderButtonToScrollToTopOrBottom(false)}
        </>
        )}
        {!printChapterBriefs && !printChapterBriefQuestions && hasCreateSubjectAccess && <BriefMeTabs selectedType="Browse" />}
        <div style={{ padding: briefOrder.length === 0 ? '40px 40px 200px 40px' : 40 }}>
          {this.renderPageContent()}
          {showEditModal && this.renderEditModal(showEditModal)}
        </div>
      </div>
    );
  }

  renderButtonToScrollToTopOrBottom(isTopArrow = true) {
    return (
      <div
        role="presentation"
        style={{
          position: 'fixed', top: isTopArrow && 60, bottom: !isTopArrow && 60, left: 260, cursor: 'pointer',
        }}
        onClick={() => (isTopArrow ? window.scrollTo(0, 0) : window.scrollTo(0, document.body.scrollHeight))}
      >
        <img
          alt={isTopArrow ? 'up-arrow' : 'down-arrow'}
          src={isTopArrow ? UpArrow : DownArrow}
          style={{ height: 20, width: 20 }}
        />
      </div>
    );
  }

  render() {
    const { showEditModal } = this.state;
    const {
      briefMe: {
        hasCreateSubjectAccess, isLoading,
      },
    } = this.props;
    const { bmChapterId, print_chapter_briefs: printChapterBriefs, print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(this.props.location.search);

    if (printChapterBriefs || printChapterBriefQuestions) {
      return this.renderContent(hasCreateSubjectAccess, showEditModal, printChapterBriefs, printChapterBriefQuestions);
    }

    return (
      <Layout headerText={fileConstants.contentsMenuOrder.BRIEFME.label} currentPage={fileConstants.contentsMenuOrder.BRIEFME.position} onlyBriefAccess={userProfile.has_only_brief_me_access}>
        {(isLoading && !bmChapterId) ? <Loader /> : this.renderContent(hasCreateSubjectAccess, showEditModal)}
      </Layout>
    );
  }
}

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  getBriefMeCourseSemesterDetails,
  getBriefMeSemesterSubjectList,
  getBriefMeSubjectChapterList,
  updateBriefReducer,
  addSubject,
  addChapter,
  editSubjectName,
  toggleSubjectStatus,
  toggleChapterStatus,
  updateBmChapterOrder,
  showToast
})(BriefMeBrowse);
