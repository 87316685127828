import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import BriefMePermissions from './setPermissions';
import BriefMeDashboard from './briefMeDashboard';
import fileConstants from '../../shared/constants/fileConstants';
import Layout from '../layout';
import { getBriefFilterData, updateBriefReducer } from '../../actions';
import BriefMeBrowse from './briefMeBrowse';
import BriefFindById from './briefFindById';
import BriefFindAndReplace from './briefFindAndReplace';
import Dictionary from './dictionary';
import Formula from './formula';
import Diagram from './diagram';
import SubscriptConverter from './subscriptConverter';
import QuestionTagging from './questionTagging';

const cookies = new Cookies();
const userProfile = cookies.get('user');
const history = createBrowserHistory({
  forceRefresh: true,
});

const BRIEFME_TYPES = {
  dashboard: 'Dashboard',
  browse: 'Browse',
  permissions: 'Permissions',
  find: 'Find',
  findAndReplace: 'Find And Replace',
  subscriptConverter: 'Subscript Converter',
  dictionary: 'Dictionary',
  formula: 'Formula',
  diagram: 'Diagram',
  questionTagging: 'Question Tagging',
};

window.addEventListener('popstate', () => {
  history.go();
});

class BriefMeContent extends PureComponent {
  constructor(props) {
    super(props);
    const { match: { params: { briefType } } } = props;
    this.state = {
      selectedType: briefType ? BRIEFME_TYPES[briefType] : BRIEFME_TYPES.dashboard,
    };
  }

  componentDidMount() {
    const { hasBriefMeAccess } = this.props;
    this.props.getBriefFilterData();
    if (hasBriefMeAccess === null) {
      this.props.updateBriefReducer({ hasBriefMeAccess: userProfile.has_brief_me_access });
    }
  }

  renderBriefMe() {
    const { selectedType } = this.state;
    const { hasCreateSubjectAccess, hasBriefMeAccess } = this.props;
    return (
      <>
        {hasCreateSubjectAccess && selectedType === BRIEFME_TYPES.dashboard && <BriefMeDashboard />}
        {hasBriefMeAccess && selectedType === BRIEFME_TYPES.browse && <BriefMeBrowse />}
        {hasCreateSubjectAccess && selectedType === BRIEFME_TYPES.permissions && <BriefMePermissions />}
        {hasCreateSubjectAccess && selectedType === BRIEFME_TYPES.find && <BriefFindById />}
        {hasCreateSubjectAccess && selectedType === BRIEFME_TYPES.findAndReplace && <BriefFindAndReplace />}
        {hasCreateSubjectAccess && selectedType === BRIEFME_TYPES.subscriptConverter && <SubscriptConverter />}
        {hasBriefMeAccess && selectedType === BRIEFME_TYPES.dictionary && <Dictionary />}
        {hasBriefMeAccess && selectedType === BRIEFME_TYPES.formula && <Formula />}
        {hasBriefMeAccess && selectedType === BRIEFME_TYPES.diagram && <Diagram />}
        {hasBriefMeAccess && selectedType === BRIEFME_TYPES.questionTagging && <QuestionTagging />}
      </>
    );
  }

  render() {
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.BRIEFME.label} currentPage={fileConstants.contentsMenuOrder.BRIEFME.position} onlyBriefAccess={userProfile.has_only_brief_me_access}>
        {this.renderBriefMe()}
      </Layout>
    );
  }
}

BriefMeContent.propTypes = {
  match: PropTypes.object.isRequired,
  hasBriefMeAccess: PropTypes.bool.isRequired,
  updateBriefReducer: PropTypes.func.isRequired,
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
};

BriefMeContent.defaultProps = {
};

const mapStateToProps = ({ question, briefMe }) => ({
  subjects: question.subjects,
  hasBriefMeAccess: briefMe.hasBriefMeAccess,
  isLoading: false,
  hasCreateSubjectAccess: briefMe.hasCreateSubjectAccess,
});

export default connect(mapStateToProps, { getBriefFilterData, updateBriefReducer })(BriefMeContent);
