import React, { PureComponent } from 'react';
import { createBrowserHistory } from 'history';
import Text from './text';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import { getVimeoVideoId, isMobileDevice } from '../../helpers/Utils';
import ReportError from '../reportError';

const history = createBrowserHistory({
  forceRefresh: true,
});

const IS_MOBILE = isMobileDevice();
const SOLUTION_TYPES = {
  TEXT: 'Text',
  VIDEO: 'Video',
};

const IMAGE_WIDTH = {
  0: { width: '50%', maxWidth: 310 },
  1: { width: '25%', maxWidth: 160 },
  2: { width: '50%', maxWidth: 310 },
  3: { width: '70%', maxWidth: 430 },
};

const OPTION_IMAGE_WIDTH = {
  0: { width: '40%', maxWidth: 250 },
  1: { width: '25%', maxWidth: 160 },
  2: { width: '40%', maxWidth: 250 },
  3: { width: '50%', maxWidth: 310 },
};

const DIFFICULTY_COLOR = {
  EASY: '#49BEB7',
  MEDIUM: '#FFB038',
  HARD: '#FF5F5F',
};

const DIFFICULTY = {
  EASY: 'E',
  MEDIUM: 'M',
  HARD: 'H',
};

const DIFFICULTY_VALUE_MAPPING = [null, 'EASY', 'EASY', 'MEDIUM', 'HARD', 'HARD'];

class QuestionView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBookmarkTypeModal: false,
      showVideoSolutionModal: false,
      showReportErrorModal: false,
      selectedErrorType: 0,
      reportText: '',
      showSolution: false,
    };
    this.renderOptions = this.renderOptions.bind(this);
    this.renderSolution = this.renderSolution.bind(this);
    this.optionsLayout = this.optionsLayout.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getImageWidth(isQuestionText, widthType) {
    const isDefinedWidth = widthType === '1' || widthType === '2' || widthType === '3';
    if (isDefinedWidth) {
      return isQuestionText ? IMAGE_WIDTH[widthType] : OPTION_IMAGE_WIDTH[widthType];
    }
    return isQuestionText ? IMAGE_WIDTH['0'] : OPTION_IMAGE_WIDTH['0'];
  }

  changeShowSolutionState(value) {
    const { showSolution } = this.state;
    let showSolutionText = '';
    if (showSolution !== value) {
      showSolutionText = value;
    }
    this.setState({ showSolution: showSolutionText });
  }

  showHideSolution(value) {
    const { showSolution } = this.state;
    const { dontHideSolution } = this.props;
    const text = dontHideSolution ? 'Solution' : (showSolution === value) ? 'Hide Solution' : 'Show Solution';
    return (
      <div
        style={{ display: 'flex', cursor: 'pointer', marginRight: IS_MOBILE ? 10 : 15 }}
        onClick={() => !dontHideSolution && this.changeShowSolutionState(value)}
      >
        <Text
          fontWeight={StyleConstants.textWeight.bold}
          text={text}
          color={StyleConstants.contentSearch.solutionHeaderColor}
          style={{
            textDecoration: 'underline',
          }}
        />
      </div>
    );
  }

  renderSolution(currentQuestion) {
    const { showSolution } = this.state;
    const { dontHideSolution, videoSolutionHeight, videoSolutionWidth } = this.props;
    const videoHeight = videoSolutionHeight || (IS_MOBILE ? 150 : 300);
    const videoWidth = videoSolutionWidth || (IS_MOBILE ? 250 : 640);
    const videoId = getVimeoVideoId(currentQuestion.video_solution_uri);
    if (currentQuestion.solution_supporting_picture || currentQuestion.solution_text || videoId) {
      return (
        <div style={{ margin: currentQuestion.solution_text !== '' && '15px 0px' }}>
          <div style={{ display: 'flex' }}>
            {this.showHideSolution(SOLUTION_TYPES.TEXT)}
            {false && (videoId !== '') && this.showHideSolution(SOLUTION_TYPES.VIDEO)}
          </div>

          <div style={{ display: (showSolution === SOLUTION_TYPES.TEXT || showSolution === true || dontHideSolution) ? 'flex' : 'none', flexDirection: 'column' }}>
            {currentQuestion.solution_supporting_picture
              && (
              <img
                alt="img"
                height="100%"
                width={OPTION_IMAGE_WIDTH[currentQuestion.image_widths.solution_supporting_picture_size].width}
                src={currentQuestion.solution_supporting_picture}
                style={{ maxWidth: OPTION_IMAGE_WIDTH[currentQuestion.image_widths.solution_supporting_picture_size].maxWidth, marginTop: 5 }}
              />
              )}
            <div style={{ marginTop: 5, display: 'flex' }}>
              {(currentQuestion.solution_text && currentQuestion.solution_text !== '')
                && (
                <Text
                  color={StyleConstants.textColor.primary}
                  fontWeight={StyleConstants.textWeight.semiBold}
                  text={currentQuestion.solution_text}
                />
                )}
            </div>
            {(videoId !== '')
              && (
              <div style={{ borderRadius: 4, marginTop: 5 }}>
                <iframe title="Question Video Solution" src={`https://player.vimeo.com/video/${videoId}#toolbar=0`} frameBorder="0" allow="autoplay; fullscreen" width={videoWidth} height={videoHeight} allowFullScreen />
              </div>
              )}
          </div>
        </div>
      );
    }
  }

  renderOptions(text, srcImage, widthType, index, isCorrectAnswer = false) {
    const { width, maxWidth } = this.getImageWidth(false, widthType);
    const hasImage = srcImage && srcImage !== '';
    if (hasImage) {
      return (
        <div id={`Option-Tap-${index}`} style={{ marginLeft: 15, marginBottom: 10, display: 'flex' }}>
          <Text
            color={isCorrectAnswer ? StyleConstants.contentSearch.answerColor : StyleConstants.contentSearch.optionColor}
            fontWeight={isCorrectAnswer ? StyleConstants.textWeight.bold : ''}
            text={`${index}. `}
          />
          <img alt="img" width={width} height="100%" src={srcImage} style={{ maxWidth }} />
        </div>
      );
    }
    return (
      <div id={`Option-Tap-${index}`} style={{ marginLeft: 15 }}>
        <Text
          color={isCorrectAnswer ? StyleConstants.contentSearch.answerColor : StyleConstants.contentSearch.optionColor}
          fontWeight={isCorrectAnswer ? StyleConstants.textWeight.bold : ''}
          text={`${index}.  ${text}`}
          style={{ display: 'flex' }}
        />
      </div>
    );
  }

  optionsLayout(question) {
    const options_array = [1, 2, 3, 4];
    const marginTop = 10;
    const imageWidth = (question.image_widths && !IS_MOBILE) ? question.image_widths : {};
    if ((!question.options_layout || question.options_layout < 2) && !IS_MOBILE && false) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop }}>
          {options_array.map((i) => <div className="mr-4" key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</div>)}
        </div>
      );
    }
    if ((!question.options_layout || (question.options_layout <= 2)) && !IS_MOBILE) {
      return (
        <div style={{ marginTop }}>
          <tr>
            {options_array.map((i) => {
              if (i <= 2) {
                return (<td style={{ paddingRight: 20 }} key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</td>);
              }
              return '';
            })}
          </tr>
          <tr>
            {options_array.map((i) => {
              if (i > 2) {
                return (<td key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</td>);
              }
              return '';
            })}
          </tr>
        </div>
      );
    }
    return (
      <div style={{ marginTop }}>
        {options_array.map((i) => <div key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</div>)}
      </div>
    );
  }

  renderQuestionViewComponents(currentIndex, text, currentQuestion, hasImage, isQuestionText, width, srcImage, maxWidth) {
    const { canReport, canEdit } = this.props;
    if (currentQuestion.image_widths.supporting_picture_position === 'center' || IS_MOBILE) {
      return (
        <div>
          <tr>
            <td style={{ display: 'flex' }}>
              {!!currentIndex
                && (
                <Text
                  text={`${currentIndex}. `}
                  color={StyleConstants.contentSearch.primary}
                />
                )}
              <div style={{ whiteSpace: 'pre-line', marginLeft: currentIndex && 10 }}>
                <Text
                  text={text}
                  color={StyleConstants.contentSearch.primary}
                />
              </div>
            </td>
          </tr>
          <tr>
            {hasImage
              && (
              <td className="question-analysis" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: isQuestionText && 'center', margin: '10px 0px' }}>
                  <img alt="img" width={width} height="100%" src={srcImage} style={{ maxWidth }} />
                </div>
              </td>
              )}
          </tr>
          <tr>
            <div style={{ marginLeft: 15 }}>
              {this.optionsLayout(currentQuestion)}
            </div>
          </tr>
        </div>
      );
    }
    return (
      <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
        <td style={{ borderTop: 0, verticalAlign: 'top', marginRight: (canEdit || canReport) ? 10 : 0 }}>
          <div>
            <div style={{ width: 30, float: 'left' }}>
              {!!currentIndex
                && (
                <Text
                  text={`${currentIndex}. `}
                  color={StyleConstants.contentSearch.primary}
                />
                )}
            </div>
            <div style={{ whiteSpace: 'pre-line', marginLeft: currentIndex && 30 }}>
              {(!hasImage || isQuestionText)
                && (
                <Text
                  text={text}
                  color={StyleConstants.contentSearch.primary}
                />
                )}
            </div>
          </div>
          <div style={{ marginLeft: 15 }}>
            {this.optionsLayout(currentQuestion)}
          </div>
        </td>
        <td>
          {hasImage
            && (
            <div style={{ verticalAlign: 'top' }}>
              <img alt="img" width={width} height="100%" src={srcImage} style={{ maxWidth }} />
            </div>
            )}
        </td>
      </tr>
    );
  }

  renderReportError(currentQuestion) {
    const { canReport, canEdit } = this.props;
    return (
      <div>
        {canEdit && (
        <div
          className="Button Secondary-Button"
          style={{ marginBottom: 10, width: 100 }}
          role="presentation"
          onClick={() => history.push(`/questions/edit/${currentQuestion.id}`)}
        >
          Edit
        </div>
        )}
        {canReport && <ReportError questionId={currentQuestion.id} key={currentQuestion.id} />}
      </div>
    );
  }

  renderView(text, srcImage, widthType) {
    const isQuestionText = true;
    const {
      currentQuestion, currentIndex, canReport, canEdit,
    } = this.props;
    const { width, maxWidth } = this.getImageWidth(isQuestionText, widthType);
    const hasImage = srcImage && srcImage !== '';
    const question = currentQuestion;

    return (
      <div key={`QuestionID_${currentQuestion.id}`} style={{ padding: 20, margin: '0px auto' }}>
        {question && (!!question.complexity) && false
          && (
          <div style={{
            color: DIFFICULTY_COLOR[DIFFICULTY_VALUE_MAPPING[question.complexity]], borderColor: DIFFICULTY_COLOR[question.complexity], textAlign: 'center', marginRight: '0.7%', height: '24px', width: '24px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '50%', lineHeight: '22px', marginBottom: 10, cursor: 'default',
          }}
          >
            {DIFFICULTY[DIFFICULTY_VALUE_MAPPING[question.complexity]]}
          </div>
          )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {this.renderQuestionViewComponents(currentIndex, text, currentQuestion, hasImage, isQuestionText, width, srcImage, maxWidth)}
          {(canReport || canEdit) && this.renderReportError(currentQuestion)}
        </div>
        <div style={{ paddingLeft: IS_MOBILE ? 0 : 30 }}>
          <div style={{ whiteSpace: 'pre-line' }}>
            {[...Array(4)].map((_, i) => currentQuestion.answers.includes(i + 1) && this.renderSolution(currentQuestion))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { currentQuestion, style } = this.props;
    const questionImageWidth = currentQuestion.image_widths;
    if (!currentQuestion.id) {
      return <div />;
    }
    return (
      <div
        id={`question-id-${currentQuestion.id}`}
        style={{
          backgroundColor: StyleConstants.color.white, boxShadow: '1px 2px 6px #8B9DAF26', borderRadius: 4, margin: '20px 0px', ...style,
        }}
      >
        {this.renderView(currentQuestion.question_text, currentQuestion.supporting_picture, questionImageWidth && questionImageWidth.supporting_picture_size)}
      </div>
    );
  }
}

export default QuestionView;
