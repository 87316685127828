import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  DropDown, Loader, BriefMeTabs, ShowDictionaryItem, EditDictionaryItem, Text,
} from '../common';
import {
  getBriefsDictionary,
  updateBriefsDictionary,
  resetDictionaryItem,
} from '../../actions';

const MOBILE_VIEW_WIDTH = 325;
const history = createBrowserHistory({
  forceRefresh: true,
});

class Dictionary extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    this.props.getBriefsDictionary({ bmSubjectId });
  }

  handleAddButtonClick() {
    this.props.updateBriefsDictionary({ displayEditView: false }).then(() => {
      this.props.resetDictionaryItem({ displayEditView: true });
    });
  }

  handleClick({ id, term, description, selectedIndex }) {
    const { show_versions: showVersions } = queryString.parse(window.location.search);
    this.props.updateBriefsDictionary({ displayEditView: false }).then(() => {
      if (!showVersions) {
        this.props.updateBriefsDictionary({
          term, description, isChanged: false, enableFilterSearch: false, selectedIndex,
          selectedDictionaryItem: id, displayEditView: true, isDictionaryItemSelected: true,
        });
      }
    });
  }

  rendersubjectSelection() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    const { briefMe: { subjects } } = this.props;
    let currentSubject = {};
    if (Object.values(subjects).length) {
      currentSubject = Object.values(subjects).filter((subject) => subject.id === parseInt(bmSubjectId, 10))[0] || {};
    }
    const subjectDropdownOptions = {
      key: Object.values(subjects).length,
      optionType: 'BmSubject',
      defaultText: 'BmSubject',
      optionsList: Object.values(subjects),
      selectedItem: currentSubject,
      width: 250,
      height: 35,
      onValueChange: (id, name) => history.push({ search: id ? `?bm_subject_id=${id}` : '' }),
    };
    return (
      <div style={{ marginBottom: 15 }}>
        {this.renderDropdown(subjectDropdownOptions)}
      </div>
    );
  }

  renderDropdown(dropdownOptions) {
    return (
      <DropDown
        key={dropdownOptions.key}
        selectedItem={dropdownOptions.selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={dropdownOptions.onValueChange}
      />
    );
  }

  renderButtonToAddBrief() {
    const { briefsDictionary: { dictionary } } = this.props;
    return (
      <>
        <div
          role="presentation"
          className="fa fa-plus"
          style={{ margin: '10px 0px', cursor: 'pointer', fontSize: 24 }}
          onClick={() => this.handleAddButtonClick()}
        />
        {!!dictionary.length && (
          <div style={{ marginBottom: 15 }}>
            <Text
              text={`Total terms: ${dictionary.length}`}
              color={StyleConstants.color.mediumDark}
              fontWeight={StyleConstants.textWeight.bold}
              fontSize={StyleConstants.textSize.title}
            />
          </div>
        )}
      </>
    );
  }

  renderNoContent() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    const { briefsDictionary: { dictionary } } = this.props;
    let message = '';
    if (parseInt(bmSubjectId, 10)) {
      message = 'Create new Terms';
    } else if (!dictionary.length) {
      message = 'Please select a subject to proceed';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', fontSize: 24 }}>
        <p>{message}</p>
      </div>
    );
  }

  renderDictionaryContents() {
    const { briefsDictionary: { dictionary } } = this.props;
    if (!dictionary.length) {
      return this.renderNoContent();
    }

    const {
      briefsDictionary: {
        selectedDictionaryItem, displayEditView,
      },
      printDictionary,
    } = this.props;

    return (
      <>
        <style jsx>
          {`
            table tr td{
              padding: 10px 10px !important;
            }
            th {
              border-bottom: none !important;
            }
        `}
        </style>
        <table
          className="table table-striped table-hover"
          style={{
            textAlign: 'center',
            width: 'fit-content',
            backgroundColor: 'white',
            padding: 1,
            border: `1px solid ${StyleConstants.color.inactive}`,
          }}
        >
          <thead>
            <tr
              style={{
                display: 'flex',
                fontWeight: 'bold',
                fontSize: StyleConstants.textSize.subHeader,
              }}
            >
              <th> Index </th>
              <th> Term </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'start' }}>
            {dictionary.map((item, index) => {
              const isItemSelected = (!printDictionary && displayEditView && (item.id === selectedDictionaryItem));
              return (
                <tr
                  className="table-row-selection"
                  style={{
                    border: isItemSelected ? `2px solid ${StyleConstants.color.primary}` : ' ',
                    backgroundColor: isItemSelected ? 'white' : ' ',
                    display: 'flex',
                  }}
                  onClick={() => this.handleClick({ ...item, selectedIndex: index })}
                >
                  <td style={{ fontWeight: StyleConstants.textWeight.bold, width: 50 }}>{index + 1}</td>
                  <td>
                    <ShowDictionaryItem
                      id={item.id}
                      key={item.id}
                      dictionaryTerm={item.term}
                      dictionaryDescription={item.description}
                      index={index}
                      width={MOBILE_VIEW_WIDTH}
                      isItemSelected={isItemSelected}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
  
  renderBody() {
    const { briefsDictionary: { displayEditView, selectedIndex } } = this.props;
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    return (
      <div style={{ margin: '10px 40px', height: '100%' }}>
        {this.rendersubjectSelection()}
        {(selectedIndex !== null) && <EditDictionaryItem displayEditView={displayEditView} bmSubjectId={parseInt(bmSubjectId, 10)} />}
        {!!parseInt(bmSubjectId, 10) && this.renderButtonToAddBrief()}
        {this.renderDictionaryContents()}
      </div>
    );
  }

  render() {
    const { briefMe: { isLoading } } = this.props;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div>
        <BriefMeTabs selectedType="Dictionary" />
        {this.renderBody()}
      </div>
    );
  }

}

Dictionary.propTypes = {
  briefsDictionary: PropTypes.object.isRequired,
  updateBriefsDictionary: PropTypes.func.isRequired,
  resetDictionaryItem: PropTypes.func.isRequired,
  briefMe: PropTypes.object.isRequired,
  printDictionary: PropTypes.bool,
};

Dictionary.defaultProps = {
  printDictionary: false,
};
const mapStateToProps = ({ briefsDictionary, briefMe }) => ({
  briefsDictionary,
  briefMe,
});

export default connect(mapStateToProps, {
  getBriefsDictionary,
  updateBriefsDictionary,
  resetDictionaryItem,
})(Dictionary);
