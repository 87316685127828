import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import { numberFormatterWithCommas } from '../../helpers/Utils';
import QuestionView from '../common/questionView';
import Text from '../common/text';
import Layout from '../layout';
import offlineTags from '../../shared/images/common/offlinetags.svg';
import {
  getInstituteCourseSubjects, getInstituteSubjectChapterList, getInstituteChapterTopicList, getInstituteTopicQuestions,
} from '../../actions/index';
import { DIFFICULTY, DIFFICULTY_COLOR, DIFFICULTY_VALUE_MAPPING } from '../../shared/constants/fieldTypes';

const history = createBrowserHistory({
  forceRefresh: true,
});

class Browse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCourse: '',
      selectedSubject: '',
    };
  }

  componentDidMount() {
    const {
      courseId, subjectId, chapterId, topicId, page,
    } = queryString.parse(this.props.location.search);
    if (topicId && this.props.questions.length === 0) {
      this.props.getInstituteTopicQuestions(topicId, page);
      return;
    }
    if (chapterId && this.props.topics.length === 0) {
      this.props.getInstituteChapterTopicList(chapterId);
      return;
    }
    if (courseId && subjectId && this.props.chapters.length === 0) {
      this.props.getInstituteSubjectChapterList(courseId, subjectId);
      return;
    }
    if (this.props.subjects.length === 0 && !topicId && !chapterId && !courseId && !subjectId) {
      this.props.getInstituteCourseSubjects({ courses: true, subjects: true });
    }
  }

  renderOverViewElement(text, value) {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10,
      }}
      >
        <Text
          text={text}
          style={{ textAlign: 'center' }}
          color={StyleConstants.textColor.secondary}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.subHeader}
        />
        <Text
          text={value}
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          fontSize={StyleConstants.textSize.subHeader}
        />
      </div>
    );
  }

  renderQuestionStats() {
    const { overallDetails } = this.props;
    return (
      <>
        <Text
          text="Question Stats"
          fontSize={StyleConstants.textSize.tertiaryHeader}
          color={StyleConstants.textColor.secondary}
          fontWeight={StyleConstants.textWeight.semiBold}
        />
        <div style={{
          display: 'flex', justifyContent: 'space-evenly', padding: 10, border: '1px solid #ddd', marginBottom: 20,
        }}
        >
          {(overallDetails.total_question_count || overallDetails.total_question_count === 0) && this.renderOverViewElement('TOTAL QUESTIONS', numberFormatterWithCommas(overallDetails.total_question_count))}
          {(overallDetails.untagged_question_count) && this.renderOverViewElement('WITHOUT TOPIC TAGGING', `${numberFormatterWithCommas(overallDetails.untagged_question_count)} (${parseInt((overallDetails.untagged_question_count * 100) / overallDetails.total_question_count)})%`)}
          {(overallDetails.untagged_complexity_question_count || overallDetails.untagged_complexity_question_count === 0) && this.renderOverViewElement('WITHOUT DIFFICULTY TAGGING', `${numberFormatterWithCommas(overallDetails.untagged_complexity_question_count)} (${Math.ceil((overallDetails.untagged_complexity_question_count * 100) / overallDetails.total_question_count)})%`)}
          {(overallDetails.questions_to_be_proofread || overallDetails.questions_to_be_proofread === 0) && this.renderOverViewElement('WITHOUT PROOFREADING', `${numberFormatterWithCommas(overallDetails.questions_to_be_proofread)} (${Math.ceil((overallDetails.questions_to_be_proofread * 100) / overallDetails.total_question_count)})%`)}
          {(overallDetails.report_error_question_count || overallDetails.report_error_question_count === 0) && this.renderOverViewElement('UNRESOLVED ERRORS', `${numberFormatterWithCommas(overallDetails.report_error_question_count)} (${Math.ceil((overallDetails.report_error_question_count * 100) / overallDetails.total_question_count)})%`)}
        </div>
      </>
    );
  }

  renderPageTableTitle(text) {
    return (
      <Text
        text={text}
        style={{ margin: '10px 0px' }}
        fontSize={StyleConstants.textSize.tertiaryHeader}
        color={StyleConstants.textColor.secondary}
        fontWeight={StyleConstants.textWeight.semiBold}
      />
    );
  }

  renderPageTitle(text) {
    return (
      <Text
        text={text}
        color={StyleConstants.color.primary}
        fontFamily={StyleConstants.textFont.header}
        fontSize={StyleConstants.textSize.secondaryHeader}
        fontWeight={StyleConstants.textWeight.bold}
      />
    );
  }

  renderNavigationText(text, isHyperlink = false) {
    return (
      <Text
        text={text}
        style={{ cursor: isHyperlink && 'pointer', textDecoration: isHyperlink && 'underline' }}
        color={StyleConstants.textColor.secondary}
        fontFamily={StyleConstants.textFont.header}
        fontSize={StyleConstants.textSize.tertiaryHeader}
        fontWeight={StyleConstants.textWeight.bold}
      />
    );
  }

  renderPageTopNavigation(pageHeaderText) {
    const links = this.getPageLinks();
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderPageTitle(pageHeaderText)}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.renderNavigationText('in ')}
          {links.map((link, index) => (
            <>
              <div onClick={() => history.push(link.path)}>
                {this.renderNavigationText(link.name, true)}
              </div>
              {index + 1 !== links.length && this.renderNavigationText(' > ')}
            </>
          ))}
        </div>
      </div>
    );
  }

  getPageLinks() {
    const { overallDetails } = this.props;
    const links = [];
    if (overallDetails.selectedSubject && overallDetails.selectedSubject.name) {
      links.push({ path: '/browse', name: overallDetails.selectedCourse.name });
    }
    if (overallDetails.selectedChapter && overallDetails.selectedChapter.name) {
      links.push({ path: `/browse?courseId=${overallDetails.selectedCourse.id}&subjectId=${overallDetails.selectedSubject.id}`, name: overallDetails.selectedSubject.name });
    }
    if (overallDetails.selectedTopic && overallDetails.selectedTopic.name) {
      links.push({ path: `/browse?chapterId=${overallDetails.selectedChapter.id}&subjectId=${overallDetails.selectedSubject.id}`, name: overallDetails.selectedChapter.name });
    }
    return links;
  }

  renderTags(color, text, type) {
    return (
      <div
        data-tip={type}
        style={{
          color, marginRight: 10, borderRadius: '15px', padding: '1px 9px', textAlign: 'center', height: '24px', borderStyle: 'solid', borderWidth: '1px', lineHeight: '22px',
        }}
      >
        {text}
      </div>
    );
  }

  renderTagData(question) {
    return (
      <div style={{ display: 'flex', marginLeft: 40 }}>
        {question.tags.difficulty && parseInt(question.tags.difficulty, 10) && (question.tags.difficulty !== 0) && (
          <div
            className="Circle"
            style={{
              color: DIFFICULTY_COLOR[DIFFICULTY_VALUE_MAPPING[question.tags.difficulty]], borderColor: DIFFICULTY_COLOR[question.tags.difficulty], textAlign: 'center', marginRight: 10,
            }}
          >
            {DIFFICULTY[DIFFICULTY_VALUE_MAPPING[question.tags.difficulty]]}
          </div>
        )}
        {question.tags.dates && question.tags.dates.assignment
          && question.tags.dates.assignment.map((assignment_date) => (
            this.renderTags('#FF1493', assignment_date, 'Assignment', question.id)
          ))}
        {question.tags.dates
          && question.tags.dates.test && question.tags.dates.test.map((dates) => (
          this.renderTags('#5FB5FF', dates, 'Test', question.id)
        ))}
        {(question.tags.previousPapers)
          && (
            <>
              {question.tags.previousPapers.map((type) => (
                this.renderTags('#A054A0', type, 'previousPaper')
              ))}
            </>
          )}
        {(question.tags.offline)
          && (
            <img
              src={offlineTags}
              alt=""
              style={{
                width: '13px', height: '24px', borderRadius: '1px', opacity: 1,
              }}
            />
          )}
      </div>
    );
  }

  renderTopicQuestionList() {
    const { overallDetails, questions } = this.props;
    const { page } = queryString.parse(this.props.location.search);
    const startIndex = ((page - 1) * 20) + 1;
    return (
      <>
        {this.renderPageTopNavigation(overallDetails.selectedTopic.name)}
        <div className="Card-View" style={{ marginTop: 20 }}>
          {this.renderQuestionStats()}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {this.renderPageTableTitle('Questions')}
            {this.props.questions.length > 0
              && (
              <Pagination
                activePage={parseInt(page)}
                itemsCountPerPage={20}
                totalItemsCount={overallDetails.total_question_count}
                pageRangeDisplayed={5}
                onChange={(event) => history.push(`/browse?topicId=${overallDetails.selectedTopic.id}&page=${event}`)}
              />
              )}
            <div />
          </div>
          { window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])}
          {questions.map((question, index) => (
            <div
              style={{
                marginBottom: 10, border: '1px solid #ddd', borderRadius: 4, cursor: 'pointer', padding: 10,
              }}
            >
              <div>
                {this.renderTagData(question)}
                <QuestionView
                  currentIndex={index + startIndex}
                  style={{ backgroundColor: 'inherit', margin: 0, boxShadow: '' }}
                  currentQuestion={question}
                  dontHideSolution
                  canReport
                  canEdit
                  videoSolutionWidth="460px"
                  videoSolutionHeight="200px"
                />
              </div>
            </div>
          ))}
          {this.props.questions.length > 0
            && (
            <div style={{
              display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
            }}
            >
              <Pagination
                activePage={parseInt(page)}
                itemsCountPerPage={20}
                totalItemsCount={overallDetails.total_question_count}
                pageRangeDisplayed={5}
                onChange={(event) => history.push(`/browse?topicId=${overallDetails.selectedTopic.id}&page=${event}`)}
              />
            </div>
            )}
        </div>
      </>
    );
  }

  renderTopicTable() {
    const { topics } = this.props;
    return (
      <>
        {this.renderPageTableTitle('Topics')}
        <table className="table table-striped table-hover" style={{ textAlign: 'center', marginBottom: 0, columnSpan: '10px' }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Topic </th>
              <th style={{ textAlign: 'end' }}> Question Count </th>
              <th style={{ textAlign: 'end' }}> Easy </th>
              <th style={{ textAlign: 'end' }}> Medium </th>
              <th style={{ textAlign: 'end' }}> Hard </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'start' }}>
            {topics.map((topic, index) => (
              <tr className="table-row-selection" onClick={() => history.push(`/browse?topicId=${topic.id}&page=1`)}>
                <td>
                  {' '}
                  {index + 1}
                  {' '}
                </td>
                <td style={{ color: StyleConstants.color.primary, fontWeight: StyleConstants.textWeight.semiBold }}>
                  {' '}
                  {topic.name}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {topic.question_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {topic.easy_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {topic.medium_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {topic.hard_count}
                  {' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  renderChapterTopicList() {
    const { overallDetails } = this.props;
    return (
      <>
        {this.renderPageTopNavigation(overallDetails.selectedChapter.name)}
        <div className="Card-View" style={{ marginTop: 20 }}>
          {this.renderQuestionStats()}
          {this.renderTopicTable()}
        </div>
      </>
    );
  }

  renderChapterTable() {
    const { chapters } = this.props;
    return (
      <>
        {this.renderPageTableTitle('Chapters')}
        <table className="table table-striped table-hover" style={{ textAlign: 'center', marginBottom: 0, columnSpan: '10px' }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Chapter </th>
              <th style={{ textAlign: 'end' }}> Question Count </th>
              <th style={{ textAlign: 'end' }}> Easy </th>
              <th style={{ textAlign: 'end' }}> Medium </th>
              <th style={{ textAlign: 'end' }}> Hard </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'start' }}>
            {chapters.map((chapter, index) => (
              <tr className="table-row-selection" onClick={() => history.push(`/browse?chapterId=${chapter.id}`)}>
                <td>
                  {' '}
                  {index + 1}
                  {' '}
                </td>
                <td style={{ color: StyleConstants.color.primary, fontWeight: StyleConstants.textWeight.semiBold }}>
                  {' '}
                  {chapter.name}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {chapter.question_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {chapter.easy_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {chapter.medium_count}
                  {' '}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {' '}
                  {chapter.hard_count}
                  {' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  renderSubjectChapterList() {
    const { overallDetails } = this.props;
    return (
      <>
        {this.renderPageTopNavigation(overallDetails.selectedSubject.name)}
        <div className="Card-View" style={{ marginTop: 20 }}>
          {this.renderQuestionStats()}
          {this.renderChapterTable()}
        </div>
      </>
    );
  }

  renderCourses() {
    const { courses } = this.props;
    const { selectedCourse } = this.state;
    return (
      <div className="Card-View">
        <Text
          text="Select a Course"
          color={StyleConstants.color.mediumDark}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.secondaryHeader}
        />
        <div className="flexcontainer">
          {courses.map((course) => (
            <div
              className={`Button ${selectedCourse === course.id && 'Secondary-Button'}`}
              onClick={() => this.setState({ selectedCourse: course.id, selectedSubject: '' })}
              style={{
                margin: 10, padding: '0 20px', border: '1px solid #ddd', borderRadius: 4,
              }}
            >
              {course.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderSubjects() {
    const { subjects, courseSubjectMapping } = this.props;
    const { selectedSubject, selectedCourse } = this.state;
    return (
      <div className="Card-View" style={{ marginTop: 20 }}>
        <Text
          text="Select a Subject"
          color={StyleConstants.color.mediumDark}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.secondaryHeader}
        />
        <div className="flexcontainer">
          {subjects.map((subject) => courseSubjectMapping[selectedCourse].includes(subject.id) && (
            <div
              className={`Button ${selectedSubject === subject.id && 'Secondary-Button'}`}
              onClick={() => this.setState({ selectedSubject: subject.id })}
              style={{
                margin: 10, padding: '0 20px', border: '1px solid #ddd', borderRadius: 4,
              }}
            >
              {subject.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderCourseSubjectSelection() {
    const { selectedCourse, selectedSubject } = this.state;
    return (
      <>
        {this.renderCourses()}
        {selectedCourse && this.renderSubjects()}
        <div style={{
          position: 'absolute', bottom: 100, display: 'flex', justifyContent: 'center', width: window.innerWidth - 350,
        }}
        >
          <div
            className="Button Primary-Button"
            style={{ width: 250, opacity: !selectedSubject && 0.5 }}
            onClick={() => selectedSubject && history.push(`/browse?courseId=${selectedCourse}&subjectId=${selectedSubject}`)}
          >
            Next
          </div>
        </div>
      </>
    );
  }

  renderPageContent() {
    const {
      questions, topics, chapters, subjects, courses,
    } = this.props;
    if (questions.length > 0) {
      return this.renderTopicQuestionList();
    }
    if (topics.length > 0) {
      return this.renderChapterTopicList();
    }
    if (chapters.length > 0) {
      return this.renderSubjectChapterList();
    }
    if (subjects.length > 0 && courses.length > 0) {
      return this.renderCourseSubjectSelection();
    }
  }

  render() {
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.BROWSE.label} currentPage={fileConstants.contentsMenuOrder.BROWSE.position}>
        <div style={{ padding: 40 }}>
          {this.renderPageContent()}
        </div>
      </Layout>
    );
  }
}

Browse.propTypes = {
  topics: PropTypes.array,
  courses: PropTypes.array,
  subjects: PropTypes.array,
  chapters: PropTypes.array,
  questions: PropTypes.array,
  overallDetails: PropTypes.object,
  courseSubjectMapping: PropTypes.object,
  getInstituteCourseSubjects: PropTypes.func.isRequired,
  getInstituteTopicQuestions: PropTypes.func.isRequired,
  getInstituteChapterTopicList: PropTypes.func.isRequired,
  getInstituteSubjectChapterList: PropTypes.func.isRequired,
};

Browse.defaultProps = {
  topics: [],
  courses: [],
  subjects: [],
  chapters: [],
  questions: [],
  overallDetails: {},
  courseSubjectMapping: {},
};

const mapStateToProps = ({ home }) => ({
  topics: home.topics,
  courses: home.courses,
  subjects: home.subjects,
  chapters: home.chapters,
  questions: home.questions,
  overallDetails: home.overallDetails,
  courseSubjectMapping: home.courseSubjectMapping,
});

export default connect(mapStateToProps, {
  getInstituteCourseSubjects, getInstituteSubjectChapterList, getInstituteChapterTopicList, getInstituteTopicQuestions,
})(Browse);
