import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from './text';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import DropDownArrow from '../../shared/images/common/drop-down-arrow.svg'
import Search from '../../shared/images/common/search.svg'
class DropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptionId: null,
      showOptions: false,
      searchString: '',
      width: props.width,
      defaultText: props.defaultText || 'All',
      optionsList: props.optionsList,
    }
    this.renderContent = this.renderContent.bind(this);
  }
  showComponents = (event) => {
    event.preventDefault();
    this.setState({ showOptions: true }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }
  closeComponents = (event) => {
    if (event && (event.target.className !== 'show-options')) {
      this.setState({ showOptions: false }, () =>
        document.removeEventListener('click', this.closeComponents));
    }
  }
  renderOptions(option) {
    const { selectedItem, selectedItems } = this.props;
    const length = selectedItems && selectedItems.length > 0 && selectedItems.filter((item) => {
      return item.id === option.id || item.name === option.name
    }).length;
    if (selectedItems) {
      return (
        <div style={{
          padding: '10px 30px', background: StyleConstants.color.white, alignItems: 'center', cursor: 'pointer',
          borderBottom: StyleConstants.border.dropDown, display: 'flex', justifyContent: 'space-between'
        }} onClick={() => this.setState({ showOptions: 'false', optionsList: this.props.optionsList, selectedOptionId: option.id }, () => this.props.onValueChange(option.id, option.name))}>
          <Text
            key={option.id}
            text={option.name}
            color={length === 1 && StyleConstants.contentSearch.answerColor}
          />
          {length === 1 && (
            <div onClick={(event) => {
              event.stopPropagation(); this.setState({
                optionsList: this.props.optionsList, selectedOptionId: null
              }, () => this.props.onValueChange(option.id, option.name))
            }}>
              <img src="/images/common/x-mark.svg" alt="close" style={{ marginLeft: 10 }} />
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        key={option.id}
        style={{
          padding: '10px 30px', background: StyleConstants.color.white, alignItems: 'center', cursor: 'pointer',
          borderBottom: StyleConstants.border.dropDown, display: 'flex', justifyContent: 'space-between'
        }}
        onClick={() => this.setState({ showOptions: 'false', optionsList: this.props.optionsList, selectedOptionId: option.id }, () => this.props.onValueChange(option.id, option.name))}>
        <Text
          key={option.id}
          text={option.name}
          color={selectedItem && (option.id === selectedItem.id) && StyleConstants.contentSearch.answerColor}
        />
        {selectedItem && (option.id === selectedItem.id) &&
          <div onClick={(event) => { event.stopPropagation(); this.setState({ optionsList: this.props.optionsList, selectedOptionId: null }, () => this.props.onValueChange(null)) }}>
            <img src="/images/common/x-mark.svg" alt="close" style={{ marginLeft: 10 }} />
          </div>
        }
      </div>
    );
  }

  renderSelectedItemsInDropdown(selectedItems) {
    return selectedItems.map(item => { return item.name }).join(', ').substring(0, 50);
  }

  renderOptionsList(optionsList) {
    const { width } = this.state;
    return (
      <div style={{ maxHeight: 130, minHeight: 35, overflow: 'scroll', zIndex: 1, backgroundColor: 'white', borderRadius: '0px 0px 4px 4px', width, position: 'absolute', border: '1px solid #7C94BA', borderRadius: 4 }}>
        {optionsList.map((option) => this.renderOptions(option))}
      </div>
    );
  }
  getSearchResults() {
    const { optionsList } = this.props;
    let searchResultList = [];
    optionsList.forEach(option => {
      if (option.name.toUpperCase().indexOf(this.state.searchString.toUpperCase()) >= 0) {
        searchResultList.push(option);
      }
    })
    this.setState({ optionsList: searchResultList })
  }
  renderContent() {
    const { showOptions, width, defaultText, optionsList } = this.state;
    const { color, optionType, style, selectedItem, height, notEditable, selectedItems } = this.props;
    const dropDownStyle = { width: width + 1, border: optionsList.length > 0 ? '1px solid #7C94BA' : '1px solid #D0D0D0', borderRadius: 4, pointerEvents: notEditable || optionsList.length === 0 ? 'none' : '', opacity: notEditable || optionsList.length === 0 ? .5 : 1, position: 'relative' }
    return (
      <div className="show-options" style={{ ...dropDownStyle, ...style }} onClick={(event) => this.showComponents(event)}>
        {!showOptions &&
          <div style={{ height, alignItems: 'center', marginLeft: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Text
              text={selectedItems && selectedItems.length > 0 ? (this.renderSelectedItemsInDropdown(selectedItems)) : (selectedItem && selectedItem.name ? selectedItem.name : defaultText)}
              color={color}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 5 }}
            />
            <img src={DropDownArrow} alt="drop-down" style={{ marginRight: 10, height: 10, width: 10 }} />
          </div>
        }
        {showOptions &&
          <div style={{ display: 'flex', padding: 5, background: StyleConstants.color.white, border: '1px solid #7C94BA', borderRadius: 4 }}>
            <img src={Search} alt="search" style={{ marginRight: 10 }} />
            <input
              id="searchString"
              type="text"
              autoComplete="off"
              placeholder={`${optionType}`}
              autoFocus
              style={{ border: 0, marginBottom: 0, height: '100%', width: '100%', backgroundColor: "inherit", margin: 0, outline: 'none', color: 'black', fontSize: StyleConstants.textSize.text }}
              onChange={(e) => {
                this.setState({ searchString: e.target.value }, () => this.getSearchResults())
              }}
            />
          </div>}
        {showOptions && this.renderOptionsList(optionsList)}
      </div>
    );
  }
  render() {
    const { optionsList, width } = this.state;
    const { notEditable, dropDownContainerStyle } = this.props;
    return (
      <div style={{ cursor: notEditable || optionsList.length === 0 ? 'not-allowed' : 'pointer', height: 40, width, ...dropDownContainerStyle }}>
        {this.renderContent()}
      </div>
    );
  }
}
DropDown.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  optionsList: PropTypes.array,
  color: PropTypes.string,
  height: PropTypes.number,
  dropDownContainerStyle: PropTypes.object,
};
DropDown.defaultProps = {
  optionsList: [],
  color: StyleConstants.color.dark,
  height: 40,
  dropDownContainerStyle: {},
};
export default DropDown;

