import React from 'react';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

class PreviewFooter extends React.Component {
  resetPage() {
    window.location.reload();
  }

  render() {
    const { submitText } = this.props;
    return (
      <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: 0,
        justifyContent: 'space-between',
        paddingBottom: 30,
        width: (window.innerWidth - 250 - 100) / 2,
        left: 300,
        border: StyleConstants.border.preview,
        placeContent: 'center',
        background: StyleConstants.color.white,
        borderBottom: 'none',
        borderTop: 'none',
      }}
      >
        <div
          role="presentation"
          className="Button Secondary-Button"
          style={{
            width: '50%', marginLeft: 5, cursor: 'pointer',
          }}
          onClick={() => this.resetPage()}
        >
          Cancel
        </div>
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{ width: '50%', marginRight: 5 }}
          onClick={() => this.props.editQuestion()}
        >
          {submitText}
        </div>
      </div>
    );
  }
}

export default PreviewFooter;
