import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { Loader, Text } from './index';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

class ShowDiagram extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getContentWithImage(imageURL) {
    return `<img src=${imageURL} alt=${imageURL} style="border: 1px solid ${StyleConstants.color.mediumDark}" />`;
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  renderDiagramBasicDetails(detailEquation, detailContent) {
    const { isSelected } = this.props;
    // eslint-disable-next-line no-unused-expressions
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    if (detailEquation !== 'Title' && (!isSelected)) return null;
    const allowTitleStyles = (detailEquation === 'Title' && isSelected);
    return (
      <div
        className={allowTitleStyles ? 'brief-title brief-basic-details' : 'brief-basic-details'}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: markdownToHTML.render(detailContent) }}
        style={{
          fontSize: allowTitleStyles ? StyleConstants.textSize.primaryTitle : StyleConstants.textSize.text,
          color: StyleConstants.textColor.primary,
          fontWeight: allowTitleStyles ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold,
        }}
      />
    );
  }

  renderDiagram() {
    const {
      diagram: {
        selectedDiagramId,
        name,
        description,
        image,
      },
    } = this.props;
    const {
      id,
      diagramName,
      diagramEquation,
      diagramImage,
      index,
      width,
      showBorder = false,
      isVersionComparison = false,
      printDiagram = false,
      isSelected,
    } = this.props;

    const diagramDetails = {
      Title: [diagramName, name], Image: [diagramImage, image], Description: [diagramEquation, description],
    };
    const displayBorder = (id && selectedDiagramId === id) || showBorder;
    const imageWidth = (window.innerWidth / 3) - 20;
    const briefData = {
      id,
      name: diagramName,
      description: diagramEquation,
      image: diagramImage,
      selectedIndex: index,
    };

    return (
      <>
        <style jsx>
          {`
          .brief-basic-details img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printDiagram ? `${imageWidth}px` : '275px'};
          }

          .brief-basic-details ul > img, .brief-basic-details ol > img {
            max-width: ${printDiagram ? `${imageWidth - 20}px` : '255px'};
          }

          .brief-basic-details td>img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: ${printDiagram ? `${imageWidth / 2}px` : '75px'};
            max-height: ${printDiagram ? `${imageWidth / 2}px` : '75px'};
          }

          .brief-basic-details td, .brief-basic-details th {
            border: 1px solid #7F7F7F;
            padding: 0.35rem;
            word-break: normal;
            vertical-align: top;
          }

          .brief-title .katex {
            font-size: 20px;
          }

          .brief-basic-details {
            text-align: center;
          }

          .brief-basic-details .katex {
            font-size: 18px;
          }

          .brief-basic-details th {
            background-color: #E3E3E3;
            font-weight: bold;
            text-align: center;
          }

          .brief-basic-details td {
            background-color: #F7F7F7;
          }

          table {
            display: block;
            overflow-x: scroll;
            box-sizing: border-box;
            border-collapse: collapse;
            margin-bottom: 20px;
            position: relative;
          }

          table-small {
            font-size: 12px;
          }

          table-small .katex {
            font-size: 12px;
          }

          table-medium {
            font-size: 14px;
          }

          table-medium .katex {
            font-size: 14px;
          }

          freeze-column td:first-child, freeze-column th:first-child {
            position: sticky;
            z-index: 9999;
            left: 0;
            box-shadow: 0.1px 0.1px 0.3px 0.3px #7F7F7F;
            background-color: #E3E3E3;
          }

          formula {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            background-color: #F7F7F7;
          }

          formula-medium {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 15px;
            background-color: #F7F7F7;
          }

          formula-medium .katex {
            font-size: 15px;
          }

          formula-small {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 13px;
            background-color: #F7F7F7;
          }

          formula-small .katex {
            font-size: 13px;
          }

          formulae {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            background-color: #F7F7F7;
          }

          formulae-medium {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 15px;
            background-color: #F7F7F7;
          }

          formulae-medium .katex {
            font-size: 15px;
          }

          formulae-small {
            display:inline-block;
            margin: 10px 0px;
            padding: 10px;
            font-size: 13px;
            background-color: #F7F7F7;
          }

          formulae-small .katex {
            font-size: 13px;
          }

          li {
            margin: 0 0 14px 0;
          }

          ::-webkit-scrollbar {
            width: 0px;
            background: transparent;
          }

        `}
        </style>
        <div
          role="presentation"
          style={{
            width: !printDiagram && width,
            margin: !printDiagram ? '0px 0px' : 'auto auto 20px',
            cursor: !isVersionComparison && !printDiagram && 'pointer',
            border: printDiagram ? `1px solid ${StyleConstants.color.mediumDark}` : 'none',
            wordBreak: 'break-word',
            overflow: 'auto',
          }}
        >
          <div role="presentation">
            {Object.entries(diagramDetails).map(([label, content]) => {
              let value = (isVersionComparison || !((id && selectedDiagramId === id)) ? content[0] : content[1]);
              if (label === 'Image') {
                value = this.getContentWithImage(value);
              }
              return this.renderDiagramBasicDetails(label, value);
            })}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              {isSelected && id && this.renderText(`Id: ${id}`)}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      briefMe: {
        isLoading,
      },
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }
    return this.renderDiagram();
  }
}

ShowDiagram.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  diagram: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  diagramName: PropTypes.string.isRequired,
  diagramEquation: PropTypes.string.isRequired,
  diagramImage: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  width: PropTypes.number,
  showBorder: PropTypes.bool,
  isVersionComparison: PropTypes.bool,
  isDiagramSearch: PropTypes.bool,
  printDiagram: PropTypes.bool,
  isSelected: PropTypes.bool,
};

ShowDiagram.defaultProps = {
  width: 400,
  showBorder: false,
  isVersionComparison: false,
  isDiagramSearch: false,
  printDiagram: false,
  isSelected: false,
};
const mapStateToProps = ({ diagram, briefMe }) => ({
  diagram,
  briefMe,
});

export default connect(mapStateToProps, {})(ShowDiagram);
