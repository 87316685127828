import Api from '../helpers/Api';
import { showToast } from './snackBar';
import { updateCursorStatus } from './index';
import search from '../components/contents/search';
import { UPDATE_BRIEF_FILTER_DATA } from './briefMe';

export const GET_SUBJECTS = 'Diagram/GET_SUBJECTS';
export const UPDATE_DIAGRAM = 'Diagram/UPDATE_DIAGRAM';
export const RESET_DIAGRAM = 'Diagram/RESET_DIAGRAM';
export const UPDATE_BRIEF_META_DATA = 'BriefMe/UPDATE_BRIEF_META_DATA';

export function getDiagramTag(diagramId) {
  return `<diagram id=${diagramId} />`;
}

export function updateDiagramDetails(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_DIAGRAM, payload });
  };
}

export function resetDiagram(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: RESET_DIAGRAM, payload });
  };
}

export function updateBriefCursorPosition(briefCursorIndex) {
  return async (dispatch) => {
    const payload = {
      briefCursorIndex,
    };
    dispatch({
      type: UPDATE_BRIEF_FILTER_DATA,
      payload,
    });
  };
}

export function getDiagram({
  bmSubjectId = null, briefId = null, limit = null, searchText = null,
}) {
  return async (dispatch, getState) => {
    const { bmSubjectId: currentBmSubjectId } = getState().diagram;
    const response = await Api({
      method: 'get',
      url: `/diagrams/get_diagrams_of_subject?bm_subject_id=${bmSubjectId || currentBmSubjectId}&search_text=${searchText || ''}`,
    });
    if (response.success) {
      const payload = {};
      payload.diagrams = response.diagrams;
      payload.hasModifyBriefAccess = response.has_modify_brief_access;
      payload.bmSubjectId = bmSubjectId;
      dispatch({ type: UPDATE_DIAGRAM, payload });
    }
    return response;
  };
}

export function createDiagram() {
  return async (dispatch, getState) => {
    const {
      diagrams, bmSubjectId, name, description, image,
    } = getState().diagram;
    dispatch(updateDiagramDetails({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: '/diagrams',
      data: {
        name,
        description,
        image,
        bm_subject_id: bmSubjectId,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.name = '';
        payload.description = '';
        payload.image = '';
        payload.isDiagramItemSelected = false;
        payload.isChanged = false;
        payload.diagrams = ([...diagrams, response.diagram]).sort((a, b) => {
          const first = a.name.toLowerCase();
          const second = b.name.toLowerCase();
          if (first < second) {
            return -1;
          } if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      payload.isUpdating = false;
      dispatch(updateDiagramDetails(payload));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateDiagram({ isDiagramSearch = false }) {
  return (dispatch, getState) => {
    const {
      bmSubjectId, name, description, selectedDiagramId, image,
    } = getState().diagram;
    const data = {
      id: selectedDiagramId,
      name,
      description,
      image,
      bm_subject_id: bmSubjectId,
    };
    dispatch(updateDiagramDetails({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: `/diagrams/${selectedDiagramId.toString()}/update`,
      data,
    }).then((response) => {
      let payload = {};
      payload = response;
      payload.id = selectedDiagramId;
      payload.isDiagramSearch = isDiagramSearch;
      if (response.success) {
        payload.isDiagramSelected = false;
        payload.selectedDiagramId = null;
        payload.selectedIndex = null;
      }
      dispatch(showToast(response.message));
      payload.isUpdating = false;
      dispatch(updateDiagramDetails(payload));
      dispatch(getDiagram({ bmSubjectId }));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function deleteDiagram() {
  return (dispatch, getState) => {
    const { selectedDiagramId, bmSubjectId } = getState().diagram;
    return Api({
      method: 'post',
      url: `/diagrams/${selectedDiagramId.toString()}/delete`,
    }).then((response) => {
      if (response.success) {
        dispatch(resetDiagram({}));
        dispatch(getDiagram({ bmSubjectId }));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateBriefContentWithDiagram(diagramId, briefId, briefContentIndex) {
  return (dispatch, getState) => {
    const { content, selectedBriefId } = getState().briefMe;
    if (selectedBriefId !== briefId) {
      return false;
    }
    let briefCursorIndex = briefContentIndex;
    if (briefCursorIndex === null) {
      briefCursorIndex = content.length;
    }
    const diagramContent = getDiagramTag(diagramId);
    const updatedContent = `${content.slice(0, briefCursorIndex)}${diagramContent}${content.slice(briefCursorIndex)}`;

    const data = {
      diagramId,
      briefId,
      content: updatedContent,
    };
    return Api({
      method: 'post',
      url: '/diagrams/create_brief_mapping',
      data,
    }).then((response) => {
      if (response.success) {
        const payload = {
          content: updatedContent,
          isChanged: false,
          metaData: response.meta_data,
          briefId,
        };
        dispatch({
          type: UPDATE_BRIEF_META_DATA,
          payload,
        });
      }
      return response;
    });
  };
}
