import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateLoaderState, getInstituteSubjects, showToast,
  updateComprehensionQuestion, updateQuestionReducer, GetComprehensionQuestionToEdit,
} from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { TEXT_CHANGES_TYPE, REPORT_ISSUE_TOAST_MESSAGES } from '../shared/constants/fieldTypes';
import {
  Loader, DropDown, ImagePreview, PreviewHeader,
  Text, EditDisplay, PreviewDisplay,
} from './common';
import fileConstants from '../shared/constants/fileConstants';
import Layout from './layout';
import PreviewFooter from './common/previewFooter';

const SELECT_SUBJECT = 'Select Subjects';
const IMAGE = 'Image';
const dropDownPadding = '20px 0';

class ComprehensionQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.mathjaxDiv = null;
    this.state = {
      questionId: 0,
      selectedSubjectItem: {},
      images: {},
      active: TEXT_CHANGES_TYPE.NO_ISSUE,
      divHeight: {},
      isNewImage: {},
      showLoader: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.scrollheight = this.scrollheight.bind(this);
    this.renderEmptyData = this.renderEmptyData.bind(this);
    this.editDisplay = this.editDisplay.bind(this);
    this.previewDisplay = this.previewDisplay.bind(this);
    this.getImage = this.getImage.bind(this);
    this.newQuestion = this.newQuestion.bind(this);
    this.renderQuestionChanges = this.renderQuestionChanges.bind(this);
  }

  componentDidMount() {
    const { newQuestion } = this.props;
    const questionId = newQuestion ? 0 : this.props.match.params.id;
    if (newQuestion) {
      this.props.getInstituteSubjects({ subjects: true }).then(() => {
        this.setData();
      });
    } else {
      this.props.GetComprehensionQuestionToEdit(questionId).then(() => {
        this.setData();
      });
    }
  }

  setData() {
    this.getQuestionData();
    this.setState({ active: TEXT_CHANGES_TYPE.NO_ISSUE });
  }

  scrollheight(id, selected = false) {
    const { divHeight } = this.state;
    divHeight[id] = this.refs[`input_${id}`] ? this.refs[`input_${id}`].scrollHeight : 260;
    divHeight[id] = divHeight[id] > 60 ? divHeight[id] : 260;
    divHeight[id] += selected ? 15 : 0;
    this.setState({ divHeight });
  }

  getQuestionData() {
    const { questions, newQuestion, subjects } = this.props;
    const questionId = newQuestion ? 0 : questions.id;
    const textChanges = {
      question_text: questions.question_text,
    };
    const images = {
      supporting_picture: questions.supporting_picture,
    };
    let selectedSubjectItem = Object.values(subjects).filter((obj) => obj.id === questions.subject_id)[0];
    selectedSubjectItem = selectedSubjectItem || {};
    this.setState({
      questionId, textChanges, images, showLoader: false, selectedSubjectItem,
    });
  }

  renderSupportingPicture(isContentEditable) {
    const { active, images, isNewImage } = this.state;
    const { questions } = this.props;
    const supportingPicture = isNewImage.supporting_picture ? URL.createObjectURL(images.supporting_picture) : questions.supporting_picture ? questions.supporting_picture.url : null;
    return (
      <ImagePreview
        header="Supporting Picture"
        option="supporting_picture"
        margin="40px 0px"
        imageUrl={supportingPicture}
        width={questions.image_widths && questions.image_widths.supporting_picture_width}
        isContentEditable={isContentEditable}
        style={{ maxWidth: 270 }}
        getImage={this.getImage}
        clear={active === TEXT_CHANGES_TYPE.NO_ISSUE}
      />
    );
  }

  getImage(image, option) {
    const { isNewImage } = this.state;
    const { questions } = this.props;
    if (!image) {
      this.handleChange(option, questions[option], TEXT_CHANGES_TYPE.NO_ISSUE, IMAGE.toLowerCase());
      isNewImage[option] = false;
    } else {
      this.handleChange(option, image, TEXT_CHANGES_TYPE.IMAGE, IMAGE.toLowerCase());
      isNewImage[option] = true;
    }
    this.setState({ isNewImage });
  }

  editDisplay(key, type, value) {
    const { divHeight, textChanges } = this.state;
    return (
      <EditDisplay
        key={key}
        type={type}
        value={value}
        data={textChanges}
        divHeight={divHeight}
        handleChange={this.handleChange}
        scrollheight={this.scrollheight}
      />
    );
  }

  previewDisplay(field) {
    const { divHeight, textChanges } = this.state;
    const content = textChanges && textChanges[field];
    return (
      <div
        ref={`input_${field}`}
        style={{
          marginBottom: 20,
          fontSize: StyleConstants.textSize.text,
          color: StyleConstants.color.dark,
          fontWeight: StyleConstants.textWeight.semiBold,
          border: StyleConstants.color.white,
          backgroundColor: StyleConstants.color.white,
          height: divHeight[field],
          width: '95%',
          borderRadius: 8,
          boxShadow: '0px 3px 6px 0px rgba(52,59,64,0.08)',
          justifyContent: 'center',
          whiteSpace: 'pre-wrap',
          maxHeight: 'fit-content',
          overflowX: 'scroll',
          resize: 'none',
          padding: 15,
        }}
      >
        <PreviewDisplay
          content={content}
          scrollheight={this.scrollheight}
        />
      </div>
    );
  }

  handleChange(type, targetValue, value, id, isNumerical = false) {
    const {
      textChanges, images, numericalChanges,
    } = this.state;
    if (isNumerical) {
      numericalChanges[type] = targetValue;
    } else if (id !== IMAGE.toLowerCase()) {
      textChanges[type] = targetValue;
    } else {
      images[type] = targetValue;
    }
    this.setState({
      active: value,
      textChanges,
      images,
      numericalChanges,
    });
  }

  renderHeader(isContentEditable) {
    const { questionId } = this.state;
    const { newQuestion } = this.props;
    return (
      <PreviewHeader isContentEditable={isContentEditable} questionId={questionId} newQuestion={newQuestion} />
    );
  }

  renderSelectedText(isSelected, text) {
    const selectedStyle = {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      color: StyleConstants.color.dark,
      fontSize: StyleConstants.textSize.subHeader,
      height: StyleConstants.fieldHeight.default,
      padding: '0px 30px',
      width: 'fit-content',
      minWidth: 200,
    };
    const successText = isSelected ? 'success' : 'error';
    const border = StyleConstants.border[successText];
    const background = StyleConstants.backgroundColor[successText];
    return (
      <Text style={{ ...selectedStyle, border, background }} text={text} />
    );
  }

  renderDropDown(selectedItem, filterItems, defaultText, optionType, onChange, notEditable = false) {
    const length = 250;
    return (
      <DropDown
        key={`defaultText_${filterItems.length}`}
        optionType={optionType}
        selectedItem={selectedItem}
        onValueChange={onChange}
        notEditable={notEditable}
        optionsList={filterItems}
        defaultText={defaultText}
        width={length}
        height={40}
      />
    );
  }

  renderSubject(isContentEditable) {
    const { selectedSubjectItem } = this.state;
    if (isContentEditable) {
      const { subjects, newQuestion } = this.props;
      const filterItems = [];
      for (let i = 0; i < Object.keys(subjects).length; i += 1) {
        filterItems[i] = subjects[i];
      }
      return (
        <div style={{ padding: dropDownPadding }} onClick={newQuestion ? '' : () => this.props.showToast('Subject Cannot be Edited')}>
          {this.renderDropDown(selectedSubjectItem, filterItems, SELECT_SUBJECT,
            'Subjects', (id, name) => this.setState({
              selectedSubjectItem: { id, name },
              active: TEXT_CHANGES_TYPE.SUBJECT,
              selectedComprehension: {},
            }), !newQuestion)}
        </div>
      );
    }

    return (
      <div style={{ padding: dropDownPadding }}>
        {this.renderSelectedText(selectedSubjectItem.name, `Subject - ${selectedSubjectItem.name ? selectedSubjectItem.name : '  -'}`)}
      </div>
    );
  }

  newQuestion() {
    const {
      textChanges, images, questionId, active, selectedSubjectItem,
    } = this.state;
    const { questions, newQuestion } = this.props;
    const ChangesDone = active && active !== TEXT_CHANGES_TYPE.NO_ISSUE;
    const QuestionData = {};
    const imageFiles = [];
    if (active && active === TEXT_CHANGES_TYPE.UPDATED) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATE_COMPLETED);
      return;
    }
    if (newQuestion) {
      QuestionData.subject_id = parseInt(selectedSubjectItem.id);
      if (!QuestionData.subject_id) {
        this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.SELECT_SUBJECT);
      }
    }
    const isSubjectselected = !newQuestion || QuestionData.subject_id;
    const isDataPresent = ChangesDone && (!newQuestion || QuestionData.subject_id)
      && (textChanges.question_text || images.supporting_picture)
      && ((textChanges.question_text !== questions.question_text)
        || (images.supporting_picture !== questions.supporting_picture));
    if (isDataPresent) {
      const type = 'supporting_picture';
      if (images[type] && (images[type] !== questions[type])) {
        imageFiles.push(new File([images[type]], `${type}.${images[type].type && images[type].type.split('/')[1]}`,
          { type: images[type].type }));
      }
      this.props.updateComprehensionQuestion(questionId, textChanges.question_text || '', imageFiles, QuestionData.subject_id);
      this.setState({ active: TEXT_CHANGES_TYPE.UPDATED });
    } else if (isSubjectselected) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_SELECTED);
    }
  }

  renderSubmitFooter() {
    const { questions, newQuestion } = this.props;
    const submitText = questions.can_edit_institute_question ? 'Update' : newQuestion ? 'Create' : 'Submit';
    return (
      <PreviewFooter
        submitText={submitText}
        editQuestion={this.newQuestion}
      />
    );
  }

  renderQuestionHeader(text) {
    return (
      <div style={{ margin: '10px 0px 15px' }}>
        <Text
          color={StyleConstants.color.dark}
          fontSize={StyleConstants.textSize.subHeader}
          text={text}
        />
      </div>
    );
  }

  renderQuestionChanges(isContentEditable, type) {
    if (isContentEditable) {
      return this.editDisplay(type, type, TEXT_CHANGES_TYPE[type.toUpperCase()]);
    }
    return this.previewDisplay(type);
  }

  renderText(isContentEditable, text, type, isImage = false) {
    return (
      <div style={{ padding: '10px 0' }}>
        {this.renderQuestionHeader(text)}
        {this.renderQuestionChanges(isContentEditable, type)}
        {isImage && this.renderSolutionSupprotingPicture(isContentEditable)}
      </div>
    );
  }

  renderQuestionView(isContentEditable) {
    return (
      <div
        style={{
          width: '50%',
          border: StyleConstants.border.preview,
          background: !isContentEditable && '#F7F7F7 0% 0% no-repeat padding-box',
          backgroundColor: isContentEditable && StyleConstants.color.white,
          fontWeight: StyleConstants.textWeight.semiBold,
          fontSize: StyleConstants.textSize.subHeader,
          padding: 30,
        }}
      >
        {this.renderHeader(isContentEditable)}
        {this.renderText(isContentEditable, 'Comprehension Question Text', 'question_text')}
        {this.renderSubject(isContentEditable)}
        {this.renderSupportingPicture(isContentEditable)}
        {this.renderEmptyData(isContentEditable)}
        {isContentEditable && this.renderSubmitFooter()}
      </div>
    );
  }

  renderData() {
    const { showLoader } = this.state;
    const { newQuestion } = this.props;
    if (showLoader) {
      return (<Loader />);
    }
    return (
      <div style={{ display: 'flex', margin: newQuestion ? 20 : 50 }}>
        {this.renderQuestionView(true)}
        {this.renderQuestionView(false)}
      </div>
    );
  }

  renderEmptyData(isContentEditable) {
    return (
      <div style={{ opacity: 0 }}>
        {this.renderQuestionChanges(isContentEditable, 'question')}
      </div>
    );
  }

  render() {
    const { newQuestion } = this.props;
    if (newQuestion) {
      return this.renderData();
    }
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.TOOLS.label} currentPage={fileConstants.contentsMenuOrder.TOOLS.position}>
        {this.renderData()}
        {this.renderEmptyData()}
      </Layout>
    );
  }
}
ComprehensionQuestion.propTypes = {
  questions: PropTypes.object,
  redirectReportPage: PropTypes.bool,
  loaderState: PropTypes.number,
  comprehension: PropTypes.bool,
  questionTypes: PropTypes.bool,
};

ComprehensionQuestion.defaultProps = {
  questions: {},
  subjects: {},
  redirectReportPage: false,
  loaderState: true,
  comprehension: false,
  questionTypes: {},
};

const mapStateToProps = ({ question, global }) => ({
  questions: question.questionData,
  redirectReportPage: question.redirectReportPage,
  loaderState: global.loaderState,
  subjects: question.subjects,
  questionTypes: question.questionTypes,
});
export default connect(mapStateToProps, {
  updateLoaderState,
  showToast,
  getInstituteSubjects,
  updateComprehensionQuestion,
  updateQuestionReducer,
  GetComprehensionQuestionToEdit,
})(ComprehensionQuestion);
