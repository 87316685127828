import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Menubar from '../contents/menuBar';
import fileConstants from '../../shared/constants/fileConstants';
import Header from '../common/header';

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayHeight: '',
      displayWidth: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ displayHeight: global.innerHeight, displayWidth: global.innerWidth });
    }, 1);
    // show loading only if new route takes more than 200 ms
  }

  render() {
    const {
      children, currentPage, headerText, hideHeader, hideMenuBar, onlyBriefAccess,
    } = this.props;
    const { displayWidth } = this.state;
    return (
      <div style={{ width: displayWidth, display: 'flex', overflowX: 'hidden' }}>
        {!hideMenuBar && <Menubar menuList={onlyBriefAccess ? [] : fileConstants.contentsMenu} selectedOption={currentPage} headerText={onlyBriefAccess ? 'Brief Me' : 'Content Board'} />}
        <div style={{ width: hideMenuBar ? displayWidth : displayWidth - 250, marginLeft: hideMenuBar ? 0 : 250 }}>
          {!hideHeader && <Header text={headerText} />}
          {children}
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  currentPage: PropTypes.number.isRequired,
  headerText: PropTypes.string.isRequired,
  hideMenuBar: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  title: 'Idea Board',
  children: null,
  hideMenuBar: false,
};

export default connect()(Layout);
