import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  DropDown, Loader, BriefMeTabs, ShowFormula, EditFormula, Text,
} from '../common';
import {
  getFormula,
  updateFormulaDetails,
  resetFormula,
} from '../../actions';

const MOBILE_VIEW_WIDTH = 325;
const history = createBrowserHistory({
  forceRefresh: true,
});

const isChemicalEquation = history.location.state?.bmSubjectName === 'Chemistry';

class Formula extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    this.props.getFormula({ bmSubjectId });
  }

  handleAddButtonClick() {
    this.props.updateFormulaDetails({ displayEditView: false }).then(() => {
      this.props.resetFormula({ displayEditView: true });
    });
  }

  handleClick({
    id, name, equation, derivation, explanation, selectedIndex,
  }) {
    const { show_versions: showVersions } = queryString.parse(window.location.search);
    this.props.updateFormulaDetails({ displayEditView: false }).then(() => {
      if (!showVersions) {
        this.props.updateFormulaDetails({
          name,
          equation,
          derivation,
          explanation,
          isChanged: false,
          enableFilterSearch: false,
          selectedIndex,
          selectedFormulaId: id,
          displayEditView: true,
          isFormulaSelected: true,
        });
      }
    });
  }

  rendersubjectSelection() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    const { briefMe: { subjects } } = this.props;
    let currentSubject = {};
    if (Object.values(subjects).length) {
      currentSubject = Object.values(subjects).filter((subject) => subject.id === parseInt(bmSubjectId, 10))[0] || {};
    }
    const subjectDropdownOptions = {
      key: Object.values(subjects).length,
      optionType: 'BmSubject',
      defaultText: 'BmSubject',
      optionsList: Object.values(subjects),
      selectedItem: currentSubject,
      width: 250,
      height: 35,
      onValueChange: (id, name) => history.push({ state: { bmSubjectName: name }, search: id ? `?bm_subject_id=${id}` : '' }),
    };
    return (
      <div style={{ marginBottom: 15 }}>
        {this.renderDropdown(subjectDropdownOptions)}
      </div>
    );
  }

  renderDropdown(dropdownOptions) {
    return (
      <DropDown
        key={dropdownOptions.key}
        selectedItem={dropdownOptions.selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={dropdownOptions.onValueChange}
      />
    );
  }

  renderButtonToAddBrief() {
    const { formula: { formulas } } = this.props;
    return (
      <>
        <div
          role="presentation"
          className="fa fa-plus"
          style={{ margin: '10px 0px', cursor: 'pointer', fontSize: 24 }}
          onClick={() => this.handleAddButtonClick()}
        />
        {!!formulas.length && (
          <div style={{ marginBottom: 15 }}>
            <Text
              text={`Total ${isChemicalEquation ? 'Chemical Equation' : ' Formula'}s: ${formulas.length}`}
              color={StyleConstants.color.mediumDark}
              fontWeight={StyleConstants.textWeight.bold}
              fontSize={StyleConstants.textSize.title}
            />
          </div>
        )}
      </>
    );
  }

  renderNoContent() {
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    const { formula: { formulas } } = this.props;
    let message = '';
    if (parseInt(bmSubjectId, 10)) {
      message = `Create new ${isChemicalEquation ? 'Chemical Equation' : 'Formula'}s`;
    } else if (!formulas.length) {
      message = 'Please select a subject to proceed';
    }
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', fontSize: 24,
      }}
      >
        <p>{message}</p>
      </div>
    );
  }

  renderFormulaContents() {
    const {
      formula: {
        selectedFormulaId, displayEditView, formulas,
      },
      printFormula,
    } = this.props;
    if (!formulas.length) {
      return this.renderNoContent();
    }
    return (
      <>
        <style jsx>
          {`
            table tr td{
              padding: 10px 10px !important;
            }
            th {
              border-bottom: none !important;
            }
        `}
        </style>
        <table
          className="table table-striped table-hover"
          style={{
            textAlign: 'center',
            width: 'fit-content',
            backgroundColor: 'white',
            padding: 1,
            border: `1px solid ${StyleConstants.color.inactive}`,
          }}
        >
          <thead>
            <tr
              style={{
                display: 'flex',
                fontWeight: 'bold',
                fontSize: StyleConstants.textSize.subHeader,
              }}
            >
              <th> Index </th>
              <th> Name </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'start' }}>
            {formulas.map((item, index) => {
              const isSelected = (!printFormula && displayEditView && (item.id === selectedFormulaId));
              return (
                <tr
                  className="table-row-selection"
                  style={{
                    border: isSelected ? `2px solid ${StyleConstants.color.primary}` : ' ',
                    backgroundColor: isSelected ? 'white' : ' ',
                    display: 'flex',
                  }}
                  onClick={() => this.handleClick({ ...item, selectedIndex: index })}
                >
                  <td style={{ fontWeight: StyleConstants.textWeight.bold, width: 50 }}>{index + 1}</td>
                  <td>
                    <ShowFormula
                      id={item.id}
                      key={item.id}
                      formulaName={item.name}
                      formulaEquation={item.equation}
                      formulaExplanation={item.explanation}
                      formulaDerivation={item.derivation}
                      index={index}
                      width={MOBILE_VIEW_WIDTH}
                      isSelected={isSelected}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderBody() {
    const { formula: { displayEditView, selectedIndex } } = this.props;
    const { bm_subject_id: bmSubjectId } = queryString.parse(window.location.search);
    return (
      <div style={{ margin: '10px 40px', height: '100%' }}>
        {this.rendersubjectSelection()}
        {(selectedIndex !== null) && <EditFormula isChemicalEquation={isChemicalEquation} displayEditView={displayEditView} bmSubjectId={parseInt(bmSubjectId, 10)} />}
        {!!parseInt(bmSubjectId, 10) && this.renderButtonToAddBrief()}
        {this.renderFormulaContents()}
      </div>
    );
  }

  render() {
    const { briefMe: { isLoading } } = this.props;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div>
        <BriefMeTabs selectedType="Formula" />
        {this.renderBody()}
      </div>
    );
  }
}

Formula.propTypes = {
  formula: PropTypes.object.isRequired,
  updateFormulaDetails: PropTypes.func.isRequired,
  resetFormula: PropTypes.func.isRequired,
  briefMe: PropTypes.object.isRequired,
  printFormula: PropTypes.bool,
};

Formula.defaultProps = {
  printFormula: false,
};
const mapStateToProps = ({ formula, briefMe }) => ({
  formula,
  briefMe,
});

export default connect(mapStateToProps, {
  getFormula,
  updateFormulaDetails,
  resetFormula,
})(Formula);
