import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { serverUnderMaintenance, serverError, logout } from '../actions/index';
import { store } from '../index';
import constants from '../shared/constants/environmentConfig';
import { getHomeDomain } from './Utils';

const cookies = new Cookies();
const Constants = constants();

const notifyServerMaintenance = () => {
  store.dispatch(serverUnderMaintenance());
};

const getToken = () => {
  const token = cookies.get('token');
  if (token) {
    return `bearer ${cookies.get('token')}`;
  }
  return '';
};

export const getUser = () => {
  const user = cookies.get('user');
  return user || {};
};

const Api = (options) => {
  const onSuccess = (response) => {
    const env = process.env.REACT_APP_ENV;
    if (env !== 'production') {
      console.log('Request Successful!', 'response');
    }
    if (response.status === 401) {
      logout();
    }
    if (response.status === 406) {
      if (response.data.redirect_domain) {
        window.location.href = response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
    }
    return response.data;
  };

  const onError = (error) => {
    const env = process.env.REACT_APP_ENV;
    if (error.response && error.response.status === 503) {
      notifyServerMaintenance();
    }
    if (error.response && error.response.status === 401) {
      logout();
    }
    if (error.response && error.response.status === 406) {
      if (error.response.data && error.response.data.redirect_domain) {
        window.location.href = error.response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
    }

    if (env !== 'production') {
      console.log('Request Failed:', error);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('Status:', error.response.status);
        console.log('Data:', error.response.data);
        console.log('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
      }
    }
    store.dispatch(serverError());
    return error.response || error.message;
  };

  if (options.method === 'post') {
    if (options.attachment) {
      const config = { headers: { 'Content-Type': 'multipart/form-data', 
    }, onUploadProgress: options.onUploadProgress,
  };
      const formData = new FormData();
      const attachmentLength = options.attachment.length ? options.attachment.length : 1;
      if (attachmentLength === 1 && (!options.attachment.length || options.type !== 'spreadsheet')) {
        formData.append(options.type, options.attachment);
      } else {
        options.attachment.forEach((file, index) => {
          formData.append(`${options.type}_${index + 1}`, file);
        });
      }
      if (options.data) {
        Object.keys(options.data).forEach((key) => {
          formData.set(key, options.data[key]);
        });
      }
      formData.set(`${options.type}_length`, attachmentLength);
      formData.set('token', getToken());
      formData.set('contentEngineAppVersionCode', Constants.contentEngineAppVersionCode);
      return axios.post(`${Constants.api.url}${options.url}`,
        formData,
        config).then(onSuccess)
        .catch(onError);
    }
    return axios({
      method: options.method,
      url: `${Constants.api.url}${options.url}`,
      data: options.data,
      transformRequest: [function (data) {
        data = data || {};
        data.token = getToken();
        data.contentEngineAppVersionCode = Constants.contentEngineAppVersionCode;
        return queryString.stringify(data);
      }],
    })
      .then(onSuccess)
      .catch(onError);
  }
  let url = `${Constants.api.url}${options.url}`;
  if (options.url.includes('?')) {
    url += `&token=${getToken()}&contentEngineAppVersionCode=${Constants.contentEngineAppVersionCode}`;
  } else {
    url += `?token=${getToken()}&contentEngineAppVersionCode=${Constants.contentEngineAppVersionCode}`;
  }
  return axios({
    method: options.method,
    url,
  })
    .then(onSuccess)
    .catch(onError);
};

export default Api;
