import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import {
  DropDown, Text, Loader, BriefMeTabs, Modal,
} from '../common/index';

import {
  updatePermissions, removePermissions, updateBriefReducer,
} from '../../actions';

class BriefMePermissions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newMembershipID: '',
      newPermission: {},
      showModal: false,
      selectedIndexToDelete: '',
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  getPermittedSubjects(id, name, subjectIDs) {
    if (subjectIDs && subjectIDs.filter((option) => { return option.id === id; }).length === 1) {
      return subjectIDs.filter((option) => { return option.id !== id; });
    } if (subjectIDs) {
      return [...subjectIDs, { id: id || '', name: name || '' }];
    }
    return [{ id: id || '', name: name || '' }];
  }

  handleSave(membershipsWithPermission, newMembershipID, newPermission, isNewInfoPresent) {
    this.props.updatePermissions(membershipsWithPermission, { newMembershipID, newPermission }, isNewInfoPresent);
    this.setState({ newMembershipID: '', newPermission: '' });
  }

  updateRow(index, newChanges) {
    this.props.membershipsWithPermission[index] = { ...this.props.membershipsWithPermission[index], ...newChanges };
    this.props.updateBriefReducer({ membershipsWithPermission: [...this.props.membershipsWithPermission] });
  }

  removeRow(index) {
    this.props.removePermissions(index, this.props.membershipsWithPermission);
    this.handleCloseModal();
  }

  renderText(text, style) {
    return (
      <Text
        fontSize={StyleConstants.textSize.subHeader}
        color={StyleConstants.color.dark}
        style={style}
        text={text}
      />
    );
  }

  renderPermissionDropdown(dropdownOptions, selectedItem, index = null) {
    return (
      <DropDown
        selectedItem={selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={(id, name) => {
          if (index === null) {
            this.setState({ newPermission: { id, name } });
          } else {
            this.updateRow(index, { permission: { id, name } });
          }
        }}
      />
    );
  }

  renderEachRow(membershipDetail, permissionDropdownOptions, index, subjects) {
    return (
      <div style={{
        display: 'flex', alignItems: 'center', marginBottom: 20,
      }}
      >
        <img role="presentation" src="/images/common/x-mark.svg" alt="close" style={{ cursor: 'pointer', height: 13 }} onClick={() => this.setState({ showModal: true, selectedIndexToDelete: index })} />
        {this.renderText(membershipDetail.membershipID, { width: 120, marginLeft: 55 })}
        {this.renderText(membershipDetail.name, {
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 150,
        })}
        <div style={{ margin: '0px 110px 0px 60px' }}>
          {this.renderPermissionDropdown(permissionDropdownOptions, membershipDetail.permission, index)}
        </div>
        {membershipDetail.permission.name === 'Brief Me Editor' && (
          <DropDown
            key={Object.values(subjects).length}
            selectedItems={membershipDetail.subjectIDs}
            optionsList={Object.values(subjects)}
            optionType="BmSubjects"
            defaultText="BmSubjects"
            width={200}
            height={35}
            color={StyleConstants.color.dark}
            onValueChange={(id, name) => this.updateRow(index, { subjectIDs: this.getPermittedSubjects(id, name, membershipDetail.subjectIDs) })}
          />
        )}
      </div>
    );
  }


  handleCloseModal() {
    this.setState({ showModal: false });
  }

  permissionsRemovalConfirmation(index) {
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text
          fontSize={StyleConstants.textSize.subHeader}
          color={StyleConstants.color.dark}
          style={{
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 550,
          }}
          text="Are you sure you want to remove all the Brief Me permissions of this user?"
        />
        <div className="modalButtonView">
          <div className="Button Secondary-Button" style={{ width, marginRight: 5 }} onClick={this.handleCloseModal}> Cancel </div>
          <div className="Button Primary-Button" style={{ width }} onClick={() => this.removeRow(index)}> Remove </div>
        </div>
      </div>
    );
  }

  renderModal(showModal, index) {
    return (
      <Modal
        key={showModal}
        isModalVisible={showModal}
        handleCloseModal={this.handleCloseModal}
        height={130}
        width={600}
      >
        {this.permissionsRemovalConfirmation(index)}
      </Modal>
    );
  }

  render() {
    const { subjects, membershipsWithPermission, hasCreateSubjectAccess } = this.props;
    const { newMembershipID, newPermission, showModal, selectedIndexToDelete } = this.state;
    const permissions = [{ id: 1, name: 'Brief Me Editor' }, { id: 2, name: 'Brief Me Manager' }];
    const headerText = [{ marginLeft: 70, text: 'ID' }, { marginLeft: 100, text: 'Name' }, { marginLeft: 210, text: 'Permissions' }, { marginLeft: 220, text: 'Subjects' }];
    const isNewInfoPresent = newMembershipID && newPermission.id;
    let isInfoUpdated = membershipsWithPermission.length > 0;

    const permissionDropdownOptions = {
      optionsList: permissions,
      optionType: 'Permissions',
      defaultText: 'Permissions',
      width: 180,
      height: 35,
    };

    if (Object.values(subjects).length === 0) {
      return <Loader />;
    }

    return (
      <>
        {hasCreateSubjectAccess && <BriefMeTabs selectedType="Permissions" />}
        <div role="presentation" className="Card-View" style={{ display: 'flex', flexDirection: 'column', margin: '50px 100px 200px 100px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            {headerText.map(({ marginLeft, text }) => { return this.renderText(text, { marginLeft }); })}
          </div>
          {membershipsWithPermission.map((membershipDetail, index) => {
            isInfoUpdated = isInfoUpdated && membershipDetail.membershipID && membershipDetail.permission.id;
            isInfoUpdated = membershipDetail.permission.name === 'Brief Me Editor' ? isInfoUpdated && membershipDetail.subjectIDs.length : isInfoUpdated;
            return this.renderEachRow(membershipDetail, permissionDropdownOptions, index, subjects);
          })}
          <div style={{
            display: 'flex', alignItems: 'center', marginTop: 20,
          }}
          >
            <input
              style={{
                width: 100, height: 35, marginLeft: 40, backgroundColor: '#FFF',
              }}
              value={newMembershipID}
              placeholder="ID"
              onChange={(event) => this.setState({ newMembershipID: event.target.value })}
            />
            <div style={{ marginLeft: 255 }}>
              {this.renderPermissionDropdown(permissionDropdownOptions, newPermission)}
            </div>
            <div
              role="presentation"
              className="Button Primary-Button"
              style={{
                width: 100, marginLeft: 220, opacity: (isInfoUpdated || isNewInfoPresent) ? 1 : 0.5, cursor: (isInfoUpdated || isNewInfoPresent) ? 'pointer' : 'not-allowed',
              }}
              onClick={() => (isInfoUpdated || isNewInfoPresent) && this.handleSave(membershipsWithPermission, newMembershipID, newPermission, isNewInfoPresent)}
            >
              Save
            </div>
          </div>
        </div>
        {showModal && this.renderModal(showModal, selectedIndexToDelete)}
      </>
    );
  }
}

const mapStateToProps = ({ briefMe }) => ({
  hasCreateSubjectAccess: briefMe.hasCreateSubjectAccess,
  subjects: briefMe.subjects,
  membershipsWithPermission: briefMe.membershipsWithPermission,
});

export default connect(mapStateToProps, {
  updatePermissions, removePermissions, updateBriefReducer,
})(BriefMePermissions);
