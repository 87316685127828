import { UPDATE_QUESTION_TAGGING_DATA, TAG_BRIEFS_TO_QUESTION } from '../actions';

const initialState = {
  briefs: [],
  suggestedBriefs: [],
  subjects: [],
  chapters: {},
  questions: [],
  currentQuestion: '',
  totalQuestionCount: 0,
};

export default function (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case UPDATE_QUESTION_TAGGING_DATA:
      return { ...state, ...action.payload };
    case TAG_BRIEFS_TO_QUESTION:
      newState.currentQuestion = action.payload.currentQuestion;
      newState.questions[action.payload.questionIndex % 20].briefs = action.payload.briefs;
      return { ...newState };
    default:
      return state;
  }
}
