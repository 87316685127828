import devConfig from './development.json';
import prodConfig from './production';
import stagingConfig from './staging';
import baseConfig from './base';

export default () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'production') {
    return { ...baseConfig, ...prodConfig };
  } if (env === 'staging') {
    return { ...baseConfig, ...stagingConfig };
  }
  return { ...baseConfig, ...devConfig };
};
