import Api from '../helpers/Api';
import { showToast } from '.';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

export const FILTER_CATEGORIES = {
  COURSE: 'selectedCourse',
  SUBJECT: 'selectedSubject',
  CHAPTER: 'selectedChapter',
  TOPIC: 'selectedTopic',
  TEST: 'selectedTest',
};
export const DIFFICULTY_VALUE_MAPPING = [
  {
    id: '',
    name: '',
  },
  {
    id: 2,
    name: 'Easy',
  },
  {
    id: 2,
    name: 'Easy',
  },
  {
    id: 3,
    name: 'Medium',
  },
  {
    id: 4,
    name: 'Hard',
  },
  {
    id: 4,
    name: 'Hard',
  },
];

export const GET_SEARCH_QUESTIONS = 'Search/GET_SEARCH_QUESTIONS';
export const UPDATE_SEARCH_STRING = 'Search/UPDATE_SEARCH_STRING';
export const UPDATE_FILTER_LIST = 'Search/UPDATE_FILTER_LIST';
export const RESET_SUBJECTS = 'Search/RESET_SUBJECTS';
export const RESET_CHAPTERS = 'Search/RESET_CHAPTERS';
export const RESET_TOPICS = 'Search/RESET_TOPICS';
export const UPDATE_APPLIED_FILTERS = 'Search/UPDATE_APPLIED_FILTERS';
export const UPDATE_TAG = 'Search/UPDATE_TAG';
export const UPDATE_DIFFICULTY = 'Search/UPDATE_DIFFICULTY';
export const HANDLE_PAGINATION = 'Search/HANDLE_PAGINATION';
export const UPDATE_SEARCH_LOADER_STATE = 'Search/UPDATE_SEARCH_LOADER_STATE';

export const searchQuestions = () => async (dispatch, getState) => {
  dispatch(updateSearchLoaderState(true));

  const { searchText, appliedFilters, activePage } = getState().search;

  const encodedSearchText = encodeURIComponent(searchText);
  let url_params = `search_question_text=${encodedSearchText}`;

  if (appliedFilters.selectedCourse && Object.keys(appliedFilters.selectedCourse).length) {
    url_params += `&test_type_id=${appliedFilters.selectedCourse.id}`;
  }
  if (appliedFilters.selectedSubject && Object.keys(appliedFilters.selectedSubject).length) {
    url_params += `&subject_id=${appliedFilters.selectedSubject.id}`;
  }
  if (appliedFilters.selectedChapter && Object.keys(appliedFilters.selectedChapter).length) {
    url_params += `&chapter_id=${appliedFilters.selectedChapter.id}`;
  }
  if (appliedFilters.selectedTopic && Object.keys(appliedFilters.selectedTopic).length) {
    url_params += `&checkpoint_id=${appliedFilters.selectedTopic.id}`;
  }
  if (appliedFilters.selectedTest && Object.keys(appliedFilters.selectedTest).length) {
    url_params += `&test_ids=${appliedFilters.selectedTest.id}`;
  }

  const page = activePage ? (activePage > 200 ? 200 : activePage) : 1;
  url_params += `&page=${page}`;

  const response = await Api({
    method: 'get',
    url: `/content_engine/get_search_questions?${url_params}`,
  });
  if (response.success) {
    const payload = {
      testList: [],
      recentTestReport: {},
      subjectList: [],
      questions: response.questions,
      totalQuestionsCount: response.total_question_count,
      fetching: false,
      searchApplied: true,
    };
    dispatch({
      type: GET_SEARCH_QUESTIONS,
      payload,
    });
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }
  dispatch(updateSearchLoaderState(false));
};

export const updateSearchString = (searchText) => {
  return {
    type: UPDATE_SEARCH_STRING,
    payload: {
      searchText,
      searchApplied: false,
    },
  };
};

export const updateSearchLoaderState = (state) => {
  const payload = { fetching: state };
  return {
    type: UPDATE_SEARCH_LOADER_STATE,
    payload,
  };
};

export const handlePagination = (response, loadQuestions = true) => async (dispatch) => {
  dispatch({
    type: HANDLE_PAGINATION,
    payload: response,
  });
  loadQuestions && dispatch(searchQuestions());
};

export const updateQuestionComplexity = (question_id, complexity) => async (dispatch, getState) => {
  const response = await Api({
    method: 'post',
    url: '/question_tagging/update_question_complexity',
    data: { id: question_id, complexity },
  });

  if (response.success) {
    const { complexity } = response;
    dispatch({
      type: UPDATE_DIFFICULTY,
      payload: {
        complexity,
        question_id,
      },
    });
    dispatch(showToast('Successfully updated.', TOAST_MESSAGE_TYPES.SUCCESS));
  }
};

export const updateQuestionTagging = (question_id, checkpoint_id) => async (dispatch, getState) => {
  const { appliedFilters } = getState().search;
  const response = await Api({
    method: 'post',
    url: '/content_engine/add_checkpoint',
    data: {
      question_id,
      checkpoint_id,
      course_id: appliedFilters[FILTER_CATEGORIES.COURSE] && appliedFilters[FILTER_CATEGORIES.COURSE].id ? appliedFilters[FILTER_CATEGORIES.COURSE].id : '',
      chapter_id: appliedFilters[FILTER_CATEGORIES.CHAPTER] && appliedFilters[FILTER_CATEGORIES.CHAPTER].id ? appliedFilters[FILTER_CATEGORIES.CHAPTER].id : '',
    },
  });

  if (response.success) {
    const { mapped_checkpoints } = response;
    dispatch({
      type: UPDATE_TAG,
      payload: {
        mapped_checkpoints,
        question_id,
      },
    });
    dispatch(showToast('Successfully updated.', TOAST_MESSAGE_TYPES.SUCCESS));
  } else if (response.mapped_checkpoints) {
    dispatch({
      type: UPDATE_TAG,
      payload: {
        mapped_checkpoints: response.mapped_checkpoints,
        question_id,
      },
    });
  }
};

export const getAllFilterCategories = () => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: '/content_engine/get_all_filter_categories?include_all_test_types=true',
  });

  if (response.success) {
    const payload = {
      courses: response.courses ? response.courses : [],
      subjects: response.subjects ? response.subjects : [],
      chapters: response.chapters ? response.chapters : [],
      checkpoints: response.checkpoints,
      tests: response.tests ? response.tests : [],
      difficulty: response.difficulty,
      searchApplied: false,
    };
    dispatch({
      type: UPDATE_FILTER_LIST,
      payload,
    });
    dispatch(handlePagination({ activePage: 1 }, false));
  }
};

export const updateFilters = (type, key, value) => async (dispatch, getState) => {
  const payload = {
    [type]: {
      id: key ? parseInt(key) : '',
      name: value || '',
    },
  };

  if (type === FILTER_CATEGORIES.COURSE) {
    dispatch({
      type: RESET_SUBJECTS,
    });
  }

  if (type === FILTER_CATEGORIES.SUBJECT) {
    dispatch({
      type: RESET_CHAPTERS,
    });
  }

  if (type === FILTER_CATEGORIES.CHAPTER) {
    dispatch({
      type: RESET_TOPICS,
    });
  }

  dispatch({
    type: UPDATE_APPLIED_FILTERS,
    payload,
  });
};
