import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import { DropDown, Text } from './index';
import { updateBriefReducer, showToast } from '../../actions';

class BriefsFilter extends PureComponent {
  renderDropdown(dropDownOptions) {
    const { briefMe: { appliedFilters } } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Text fontSize={14} style={{ alignSelf: 'center', marginRight: 20 }} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text={`${dropDownOptions.optionType}:`} />
        <DropDown
          key={appliedFilters.subject.id}
          selectedItem={dropDownOptions.selectedItem}
          optionsList={dropDownOptions.optionList ? [...dropDownOptions.optionList] : []}
          optionType={dropDownOptions.optionType}
          width={dropDownOptions.width || 150}
          color={StyleConstants.color.dark}
          onValueChange={dropDownOptions.onValueChange}
        />
      </div>
    );
  }

  render() {
    const { briefMe: { appliedFilters, subjects, chapters } } = this.props;
    const subjectDropDownOptions = {
      selectedItem: appliedFilters.subject,
      optionList: Object.values(subjects),
      optionType: 'Subject',
      width: 300,
      onValueChange: (id, name) => {
        this.props.updateBriefReducer({
          appliedFilters: {
            subject: { id: id || '', name: name || '' },
            chapter: {},
          },
        });
      },
    };
    const chapterDropDownOptions = {
      selectedItem: appliedFilters.chapter,
      optionList: appliedFilters.subject.id ? Object.values(chapters[appliedFilters.subject.id]) : [],
      optionType: 'Chapter',
      width: 300,
      onValueChange: (id, name) => {
        this.props.updateBriefReducer({
          appliedFilters: {
            subject: appliedFilters.subject,
            chapter: { id: id || '', name: name || '' },
          },
          addParams: true,
        });
        this.props.filterFunction();
      },
    };
    return (
      <div style={{ display: 'flex', marginTop: '20px' }}>
        {this.renderDropdown(subjectDropDownOptions)}
        <div style={{ display: 'flex', marginLeft: 50 }}>
          {this.renderDropdown(chapterDropDownOptions)}
        </div>
      </div>
    );
  }
}

BriefsFilter.propTypes = {
  filterFunction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, { updateBriefReducer, showToast })(BriefsFilter);
