import Api from '../helpers/Api';
import { showToast } from './snackBar';
import { updateCursorStatus } from './index';
import search from '../components/contents/search';
import { UPDATE_BRIEF_FILTER_DATA } from './briefMe';

export const GET_SUBJECTS = 'Formula/GET_SUBJECTS';
export const UPDATE_FORMULA = 'Formula/UPDATE_FORMULA';
export const RESET_FORMULA = 'Formula/RESET_FORMULA';
export const UPDATE_BRIEF_META_DATA = 'BriefMe/UPDATE_BRIEF_META_DATA';

export function getFormulaTag(formulaId) {
  return `<formulae id=${formulaId} />`;
}

export function updateFormulaDetails(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_FORMULA, payload });
  };
}

export function resetFormula(payload = {}) {
  return async (dispatch) => {
    dispatch({ type: RESET_FORMULA, payload });
  };
}

export function updateBriefCursorPosition(briefCursorIndex) {
  return async (dispatch) => {
    const payload = {
      briefCursorIndex,
    };
    dispatch({
      type: UPDATE_BRIEF_FILTER_DATA,
      payload,
    });
  };
}

export function getFormula({
  bmSubjectId = null, briefId = null, limit = null, searchText = null,
}) {
  return async (dispatch, getState) => {
    const { bmSubjectId: currentBmSubjectId } = getState().formula;
    const response = await Api({
      method: 'get',
      url: `/formulas/get_formulas_of_subject?bm_subject_id=${bmSubjectId || currentBmSubjectId}&search_text=${searchText || ''}`,
    });
    if (response.success) {
      const payload = {};
      payload.formulas = response.formulas;
      payload.hasModifyBriefAccess = response.has_modify_brief_access;
      payload.bmSubjectId = bmSubjectId;
      dispatch({ type: UPDATE_FORMULA, payload });
    }
    return response;
  };
}

export function createFormula({ isChemicalEquation }) {
  return async (dispatch, getState) => {
    const {
      formulas, bmSubjectId, name, equation, derivation, explanation,
    } = getState().formula;
    dispatch(updateFormulaDetails({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: '/formulas',
      data: {
        name,
        equation,
        derivation,
        explanation,
        bm_subject_id: bmSubjectId,
        type: isChemicalEquation ? 'ChemicalEquation' : '',
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.name = '';
        payload.equation = '';
        payload.derivation = '';
        payload.explanation = '';
        payload.isFormulaItemSelected = false;
        payload.isChanged = false;
        payload.formulas = ([...formulas, response.formula]).sort((a, b) => {
          const first = a.name.toLowerCase();
          const second = b.name.toLowerCase();
          if (first < second) {
            return -1;
          } if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      payload.isUpdating = false;
      dispatch(updateFormulaDetails(payload));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateFormula({ isChemicalEquation, isFormulaSearch = false }) {
  return (dispatch, getState) => {
    const {
      bmSubjectId, name, equation, selectedFormulaId, derivation, explanation,
    } = getState().formula;
    const data = {
      id: selectedFormulaId,
      name,
      derivation,
      equation,
      explanation,
      bm_subject_id: bmSubjectId,
      type: isChemicalEquation ? 'ChemicalEquation' : '',
    };
    dispatch(updateFormulaDetails({ isUpdating: true }));
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: `/formulas/${selectedFormulaId.toString()}/update`,
      data,
    }).then((response) => {
      let payload = {};
      payload = response;
      payload.id = selectedFormulaId;
      payload.isFormulaSearch = isFormulaSearch;
      if (response.success) {
        payload.isFormulaSelected = false;
        payload.selectedFormulaId = null;
        payload.selectedIndex = null;
      }
      dispatch(showToast(response.message));
      payload.isUpdating = false;
      dispatch(updateFormulaDetails(payload));
      dispatch(getFormula({ bmSubjectId }));
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function deleteFormula() {
  return (dispatch, getState) => {
    const { selectedFormulaId, bmSubjectId } = getState().formula;
    return Api({
      method: 'post',
      url: `/formulas/${selectedFormulaId.toString()}/delete`,
    }).then((response) => {
      if (response.success) {
        dispatch(resetFormula({}));
        dispatch(getFormula({ bmSubjectId }));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateBriefContentWithFormula(formulaId, briefId, briefContentIndex) {
  return (dispatch, getState) => {
    const { content, selectedBriefId } = getState().briefMe;
    if (selectedBriefId !== briefId) {
      return false;
    }
    let briefCursorIndex = briefContentIndex;
    if (briefCursorIndex === null) {
      briefCursorIndex = content.length;
    }
    const formulaContent = getFormulaTag(formulaId);
    const updatedContent = `${content.slice(0, briefCursorIndex)}${formulaContent}${content.slice(briefCursorIndex)}`;

    const data = {
      formulaId,
      briefId,
      content: updatedContent,
    };
    return Api({
      method: 'post',
      url: '/formulas/create_brief_mapping',
      data,
    }).then((response) => {
      if (response.success) {
        const payload = {
          content: updatedContent,
          isChanged: false,
          metaData: response.meta_data,
          briefId,
        };
        dispatch({
          type: UPDATE_BRIEF_META_DATA,
          payload,
        });
      }
      return response;
    });
  };
}
