import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stackedit from 'stackedit-js';
import {
  Modal, Text, Loader,
} from '.';
import {
  addItemToDictionary, updateBriefsDictionary,
  uploadImage, showToast,
  updateItemInDictionary, deleteItemInDictionary,
} from '../../actions';
import UploadImage from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

const MOBILE_VIEW_WIDTH = 375;
const EDIT_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);
const stackedit = new Stackedit();

class EditDictionaryItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteDictionaryItemModal: false,
      showUploadImageModal: false,
      imageFile: '',
      isTextCopied: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseUploadImageModal = this.handleCloseUploadImageModal.bind(this);
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderDeleteDictionaryItemModal(showDeleteDictionaryItemModal) {
    return (
      <Modal
        key={showDeleteDictionaryItemModal}
        isModalVisible={showDeleteDictionaryItemModal}
        handleCloseModal={this.handleCloseModal}
        height={130}
        width={600}
      >
        {this.deleteDictionaryItem()}
      </Modal>
    );
  }

  renderUploadImageModal(showUploadImageModal) {
    const { briefMe: { imageURL } } = this.props;

    return (
      <Modal
        header="Upload Image"
        key={showUploadImageModal}
        isModalVisible={showUploadImageModal}
        handleCloseModal={this.handleCloseUploadImageModal}
        height={imageURL ? 380 : 330}
        width={imageURL ? 800 : 600}
        maxWidth={imageURL && 800}
      >
        {this.uploadImage()}
      </Modal>
    );
  }

  copyToClipboard(event) {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.setState({ isTextCopied: true });
  }

  uploadImage() {
    const { imageFile, isTextCopied } = this.state;
    const { briefMe: { isUpdating, imageURL } } = this.props;

    if (isUpdating) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    if (imageFile) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {imageFile
              && (
                <div style={{
                  height: 'auto', width: 'fit-content', textAlign: 'center', margin: 'auto',
                }}
                >
                  <img
                    alt={imageFile}
                    src={URL.createObjectURL(imageFile)}
                    style={{
                      height: '150px', width: 'auto', marginTop: 10, borderRadius: 10,
                    }}
                  />
                  <div
                    className="Button Secondary-Button"
                    role="presentation"
                    style={{ margin: '20px 80px', width: 100 }}
                    onClick={() => this.setState({ imageFile: '', isTextCopied: false }, () => this.props.updateBriefsDictionary({ imageURL: '' }))}
                  >
                    Clear
                  </div>
                </div>
              )}
          </div>
          {!imageURL && <div role="presentation" className="Button Primary-Button" onClick={() => this.props.uploadImage(this.state.imageFile)}>Upload</div>}
          {imageURL && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.renderTitle('Image URL:')}
                {document.queryCommandSupported('copy') && <div role="presentation" className={`Button ${isTextCopied ? 'Secondary-Button' : 'Primary-Button'}`} style={{ width: 100 }} onClick={isTextCopied ? () => {} : (event) => this.copyToClipboard(event)}>{`${isTextCopied ? 'Copied' : 'Copy'}`}</div>}
              </div>
              <textarea
                readOnly
                ref={(textarea) => this.textArea = textarea}
                value={imageURL}
                style={{
                  marginTop: 10, overflow: 'scroll', maxHeight: 45, maxWidth: 740,
                }}
              />
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div
          role="presentation"
          style={{
            display: 'flex', flexDirection: 'column', borderRadius: 5, height: 220, border: `1px solid ${StyleConstants.color.mediumLight}`, backgroundColor: '#FFF', cursor: 'pointer',
          }}
          onClick={() => document.getElementById('image-input').click()}
        >
          <input
            style={{
              display: 'none',
            }}
            id="image-input"
            type="file"
            accept="image/*"
            onChange={(event) => this.setState({ imageFile: event.target.files[0] })}
          />
          <img src={UploadImage} alt="upload_image" style={{ marginTop: 80 }} />
          <Text style={{ color: StyleConstants.color.mediumLight, marginTop: 10, textAlign: 'center' }} text="Drag or browse the Diagram file to upload" />
        </div>
        <div style={{ display: 'flex' }}>
          <img src={info} alt="info" />
          <Text style={{ color: StyleConstants.color.mediumLight, padding: 8 }} text="Upload only .Jpg or .Png files" />
        </div>
      </>
    );
  }

  editContent(description) {
    let textContent = '';

    stackedit.openFile({
      content: {
        text: description,
      },
    });

    stackedit.on('fileChange', (file) => {
      textContent = file.content.text;
    });
    stackedit.on('close', () => {
      this.props.updateBriefsDictionary({ description: textContent, isChanged: true });
    });
  }

  onSaveButtonClick() {
    const {
      briefsDictionary: {
        isDictionaryItemSelected, description, term, selectedBriefId, bmSubjectId,
      },
    } = this.props;

    if (isDictionaryItemSelected) {
      this.props.updateItemInDictionary({ selectedBriefId, term, description, bmSubjectId });
    } else {
      this.setState({ displayEditView: false }, () => {
        this.props.addItemToDictionary({ term, description, bmSubjectId });
      });
    }
  }

  deleteDictionaryItem() {
    const {
      briefsDictionary: {
        selectedBriefId, term,
      },
    } = this.props;
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text
          fontSize={StyleConstants.textSize.subHeader}
          color={StyleConstants.color.dark}
          style={{
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 550,
          }}
          text={`Are you sure you want to delete the dictionary  ${term}?`}
        />
        <div className="modalButtonView">
          <div role="presentation" className="Button Secondary-Button" style={{ width, marginRight: 5 }} onClick={this.handleCloseModal}> Cancel </div>
          <div role="presentation" className="Button Primary-Button" style={{ width }} onClick={() => this.setState({ showDeleteDictionaryItemModal: false }, () => this.props.deleteItemInDictionary({ selectedBriefId }))}> Delete </div>
        </div>
      </div>
    );
  }

  handleCloseUploadImageModal() {
    this.setState({ showUploadImageModal: false, isTextCopied: false });
  }

  handleCloseModal() {
    this.setState({ showDeleteDictionaryItemModal: false });
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') {
      this.props.updateBriefsDictionary({ displayEditView: false });
    }
  }

  editBrief() {
    const {
      briefMe: {
        isAdmin,
        isUpdating,
      }, isDictionarySearch,
    } = this.props;

    const {
      briefsDictionary: {
        selectedIndex,
        isDictionaryItemSelected,
        term,
        description,
        isChanged,
        hasModifyBriefAccess,
      },
    } = this.props;

    const enableSaveButton = term && description && isChanged;
    const width = EDIT_VIEW_WIDTH;
    const buttonWidth = 100;
    const inputStyle = { marginBottom: 20, borderRadius: 0, backgroundColor: '#FFF' };
    return (
      <div
        role="presentation"
        className="Card-View"
        style={{
          width, position: 'fixed', top: 0, bottom: 0, right: 0,
        }}
        onKeyUp={(event) => this.handleKeyUp(event)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          {!isDictionarySearch && this.renderText(isDictionaryItemSelected ? `Position: ${selectedIndex + 1}` : 'New item')}
          <div
            role="presentation"
            style={{
              height: 20, width: 20, border: '1px solid #000', borderRadius: 50, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            onClick={() => this.props.updateBriefsDictionary({ displayEditView: false })}
          >
            <img src="/images/common/x-mark.svg" alt="close" style={{ height: 10 }} />
          </div>
        </div>
        <input style={inputStyle} type="text" value={term} placeholder="Term" onChange={(event) => this.props.updateBriefsDictionary({ term: event.target.value, isChanged: true })} autoFocus />
        <textarea
          style={{
            marginBottom: 10, height: 500, borderRadius: 0, maxWidth: width - 40,
          }}
          value={description}
          placeholder="Description"
          onChange={(event) => this.props.updateBriefsDictionary({ description: event.target.value, isChanged: true })}
        />
        <div style={{
          display: 'flex', justifyContent: 'flex-end', color: StyleConstants.color.dark, marginBottom: 10,
        }}
        >
          {`${description.length} chars | ${(description.length > 0 ? description.trim().split(/\s+/).length : 0)} words`}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.setState({ showUploadImageModal: true })}>
            Add Image
          </div>
          <div role="presentation" className="Button Secondary-Button" style={{ width: buttonWidth, border: `1px solid ${StyleConstants.color.primary}` }} onClick={() => this.editContent(description)}>
            Markdown
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          {isDictionaryItemSelected && (
          <div style={{ display: 'flex' }}>
            {isAdmin && (
              <div role="presentation" className="Button Error-Button" style={{ width: buttonWidth }} onClick={() => (isDictionaryItemSelected && this.setState({ showDeleteDictionaryItemModal: true }))}>
                Delete
              </div>
            )}
          </div>
          )}         
          {isUpdating && this.showLoader({ marginRight: isDictionaryItemSelected && 40, marginLeft: !isDictionaryItemSelected && 40 })}
          {hasModifyBriefAccess && !isUpdating && (
            <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth, opacity: enableSaveButton ? 1 : 0.5, cursor: enableSaveButton ? 'pointer' : 'not-allowed' }} onClick={() => (enableSaveButton && this.onSaveButtonClick())}>
              {isDictionaryItemSelected ? 'Save' : 'Create'}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      briefsDictionary: {
        isLoading, isDictionaryItemSelected, selectedIndex, displayEditView,
      },
    } = this.props;
    const {
      showDeleteDictionaryItemModal, showUploadImageModal,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <>
        {displayEditView && (selectedIndex !== null) && this.editBrief()}
        {showUploadImageModal && this.renderUploadImageModal(showUploadImageModal)}
        {isDictionaryItemSelected && this.renderDeleteDictionaryItemModal(showDeleteDictionaryItemModal)}
      </>
    );
  }
}

EditDictionaryItem.propTypes = {
  briefsDictionary: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
  updateBriefsDictionary: PropTypes.func.isRequired,
  updateItemInDictionary: PropTypes.func.isRequired,
  deleteItemInDictionary: PropTypes.func.isRequired,
  isDictionarySearch: PropTypes.bool,
};

EditDictionaryItem.defaultProps = {
  isDictionarySearch: false,
};

const mapStateToProps = ({ briefMe, briefsDictionary }) => ({
  briefMe,
  briefsDictionary,
});

export default connect(mapStateToProps, {
  addItemToDictionary,
  updateBriefsDictionary,
  uploadImage,
  showToast,
  updateItemInDictionary,
  deleteItemInDictionary,
})(EditDictionaryItem);
