import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import BriefQuestion from './briefQuestions';
import NewBriefQuestion from './newBriefQuestion';
import {
  DropDown, Modal, Text, Loader, EditBrief, ShowBrief,
} from './common';
import {
  orderBriefs, getBriefs, updateBriefReducer, deleteChapter, editChapterName, getVersionsDetails, getVersionsComparison, restoreVersion, getBriefQuestionsForChapter,
} from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import fileConstants from '../shared/constants/fileConstants/briefMe.json';

const MOBILE_VIEW_WIDTH = 325;
const QUESTION_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);
const history = createBrowserHistory({
  forceRefresh: true,
});

class BriefMe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIssue: {},
      selectedNewerVersion: {},
      selectedOlderVersion: {},
      toIndex: 0,
      showEditModal: false,
      isCurrentBrief: false,
      economyMode: false,
    };
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.removeNewBriefQuestion = this.removeNewBriefQuestion.bind(this);
  }

  componentDidMount() {
    const { briefMe: { showInconsistentBriefs }, bmChapterId } = this.props;
    const { show_versions: showVersions, brief_id: briefId } = queryString.parse(window.location.search);

    this.props.getBriefs(bmChapterId, showInconsistentBriefs);
    this.props.getBriefQuestionsForChapter(bmChapterId);

    if (showVersions) {
      this.props.getVersionsDetails(briefId).then((response) => this.renderLatestVersionsComparison(response, briefId));
    }
  }

  removeNewBriefQuestion() {
    this.props.updateBriefReducer({ isAddBriefQuestion: false });
  }

  getItemIndex(array, selectedItem) {
    const selectedVersion = array.filter((item) => { return item.id === selectedItem.id; })[0];
    return array.indexOf(selectedVersion);
  }

  renderLatestVersionsComparison(response, briefId) {
    if (response.success) {
      const { briefMe: { briefVersions } } = this.props;
      const numberOfVersions = briefVersions.length;
      this.setState({ selectedNewerVersion: (numberOfVersions - 1) >= 0 ? briefVersions[numberOfVersions - 1] : {}, selectedOlderVersion: (numberOfVersions - 2) >= 0 ? briefVersions[numberOfVersions - 2] : {}, isCurrentBrief: briefVersions[numberOfVersions - 1]?.is_current_brief }, () => {
        this.props.getVersionsComparison(briefId, this.state.selectedNewerVersion.id, this.state.selectedOlderVersion.id, this.state.isCurrentBrief);
      });
    }
  }

  toggleStatus(status) {
    return status === 'ACTIVE' ? 'HIDDEN' : 'ACTIVE';
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  onDropdownValueChange(id, name, type, showInconsistentBriefs, issues, bmChapterId) {
    const { brief_id: briefId } = queryString.parse(window.location.search);
    const { briefMe: { briefVersions } } = this.props;

    this.setState({ [type]: { id, name } }, () => {
      if (showInconsistentBriefs) {
        this.props.getBriefs(bmChapterId, showInconsistentBriefs, issues[this.state.selectedIssue.id - 1]?.message);
      } else if (this.getItemIndex(briefVersions, this.state.selectedNewerVersion) <= this.getItemIndex(briefVersions, this.state.selectedOlderVersion)) {
        this.setState({ selectedOlderVersion: {}, isCurrentBrief: (briefVersions.filter((version) => { return version.id === this.state.selectedNewerVersion.id; })[0])?.is_current_brief }, () => this.props.getVersionsComparison(briefId, this.state.selectedNewerVersion.id));
      } else if (type === 'selectedNewerVersion') {
        this.setState({ isCurrentBrief: (briefVersions.filter((version) => { return version.id === this.state.selectedNewerVersion.id; })[0])?.is_current_brief }, () => {
          this.props.getVersionsComparison(briefId, this.state.selectedNewerVersion.id, this.state.selectedOlderVersion.id, this.state.isCurrentBrief);
        });
      } else {
        this.props.getVersionsComparison(briefId, this.state.selectedNewerVersion.id, this.state.selectedOlderVersion.id, this.state.isCurrentBrief);
      }
    });
  }

  renderDropdown(dropdownOptions, type, selectedItem, issues, bmChapterId, showInconsistentBriefs = false) {
    const { brief_id: briefId } = queryString.parse(window.location.search);

    return (
      <DropDown
        key={showInconsistentBriefs ? dropdownOptions.key : `${briefId} - ${dropdownOptions.key} - ${dropdownOptions.optionsList[0]?.id}`}
        selectedItem={selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={(id, name) => this.onDropdownValueChange(id, name, type, showInconsistentBriefs, issues, bmChapterId)}
      />
    );
  }

  editChapterName() {
    const {
      briefMe: {
        changedChapterName,
      }, bmChapterId,
    } = this.props;
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input style={{ width: 180, marginRight: 15, backgroundColor: '#FFF' }} type="text" value={changedChapterName} placeholder="Chapter Name" onChange={(event) => this.props.updateBriefReducer({ changedChapterName: event.target.value })} />
        <div className="modalButtonView">
          <div className="Button Primary-Button" style={{ width }} onClick={() => { this.props.editChapterName(changedChapterName, bmChapterId); this.handleCloseEditModal(); }}> Update </div>
        </div>
      </div>
    );
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderToggleChapter() {
    const {
      briefMe: {
        selectedChapterStatus,
      }, bmChapterId,
    } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={selectedChapterStatus === 'ACTIVE'}
          onChange={() => this.props.updateBriefReducer({ selectedChapterStatus: this.toggleStatus(selectedChapterStatus), bmChapterId })}
          style={{ fontSize: StyleConstants.textSize.tertiaryHeader, margin: '0px 5px', cursor: 'pointer' }}
        />
        {this.renderTitle('Active')}
      </div>
    );
  }

  handleCloseEditModal() {
    this.setState({ showEditModal: false });
  }

  renderEditModal(showEditModal) {
    return (
      <Modal
        header="Edit Chapter Name"
        key={showEditModal}
        isModalVisible={showEditModal}
        handleCloseModal={this.handleCloseEditModal}
        height={150}
        width={600}
      >
        {this.editChapterName()}
      </Modal>
    );
  }

  deleteChapter() {
    const {
      briefMe: {
        overallDetails,
      }, bmChapterId,
    } = this.props;

    return (
      <div
        role="presentation"
        className="Button Error-Button"
        onClick={() => {
          this.props.deleteChapter(bmChapterId, overallDetails.selectedBmSubject.id);
        }}
        style={{
          width: 140, padding: '0px 20px',
        }}
      >
        Delete Chapter
      </div>
    );
  }

  handleAddButtonClick(index) {
    this.props.updateBriefReducer({ displayEditView: false }).then(() => {
      this.props.updateBriefReducer({
        title: '',
        content: '',
        audioURL: '',
        timeToRead: '',
        deprecated: false,
        pollyText: '<speak> Enter the text here </speak>',
        selectedBriefId: null,
        enableFilterSearch: false,
        isBriefSelected: false,
        selectedIndex: index,
        displayEditView: true,
        showBriefQuestionView: false,
        isAddBriefQuestion: false,
        previousYearQuestionId: '',
      });
    });
  }

  renderButtonToAddBrief(index) {
    return (
      <div
        role="presentation"
        className="fa fa-plus"
        style={{ margin: '10px 0px', cursor: 'pointer' }}
        onClick={() => this.handleAddButtonClick(index)}
      />
    );
  }

  renderButtonToMoveBrief(fromIndex, toIndex) {
    const { briefMe: { isOrderBeingUpdated, briefOrder, briefs }, bmChapterId } = this.props;
    const parentIdOfToIndex = briefs[briefOrder[toIndex]].parent_id;
    const isLoading = (!isOrderBeingUpdated && this.state.toIndex === toIndex);
    const parentIdOfFromIndex = briefs[briefOrder[fromIndex]].parent_id;
    let message = `Move from ${fromIndex + 1} to ${toIndex + 1}`;
    let disableMoveBrief = false;
    if (isLoading) {
      return this.showLoader({ marginLeft: 130 });
    } if (parentIdOfToIndex && parentIdOfFromIndex !== parentIdOfToIndex) {
      message = 'Cannot Move into another parent - child!';
      disableMoveBrief = true;
    } else if (parentIdOfFromIndex && ((parentIdOfFromIndex !== parentIdOfToIndex))) {
      message = 'Cannot Move outside from parent!';
      disableMoveBrief = true;
    } else if (parentIdOfFromIndex === briefOrder[toIndex]) {
      message = 'Cannot Move to parent position!';
      disableMoveBrief = true;
    }
    return (
      <div
        role="presentation"
        className="Move-Button"
        style={{ marginLeft: disableMoveBrief ? 30 : 90, opacity: disableMoveBrief ? 0.5 : 1, cursor: disableMoveBrief ? 'not-allowed' : 'pointer' }}
        onClick={() => !disableMoveBrief && this.setState({ toIndex }, () => this.props.orderBriefs(fromIndex, toIndex, bmChapterId))}
      >
        {message}
      </div>
    );
  }

  renderButtonsToAddOrMove(isBriefSelected, selectedIndex, index) {
    const { briefMe: { briefs, briefOrder } } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {this.renderButtonToAddBrief(index)}
        {(isBriefSelected && selectedIndex !== index) && (
          <div style={{ margin: 10 }}>
            {(Object.keys(briefs).length > 0) && (index !== briefOrder.length) && this.renderButtonToMoveBrief(selectedIndex, index)}
          </div>
        )}
      </div>
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  renderBriefsList(briefs, briefOrder, width, printBriefs = false) {
    const { briefMe: { selectedIndex, isBriefSelected, showInconsistentBriefs }, bmChapterId } = this.props;
    let parentIndex = 0;
    let subIndex = 0;
    if (Object.keys(briefs).length !== briefOrder.length) {
      return (
        <div style={{ margin: '100px auto auto auto' }}>
          {this.renderTitle("Brief order either contains few extra brief IDs or doesn't contain few brief IDs of this chapter.")}
        </div>
      );
    }

    return (
      <div style={{ width: printBriefs ? 800 : width, margin: printBriefs && 'auto' }}>
        {!printBriefs && (showInconsistentBriefs ? <div style={{ height: 25 }} /> : this.renderButtonsToAddOrMove(isBriefSelected, selectedIndex, 0))}
        {briefOrder.map((briefId, index) => {
          const {
            id, title, content, time_to_read, audio_url, deprecated, polly_text, brief_questions_count, brief_previous_year_questions_count, message_to_improve_consistency, tags, parent_id, meta_data, 
          } = briefs[briefId];
          const briefDetails = {
            id, title, content, timeToRead: time_to_read, audioURL: audio_url, deprecated, pollyText: polly_text, index, width: MOBILE_VIEW_WIDTH, briefQuestionsCount: brief_questions_count, briefPreviousYearQuestionsCount: brief_previous_year_questions_count, messageToImproveConsistency: message_to_improve_consistency, briefTags: tags, printBriefs, displayAddButton: true, isAudioModal: false, showBorder: false, isVersionComparison: false, parentId: parent_id, metaData: meta_data, 
          };
          if (!parent_id) {
            parentIndex += 1;
            subIndex = 0;
          } else {
            subIndex += 1;
          }
          briefDetails.titleWithIndex = `${parentIndex}.${subIndex || ''} ${briefDetails.title}`;
          return <ShowBrief briefDetails={briefDetails} bmChapterId={bmChapterId} />;
        })}
      </div>
    );
  }

  renderEditButton() {
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        onClick={() => this.setState({ showEditModal: true })}
        style={{
          width: 100,
        }}
      >
        Edit Name
      </div>
    );
  }

  renderVersionNotPresentMessage() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
        {this.renderTitle('Versions are not present for this brief.')}
      </div>
    );
  }

  renderVersionDetails(versionDropdownOptions, type, selectedVersion, dropdownOptionsList) {
    const {
      briefMe: {
        selectedIndex, olderBrief, newerBrief, newerVersionCreator, olderVersionCreator,
      }, bmChapterId,
    } = this.props;
    const brief = (type === 'selectedNewerVersion') ? newerBrief : olderBrief;

    if (brief) {
      const {
        id, title, content, time_to_read: timeToRead, audio_url: audioURL, deprecated, polly_text: pollyText, meta_data: metaData,
      } = brief;
      const authorName = (type === 'selectedNewerVersion') ? newerVersionCreator : olderVersionCreator;
      const briefDetails = {
        id: brief.id, title, content, timeToRead, audioURL, deprecated, pollyText, index: selectedIndex, width: 500, briefQuestionsCount: '', briefPreviousYearQuestionsCount: '', messageToImproveConsistency: '', briefTags: [], printBriefs: false, displayAddButton: false, isAudioModal: false, showBorder: true, isVersionComparison: true, metaData, 
      };

      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderDropdown(versionDropdownOptions, type, selectedVersion, dropdownOptionsList, bmChapterId)}
          <div style={{ marginTop: 30 }}>
            {authorName && this.renderTitle(`Author: ${authorName}`)}
            <ShowBrief briefDetails={briefDetails} bmChapterId={bmChapterId} />
            {((type === 'selectedOlderVersion') || !this.state.isCurrentBrief) && <div className="Button Primary-Button" style={{ width: 150, marginTop: 30 }} onClick={() => this.props.restoreVersion(id, selectedVersion.id)}> Restore this version </div>}
          </div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderDropdown(versionDropdownOptions, type, selectedVersion, dropdownOptionsList, bmChapterId)}
        <div style={{ marginTop: 30 }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            {this.renderTitle(`${(type === 'selectedNewerVersion') ? 'Newer Version' : 'Older Version'} is not selected.`)}
          </div>
        </div>
      </div>
    );
  }

  renderColumnDetails(columnName, columnValue) {
    columnValue = columnValue.filter((element) => {
      return element !== '';
    });
    return (
      <div style={{ margin: 10, cursor: 'pointer' }} onClick={() => this.setState({ selectedColumn: columnName })}>
        {`${columnName}: [ `}
        {columnValue.map((detail, index) => {
          return (
            <>
              {detail}
              {' '}
              {(index !== columnValue.length - 1) && ', '}
              {' '}
            </>
          );
        })}
        {' ]'}
      </div>
    );
  }

  renderVersionDifferences() {
    const { briefMe: { versionDifferences } } = this.props;

    return (
      <div className="Card-View" style={{ backgroundColor: StyleConstants.backgroundColor.error }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
          {this.renderTitle('Version Differences', true)}
        </div>
        {Object.entries(versionDifferences).map(([columnName, columnValue]) => this.renderColumnDetails(columnName, columnValue))}
      </div>
    );
  }

  renderBriefVersionComparison() {
    const { briefMe: { briefVersions } } = this.props;
    const { selectedNewerVersion, selectedOlderVersion } = this.state;

    const versionDropdownOptions = {
      key: selectedNewerVersion.id,
      width: 200,
      height: 35,
    };

    const newerVersionDropdownOptions = {
      ...versionDropdownOptions,
      optionType: 'Newer Version',
      defaultText: 'Newer Version',
      optionsList: briefVersions,
    };

    const olderBriefVersions = briefVersions.filter((version, index) => { return index < this.getItemIndex(briefVersions, selectedNewerVersion); });

    const olderVersionDropdownOptions = {
      ...versionDropdownOptions,
      optionType: 'Older Version',
      defaultText: 'Older Version',
      optionsList: olderBriefVersions,
    };

    if (briefVersions.length === 0) {
      return this.renderVersionNotPresentMessage();
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
          {this.renderVersionDetails(newerVersionDropdownOptions, 'selectedNewerVersion', selectedNewerVersion, briefVersions)}
          {this.renderVersionDetails(olderVersionDropdownOptions, 'selectedOlderVersion', selectedOlderVersion, olderBriefVersions)}
        </div>
        {selectedNewerVersion.id && selectedOlderVersion.id && this.renderVersionDifferences()}
      </>
    );
  }

  render() {
    const {
      briefMe: {
        isLoading, briefs, briefOrder, selectedIndex, hasCreateSubjectAccess, isAdmin, overallDetails, displayEditView, selectedBriefId, isAddBriefQuestion, updatedCount, showBriefQuestionView, showBriefPreviousYearQuestionView, briefQuestionListForChapter,
      }, bmChapterId,
    } = this.props;
    const { showEditModal, selectedIssue, economyMode } = this.state;
    const { print_chapter_briefs: printChapterBriefs, print_chapter_brief_questions: printChapterBriefQuestions } = queryString.parse(window.location.search);
    const width = (window.innerWidth - 250) / 2;
    const issues = fileConstants.briefIssues;
    const issueTypeDropdownOptions = {
      key: selectedIssue.id || issues.length,
      optionsList: issues,
      optionType: 'Issue Type',
      defaultText: 'Issue Type',
      width: 200,
      height: 35,
    };

    const style = { width: QUESTION_VIEW_WIDTH };
    const { show_versions: showVersions } = queryString.parse(window.location.search);

    if (isLoading) {
      return <Loader />;
    }

    if ((printChapterBriefQuestions || printChapterBriefs) && overallDetails && overallDetails.selectedBmChapter) {
      return (
        <>
          <style jsx>
            {`
            .chapter-title {
              text-align: center;
              margin: 0px 110px;
            }

            @media print {
              .chapter-title {
                margin: auto;
              }
            }

          `}
          </style>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="bg-text watermark">IDEABOARD</div>
            <div>
              {printChapterBriefQuestions
                && (
                <div
                  role="presentation"
                  className="Button Primary-Button hide-button"
                  style={{
                    width: economyMode ? 170 : 120, margin: '15px 0px 15px auto', padding: 5, float: 'right',
                  }}
                  onClick={() => this.setState({ economyMode: !economyMode })}
                >
                  {economyMode ? 'Disable Economy Mode' : 'Economy Mode'}
                </div>
                )}
              <div style={{ width: printChapterBriefQuestions ? (window.innerWidth - 100) / 2 : 800, margin: 'auto' }}>
                <div className="flexcontainer" style={{ float: 'right' }}>
                  <label
                    className="switch hide-button"
                    style={{
                      marginTop: 20, width: 110, height: 20, padding: 5,
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={printChapterBriefQuestions}
                      onChange={() => history.push({ search: printChapterBriefQuestions ? `?bmChapterId=${bmChapterId}&print_chapter_briefs=true` : `?bmChapterId=${bmChapterId}&print_chapter_brief_questions=true` })}
                    />
                    <div className="slider-text round">
                      <span className="brief-qns-on">Brief Qns</span>
                      <span className="brief-qns-off">Briefs</span>
                    </div>
                  </label>
                </div>
                <div className="chapter-title">
                  <Text fontSize={StyleConstants.textSize.primaryHeader} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.color.dark} text={overallDetails.selectedBmChapter.name} style={{ textDecoration: 'underline' }} />
                </div>
              </div>
            </div>
            {printChapterBriefs && this.renderBriefsList(briefs, briefOrder, width, true)}
            {printChapterBriefQuestions && (briefQuestionListForChapter.map((BriefQuestion, index) => (
              <NewBriefQuestion
                id={BriefQuestion.brief_id}
                question={BriefQuestion}
                index={index + 1}
                economyMode={economyMode}
              />
            )))}
          </div>
        </>
      );
    }

    if (showVersions) {
      return this.renderBriefVersionComparison();
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 40 }}>
              {this.renderEditButton()}
            </div>
            {hasCreateSubjectAccess && this.renderToggleChapter()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: isAdmin ? 750 : 700 }}>
            {hasCreateSubjectAccess && (
              <>
                {this.renderDropdown(issueTypeDropdownOptions, 'selectedIssue', selectedIssue, issues, bmChapterId, this.props.briefMe.showInconsistentBriefs)}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label className="switch" style={{ marginBottom: 0, marginRight: 5 }}>
                    <input
                      type="checkbox"
                      checked={this.props.briefMe.showInconsistentBriefs}
                      onChange={() => this.props.updateBriefReducer({ showInconsistentBriefs: !this.props.briefMe.showInconsistentBriefs }).then(() => this.props.getBriefs(bmChapterId, this.props.briefMe.showInconsistentBriefs, issues[selectedIssue.id - 1]?.message))}
                    />
                    <span className="slider round" />
                  </label>
                  {this.renderTitle('Briefs To Be Improved')}
                </div>
              </>
            )}
            {hasCreateSubjectAccess && (
              <div role="presentation" className="Button Secondary-Button" style={{ width: 100, border: `1px solid ${StyleConstants.color.primary}` }} onClick={() => window.open(`/brief_me/browse?bmChapterId=${bmChapterId}&print_chapter_briefs=true`)}>
                Proofread
              </div>
            )}
            {isAdmin && this.deleteChapter()}
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {this.renderBriefsList(briefs, briefOrder, width)}
          {(selectedIndex !== null) && !showBriefQuestionView && <EditBrief displayEditView={displayEditView} bmChapterId={bmChapterId} />}
          {selectedBriefId && (showBriefQuestionView || showBriefPreviousYearQuestionView) && (
            <BriefQuestion
              id={selectedBriefId}
              key={`${updatedCount}-${isAddBriefQuestion}`}
              style={style}
              isAddBriefQuestion={showBriefQuestionView}
              updatedCount={updatedCount}
              removeNewBriefQuestion={this.removeNewBriefQuestion}
              isPreviousYearQuestionView={showBriefPreviousYearQuestionView}
            />
          )}
        </div>
        {showEditModal && this.renderEditModal(showEditModal)}
      </>
    );
  }
}

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  orderBriefs,
  getBriefs,
  updateBriefReducer,
  deleteChapter,
  editChapterName,
  getVersionsDetails,
  getVersionsComparison,
  getBriefQuestionsForChapter,
  restoreVersion,
})(BriefMe);
