import {
  GET_SEARCH_QUESTIONS, UPDATE_SEARCH_STRING, RESET_SUBJECTS, RESET_CHAPTERS, RESET_TOPICS,
  UPDATE_APPLIED_FILTERS, FILTER_CATEGORIES, UPDATE_FILTER_LIST, UPDATE_TAG, UPDATE_DIFFICULTY, HANDLE_PAGINATION, UPDATE_SEARCH_LOADER_STATE,
} from '../actions';

const INITIAL_STATE = {
  testList: [],
  subjectList: [],
  recentTestReport: {},
  questions: [],
  searchText: '',
  difficulty: [],
  checkpoints: [],
  appliedFilters: {},
  courses: [],
  subjects: [],
  chapters: [],
  tests: [],
  totalQuestionsCount: 0,
  activePage: 1,
  itemsCountPerPage: 20,
  fetching: false,
  searchApplied: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SEARCH_QUESTIONS:
      return { ...state, ...action.payload };
    case HANDLE_PAGINATION:
      return { ...state, ...action.payload };
    case UPDATE_SEARCH_LOADER_STATE:
      return { ...state, ...action.payload };
    case UPDATE_TAG:
      const questions = [...state.questions];
      questions.forEach((question) => {
        if (question.id === action.payload.question_id) {
          question.question_checkpoint_mappings = action.payload.mapped_checkpoints;
        }
      });
      return { ...state, questions };
    case UPDATE_DIFFICULTY:
      const questionsToBeModified = [...state.questions];
      questionsToBeModified.forEach((question) => {
        if (question.id === action.payload.question_id) {
          question.complexity = action.payload.complexity;
        }
      });
      return { ...state, questions: questionsToBeModified };
    case UPDATE_SEARCH_STRING:
      return { ...state, ...action.payload };
    case UPDATE_FILTER_LIST:
      return { ...state, ...action.payload };
    case RESET_SUBJECTS:
      const resetSubjects = state.appliedFilters;
      resetSubjects[FILTER_CATEGORIES.SUBJECT] = {};
      resetSubjects[FILTER_CATEGORIES.CHAPTER] = {};
      resetSubjects[FILTER_CATEGORIES.TOPIC] = {};
      resetSubjects[FILTER_CATEGORIES.PREVIOUS_PAPERS] = [];
      return {
        ...state, appliedFilters: resetSubjects, chaptersList: [], topics: [],
      };
    case RESET_CHAPTERS:
      const resetChapters = state.appliedFilters;
      resetChapters[FILTER_CATEGORIES.CHAPTER] = {};
      resetChapters[FILTER_CATEGORIES.TOPIC] = {};
      return { ...state, appliedFilters: resetChapters, topics: [] };
    case RESET_TOPICS:
      const resetTopics = state.appliedFilters;
      resetTopics[FILTER_CATEGORIES.TOPIC] = {};
      return { ...state, appliedFilters: resetTopics };
    case UPDATE_APPLIED_FILTERS:
      const filters = state.appliedFilters;
      const current = action.payload;
      return { ...state, appliedFilters: { ...filters, ...current } };
    default:
      return state;
  }
};
