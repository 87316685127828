import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import ReportIssue from '../reportIssue';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import Layout from '../layout';

const history = createBrowserHistory({
  forceRefresh: true,
});

const FEEDBACK_TYPES = {
  report_issues: 'Report Issue',
};

class Feedback extends PureComponent {
  constructor(props) {
    super(props);
    const { type } = props.match.params;
    const urlType = type.split('&')[0];
    this.state = {
      selectedType: type ? FEEDBACK_TYPES[urlType] : FEEDBACK_TYPES.report_issues,
      selectedSubject: '',
      selectedItem: {},
    };
  }

  componentDidMount() {
  }

  render() {
    const { selectedType } = this.state;
    const isReportError = selectedType === FEEDBACK_TYPES.report_issues;
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.FEEDBACK.label} currentPage={fileConstants.contentsMenuOrder.FEEDBACK.position}>
        <div
          className="Simple-Card-View"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 30, fontFamily: StyleConstants.textFont.normal, fontSize: StyleConstants.textSize.subHeader,
          }}
        >
          {isReportError && <ReportIssue />}
        </div>
      </Layout>
    );
  }
}

Feedback.propTypes = {

};

Feedback.defaultProps = {

};

const mapStateToProps = ({ question }) => ({
  subjects: question.subjects,
});

export default connect(mapStateToProps, {})(Feedback);
