import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getQuickpointList, getChapterCheckpointList, getQuickpointFilterData, updateQuickpoint, deprecateQuickpoint, addQuickpoint, orderQuickpoints, updateQuickpointReducer, IMAGE_UPDATE_QUICKPOINT } from '../actions';
import { DropDown, Text, Loader, QuickpointFilter } from './common/index';

import StyleConstants from '../shared/constants/styleConstants/styles.json';
import '../shared/constants/styleConstants/loader.css';

const QUICKPOINT_CONTENT_WIDTH = {
  width: 300,
};

class CheatsheetList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      text: '',
      imageUpdate: IMAGE_UPDATE_QUICKPOINT.NO_CHANGE,
      selectedQuickpointId: 0,
      supportingPicture: null,
      toIndex: '',
    };
  }

  componentDidMount() {
    const searchParams = queryString.parse(window.location.hash);
    const courseID = searchParams.course_id ? parseInt(searchParams.course_id) : 0;
    const subjectID = searchParams.subject_id ? parseInt(searchParams.subject_id) : 0;
    const chapterID = searchParams.chapter_id ? parseInt(searchParams.chapter_id) : 0;
    this.props.getQuickpointFilterData(courseID, subjectID, chapterID).then((response) => {
      if (response.success) {
        this.props.getQuickpointList();
      }
    });
    window.scrollTo(0, 0);
  }

  renderButtonToAdd(index) {
    const { cheatsheetList: { appliedFilters } } = this.props;
    return (
      <div
        className="fa fa-plus"
        style={{ margin: '10px 0', cursor: 'pointer' }}
        onClick={() => this.setState({
  selectedQuickpointId: 0, imageUpdate: IMAGE_UPDATE_QUICKPOINT.NO_CHANGE, title: '', text: '', supportingPicture: '',
}, () => this.props.updateQuickpointReducer({
  appliedFilters: { course: appliedFilters.course, subject: appliedFilters.subject, chapter: appliedFilters.chapter }, enableFilterSearch: false, isQuickpointSelected: false, selectedIndex: index,
}))}
      />
    );
  }

  onSave(enableSave) {
    const {
      supportingPicture, text, title, selectedQuickpointId, imageUpdate,
    } = this.state;
    const {
      cheatsheetList: {
        appliedFilters, isQuickpointSelected, selectedIndex, configuration,
      },
    } = this.props;
    if (enableSave) {
      if (isQuickpointSelected) {
        this.props.updateQuickpoint(selectedQuickpointId, title, text, supportingPicture, appliedFilters.checkpoints, imageUpdate, configuration);
      } else {
        this.props.addQuickpoint(title, text, appliedFilters.checkpoints, supportingPicture, selectedIndex, appliedFilters.chapter.id, configuration);
      }
    }
  }

  handleQuickpointClick(id, title, text, supporting_picture, checkpoint_list, configuration, selectedIndex) {
    const { cheatsheetList: { appliedFilters } } = this.props;
    this.setState({
      selectedQuickpointId: id, imageUpdate: IMAGE_UPDATE_QUICKPOINT.NO_CHANGE, id, title, text, supportingPicture: supporting_picture,
    }, () => this.props.updateQuickpointReducer({
      appliedFilters: {
        course: appliedFilters.course, subject: appliedFilters.subject, chapter: appliedFilters.chapter, checkpoints: checkpoint_list,
      },
      enableFilterSearch: false,
      isQuickpointSelected: true,
      configuration,
      selectedIndex,
    }));
  }

  renderDescription(text) {
    return (
      <Text fontSize={StyleConstants.textSize.text} style={{ width: 130 }} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text={`${text === 'Show Image' || text === 'Show Description' || text === 'Add Image Size' ? text : `${text}: `}`} />
    );
  }

  getCheckpointsData(id, name, appliedFilters) {
    if (!!appliedFilters.checkpoints && appliedFilters.checkpoints.filter((option) => { return option.id === id; }).length === 1) {
      return appliedFilters.checkpoints.filter((option) => { return option.id !== id; });
    } if (appliedFilters.checkpoints) {
      return [...appliedFilters.checkpoints, { id: id || '', name: name || '' }];
    }
    return [{ id: id || '', name: name || '' }];
  }

  renderButtonToMoveQuickpoint(fromIndex, toIndex) {
    const { cheatsheetList: { quickpoints, isSuccessfullyOrdered, appliedFilters: { chapter } } } = this.props;
    const isLoading = (!isSuccessfullyOrdered && this.state.toIndex === toIndex);
    if (isLoading) {
      return (
        <div
          className="loader"
          style={{
            marginLeft: 250, width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2,
          }}
        />
      );
    }
    return (
      <div
        className="Move-Button"
        style={{ marginLeft: 200 }}
        onClick={() => this.setState({ toIndex }, () => this.props.orderQuickpoints(fromIndex, toIndex, chapter.id, quickpoints))}
      >
        {`Move from ${fromIndex + 1} to ${toIndex + 1}`}
      </div>
    );
  }

  renderCheckpointsDropdown(checkpoints, appliedFilters) {
    return (
      <div style={{ marginBottom: 30 }}>
        {this.renderDescription('Checkpoints')}
        <DropDown
          key={checkpoints.length}
          selectedItems={appliedFilters.checkpoints}
          optionsList={checkpoints}
          optionType="Checkpoints"
          color={StyleConstants.color.dark}
          onValueChange={(id, name) => {
            this.props.updateQuickpointReducer({
              appliedFilters: {
                course: appliedFilters.course,
                subject: appliedFilters.subject,
                chapter: appliedFilters.chapter,
                checkpoints: this.getCheckpointsData(id, name, appliedFilters),
              },
              enableFilterSearch: false,
            });
          }}
        />
      </div>
    );
  }

  renderDropdownToAddImageSize(imageSizes, configuration) {
    return (
      <div style={{ borderColor: StyleConstants.color.primary }}>
        <div style={{ marginLeft: 45 }}>
          {this.renderDescription('Add Image Size')}
        </div>
        <DropDown
          key={imageSizes.length}
          selectedItem={imageSizes[configuration.image_size - 1]}
          optionsList={imageSizes}
          optionType="Image Size"
          color={StyleConstants.color.dark}
          onValueChange={(id) => {
            this.props.updateQuickpointReducer({
              configuration: {
                ...configuration,
                image_size: id,
              },
            });
          }}
        />
      </div>
    );
  }

  renderToggleToShowDescription(configuration) {
    return (
      <div style={{ display: 'flex' }}>
        <input
          type="checkbox"
          checked={!!configuration && !!configuration.show_text}
          onChange={() => this.props.updateQuickpointReducer({ configuration: { ...configuration, show_text: !configuration.show_text } })}
          style={{ fontSize: StyleConstants.textSize.tertiaryHeader, marginRight: 10, cursor: 'pointer' }}
        />
        {this.renderDescription('Show Description')}
      </div>
    );
  }

  renderToggleToShowImage(configuration) {
    return (
      <div style={{ display: 'flex', marginTop: 15 }}>
        <input
          type="checkbox"
          checked={!!configuration && !!configuration.show_image}
          onChange={() => this.props.updateQuickpointReducer({ configuration: { ...configuration, show_image: !configuration.show_image } })}
          style={{ fontSize: StyleConstants.textSize.tertiaryHeader, marginRight: 10, cursor: 'pointer' }}
        />
        <Text fontSize={StyleConstants.textSize.text} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text="Show Image" />
      </div>
    );
  }

  editQuickpoint() {
    const {
      supportingPicture, text, title, selectedQuickpointId, imageUpdate,
    } = this.state;
    const {
      cheatsheetList: {
        checkpoints, configuration, appliedFilters, isQuickpointSelected,
      },
    } = this.props;
    let enableSave = text && title && appliedFilters.checkpoints && appliedFilters.checkpoints.length;
    const width = (window.innerWidth - 250 - 30 * 3) / 2;
    const imageSizes = [{ id: 1, name: 'Small' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Large' }];
    const isImagePresent = (imageUpdate === IMAGE_UPDATE_QUICKPOINT.NO_CHANGE && isQuickpointSelected) ? !!supportingPicture && !!supportingPicture.url : !!supportingPicture;
    enableSave = isImagePresent ? enableSave && configuration.image_size : enableSave;
    const showImage = isImagePresent && configuration && configuration.show_image;

    return (
      <div className="Card-View" style={{ width }}>
        <input
          style={{
            marginBottom: 20, height: 50, borderRadius: 0, backgroundColor: '#FFF',
          }}
          type="text"
          maxLength="100"
          value={title}
          placeholder="Title"
          onChange={(event) => this.setState({ title: event.target.value })}
        />
        { configuration && configuration.show_text && (
        <textarea style={{ marginBottom: 20, height: 200, borderRadius: 0 }} value={text} placeholder="Description" onChange={(event) => this.setState({ text: event.target.value })} />
        )}
        <>
          <div style={{ display: 'flex', justifyContent: showImage ? 'space-between' : 'flex-end' }}>
            {showImage && this.renderEditViewSupportingPicture(selectedQuickpointId, imageUpdate, supportingPicture)}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <>
                      <input type="file" id="fileInput" style={{ display: 'none' }} name="fileInput" onChange={(event) => this.setState({ supportingPicture: event.target.files[0], imageUpdate: IMAGE_UPDATE_QUICKPOINT.UPDATED })} />
                      <div className="Button Secondary-Button" style={{ width: 100, marginTop: 10 }} onClick={() => document.getElementById('fileInput').click()}>
                              Add Image
                        </div>
                    </>
                  {isImagePresent && this.renderToggleToShowImage(configuration)}
                </div>
          </div>
          <div style={{ display: 'flex', marginTop: 15, justifyContent: 'space-between' }}>
            {(checkpoints.length > 0) && this.renderCheckpointsDropdown(checkpoints, appliedFilters)}
            {isImagePresent && this.renderDropdownToAddImageSize(imageSizes, configuration)}
          </div>
        </>
        {this.renderToggleToShowDescription(configuration)}
        <div style={{
          display: 'flex', justifyContent: 'space-between', marginTop: 30, alignItems: 'center',
        }}
        >
          <div className="Button Error-Button" style={{ width: 100, opacity: isQuickpointSelected ? 1 : 0.5, cursor: isQuickpointSelected ? 'pointer' : 'not-allowed' }} onClick={() => isQuickpointSelected && this.props.deprecateQuickpoint(selectedQuickpointId)}>
                  Deprecate
          </div>
          <div className="Button Secondary-Button" style={{ width: 100, opacity: enableSave ? 1 : 0.5, cursor: enableSave ? 'pointer' : 'not-allowed' }} onClick={() => this.onSave(enableSave)}>
                  Save
          </div>
        </div>
      </div>
    );
  }

  renderEditViewSupportingPicture(selectedQuickpointId, imageUpdate, supportingPicture) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderDescription('Supporting Picture')}
        <img
          src={((selectedQuickpointId > 0) && imageUpdate === IMAGE_UPDATE_QUICKPOINT.NO_CHANGE) ? supportingPicture.url : URL.createObjectURL(supportingPicture)}
          alt="supporting_picture"
          style={{
            maxWidth: QUICKPOINT_CONTENT_WIDTH.width, width: 'fit-content', border: `1px solid ${StyleConstants.color.mediumLight}`, marginBottom: 20, padding: 5,
          }}
          height="fit-content"
        />
      </div>
    );
  }

  renderCheckpointList(checkpoints) {
    return (
      <div style={{ display: 'flex', marginBottom: 20 }}>
        {this.renderDescription('Checkpoints')}
        <ul style={{ paddingLeft: 25 }}>
          {checkpoints.map((checkpoint) => { return <li>{checkpoint.name}</li>; })}
        </ul>
      </div>
    );
  }

  renderQuickpointSupportingPicture(id, supporting_picture, quickpointConfiguration) {
    const { supportingPicture, selectedQuickpointId, imageUpdate } = this.state;
    const { cheatsheetList: { isQuickpointSelected, configuration } } = this.props;
    const isImagePresent = (imageUpdate === IMAGE_UPDATE_QUICKPOINT.NO_CHANGE && isQuickpointSelected) ? !!supportingPicture && !!supportingPicture.url : !!supportingPicture;
    const showImage = isImagePresent && configuration && configuration.show_image;

    if (selectedQuickpointId === id && showImage) {
      return this.renderEditViewSupportingPicture(selectedQuickpointId, imageUpdate, supportingPicture);
    }
    if (supporting_picture && supporting_picture.url && quickpointConfiguration && quickpointConfiguration.show_image) {
      return (
        <div style={{ display: 'flex' }}>
          {this.renderDescription('Supporting Picture')}
          <img
            src={supporting_picture.url}
            alt="supporting_picture"
            style={{
                    border: `1px solid ${StyleConstants.color.mediumLight}`, marginBottom: 20, padding: 5, maxWidth: QUICKPOINT_CONTENT_WIDTH.width, width: 'fit-content',
                  }}
            height="fit-content"
          />
        </div>
      );
    }
  }

  renderButtonsToAddOrMove(selectedIndex, index) {
    return (
      <div style={{ display: 'flex' }}>
        {this.renderButtonToAdd(index)}
        {(selectedIndex !== '' && selectedIndex !== index) && (
        <div style={{ margin: 10 }}>
          {this.renderButtonToMoveQuickpoint(selectedIndex, index)}
        </div>
        )}
      </div>
    );
  }

  renderQuickpoints(id, title, text, supporting_picture, configuration, checkpoint_list, index) {
    const width = (window.innerWidth - 250 - 30 * 3) / 2;
    const { selectedQuickpointId } = this.state;
    const { cheatsheetList: { appliedFilters, selectedIndex } } = this.props;
    return (
      <>
        <div
          className="Card-View"
          style={{ width, marginRight: 30, border: (selectedIndex === index) && `2px solid ${StyleConstants.color.primary}` }}
          onClick={() => this.handleQuickpointClick(id, title, text, supporting_picture, checkpoint_list, configuration, index)}
        >
          {window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])}
          <div style={{ display: 'flex', marginBottom: 20 }}>
            {this.renderDescription('Title')}
            <div style={{ width: QUICKPOINT_CONTENT_WIDTH.width }}>{ selectedQuickpointId === id ? this.state.title : title }</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 20, whiteSpace: 'pre-wrap' }}>
            {this.renderDescription('Description')}
            <div style={{ width: QUICKPOINT_CONTENT_WIDTH.width }}>{ selectedQuickpointId === id ? this.state.text : text }</div>
          </div>
          {selectedQuickpointId === id && appliedFilters.checkpoints && (appliedFilters.checkpoints.length > 0)
            ? this.renderCheckpointList(appliedFilters.checkpoints)
            : (checkpoint_list.length > 0) && this.renderCheckpointList(checkpoint_list)}
          {this.renderQuickpointSupportingPicture(id, supporting_picture, configuration)}
        </div>
        {this.renderButtonsToAddOrMove(selectedIndex, index + 1)}
      </>
    );
  }

  renderQuickpointList(quickpoints, height) {
    const { cheatsheetList: { selectedIndex } } = this.props;
    return (
      <div style={{ height: height - 50, overflowY: 'scroll' }}>
        {this.renderButtonsToAddOrMove(selectedIndex, 0)}
        {quickpoints.map(({
          id, title, text, supporting_picture, configuration, checkpoint_list,
        }, index) => {
          return this.renderQuickpoints(id, title, text, supporting_picture, configuration, checkpoint_list, index);
        })}
      </div>
    );
  }

  render() {
    const {
      cheatsheetList: {
        quickpoints, selectedChapter, isQuickpointSelected, selectedIndex, isLoading,
      },
    } = this.props;
    const height = (window.innerHeight - 150);

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div style={{ height }}>
        <QuickpointFilter
          filterFunction={this.props.getQuickpointList}
        />
        <div style={{ display: 'flex' }}>
          {selectedChapter.show_quickpoint && this.renderQuickpointList(quickpoints, height)}
          <div style={{ height: height - 90, marginTop: 30, overflowY: 'scroll' }}>
            {(selectedIndex !== '' || isQuickpointSelected) && this.editQuickpoint()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cheatsheetList }) => ({
  cheatsheetList,
});

export default connect(mapStateToProps, {
  getQuickpointList, getChapterCheckpointList, getQuickpointFilterData, deprecateQuickpoint, addQuickpoint, updateQuickpoint, orderQuickpoints, updateQuickpointReducer,
})(CheatsheetList);
