import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stackedit from 'stackedit-js';
import {
  Modal, Text, Loader,
} from '.';
import {
  createDiagram, updateDiagramDetails,
  uploadImage, showToast,
  updateDiagram, deleteDiagram,
  clearImageFile,
} from '../../actions';
import UploadImage from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

const MOBILE_VIEW_WIDTH = 375;
const EDIT_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);
const stackedit = new Stackedit();

class EditDiagram extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteDiagramModal: false,
      showUploadImageModal: false,
      imageFile: '',
      isTextCopied: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseUploadImageModal = this.handleCloseUploadImageModal.bind(this);
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderDeleteDiagramModal(showDeleteDiagramModal) {
    return (
      <Modal
        key={showDeleteDiagramModal}
        isModalVisible={showDeleteDiagramModal}
        handleCloseModal={this.handleCloseModal}
        height={130}
        width={600}
      >
        {this.deleteDiagram()}
      </Modal>
    );
  }

  renderUploadImageModal(showUploadImageModal) {
    const { briefMe: { imageURL } } = this.props;

    return (
      <Modal
        header="Upload Image"
        key={showUploadImageModal}
        isModalVisible={showUploadImageModal}
        handleCloseModal={this.handleCloseUploadImageModal}
        height={imageURL ? 380 : 330}
        width={imageURL ? 800 : 600}
        maxWidth={imageURL && 800}
      >
        {this.uploadImage()}
      </Modal>
    );
  }

  copyToClipboard(event) {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.setState({ isTextCopied: true });
  }

  uploadImage() {
    const { imageFile, isTextCopied } = this.state;
    const { briefMe: { isUpdating, imageURL } } = this.props;

    if (isUpdating) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    if (imageFile) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {imageFile
              && (
                <div style={{
                  height: 'auto', width: 'fit-content', textAlign: 'center', margin: 'auto',
                }}
                >
                  <img
                    alt={imageFile}
                    src={URL.createObjectURL(imageFile)}
                    style={{
                      height: '150px', width: 'auto', marginTop: 10, borderRadius: 10,
                    }}
                  />
                  <div
                    className="Button Secondary-Button"
                    role="presentation"
                    style={{ margin: '20px 80px', width: 100 }}
                    onClick={() => this.setState({ imageFile: '', isTextCopied: false }, () => this.props.updateDiagramDetails({ imageURL: '' }))}
                  >
                    Clear
                  </div>
                </div>
              )}
          </div>
          {!imageURL
          && (
          <div
            role="presentation"
            className="Button Primary-Button"
            onClick={() => {
              this.props.uploadImage(this.state.imageFile).then((response) => {
                this.props.updateDiagramDetails({ image: response.brief_image_url, isChanged: true });
                this.handleCloseUploadImageModal();
                this.props.clearImageFile();
              });
              this.setState({ imageFile: '' });
            }}
          >
            Upload
          </div>
          )}
        </>
      );
    }
    return (
      <>
        <div
          role="presentation"
          style={{
            display: 'flex', flexDirection: 'column', borderRadius: 5, height: 220, border: `1px solid ${StyleConstants.color.mediumLight}`, backgroundColor: '#FFF', cursor: 'pointer',
          }}
          onClick={() => document.getElementById('image-input').click()}
        >
          <input
            style={{
              display: 'none',
            }}
            id="image-input"
            type="file"
            accept="image/*"
            onChange={(event) => this.setState({ imageFile: event.target.files[0] })}
          />
          <img src={UploadImage} alt="upload_image" style={{ marginTop: 80 }} />
          <Text style={{ color: StyleConstants.color.mediumLight, marginTop: 10, textAlign: 'center' }} text="Drag or browse the Diagram file to upload" />
        </div>
        <div style={{ display: 'flex' }}>
          <img src={info} alt="info" />
          <Text style={{ color: StyleConstants.color.mediumLight, padding: 8 }} text="Upload only .Jpg or .Png files" />
        </div>
      </>
    );
  }

  editContent(content) {
    let textContent = '';

    stackedit.openFile({
      content: {
        text: content,
      },
    });

    stackedit.on('fileChange', (file) => {
      textContent = file.content.text;
    });
    stackedit.on('close', () => {
      this.props.updateDiagramDetails({ description: textContent, isChanged: true });
    });
  }

  onSaveButtonClick() {
    const {
      diagram: {
        isDiagramSelected, description, name, bmSubjectId, image,
      }, isChemicalEquation,
    } = this.props;

    if (isDiagramSelected) {
      this.props.updateDiagram({
        name, description, bmSubjectId, image, isChemicalEquation,
      });
    } else {
      this.setState({ displayEditView: false }, () => {
        this.props.createDiagram({
          name, description, bmSubjectId, image, isChemicalEquation,
        });
      });
    }
  }

  deleteDiagram() {
    const {
      diagram: {
        name,
      },
    } = this.props;
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text
          fontSize={StyleConstants.textSize.subHeader}
          color={StyleConstants.color.dark}
          style={{
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 550,
          }}
          text={`Are you sure you want to delete the diagram ${name}?`}
        />
        <div className="modalButtonView">
          <div role="presentation" className="Button Secondary-Button" style={{ width, marginRight: 5 }} onClick={this.handleCloseModal}> Cancel </div>
          <div role="presentation" className="Button Primary-Button" style={{ width }} onClick={() => this.setState({ showDeleteDiagramModal: false }, () => this.props.deleteDiagram())}> Delete </div>
        </div>
      </div>
    );
  }

  handleCloseUploadImageModal() {
    this.setState({ showUploadImageModal: false, isTextCopied: false });
  }

  handleCloseModal() {
    this.setState({ showDeleteDiagramModal: false });
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') {
      this.props.updateDiagramDetails({ displayEditView: false });
    }
  }

  editDiagram() {
    const {
      briefMe: {
        isAdmin,
        isUpdating,
      }, isDiagramSearch, isChemicalEquation,
    } = this.props;

    const {
      diagram: {
        selectedIndex,
        isDiagramSelected,
        name,
        description,
        isChanged,
        image,
        hasModifyBriefAccess,
      },
    } = this.props;
    const enableSaveButton = name && image && isChanged;
    const width = EDIT_VIEW_WIDTH;
    const buttonWidth = 100;
    const inputStyle = { marginBottom: 20, borderRadius: 0, backgroundColor: '#FFF' };
    return (
      <div
        role="presentation"
        className="Card-View"
        style={{
          width, position: 'fixed', top: 0, bottom: 0, right: 0,
        }}
        onKeyUp={(event) => this.handleKeyUp(event)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          {!isDiagramSearch && this.renderText(isDiagramSelected ? `Position: ${selectedIndex + 1}` : 'New item')}
          <div
            role="presentation"
            style={{
              height: 20, width: 20, border: '1px solid #000', borderRadius: 50, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            onClick={() => this.props.updateDiagramDetails({ displayEditView: false })}
          >
            <img src="/images/common/x-mark.svg" alt="close" style={{ height: 10 }} />
          </div>
        </div>
        <div> Name</div>
        <input style={inputStyle} type="text" value={name} placeholder="Name" onChange={(event) => this.props.updateDiagramDetails({ name: event.target.value, isChanged: true })} autoFocus />
        <div> Image</div>
        <textarea
          style={{
            marginBottom: 10, height: 40, borderRadius: 0, maxWidth: width - 40,
          }}
          value={image}
          placeholder="Image (symbols and variables)"
          onChange={(event) => this.props.updateDiagramDetails({ image: event.target.value, isChanged: true })}
        />
        <div> Description</div>
        <textarea
          style={{
            marginBottom: 10, height: 450, borderRadius: 0, maxWidth: width - 40,
          }}
          value={description}
          placeholder="Description"
          onChange={(event) => this.props.updateDiagramDetails({ description: event.target.value, isChanged: true })}
        />
        <div style={{
          display: 'flex', justifyContent: 'flex-end', color: StyleConstants.color.dark, marginBottom: 10,
        }}
        >
          {`${description.length} chars | ${(description.length > 0 ? description.trim().split(/\s+/).length : 0)} words`}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.setState({ showUploadImageModal: true })}>
            Add Image
          </div>
          <div role="presentation" className="Button Secondary-Button" style={{ width: buttonWidth, border: `1px solid ${StyleConstants.color.primary}` }} onClick={() => this.editContent(description)}>
            Markdown
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          {isDiagramSelected && (
          <div style={{ display: 'flex' }}>
            {isAdmin && (
              <div role="presentation" className="Button Error-Button" style={{ width: buttonWidth }} onClick={() => (isDiagramSelected && this.setState({ showDeleteDiagramModal: true }))}>
                Delete
              </div>
            )}
          </div>
          )}
          {isUpdating && this.showLoader({ marginRight: isDiagramSelected && 40, marginLeft: !isDiagramSelected && 40 })}
          {hasModifyBriefAccess && !isUpdating && (
            <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth, opacity: enableSaveButton ? 1 : 0.5, cursor: enableSaveButton ? 'pointer' : 'not-allowed' }} onClick={() => (enableSaveButton && this.onSaveButtonClick())}>
              {isDiagramSelected ? 'Save' : 'Create'}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      diagram: {
        isLoading, isDiagramSelected, selectedIndex, displayEditView,
      },
    } = this.props;
    const {
      showDeleteDiagramModal, showUploadImageModal,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <>
        {displayEditView && (selectedIndex !== null) && this.editDiagram()}
        {showUploadImageModal && this.renderUploadImageModal(showUploadImageModal)}
        {isDiagramSelected && this.renderDeleteDiagramModal(showDeleteDiagramModal)}
      </>
    );
  }
}

EditDiagram.propTypes = {
  diagram: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
  updateDiagram: PropTypes.func.isRequired,
  updateDiagramDetails: PropTypes.func.isRequired,
  deleteDiagram: PropTypes.func.isRequired,
  isDiagramSearch: PropTypes.bool,
  isChemicalEquation: PropTypes.bool,
};

EditDiagram.defaultProps = {
  isDiagramSearch: false,
  isChemicalEquation: false,
};

const mapStateToProps = ({ briefMe, diagram }) => ({
  briefMe,
  diagram,
});

export default connect(mapStateToProps, {
  createDiagram,
  updateDiagram,
  uploadImage,
  showToast,
  updateDiagramDetails,
  deleteDiagram,
  clearImageFile,
})(EditDiagram);
