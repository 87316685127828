import React from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';

import {
  QuestionView, Text, Loader, QuestionFilter,
} from './common';
import { DIFFICULTY_VALUE_MAPPING } from '../shared/constants/fieldTypes';
import {
  getAutoTagDifficulties, updateQuestionReducer, updateDifficulty, getFilterData, TOTAL_QUESTIONS_PER_PAGE,
} from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';

class AutoTagDifficulty extends React.Component {
  componentDidMount() {
    this.props.getFilterData();
    this.props.getAutoTagDifficulties();
  }

  predictionData(heading, description) {
    return (
      <div style={{ marginRight: 40, display: 'flex', flexDirection: 'column' }}>
        <Text color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} text={heading} />
        <Text color={StyleConstants.textColor.secondary} text={description} />
      </div>
    );
  }

  updateDifficulty(question, index, status, complexity) {
    return this.props.updateDifficulty({
      questionId: question.question_id, index, status, currentDifficulty: question.complexity, suggestedDifficulty: question.predicted_difficulty, difficultyScore: question.predicted_percentage, complexity,
    });
  }

  displayAction(question, index) {
    const isAccepted = question.status === 1;
    const isRejected = question.status === 2;
    const emptyAction = !(isAccepted || isRejected);
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={emptyAction || isRejected ? 'Button Accept-Button' : 'Button Accept-Selected '}
          style={{
            padding: 10, width: 100, margin: '0px 10px', opacity: emptyAction || isAccepted ? 1 : 0.8,
          }}
          onClick={() => { !isAccepted && this.updateDifficulty(question, index, 1, question.predicted_difficulty); }}
        >
          {emptyAction || isRejected ? 'Accept' : 'Accepted'}
        </div>
        <div
          className={emptyAction || isAccepted ? 'Button Reject-Button' : 'Button Reject-Selected'}
          Reject-Selected
          style={{
            padding: 10, width: 100, margin: '0px 10px', opacity: emptyAction || isRejected ? 1 : 0.8,
          }}
          onClick={() => { !isRejected && this.updateDifficulty(question, index, 2, question.complexity); }}
        >
          {emptyAction || isAccepted ? ' Reject' : ' Rejected'}
        </div>
      </div>
    );
  }

  predictionInfo(question, index) {
    return (
      <div
        className="Card-View"
        style={{
          display: 'flex', backgroundColor: StyleConstants.backgroundColor.lightGray, flexDirection: 'row', marginTop: '-18px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.predictionData('CURRENT DIFFICULTY', DIFFICULTY_VALUE_MAPPING[question.complexity])}
          {this.predictionData('DIFFICULTY SCORE', `${question.predicted_percentage}%`)}
          {this.predictionData('SUGGESTED DIFFICULTY', DIFFICULTY_VALUE_MAPPING[question.predicted_difficulty])}
          {this.displayAction(question, index)}
        </div>
      </div>
    );
  }

  render() {
    const {
      question: {
        questionsWithMismatchedPredictions, isLoading, autoTagPageIndex, totalMismatchedPredictions,
      },
    } = this.props;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <>
        <QuestionFilter
          filterFunction={() => {
					  this.props.getAutoTagDifficulties(autoTagPageIndex, true);
          }}
        />
        {window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])}
        {questionsWithMismatchedPredictions.map((question, index) => (
          <>
            <QuestionView
              videoSolutionWidth="460px"
              videoSolutionHeight="200px"
              currentIndex={((autoTagPageIndex - 1) * TOTAL_QUESTIONS_PER_PAGE) + (index + 1)}
              currentQuestion={question}
            />
            {this.predictionInfo(question, index)}
          </>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
          <Pagination
            activePage={parseInt(autoTagPageIndex)}
            itemsCountPerPage={TOTAL_QUESTIONS_PER_PAGE}
            totalItemsCount={totalMismatchedPredictions}
            pageRangeDisplayed={5}
            onChange={(pageIndex) => {
						  window.scroll(0, 0);
						  this.props.updateQuestionReducer({ autoTagPageIndex: pageIndex });
						  this.props.getAutoTagDifficulties(pageIndex);
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ question }) => ({
  question,
});
export default connect(mapStateToProps, {
  getAutoTagDifficulties, updateQuestionReducer, updateDifficulty, getFilterData,
})(AutoTagDifficulty);
