import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  BriefMeTabs, DropDown, Loader, Text, BriefView,
} from '../common/index';
import {
  briefSearchByWord, replaceBriefText, getBrief, updateBriefReducer,
} from '../../actions';

class BriefFindAndReplace extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: {},
      searchText: '',
      replacingText: '',
      enableSearchButton: false,
      displayNumberOfResults: false,
    };
  }

  componentDidMount() {
    const { brief_id: briefId } = queryString.parse(window.location.search);
    if (briefId) {
      this.props.getBrief(briefId);
    }
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  checkIfSearchTextEmpty() {
    this.setState({ enableSearchButton: this.state.searchText.trim() !== '' });
  }

  handleInputChange(isSearchText, event) {
    if (isSearchText) {
      this.setState({ searchText: event.target.value }, () => this.checkIfSearchTextEmpty());
    } else {
      this.setState({ replacingText: event.target.value }, () => this.checkIfSearchTextEmpty());
    }
  }

  renderSubjectDropdown(dropdownOptions) {
    return (
      <DropDown
        key={dropdownOptions.key}
        selectedItem={dropdownOptions.selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={(id, name) => this.setState({ selectedSubject: { id, name } }, () => this.checkIfSearchTextEmpty())}
      />
    );
  }

  renderInputBox(isSearchText = true) {
    const { searchText, replacingText } = this.state;
    const inputStyle = { backgroundColor: '#FFF', height: 40 };
    return (
      <input
        style={inputStyle}
        type="text"
        value={isSearchText ? searchText : replacingText}
        placeholder={isSearchText ? 'Find' : 'Replace'}
        onChange={(event) => this.handleInputChange(isSearchText, event)}
      />
    );
  }

  renderBriefSearch(subjectDropdownOptions) {
    const { searchText, replacingText, selectedSubject, enableSearchButton } = this.state;
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', width: 830, margin: '0px 40px',
      }}
      >
        {this.renderInputBox()}
        {this.renderInputBox(false)}
        {this.renderSubjectDropdown(subjectDropdownOptions)}
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{
            width: 100, height: 40, opacity: enableSearchButton ? 1 : 0.5, cursor: enableSearchButton ? 'pointer' : 'not-allowed',
          }}
          onClick={enableSearchButton ? () => this.props.briefSearchByWord(searchText.trim(), replacingText.trim(), selectedSubject.id || '').then(() => this.setState({ displayNumberOfResults: true, enableSearchButton: false })) : () => {}}
        >
          Search
        </div>
      </div>
    );
  }

  renderMatchingOrReplacingText(text) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  renderTable() {
    const headings = ['Id', 'Original', 'New', 'Replace'];
    const { searchText, replacingText } = this.state;
    const { briefMe: { searchedBriefs } } = this.props;

    return (
      <div style={{ overflowX: 'auto', margin: '2% 3% 3% 3%', width: '94%' }}>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead>
            <tr
              className="table-header"
              style={{
                fontSize: '18px', fontWeight: '700', height: 55, color: '#11426C', paddingLeft: 20,
              }}
            >
              {headings.map((heading, index) => {
                return (
                  <th style={{
                    width: (index === 0 || index === headings.length - 1) ? '10%' : '40%', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
                  }}
                  >
                    {' '}
                    {heading.toLocaleUpperCase()}
                    {' '}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {searchedBriefs?.map((briefData) => {
              const rowData = [briefData.id, briefData.matching_substring, briefData.replacing_substring, 'Replace'];
              const enableReplaceButton = rowData[2].length > 0;
              return (
                <tr style={{ fontSize: StyleConstants.textSize.subHeader, color: StyleConstants.textColor.secondary, fontWeight: '400' }}>
                  {
                    rowData.map((key, columnIndex) => {
                      return (
                        <td
                          role="presentation"
                          style={{
                            padding: '12px', color: StyleConstants.textColor.secondary, borderRight: '1px solid #DFE3EA', cursor: (columnIndex === 0 || columnIndex === 1) && 'pointer',
                          }}
                          onClick={() => (columnIndex === 0 || columnIndex === 1) && this.props.updateBriefReducer({ selectedBriefId: briefData.id }).then(() => window.open(`/brief_me/findAndReplace?brief_id=${briefData.id}`))}
                        >
                          {(columnIndex === 0)
                            ? key
                            : ((columnIndex === rowData.length - 1)
                              ? (
                                <div role="presentation" className="Button Primary-Button" style={{ opacity: enableReplaceButton ? 1 : 0.5, cursor: enableReplaceButton ? 'pointer' : 'not-allowed' }} onClick={enableReplaceButton ? () => this.props.replaceBriefText(briefData, searchText, replacingText) : () => {}}>
                                  {key}
                                </div>
                              )
                              : this.renderMatchingOrReplacingText(key))}
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {
      briefMe: {
        hasCreateSubjectAccess, subjects, isLoading, selectedBrief, searchedBriefs,
      },
    } = this.props;
    const { displayNumberOfResults, selectedSubject } = this.state;
    const { brief_id: briefId } = queryString.parse(window.location.search);
    const subjectDropdownOptions = {
      key: Object.values(subjects).length,
      optionType: 'BmSubject',
      defaultText: 'BmSubject',
      optionsList: Object.values(subjects),
      selectedItem: selectedSubject,
      width: 250,
      height: 35,
    };

    if (isLoading) {
      return <Loader />;
    }

    if (briefId && Object.keys(selectedBrief).length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
          {this.renderTitle(`Brief is not present with ID = ${briefId}`)}
        </div>
      );
    }

    if (briefId) {
      return <BriefView selectedType="Find And Replace" />;
    }

    return (
      <div style={{ minHeight: 250 }}>
        {hasCreateSubjectAccess && <BriefMeTabs selectedType="Find And Replace" />}
        {this.renderBriefSearch(subjectDropdownOptions)}
        {displayNumberOfResults && <div style={{ margin: '10px 40px 0px 40px' }}>{`${searchedBriefs.length} results found`}</div>}
        {this.renderTable()}
      </div>
    );
  }
}

BriefFindAndReplace.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  briefSearchByWord: PropTypes.func.isRequired,
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  briefSearchByWord, replaceBriefText, getBrief, updateBriefReducer,
})(BriefFindAndReplace);
