import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { BriefMeTabs, Text } from '../common';

class SubscriptConverter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      molecularFormula: '',
      isSuperscript: false,
      isTextCopied: false,
    };
  }

  copyToClipboard(event) {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.setState({ isTextCopied: true });
  }

  convertToSubscript(formula, isSuperscript) {
    const formulaWords = formula.split('');
    let molecularFormula = '';
    for (let index = 0; index < formulaWords.length; index++) {
      if (!formulaWords[index].match(/\d+/)) {
        molecularFormula += formulaWords[index];
      } else {
        let numberInString = '';
        while ((index < formulaWords.length) && formulaWords[index].match(/\d+/)) {
          numberInString += formulaWords[index];
          index += 1;
        }
        molecularFormula += isSuperscript ? `<sup>${numberInString}</sup>` : `<sub>${numberInString}</sub>`;
        if (index < formulaWords.length) {
          molecularFormula += formulaWords[index];
        }
      }
    }
    return molecularFormula;
  }

  renderSlider() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px 40px' }}>
        <label className="switch" style={{ marginBottom: 0, marginRight: 5 }}>
          <input
            type="checkbox"
            checked={this.state.isSuperscript}
            onChange={() => this.setState({ isSuperscript: !this.state.isSuperscript, isTextCopied: false })}
          />
          <span className="slider round" />
        </label>
        <Text fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text="Superscript" />
      </div>
    );
  }

  render() {
    const { molecularFormula, isSuperscript, isTextCopied } = this.state;
    const { hasCreateSubjectAccess } = this.props;

    return (
      <>
        {hasCreateSubjectAccess && <BriefMeTabs selectedType="Subscript Converter" />}
        {this.renderSlider()}
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 40px', width: 750,
          }}
        >
          <input
            autoFocus
            style={{ borderRadius: 0, width: 300, backgroundColor: '#FFF' }}
            type="text"
            value={molecularFormula}
            placeholder="Molecular Formula"
            onChange={(event) => this.setState({ molecularFormula: event.target.value, isTextCopied: false })}
          />
          <textarea
            ref={(textarea) => this.textArea = textarea}
            readOnly
            value={this.convertToSubscript(molecularFormula, isSuperscript)}
            style={{
              overflow: 'scroll', maxHeight: 35, minHeight: 35, maxWidth: 300, minWidth: 300,
            }}
          />
          {molecularFormula.length > 0 && document.queryCommandSupported('copy') && (
          <div
            role="presentation"
            className={`Button ${isTextCopied ? 'Secondary-Button' : 'Primary-Button'}`}
            style={{ width: 100 }}
            onClick={isTextCopied ? () => {} : (event) => this.copyToClipboard(event)}
          >
            {`${isTextCopied ? 'Copied' : 'Copy'}`}
          </div>
          )}
        </div>
      </>
    );
  }
}

SubscriptConverter.propTypes = {
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  hasCreateSubjectAccess: briefMe.hasCreateSubjectAccess,
});

export default connect(mapStateToProps, {})(SubscriptConverter);
