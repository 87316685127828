import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import _ from 'lodash';
import 'react-responsive-modal/styles.css';
import Close from '../../shared/images/common/close_black.svg';
import SearchWeb from '../../shared/images/common/search-web.svg';
import { toTitleCase } from '../../helpers/Utils';
import { updateBriefContentWithFormula, updateBriefContentWithDiagram } from '../../actions';

class TableModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
    };
    this.getSearchData = _.debounce(this.getSearchData.bind(this), 500);
    this.onAddClick = _.debounce(this.onAddClick.bind(this), 500);
  }

  onAddClick() {
    const { onAddButtonClick, briefId, briefContentIndex, handleCloseModal } = this.props;
    const { selectedId } = this.state;
    onAddButtonClick(briefId, briefContentIndex, selectedId);
    handleCloseModal();
  }

  getSearchData(searchTerm) {
    const { getSearchData } = this.props;
    getSearchData(searchTerm);
  }

  handleSelection(selectedId) {
    this.setState({ selectedId });
  }

  renderAddButton() {
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        onClick={() => this.onAddClick()}
      >
        Add
      </div>
    );
  }

  renderTable() {
    const {
      children, tableValues, tableHeaders, isAddButtonRequired,
    } = this.props;
    const { selectedId } = this.state;
    return (
      <>
        <table
          className="table table-hover"
          style={{
            width: '100%', height: 400, overflow: 'scroll',
          }}
        >
          <thead>
            <tr
              style={{
                fontSize: '18px', fontWeight: '700', height: 55, color: '#11426C',
              }}
            >
              <th
                style={{
                  width: '20%', borderRight: '1px solid #DFE3EA',
                }}
              >
                S. No
              </th>
              {tableHeaders.map((tableHeader, index) => {
                return (
                  <th
                    style={{
                      width: '60%', borderRight: '1px solid #DFE3EA', padding: 5,
                    }}
                  >
                    {toTitleCase(tableHeader)}
                  </th>
                );
              })}
              <th
                style={{
                  width: '20%', borderRight: '1px solid #DFE3EA', padding: 5,
                }}
              >
                Select
              </th>
            </tr>
          </thead>
          <tbody>
            {tableValues.map((tableValue, index) => {
              return (
                <tr
                  style={{
                    fontSize: '18px', fontWeight: '700', height: 55, color: '#11426C',
                  }}
                  onClick={() => { this.handleSelection(tableValue.id); }}
                >
                  <td
                    style={{
                      width: '15%', borderRight: '1px solid #DFE3EA', padding: 5,
                    }}
                  >
                    {index + 1}
                  </td>
                  {tableHeaders.map((tableHeader, index) => {
                    return (
                      <td
                        style={{
                          width: '70%', borderRight: '1px solid #DFE3EA', padding: 5,
                        }}
                      >
                        {toTitleCase(tableValue[tableHeader])}
                      </td>
                    );
                  })}
                  <td
                    style={{
                      width: '15%', borderRight: '1px solid #DFE3EA', padding: 5,
                    }}
                  >
                    <input
                      style={{ display: 'flex', margin: 'auto' }}
                      type="radio"
                      checked={selectedId === tableValue.id}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isAddButtonRequired && this.renderAddButton()}
        {/* {children} */}
      </>
    );
  }

  renderSearchField() {
    return (
      <div style={{
        display: 'flex', alignItems: 'center', marginBottom: 15,
      }}
      >
        <input
          placeholder="Search formula name"
          style={{
            outline: 'none',
            fontSize: 16,
            height: 40,
            width: '100%',
            border: '1px solid black',
            borderRadius: 0,
            backgroundColor: 'white',
          }}
          onChange={(event) => this.getSearchData(event.target.value)}
        />
        <div
          style={{
            height: 40, backgroundColor: '#E5E5E5', display: 'flex', alignItems: 'center', padding: '0 15px', cursor: 'pointer',
          }}
          onClick={(event) => this.props.getSearchData(event.target.value)}
        >
          <img src={SearchWeb} height={35} width={35} alt="Search" />
        </div>
      </div>
    );
  }

  render() {
    const {
      isModalVisible, header, maxWidth, headerStyle, headerHeight, height, width, iconHeight, iconWidth, handleCloseModal,
      searchFieldRequired,
    } = this.props;
    return (
      <Modal
        center
        open={isModalVisible}
        onClose={handleCloseModal}
        styles={{
          closeIcon: {
            opacity: 0.5,
            cursor: 'pointer',
            width: iconHeight,
            height: iconWidth,
            position: 'absolute',
            right: 0,
            top: 10,
          },
          overflow: 'auto',
          modal: {
            padding: '20px 30px 30px 30px',
            height,
            width,
            minWidth: 300,
            maxWidth,
            borderRadius: 4,
          },
          closeButton: {
            display: 'none',
          },
        }}
        classNames={{ closeButton: false }}
      >

        {header && (
          <div
            className="d-flex justify-content-between"
            style={{
              borderBottom: '1px solid #efefef', alignItems: 'center', display: 'flex', height: headerHeight || '', justifyContent: 'space-between',
            }}
          >
            <b style={{ fontSize: 18, ...headerStyle, whiteSpace: 'pre-wrap' }}>
              {' '}
              {header}
              {' '}
            </b>
            <img
              src={Close}
              alt=""
              style={{
                height: '10.5px', width: '10.5px', color: '#43464B', float: 'right', cursor: 'pointer',
              }}
              onClick={() => handleCloseModal()}
            />
          </div>
        )}
        {searchFieldRequired
          && this.renderSearchField()}
        {this.renderTable()}
      </Modal>
    );
  }
}

TableModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  briefId: PropTypes.number,
  briefContentIndex: PropTypes.number,
  width: PropTypes.number,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  image: PropTypes.string,
  isModalVisible: PropTypes.bool,
  onAddButtonClick: PropTypes.func.isRequired,
  getSearchData: PropTypes.func.isRequired,
};

TableModal.defaultProps = {
  isModalVisible: false,
  maxWidth: 600,
  iconHeight: 20,
  iconWidth: 20,
  image: '',
  height: 400,
  briefId: 0,
  briefContentIndex: 0,
  width: 200,
};

const mapStateToProps = ({ briefMe, formula }) => ({
  briefMe,
  formula,
});

export default connect(mapStateToProps, {
  updateBriefContentWithFormula,
  updateBriefContentWithDiagram,
})(TableModal);
