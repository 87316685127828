import Constants from '../shared/constants/environmentConfig';

const constants = Constants();

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const isMac = () => {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
};

export const getVimeoVideoId = (url) => {
  const videoUrl = url ? url.match(/.*\/*vimeo.com\/(\d+)\/*/) : '';
  return videoUrl ? videoUrl[1] : '';
};

export const renderMathjax = () => {
  return window && window.MathJax && window.MathJax.Hub && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
};

export const getHomeDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.homeDomain;
};

export const getAccountsDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.accountsDomain;
};

export const asciiForMathJax = () => {
  const asciiMathJax = 'U = 1/(R_(si) + sum_(i=1)^n(s_n/lambda_n) + R_(se))';
  return asciiMathJax;
};

export const UUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3|0x8); // eslint-disable-line
    return v.toString(16);
  });
};
export const percentageCalulator = (percentageOf, totalValue) => {
  if (totalValue) {
    const percentage = ((percentageOf * 100) / totalValue);
    return `${Math.floor(percentage)}%`;
  }
  return '0%';
};

export const isValidNumber = (number) => {
  const data = number && number.toString();
  const isNumber = data && !isNaN(data) && data.indexOf('-') <= 0;
  return isNumber;
};

export const numberFormatterWithCommas = (data) => {
  return data ? data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
};

export const getUniqObjectsFromArrayofObjects = (list) => {
  let uniqArray = [];
  uniqArray = list.reduce((accumulator, current) => [...accumulator.filter((obj) => obj.id !== current.id), current], []);
  return uniqArray;
};

export const toTitleCase = (string) => {
  const newString = (string.split('_').join(' ').toLowerCase());
  return newString[0].toUpperCase() + newString.slice(1, newString.length);
};

const upper = (word) => {
  return word.substr(0, 1).toUpperCase() + word.substr(1);
};

const lower = (word) => {
  return word.toLowerCase();
};

export const titleCase = (title) => {
  const small = '(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|v[.]?|via|vs[.]?)';
  const punct = "([!\"#$%&'()*+,./:;<=>?@[\\\\\\]^_`{|}~-]*)";
  const parts = []; const split = /[:.;?!] |(?: |^)["Ò]/g; let
    index = 0;

  while (true) {
    const m = split.exec(title);
    parts.push(title.substring(index, m ? m.index : title.length)
      .replace(/\b([A-Za-z][a-z.'Õ]*)\b/g, (all) => {
        return /[A-Za-z]\.[A-Za-z]/.test(all) ? all : upper(all);
      })
      .replace(RegExp(`\\b${small}\\b`, 'ig'), lower)
      .replace(RegExp(`^${punct}${small}\\b`, 'ig'), (all, punct, word) => {
        return punct + upper(word);
      })
      .replace(RegExp(`\\b${small}${punct}$`, 'ig'), upper));

    index = split.lastIndex;

    if (m) parts.push(m[0]);
    else break;
  }

  return parts.join('').replace(/ V(s?)\. /ig, ' v$1. ')
    .replace(/(['Õ])S\b/ig, '$1s')
    .replace(/\b(AT&T|Q&A)\b/ig, (all) => {
      return all.toUpperCase();
    });
};

export function getFormulaSearchRegexp(formulaId) {
  return new RegExp(`<formulae(\\s)(id)(\\s?)(=)(\\s?)${formulaId}(\\s?)/>`);
}

export function getFormulaEquation(formulaId, equation) {
  return `<formulae id=${formulaId}> ${equation} </formulae>\n`;
}

export function getContentWithFormulas(content, formulas) {
  if (!formulas) {
    return content;
  }
  let briefContent = content;
  Object.keys(formulas).map((formulaId) => {
    const formulaSearchRegExp = getFormulaSearchRegexp(formulaId);
    const formulaEquation = getFormulaEquation(formulaId, formulas[formulaId].equation);
    briefContent = briefContent.replace(formulaSearchRegExp, formulaEquation);
  });
  return briefContent;
}

export function getDiagramSearchRegexp(diagramId) {
  return new RegExp(`<diagram(\\s)(id)(\\s?)(=)(\\s?)${diagramId}(\\s?)/>`);
}

export function getDiagramEquation(diagramId, image, description) {
  return `<diagram id=${diagramId}> <img src=${image} alt=${image}-Diagram-${diagramId} /> <div class='brief-diagram-description'> ${description} </div> </diagram>\n`;
}

export function getContentWithDiagrams(content, diagrams) {
  if (!diagrams) {
    return content;
  }
  let briefContent = content;
  Object.keys(diagrams).map((diagramId) => {
    const diagramSearchRegExp = getDiagramSearchRegexp(diagramId);
    const diagramEquation = getDiagramEquation(diagramId, diagrams[diagramId].image, diagrams[diagramId].description);
    briefContent = briefContent.replace(diagramSearchRegExp, diagramEquation);
  });
  return briefContent;
}

export function getFormattedBriefContent(content, formulas, diagrams) {
  let briefContent = content;
  briefContent = getContentWithFormulas(briefContent, formulas);
  briefContent = getContentWithDiagrams(briefContent, diagrams);
  return briefContent;
}
