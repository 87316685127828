import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants/briefMe.json';
import {
  DropDown, Text, Loader, BriefMeTabs,
} from '../common';
import {
  getBriefMeDashBoardDetails, getDtpOperatorsProductivityDetails, setBriefsOperatorsProductivityDetails, formatBriefHealth, secondaryCourseFilterData, updateActiveDashboardFilter, secondarySubjectFilterData, MAIN_FILTER_TYPES,
} from '../../actions';
import Layout from '../layout';
import { numberFormatterWithCommas } from '../../helpers/Utils';

class BriefMeDashBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIssue: {},
      selectedSubject: {},
      selectedChapter: {},
      secondaryFiltertype: 'Filter',
      briefsEditorsBoundayValue: { Monthly: [-1, -1, 50, 50, 50, 50, 50], Daily: [-1, -1, 5, 5, 5, 5, 5], Weekly: [-1, -1, 15, 15, 15, 15, 15] },
    };
  }

  componentDidMount() {
    this.props.getBriefMeDashBoardDetails({ id: 1, name: 'overall' }, true);
    this.props.setBriefsOperatorsProductivityDetails({ id: 1, name: 'Daily' });
  }

  renderText(text) {
    return (
      <Text
        fontSize={StyleConstants.textSize.subHeader}
        style={{
           display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
        }}
        text={text}
      />
    )
  }

  renderTable(headings, tableDatas, highlightIndex = 0, boundaryValue = '', blockBoundaryValue, isFeedback = false) {
    return (
      <div>
        <table
          className="table table-striped table-hover fixed_header"
          style={{
            textAlign: 'center', marginBottom: 0, columnSpan: '10px',
          }}
        >
          <thead>
            <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontWeight: '800' }}>
              {headings.map((heading, columnIndex) => {
                return (
                  <th rowSpan="2" style={{ textAlign: columnIndex === 0 ? 'left' : (isFeedback && columnIndex < headings.length - 1 ? 'center' : 'right'), padding: '12px', verticalAlign: 'middle' }}>
                    {' '}
                    {heading.toLocaleUpperCase()}
                    {' '}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tableData, index) => {
              const rowData = Object.keys(tableData);
              return (
                <tr style={{ fontSize: StyleConstants.textSize.subHeader, color: highlightIndex === index ? StyleConstants.textColor.primary : StyleConstants.textColor.secondary, fontWeight: highlightIndex === index ? '800' : '400' }}>
                  {
                    rowData.map((key, columnIndex) => {
                      return (
                        <td style={{ textAlign: columnIndex === 0 ? 'left' : (isFeedback && columnIndex < rowData.length - 1 ? 'center' : 'right'), padding: '12px', color: highlightIndex === index ? StyleConstants.textColor.primary : !blockBoundaryValue ? (boundaryValue[columnIndex] > 0 && tableData[key] < boundaryValue[columnIndex]) ? StyleConstants.color.error : StyleConstants.textColor.secondary : StyleConstants.textColor.secondary }}>{isFeedback ? this.renderText(tableData[key]) : numberFormatterWithCommas(tableData[key])}</td>);
                    })
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderIssueDropdown(dropdownOptions, selectedItem, briefsHealthData, selectedCourseId) {
    return (
      <DropDown
        selectedItem={selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        dropDownContainerStyle={{ marginLeft: 30 }}
        onValueChange={(id, name) => this.setState({ selectedIssue: { id, name } }, () => this.props.secondaryCourseFilterData(briefsHealthData, selectedCourseId ? selectedCourseId - 1 : 0, this.state.selectedIssue.id))}
      />
    );
  }

  renderDropdown(key, width, type, optionsList, selectedItem, isSubjectDropdown = true) {
    const { selectedSubject, selectedChapter } = this.state;

    return (
      <DropDown
        key={key}
        width={width}
        optionType={type}
        selectedItem={isSubjectDropdown ? selectedSubject : selectedChapter}
        defaultText={type}
        onValueChange={(id, name) => this.setState({ [selectedItem]: { id, name } }, () => isSubjectDropdown && this.setState({ selectedChapter: {} }))}
        optionsList={optionsList}
        dropDownContainerStyle={{ marginRight: isSubjectDropdown ? 30 : 0 }}
      />
    )
  }

  renderFeedbackTable(selectedChapter, feedback, overallBriefsFeedback, briefsEditorsBoundayValue, filterDuration) {
    if (Object.keys(feedback).length > 0) {
      if (selectedChapter.id) {
        if (feedback.overall_brief_feedback[selectedChapter.id]?.length > 0) {
          return this.renderTable(feedback.brief_feedback_headings, feedback.overall_brief_feedback[selectedChapter.id], feedback.brief_feedback_headings.length - 1, briefsEditorsBoundayValue[filterDuration], false, true) 
        } else if (feedback.overall_bm_chapter_feedback[selectedChapter.id]?.length > 0) {
          return this.renderTable(feedback.bm_chapter_feedback_headings, feedback.overall_bm_chapter_feedback[selectedChapter.id], feedback.bm_chapter_feedback_headings.length - 1, briefsEditorsBoundayValue[filterDuration], false, true)
        }
        return null;
      }
      if (overallBriefsFeedback.length > 0) {
        return this.renderTable(feedback.brief_feedback_headings, overallBriefsFeedback, feedback.brief_feedback_headings.length - 1, briefsEditorsBoundayValue[filterDuration], false, true) 
      } else if (Object.values(feedback.overall_bm_chapter_feedback).length > 0) {
        return this.renderTable(feedback.bm_chapter_feedback_headings, Object.values(feedback.overall_bm_chapter_feedback), feedback.bm_chapter_feedback_headings.length - 1, briefsEditorsBoundayValue[filterDuration], false, true)
      }
    }
    return null;
  }

  render() {
    const {
      filtersList, appliedFilters, isLoading, formattedBriefsHealthData, briefsOperatorProductivityData, hasCreateSubjectAccess, feedback, subjects, chapters,
    } = this.props;
    const { briefsEditorsBoundayValue, secondaryFiltertype, selectedIssue, selectedSubject, selectedChapter } = this.state;
    const filterDuration = appliedFilters.briefOperator.name;
    const { briefMe: { briefMeHealth, briefsHealthData, courseNameForIssueTypeCheck } } = this.props;
    const showIssueTypeDropdown = filtersList.briefHealthSecondary[appliedFilters.briefHealthSecondary]?.name === courseNameForIssueTypeCheck;
    const issues = fileConstants.briefIssues;
    const issueTypeDropdownOptions = {
      optionsList: issues,
      optionType: 'Issue Type',
      defaultText: 'Issue Type',
      width: 170,
    };
    let overallBriefsFeedback = [];

    if (Object.keys(feedback).length > 0) {
      Object.values(feedback.overall_brief_feedback).forEach((briefFeedback) => { overallBriefsFeedback = overallBriefsFeedback.concat(briefFeedback) })
    }

    return (
      isLoading ? <Loader />
        : (
          <>
            {hasCreateSubjectAccess && <BriefMeTabs selectedType="Dashboard" />}
            <div
              className="Simple-Card-View"
              style={{
                display: 'flex', justifyContent: 'space-between', paddingTop: 10, flexDirection: 'column',
              }}
            >
              <div className="Card-View" style={{ margin: 20, padding: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                  <Text text={`${secondaryFiltertype === 'Filter' ? 'Overall' : filtersList.briefHealthSecondary[appliedFilters.briefHealthSecondary].name} Briefs Health`} color={StyleConstants.color.mediumDark} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.secondaryHeader} />
                  <div style={{ display: 'flex' }}>
                    <DropDown
                      key={`${Object.keys(filtersList.briefHealth).length}-${appliedFilters.briefHealth}`}
                      width={150}
                      optionType="Status"
                      selectedItem={filtersList.briefHealth[appliedFilters.briefHealth]}
                      defaultText="Overall"
                      onValueChange={(id, name) => {
                        this.props.formatBriefHealth(id || MAIN_FILTER_TYPES.OVERALL, briefsHealthData);
                        this.props.updateActiveDashboardFilter({ briefHealth: id ? id - 1 : 0 }).then(() => secondaryFiltertype !== 'Course' && this.setState({ selectedIssue: {}}));
                        this.setState({ secondaryFiltertype: name ? name !== 'Overall' ? name : 'Filter' : 'Filter' });
                      }}
                      optionsList={filtersList.briefHealth}
                      dropDownContainerStyle={{ marginRight: 30 }}
                    />
                    <DropDown
                      key={`${Object.keys(filtersList.briefHealthSecondary).length}-${secondaryFiltertype}`}
                      width={170}
                      optionType="Status"
                      selectedItem={filtersList.briefHealthSecondary[appliedFilters.briefHealthSecondary]}
                      defaultText={`Select ${secondaryFiltertype}`}
                      onValueChange={(id) => {
                        if (secondaryFiltertype === 'Course') {
                          this.props.secondaryCourseFilterData(briefsHealthData, id ? id - 1 : 0, selectedIssue.id);
                        } else if (secondaryFiltertype === 'Subject') {
                          this.props.secondarySubjectFilterData(briefsHealthData, id ? id - 1 : 0);
                        }
                        // this.props.getBriefMeDashBoardDetails({ id: id || '', name: name || '' });
                      }}
                      optionsList={filtersList.briefHealthSecondary}
                    />
                    {showIssueTypeDropdown && this.renderIssueDropdown(issueTypeDropdownOptions, selectedIssue, briefsHealthData, filtersList.briefHealthSecondary[appliedFilters.briefHealthSecondary]?.id)}
                  </div>
                </div>
                {briefMeHealth.headings && this.renderTable(briefMeHealth.headings, formattedBriefsHealthData, formattedBriefsHealthData.length - 1, briefMeHealth.boundaryValues, false)}
              </div>

              <div className="Card-View" style={{ margin: 20, padding: 30 }}>
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: 30,
                }}
                >
                  <Text text="Brief Editors Productivity" color={StyleConstants.color.mediumDark} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.secondaryHeader} />
                  <DropDown
                    key={`${Object.keys(filtersList.briefOperator).length}-${appliedFilters.briefOperator.name}`}
                    width={150}
                    optionType="Duration"
                    defaultText="Weekly"
                    selectedItem={appliedFilters.briefOperator}
                    onValueChange={(id, name) => {
                      this.props.setBriefsOperatorsProductivityDetails({ id: id || 1, name: name || 'Weekly' });
                    }}
                    optionsList={filtersList.briefOperator}
                  />
                </div>
                {Object.keys(briefsOperatorProductivityData).length > 0
              && briefsOperatorProductivityData[filterDuration]
              && this.renderTable(briefsOperatorProductivityData[filterDuration].headings, briefsOperatorProductivityData[filterDuration].datas, briefsOperatorProductivityData.length - 1, briefsEditorsBoundayValue[filterDuration], false)}
              </div>

              <div className="Card-View" style={{ margin: 20, padding: 30, marginBottom: 80 }}>
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: 30,
                }}
                >
                  <Text text="Briefs Feedback" color={StyleConstants.color.mediumDark} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.secondaryHeader} />
                  <div style={{ display: 'flex' }}>
                    {this.renderDropdown(Object.values(subjects).length, 150, "BmSubjects", Object.values(subjects), 'selectedSubject')}
                    {this.renderDropdown(selectedSubject.id, 170, "BmChapters", selectedSubject.id ? Object.values(chapters[selectedSubject.id]) : [], 'selectedChapter', false)}
                  </div>
                </div>
                {this.renderFeedbackTable(selectedChapter, feedback, overallBriefsFeedback, briefsEditorsBoundayValue, filterDuration)}
              </div>
            </div>
          </>
        )
    );
  }
}

BriefMeDashBoard.propTypes = {
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
  filtersList: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getBriefMeDashBoardDetails: PropTypes.func.isRequired,
  formattedBriefsHealthData: PropTypes.object.isRequired,
  briefsOperatorProductivityData: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
};

BriefMeDashBoard.defaultProps = {
};

const mapStateToProps = ({ home, briefMe }) => ({
  briefMe,
  hasCreateSubjectAccess: briefMe.hasCreateSubjectAccess,
  appliedFilters: briefMe.appliedFilters,
  filtersList: briefMe.filtersList,
  isLoading: briefMe.isLoading,
  formattedBriefsHealthData: briefMe.formattedBriefsHealthData,
  briefsOperatorProductivityData: briefMe.briefsOperatorProductivityData,
  feedback: briefMe.feedback,
  subjects: briefMe.subjects,
  chapters: briefMe.chapters,
  courses: home.courses,
  courseSelected: home.courseSelected,
  durationFilters: home.durationFilters,
  briefsEditorsBoundayValue: home.briefsEditorsBoundayValue,
});

export default connect(mapStateToProps, {
  getBriefMeDashBoardDetails, getDtpOperatorsProductivityDetails, setBriefsOperatorsProductivityDetails, formatBriefHealth, secondaryCourseFilterData, updateActiveDashboardFilter, secondarySubjectFilterData,
})(BriefMeDashBoard);
