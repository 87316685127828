import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '../../shared/constants/styleConstants/staticPages/snackBar.css';

class SnackBar extends PureComponent {
  render() {
    const { show, message } = this.props.snackBar;
    return (
      <div className={`snackbar ${show ? 'show' : 'hide'}`} style={{ zIndex: 1001 }}>
        {message}
      </div>
    );
  }
}

const mapStateToProps = ({ snackBar }) => ({
  snackBar,
});

export default connect(mapStateToProps)(SnackBar);
