import React from 'react';
import { ImageUploader, Text } from '.';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

import PreviewImage from '../../shared/images/common/image_preview.svg';

class ImagePreview extends React.Component {
  renderImage(isContentEditable) {
    const {
      header, option, clear, width, imageUrl, style, margin, hideHeader,
    } = this.props;
    return (
      <div style={{ width: '100%', margin }}>
        {!hideHeader && <Text style={{ fontSize: StyleConstants.textSize.subHeader, color: StyleConstants.color.dark }} text={header} />}
        <div style={{
          display: 'flex', justifyContent: 'space-between', margin: 10, alignItems: 'center', marginRight: 0,
        }}
        >
          {imageUrl
                        && (
                        <img
                          ref={option}
                          src={imageUrl}
                          alt=""
                          width={width}
                          style={style}
                        />
                        )}
          {!imageUrl && this.showPreviewImage(header)}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 140,
            pointerEvents: !isContentEditable && 'none',
            opacity: !isContentEditable && 0,
          }}
          >
            <div style={{ marginBottom: 10 }}>
              {this.props.fipper}
            </div>
            <ImageUploader
              header="Upload Image"
              width={140}
              option={option}
              getImage={this.props.getImage}
              clear={clear}
            />
          </div>
        </div>
      </div>
    );
  }

  showPreviewImage(text) {
    const { style } = this.props;
    return (
      <div style={{
        display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', ...style,
      }}
      >
        <img alt="" src={PreviewImage} height="20px" width="20px" />
        <div style={{ marginLeft: 5 }}>{text}</div>
      </div>
    );
  }

  render() {
    const { isContentEditable } = this.props;
    return (
      this.renderImage(isContentEditable)
    );
  }
}

export default ImagePreview;
