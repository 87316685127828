import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stackedit from 'stackedit-js';
import {
  Modal, Text, Loader,
} from '.';
import {
  createFormula, updateFormulaDetails,
  uploadImage, showToast,
  updateFormula, deleteFormula,
} from '../../actions';
import UploadImage from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

const MOBILE_VIEW_WIDTH = 375;
const EDIT_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);
const stackedit = new Stackedit();

class EditFormula extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteFormulaModal: false,
      showUploadImageModal: false,
      imageFile: '',
      isTextCopied: false,
      focused: '',
      isEquationFocused: false,
      isDerivationFocused: false,
      isExplanationFocused: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseUploadImageModal = this.handleCloseUploadImageModal.bind(this);
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderDeleteFormulaModal(showDeleteFormulaModal) {
    return (
      <Modal
        key={showDeleteFormulaModal}
        isModalVisible={showDeleteFormulaModal}
        handleCloseModal={this.handleCloseModal}
        height={130}
        width={600}
      >
        {this.deleteFormula()}
      </Modal>
    );
  }

  renderUploadImageModal(showUploadImageModal) {
    const { briefMe: { imageURL } } = this.props;

    return (
      <Modal
        header="Upload Image"
        key={showUploadImageModal}
        isModalVisible={showUploadImageModal}
        handleCloseModal={this.handleCloseUploadImageModal}
        height={imageURL ? 380 : 330}
        width={imageURL ? 800 : 600}
        maxWidth={imageURL && 800}
      >
        {this.uploadImage()}
      </Modal>
    );
  }

  copyToClipboard(event) {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.setState({ isTextCopied: true });
  }

  uploadImage() {
    const { imageFile, isTextCopied } = this.state;
    const { briefMe: { isUpdating, imageURL } } = this.props;

    if (isUpdating) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    if (imageFile) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {imageFile
              && (
                <div style={{
                  height: 'auto', width: 'fit-content', textAlign: 'center', margin: 'auto',
                }}
                >
                  <img
                    alt={imageFile}
                    src={URL.createObjectURL(imageFile)}
                    style={{
                      height: '150px', width: 'auto', marginTop: 10, borderRadius: 10,
                    }}
                  />
                  <div
                    className="Button Secondary-Button"
                    role="presentation"
                    style={{ margin: '20px 80px', width: 100 }}
                    onClick={() => this.setState({ imageFile: '', isTextCopied: false }, () => this.props.updateFormulaDetails({ imageURL: '' }))}
                  >
                    Clear
                  </div>
                </div>
              )}
          </div>
          {!imageURL && <div role="presentation" className="Button Primary-Button" onClick={() => this.props.uploadImage(this.state.imageFile)}>Upload</div>}
          {imageURL && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.renderTitle('Image URL:')}
                {document.queryCommandSupported('copy') && <div role="presentation" className={`Button ${isTextCopied ? 'Secondary-Button' : 'Primary-Button'}`} style={{ width: 100 }} onClick={isTextCopied ? () => {} : (event) => this.copyToClipboard(event)}>{`${isTextCopied ? 'Copied' : 'Copy'}`}</div>}
              </div>
              <textarea
                readOnly
                ref={(textarea) => this.textArea = textarea}
                value={imageURL}
                style={{
                  marginTop: 10, overflow: 'scroll', maxHeight: 45, maxWidth: 740,
                }}
              />
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div
          role="presentation"
          style={{
            display: 'flex', flexDirection: 'column', borderRadius: 5, height: 220, border: `1px solid ${StyleConstants.color.mediumLight}`, backgroundColor: '#FFF', cursor: 'pointer',
          }}
          onClick={() => document.getElementById('image-input').click()}
        >
          <input
            style={{
              display: 'none',
            }}
            id="image-input"
            type="file"
            accept="image/*"
            onChange={(event) => this.setState({ imageFile: event.target.files[0] })}
          />
          <img src={UploadImage} alt="upload_image" style={{ marginTop: 80 }} />
          <Text style={{ color: StyleConstants.color.mediumLight, marginTop: 10, textAlign: 'center' }} text="Drag or browse the Diagram file to upload" />
        </div>
        <div style={{ display: 'flex' }}>
          <img src={info} alt="info" />
          <Text style={{ color: StyleConstants.color.mediumLight, padding: 8 }} text="Upload only .Jpg or .Png files" />
        </div>
      </>
    );
  }

  editContent(content) {
    const {
      isExplanationFocused, isDerivationFocused,
    } = this.state;
    let textContent = '';

    stackedit.openFile({
      content: {
        text: content,
      },
    });

    stackedit.on('fileChange', (file) => {
      textContent = file.content.text;
    });
    if (isDerivationFocused) {
      stackedit.on('close', () => {
        this.props.updateFormulaDetails({ derivation: textContent, isChanged: true });
      });
    } else if (isExplanationFocused) {
      stackedit.on('close', () => {
        this.props.updateFormulaDetails({ explanation: textContent, isChanged: true });
      });
    } else {
      stackedit.on('close', () => {
        this.props.updateFormulaDetails({ equation: textContent, isChanged: true });
      });
    }
  }

  onSaveButtonClick() {
    const {
      formula: {
        isFormulaSelected, equation, name, bmSubjectId, derivation, explanation,
      }, isChemicalEquation,
    } = this.props;

    if (isFormulaSelected) {
      this.props.updateFormula({
        name, equation, bmSubjectId, derivation, explanation, isChemicalEquation,
      });
    } else {
      this.setState({ displayEditView: false }, () => {
        this.props.createFormula({
          name, equation, bmSubjectId, derivation, explanation, isChemicalEquation,
        });
      });
    }
  }

  deleteFormula() {
    const {
      formula: {
        name,
      },
    } = this.props;
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text
          fontSize={StyleConstants.textSize.subHeader}
          color={StyleConstants.color.dark}
          style={{
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 550,
          }}
          text={`Are you sure you want to delete the formula ${name}?`}
        />
        <div className="modalButtonView">
          <div role="presentation" className="Button Secondary-Button" style={{ width, marginRight: 5 }} onClick={this.handleCloseModal}> Cancel </div>
          <div role="presentation" className="Button Primary-Button" style={{ width }} onClick={() => this.setState({ showDeleteFormulaModal: false }, () => this.props.deleteFormula())}> Delete </div>
        </div>
      </div>
    );
  }

  handleCloseUploadImageModal() {
    this.setState({ showUploadImageModal: false, isTextCopied: false });
  }

  handleCloseModal() {
    this.setState({ showDeleteFormulaModal: false });
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} />;
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') {
      this.props.updateFormulaDetails({ displayEditView: false });
    }
  }

  editFormula() {
    const {
      briefMe: {
        isAdmin,
        isUpdating,
      }, isFormulaSearch, isChemicalEquation,
    } = this.props;

    const {
      formula: {
        selectedIndex,
        isFormulaSelected,
        name,
        equation,
        isChanged,
        derivation,
        explanation,
        hasModifyBriefAccess,
      },
    } = this.props;
    const {
      isExplanationFocused, isDerivationFocused,
    } = this.state;
    const enableSaveButton = name && equation && isChanged;
    const width = EDIT_VIEW_WIDTH;
    const buttonWidth = 100;
    const inputStyle = { marginBottom: 20, borderRadius: 0, backgroundColor: '#FFF' };
    const focusedContent = isExplanationFocused ? explanation : (isDerivationFocused ? derivation : equation);
    return (
      <div
        role="presentation"
        className="Card-View"
        style={{
          width, position: 'fixed', top: 0, bottom: 0, right: 0,
        }}
        onKeyUp={(event) => this.handleKeyUp(event)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          {!isFormulaSearch && this.renderText(isFormulaSelected ? `Position: ${selectedIndex + 1}` : 'New item')}
          <div
            role="presentation"
            style={{
              height: 20, width: 20, border: '1px solid #000', borderRadius: 50, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            onClick={() => this.props.updateFormulaDetails({ displayEditView: false })}
          >
            <img src="/images/common/x-mark.svg" alt="close" style={{ height: 10 }} />
          </div>
        </div>
        <div> Name</div>

        <input style={inputStyle} type="text" value={name} placeholder="Name" onChange={(event) => this.props.updateFormulaDetails({ name: event.target.value, isChanged: true })} autoFocus />
        <div> Equation</div>
        <textarea
          style={{
            marginBottom: 10, height: 200, borderRadius: 0, maxWidth: width - 40,
          }}
          value={equation}
          placeholder="Equation"
          onChange={(event) => this.props.updateFormulaDetails({ equation: event.target.value, isChanged: true })}
          onFocus={() => { this.setState({ isEquationFocused: true, isDerivationFocused: false, isExplanationFocused: false }); }}
        />
        <div> Explanation</div>
        <textarea
          style={{
            marginBottom: 10, height: isChemicalEquation ? 250 : 150, borderRadius: 0, maxWidth: width - 40,
          }}
          value={explanation}
          placeholder={`Explanation ${isChemicalEquation ? '(Balancing the Equation)' : '(symbols and variables)'}`}
          onChange={(event) => this.props.updateFormulaDetails({ explanation: event.target.value, isChanged: true })}
          onFocus={() => { this.setState({ isEquationFocused: false, isDerivationFocused: false, isExplanationFocused: true }); }}
        />
        {!isChemicalEquation && (
        <>
          <div> Derivation</div>
          <textarea
            style={{
              marginBottom: 10, height: 150, borderRadius: 0, maxWidth: width - 40,
            }}
            value={derivation}
            placeholder="Derivation"
            onChange={(event) => this.props.updateFormulaDetails({ derivation: event.target.value, isChanged: true })}
            onFocus={() => { this.setState({ isEquationFocused: false, isDerivationFocused: true, isExplanationFocused: false }); }}
          />
        </>
        )}
        <div style={{
          display: 'flex', justifyContent: 'flex-end', color: StyleConstants.color.dark, marginBottom: 10,
        }}
        >
          {`${focusedContent.length} chars | ${(focusedContent.length > 0 ? focusedContent.trim().split(/\s+/).length : 0)} words`}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.setState({ showUploadImageModal: true })}>
            Add Image
          </div>
          <div role="presentation" className="Button Secondary-Button" style={{ width: buttonWidth, border: `1px solid ${StyleConstants.color.primary}` }} onClick={() => this.editContent(focusedContent)}>
            Markdown
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          {isFormulaSelected && (
          <div style={{ display: 'flex' }}>
            {isAdmin && (
              <div role="presentation" className="Button Error-Button" style={{ width: buttonWidth }} onClick={() => (isFormulaSelected && this.setState({ showDeleteFormulaModal: true }))}>
                Delete
              </div>
            )}
          </div>
          )}
          {isUpdating && this.showLoader({ marginRight: isFormulaSelected && 40, marginLeft: !isFormulaSelected && 40 })}
          {hasModifyBriefAccess && !isUpdating && (
            <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth, opacity: enableSaveButton ? 1 : 0.5, cursor: enableSaveButton ? 'pointer' : 'not-allowed' }} onClick={() => (enableSaveButton && this.onSaveButtonClick())}>
              {isFormulaSelected ? 'Save' : 'Create'}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      formula: {
        isLoading, isFormulaSelected, selectedIndex, displayEditView,
      },
    } = this.props;
    const {
      showDeleteFormulaModal, showUploadImageModal,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <>
        {displayEditView && (selectedIndex !== null) && this.editFormula()}
        {showUploadImageModal && this.renderUploadImageModal(showUploadImageModal)}
        {isFormulaSelected && this.renderDeleteFormulaModal(showDeleteFormulaModal)}
      </>
    );
  }
}

EditFormula.propTypes = {
  formula: PropTypes.object.isRequired,
  briefMe: PropTypes.object.isRequired,
  updateFormula: PropTypes.func.isRequired,
  updateFormulaDetails: PropTypes.func.isRequired,
  deleteFormula: PropTypes.func.isRequired,
  isFormulaSearch: PropTypes.bool,
  isChemicalEquation: PropTypes.bool,
};

EditFormula.defaultProps = {
  isFormulaSearch: false,
  isChemicalEquation: false,
};

const mapStateToProps = ({ briefMe, formula }) => ({
  briefMe,
  formula,
});

export default connect(mapStateToProps, {
  createFormula,
  updateFormula,
  uploadImage,
  showToast,
  updateFormulaDetails,
  deleteFormula,
})(EditFormula);
