import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobileDevice } from '../../helpers/Utils';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import { logout } from '../../actions/index';
import closeButton from '../../shared/images/common/close.svg';

class Header extends PureComponent {
  render() {
    if (this.props.showBackButton) {
      return (
        <div className="App-header">
          <div
            onClick={() => this.props.updatePageState()}
            style={{
              display: 'flex', padding: 10, cursor: 'pointer', color: StyleConstants.color.white, fontSize: StyleConstants.textSize.tertiaryHeader, alignItems: 'center',
            }}
          >
            {' '}
            <img alt="Close Button" src={closeButton} width={12} height={12} style={{ marginRight: 10 }} />
            {' '}
            Back
          </div>
        </div>
      );
    }
    const { text } = this.props;
    const headerText = text || (isMobileDevice() ? 'IB' : 'IDEABOARD');
    return (
      <div className="App-header" style={{ width: '100%' }}>
        <div style={{ fontSize: StyleConstants.textSize.secondaryHeader, fontWeight: StyleConstants.textWeight.bold, color: StyleConstants.color.selected }}>
          {' '}
          {headerText}
          {' '}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  updatePageState: PropTypes.func,
  showBackButton: PropTypes.bool,
};

export default connect(null, { logout })(Header);
