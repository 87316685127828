import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getQuestionsForEdit, updateInstituteQuestion,
  getInstituteSubjects, updateComprehensionQuestion, updateQuestionReducer,
  showToast,
} from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { TEXT_CHANGES_TYPE, REPORT_ISSUE_TOAST_MESSAGES } from '../shared/constants/fieldTypes';

import {
  Loader, DropDown, ImagePreview,
  PreviewHeader, Text, EditDisplay,
  PreviewDisplay, Modal,
} from './common';
import fileConstants from '../shared/constants/fileConstants';
import Layout from './layout';
import { isValidNumber } from '../helpers/Utils';
import PreviewFooter from './common/previewFooter';

const SELECT_SUBJECT = 'Select Subjects';
const SELECT_QUESTION_TYPE = 'Select Question Type';
const REPORTED_OPTION_TYPES = ['Text', 'Image'];
const IMAGE = 'Image';
const QUESTION_TYPES = {
  FOUR_OPTIONS: 0, NUMERICAL: 1, MULTIPLE_OPTIONS: 2, COMPREHENSION: 3,
};
const SELECT_ID = 'Select Comprehension Ids';
const dropDownPadding = '20px 0';

class NewQuestion extends React.Component {
  constructor(props) {
    super(props);
    const { newQuestion } = this.props;
    this.mathjaxDiv = null;
    this.state = {
      questionId: newQuestion ? 0 : props.match.params.id,
      selectedSubjectItem: {},
      selectedQuestionTypeItem: { id: QUESTION_TYPES.FOUR_OPTIONS, name: 'FOUR_OPTIONS' },
      textChanges: {},
      numericalChanges: {},
      images: {},
      imagesPresent: {},
      corrections: {},
      imageFiles: [],
      showUpdateModal: false,
      numericalCorrections: {},
      selectedComprehension: {},
      active: TEXT_CHANGES_TYPE.NO_ISSUE,
      divHeight: {},
      isNewImage: {},
      showReportErrorModal: false,
      showLoader: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.scrollheight = this.scrollheight.bind(this);
    this.editDisplay = this.editDisplay.bind(this);
    this.previewDisplay = this.previewDisplay.bind(this);
    this.selectedOption = this.selectedOption.bind(this);
    this.getImage = this.getImage.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderOptionData = this.renderOptionData.bind(this);
    this.renderTextOption = this.renderTextOption.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.renderImages = this.renderImages.bind(this);
    this.renderQuestionView = this.renderQuestionView.bind(this);
    this.renderComprehension = this.renderComprehension.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.newQuestion = this.newQuestion.bind(this);
    this.checkForOptions = this.checkForOptions.bind(this);
    this.newQuestion = this.newQuestion.bind(this);
    this.checkForQuestionData = this.checkForQuestionData.bind(this);
    this.checkForImages = this.checkForImages.bind(this);
    this.setData = this.setData.bind(this);
    this.getQuestionData = this.getQuestionData.bind(this);
    this.checkForQuestionTypeData = this.checkForQuestionTypeData.bind(this);
  }

  componentDidMount() {
    const { questionId } = this.state;
    const { newQuestion } = this.props;
    if (newQuestion) {
      this.props.getInstituteSubjects({ subjects: true, question_types: true, comprehension_ids: true }).then(() => {
        this.setData();
      });
    } else {
      this.props.getQuestionsForEdit(questionId).then(() => {
        this.setData();
      });
    }
  }

  setData() {
    this.getQuestionData();
    this.setState({ active: TEXT_CHANGES_TYPE.NO_ISSUE });
  }

  scrollheight(id, selected = false) {
    const { divHeight } = this.state;
    divHeight[id] = this.refs[`input_${id}`] ? this.refs[`input_${id}`].scrollHeight : 60;
    divHeight[id] = divHeight[id] > 60 ? divHeight[id] : 60;
    divHeight[id] += selected ? 15 : 0;
    this.setState({ divHeight });
  }

  getQuestionData() {
    const { questions, newQuestion, subjects } = this.props;
    const { selectedQuestionTypeItem } = this.state;
    const comprehensionId = questions.comprehension_question_id;
    const textChanges = {
      question_text: questions.question_text,
      option_1: questions.option_1,
      option_2: questions.option_2,
      option_3: questions.option_3,
      option_4: questions.option_4,
      solution_text: questions.solution_text,
      answers: [...questions.answers || []],
    };
    const selectedSubjectItem = Object.values(subjects).filter((obj) => obj.id === questions.subject_id)[0];
    const questionType = questions.question_type || selectedQuestionTypeItem;
    const numericalChanges = {
      rich_response: questions.rich_response,
      notes: questions.notes,
      comprehension_question_id: comprehensionId,
    };
    const images = {
      image_option_1: questions.image_option_1,
      image_option_2: questions.image_option_2,
      image_option_3: questions.image_option_3,
      image_option_4: questions.image_option_4,
      supporting_picture: questions.supporting_picture,
      solution_supporting_picture: questions.solution_supporting_picture,
    };
    const selectedComprehension = comprehensionId ? { id: comprehensionId, name: `Comprehension Id - ${comprehensionId}` } : {};
    const imagesPresent = {
      1: newQuestion ? false : !!questions.image_option_1,
      2: newQuestion ? false : !!questions.image_option_2,
      3: newQuestion ? false : !!questions.image_option_3,
      4: newQuestion ? false : !!questions.image_option_3,
    };
    this.setState({
      textChanges,
      images,
      imagesPresent,
      numericalChanges,
      showLoader: false,
      selectedQuestionTypeItem: questionType,
      selectedSubjectItem: selectedSubjectItem || [],
      selectedComprehension,
    });
  }

  getImage(image, option) {
    const { isNewImage, imagesPresent } = this.state;
    const { questions } = this.props;
    if (!image) {
      this.handleChange(option, questions[option], TEXT_CHANGES_TYPE.NO_ISSUE, IMAGE.toLowerCase());
      isNewImage[option] = false;
    } else {
      this.handleChange(option, image, TEXT_CHANGES_TYPE.IMAGE, IMAGE.toLowerCase());
      isNewImage[option] = true;
    }
    this.setState({ isNewImage, imagesPresent });
  }

  editDisplay(key, type, value, data, placeholder = '') {
    const { divHeight } = this.state;
    return (
      <EditDisplay
        key={key}
        type={type}
        value={value}
        data={data}
        placeholder={placeholder}
        divHeight={divHeight}
        handleChange={this.handleChange}
        scrollheight={this.scrollheight}
      />
    );
  }

  previewDisplay(key, field, data, optionNumber = 0) {
    const { textChanges, divHeight } = this.state;
    const checked = textChanges.answers.indexOf(key) !== -1;
    const isOption = isValidNumber(key);
    const content = optionNumber ? `${optionNumber}) ${data[field] || ''}` : data[field];
    return (
      <div
        ref={`input_${field}`}
        style={{
          marginBottom: isOption ? 10 : 20,
          fontSize: StyleConstants.textSize.text,
          color: (checked || !isOption) ? StyleConstants.color.dark : StyleConstants.color.mediumDark,
          fontWeight: checked ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold,
          border: checked ? StyleConstants.border.success : StyleConstants.color.white,
          backgroundColor: checked ? '#F7FFF5' : StyleConstants.color.white,
          height: divHeight[field],
          width: '95%',
          borderRadius: 8,
          boxShadow: '0px 3px 6px 0px rgba(52,59,64,0.08)',
          justifyContent: 'center',
          whiteSpace: 'pre-wrap',
          maxHeight: 'fit-content',
          overflowX: 'scroll',
          resize: 'none',
          padding: 15,
        }}
      >
        <PreviewDisplay
          content={content}
          scrollheight={this.scrollheight}
        />
      </div>
    );
  }

  handleChange(type, targetValue, value, id, isNumerical = false) {
    const {
      textChanges, images, numericalChanges,
    } = this.state;
    if (isNumerical) {
      numericalChanges[type] = targetValue;
    } else if (id !== IMAGE.toLowerCase()) {
      textChanges[type] = targetValue;
    } else {
      images[type] = targetValue;
    }
    this.setState({
      active: value,
      textChanges,
      images,
      numericalChanges,
    });
  }

  selectedOption(number) {
    const { textChanges, questionId, selectedQuestionTypeItem } = this.state;
    const isMultiOption = selectedQuestionTypeItem.id === QUESTION_TYPES.MULTIPLE_OPTIONS;
    const { questions } = this.props;
    let active = TEXT_CHANGES_TYPE.ANSWER;
    const answerPosition = textChanges.answers.indexOf(number);
    if (answerPosition === -1) {
      if (isMultiOption) {
        textChanges.answers.push(number);
      } else {
        textChanges.answers = [number];
      }
    } else {
      textChanges.answers.splice(answerPosition, 1);
    }
    if (textChanges.answers.length === 0 && questionId !== 0) {
      textChanges.answers = questions.answers;
      active = TEXT_CHANGES_TYPE.NO_ISSUE;
    }
    textChanges.answers = Array.from(new Set(textChanges.answers));
    this.setState({ textChanges, active });
  }

  checkForOptions() {
    const {
      imagesPresent, images, textChanges, isNewImage,
    } = this.state;
    const { questions } = this.props;
    Object.keys(imagesPresent).map((type) => {
      let optionText = textChanges[`option_${type}`];
      let imageOptionText = images[`image_option_${type}`];
      if (imagesPresent[type] && (images[`image_option_${type}`]) !== questions[`image_option_${type}`]) {
        optionText = `image_${type} : ${`image_option_${type}`}.${images[`image_option_${type}`].type && images[`image_option_${type}`].type.split('/')[1]}`;
      } else if (!imagesPresent[type] && (textChanges[`option_${type}`]) !== questions[`option_${type}`] && textChanges[`option_${type}`] && images[`image_option_${type}`]) {
        imageOptionText = '';
        isNewImage[type] = false;
      } else if (!optionText) {
        this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.OPTION_SELECTED);
        return false;
      }
      textChanges[`option_${type}`] = optionText;
      images[`image_option_${type}`] = imageOptionText;
      this.setState({ textChanges, images, isNewImage });
    });
    return true;
  }

  checkForQuestionData() {
    const { textChanges, corrections } = this.state;
    const { questions } = this.props;
    if (textChanges.answers.length === 0) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.ANSWERS);
      return false;
    }
    if (!textChanges.question_text) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.QUESTION_TEXT);
      return false;
    }
    let optionArray = [];
    for (let index = 0; index <= 4; index++) {
      const optionData = textChanges[`option_${index}`];
      optionData && optionArray.push(optionData);
    }
    optionArray = Array.from(new Set(optionArray));
    if (optionArray.length !== 4) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.OPTION_FILLED);
      return false;
    }
    Object.keys(textChanges).map((type) => {
      const questionData = questions[type] && questions[type].toString().trim();
      const textChangeData = textChanges[type] && textChanges[type].toString().trim();
      if (questionData !== textChangeData) {
        corrections[type] = textChanges[type];
      }
      return corrections;
    });
    this.setState({ corrections });
    return true;
  }

  checkForImages() {
    const { images, corrections, imageFiles } = this.state;
    const { questions } = this.props;
    Object.keys(images).map((type) => {
      const isRemoveImageOption = (type !== 'supporting_picture' && type !== 'solution_supporting_picture') && ((images[type]) !== questions[type]) && !images[type];
      if (isRemoveImageOption) {
        corrections[type] = '';
      }
      if (images[type] && (images[type]) !== questions[type]) {
        imageFiles.push(new File([images[type]], `${type}.${images[type].type && images[type].type.split('/')[1]}`,
          { type: images[type].type }));
      }
      return imageFiles;
    });
    this.setState({ corrections, imageFiles });
    return true;
  }

  clearOtherTypeData() {
    const { numericalChanges } = this.state;
    let numericalCorrections = {};
    numericalCorrections = Object.keys(numericalChanges).map((type) => {
      numericalCorrections[type] = '';
      return numericalCorrections;
    });
    this.setState({ numericalCorrections });
  }

  checkForQuestionTypeData() {
    const {
      selectedQuestionTypeItem, numericalCorrections,
      numericalChanges, selectedComprehension, textChanges,
    } = this.state;
    const { questions } = this.props;
    const isOtherQuestionType = selectedQuestionTypeItem.id !== QUESTION_TYPES.FOUR_OPTIONS;
    if (isOtherQuestionType) {
      if (selectedQuestionTypeItem.id === QUESTION_TYPES.NUMERICAL) {
        const isNumber = isValidNumber(numericalChanges.rich_response);
        if (!isNumber) {
          this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.RICH_RESPONSE_VALID);
          return false;
        }
        Object.keys(numericalChanges).map((type) => {
          if (numericalChanges[type] && numericalChanges[type] !== questions[type]) {
            numericalCorrections[type] = numericalChanges[type];
          }
        });
        numericalCorrections.question_type = selectedQuestionTypeItem.id;
      } else if (selectedQuestionTypeItem.id === QUESTION_TYPES.MULTIPLE_OPTIONS) {
        if (textChanges.answers.length <= 1) {
          this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.MULTIPLE_CHOICE);
          return false;
        }
        this.clearOtherTypeData();
      } else if (selectedQuestionTypeItem.id === QUESTION_TYPES.COMPREHENSION) {
        if (selectedComprehension.id && selectedComprehension.id !== questions.comprehension_question_id) {
          this.clearOtherTypeData();
          numericalCorrections.comprehension_question_id = selectedComprehension.id;
          numericalCorrections.question_type = selectedQuestionTypeItem.id;
        } else if (!selectedComprehension.id) {
          this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.COMPREHENSION_PRESENT);
          return false;
        }
      }
    }
    numericalCorrections.question_type = selectedQuestionTypeItem.id;
    this.setState({ numericalCorrections });
    return true;
  }

  newQuestion() {
    const {
      active, selectedSubjectItem, numericalCorrections,
      corrections, imageFiles, questionId, selectedQuestionTypeItem,
    } = this.state;
    const { newQuestion } = this.props;
    const subjectId = parseInt(selectedSubjectItem.id);
    if (active === TEXT_CHANGES_TYPE.NO_ISSUE) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_SELECTED);
      return;
    }
    if (active && active === TEXT_CHANGES_TYPE.UPDATED) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATE_COMPLETED);
      return;
    }
    if (!selectedQuestionTypeItem.id && selectedQuestionTypeItem.id !== 0) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.QUESTION_TYPE);
      return;
    }
    if (!subjectId) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.SELECT_SUBJECT);
      return;
    }
    let isDataPresent = true;
    isDataPresent = this.checkForOptions();
    isDataPresent = isDataPresent && this.checkForQuestionData();
    isDataPresent = isDataPresent && this.checkForImages();
    isDataPresent = isDataPresent && this.checkForQuestionTypeData();
    if (isDataPresent && (Object.keys(corrections).length !== 0 || imageFiles.length !== 0 || Object.keys(numericalCorrections).length !== 0)) {
      if (newQuestion) {
        corrections.subject_id = subjectId;
      }
      this.props.updateInstituteQuestion(
        JSON.stringify(corrections), imageFiles || null, JSON.stringify(numericalCorrections), questionId,
      ).then((data) => {
        if (data.success && !newQuestion) {
          this.setState({ showUpdateModal: true });
        }
      });
      this.setState({ active: TEXT_CHANGES_TYPE.UPDATED });
    } else if (isDataPresent) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_SELECTED);
    }
    this.setState({ numericalCorrections: {}, corrections: {}, imageFiles: [] });
  }

  changeImageOption(optionNumber) {
    const { imagesPresent } = this.state;
    imagesPresent[optionNumber] = !imagesPresent[optionNumber];
    this.setState({ imagesPresent });
  }

  renderSupportingPicture(isContentEditable) {
    const { active, images } = this.state;
    const { questions } = this.props;
    const supportingPicture = images.supporting_picture === questions.supporting_picture ? questions.supporting_picture : URL.createObjectURL(images.supporting_picture);
    return (
      <ImagePreview
        header="Supporting Picture"
        option="supporting_picture"
        margin="25px 0px"
        imageUrl={supportingPicture}
        width={questions.image_widths && questions.image_widths.supporting_picture_width}
        isContentEditable={isContentEditable}
        style={{ maxWidth: 270, maxHeight: 160 }}
        getImage={this.getImage}
        clear={active === TEXT_CHANGES_TYPE.NO_ISSUE}
      />
    );
  }

  renderCheckBox(optionNumber) {
    const { textChanges } = this.state;
    const checked = textChanges.answers.indexOf(optionNumber) !== -1;
    return (
      <div style={{
        marginRight: 20,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: checked ? `${StyleConstants.color.primary}` : '#FFFFFF',
        height: '19px',
        width: '19px',
        borderRadius: '4px',
        border: !checked ? '1px solid #CBCBCB' : '1px solid #3994AE',
      }}
      >
        <input
          type="checkbox"
          style={{
            position: 'absolute', height: 20, width: 20, opacity: 0, zIndex: 1, cursor: 'pointer',
          }}
          onClick={() => this.selectedOption(optionNumber)}
        />
        <p style={{ color: StyleConstants.color.white, marginTop: 'auto' }}>&#x2713;</p>
      </div>
    );
  }

  renderTextOption(isContentEditable, optionNumber) {
    const { imagesPresent, textChanges } = this.state;
    const isImage = imagesPresent[optionNumber];
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {isContentEditable
          ? this.editDisplay(optionNumber, `option_${optionNumber}`, optionNumber, textChanges)
          : this.previewDisplay(optionNumber, `option_${optionNumber}`, textChanges, optionNumber)}
        <div style={{ marginLeft: 20 }}>
          {isContentEditable && this.renderTypeFlipper(isImage, optionNumber)}
        </div>
      </div>
    );
  }

  renderOptionData(isContentEditable, optionNumber, index) {
    const { imagesPresent } = this.state;
    const isImage = imagesPresent[optionNumber];
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
        {isContentEditable && this.renderCheckBox(optionNumber)}
        <div style={{ width: '100%' }}>
          {isImage
            ? this.renderImages(isContentEditable, optionNumber)
            : this.renderTextOption(isContentEditable, optionNumber)}
        </div>
      </div>
    );
  }

  renderOption(isContentEditable) {
    const optionsArray = [1, 2, 3, 4];
    return (
      <div style={{ padding: '20px 0px' }}>
        {this.renderQuestionHeader('Options')}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {optionsArray.map((optionNumber, index) => {
            return this.renderOptionData(isContentEditable, optionNumber, index);
          })}
        </div>
      </div>
    );
  }

  renderTypeFlipper(isImage, optionNumber) {
    return (
      <div style={{ display: 'flex', width: 140 }}>
        {REPORTED_OPTION_TYPES.map((type, index) => {
          const isTypeImage = type === IMAGE;
          const className = isImage ? isTypeImage ? 'Button Primary-Button' : 'Button Secondary-Button' : isTypeImage ? 'Button Secondary-Button' : 'Button Primary-Button';
          return (
            <div
              style={{ width: '50%', borderRadius: 0, borderLeft: (index !== 0) && 0 }}
              className={className}
              key={`flipper_${optionNumber}_${index}`}
              role="presentation"
              onClick={() => this.changeImageOption(optionNumber)}
            >
              {type}
            </div>
          );
        })}
      </div>
    );
  }

  renderImages(isContentEditable, optionNumber) {
    const {
      isNewImage, active, images, imagesPresent, textChanges,
    } = this.state;
    const imageUrl = isNewImage[`image_option_${optionNumber}`] && images[`image_option_${optionNumber}`] ? URL.createObjectURL(images[`image_option_${optionNumber}`]) : images[`image_option_${optionNumber}`];
    const isImage = imagesPresent[optionNumber];
    const checked = textChanges.answers.indexOf(optionNumber) !== -1;
    const Checkedstyle = {
      border: !isContentEditable && checked && StyleConstants.border.success, padding: !isContentEditable && checked ? '10px' : '', backgroundColor: !isContentEditable && checked ? '#F7FFF5' : '', maxWidth: 270, maxHeight: 160,
    };
    const { questions } = this.props;
    return (
      <div style={{
        display: 'flex', justifyContent: isContentEditable && 'space-between', margin: '10px 0px', alignItems: 'center',
      }}
      >
        <ImagePreview
          header={`Option ${optionNumber}`}
          option={`image_option_${optionNumber}`}
          imageUrl={imageUrl}
          hideHeader
          margin="10px 0px"
          width={questions.image_widths && questions.image_widths[`image_option_width_${optionNumber}`]}
          isContentEditable={isContentEditable}
          style={checked ? Checkedstyle : { maxWidth: 270, maxHeight: 160 }}
          checked={checked}
          fipper={this.renderTypeFlipper(isImage, optionNumber)}
          getImage={this.getImage}
          clear={active === TEXT_CHANGES_TYPE.NO_ISSUE}
        />
      </div>
    );
  }

  renderSolutionSupprotingPicture(isContentEditable) {
    const { images, active } = this.state;
    const { questions } = this.props;
    const imageUrl = images.solution_supporting_picture === questions.solution_supporting_picture ? questions.solution_supporting_picture : URL.createObjectURL(images.solution_supporting_picture);
    return (
      <ImagePreview
        header="Solution Supporting Picture"
        option="solution_supporting_picture"
        margin="25px 0px"
        imageUrl={imageUrl}
        width={questions.image_widths && questions.image_widths.supporting_picture_width}
        isContentEditable={isContentEditable}
        style={{ maxWidth: 270, maxHeight: 160 }}
        getImage={this.getImage}
        clear={active === TEXT_CHANGES_TYPE.NO_ISSUE}
      />
    );
  }

  renderSelectedText(isSelected, text) {
    const selectedStyle = {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      color: StyleConstants.color.dark,
      fontSize: StyleConstants.textSize.subHeader,
      height: StyleConstants.fieldHeight.default,
      padding: '0px 30px',
      width: 'fit-content',
      minWidth: 200,
    };
    const successText = isSelected ? 'success' : 'error';
    const border = StyleConstants.border[successText];
    const background = StyleConstants.backgroundColor[successText];
    return (
      <Text style={{ ...selectedStyle, border, background }} text={text} />
    );
  }

  changeQuestionType(id, name) {
    const { textChanges, selectedQuestionTypeItem } = this.state;
    if (selectedQuestionTypeItem.id === QUESTION_TYPES.MULTIPLE_OPTIONS || id === QUESTION_TYPES.MULTIPLE_OPTIONS) {
      textChanges.answers = [];
    }
    return (
      this.setState({ selectedQuestionTypeItem: { id, name }, textChanges, active: TEXT_CHANGES_TYPE.QUESTION_TYPE })
    );
  }

  renderOtherTypeChanges(isContentEditable, type, value, placeholder = '') {
    const { numericalChanges } = this.state;
    if (isContentEditable) {
      return (
        this.editDisplay(type, type, value, numericalChanges, placeholder)
      );
    }
    return this.previewDisplay(type, type, numericalChanges);
  }

  renderOtherTypeData(isContentEditable) {
    return (
      <>
        {this.renderQuestionHeader('Numerical Answer')}
        {this.renderOtherTypeChanges(isContentEditable, 'rich_response', TEXT_CHANGES_TYPE.RICH_RESPONSE)}
        {this.renderQuestionHeader('Notes')}
        {this.renderOtherTypeChanges(isContentEditable, 'notes', TEXT_CHANGES_TYPE.NOTES, 'Eg: Answer should be in meter')}
      </>
    );
  }

  renderHeader(isContentEditable) {
    const { questionId } = this.state;
    const { newQuestion } = this.props;
    return (
      <PreviewHeader isContentEditable={isContentEditable} questionId={questionId} newQuestion={newQuestion} />
    );
  }

  renderDropDown(selectedItem, filterItems, defaultText, optionType, onChange, notEditable = false) {
    const length = 250;
    return (
      <DropDown
        key={`defaultText_${filterItems.length}`}
        optionType={optionType}
        selectedItem={selectedItem}
        onValueChange={onChange}
        notEditable={notEditable}
        optionsList={filterItems}
        defaultText={defaultText}
        width={length}
        height={40}
      />
    );
  }

  renderQuestionType(isContentEditable) {
    const { selectedQuestionTypeItem } = this.state;
    if (isContentEditable) {
      const { questionTypes } = this.props;
      const filterItems = [];
      Object.keys(questionTypes).map((type, index) => {
        filterItems[index] = { id: questionTypes[type], name: type };
      });
      return (
        <div style={{ padding: dropDownPadding }}>
          {this.renderDropDown(selectedQuestionTypeItem, filterItems, SELECT_QUESTION_TYPE, 'Question Types', (id, name) => this.changeQuestionType(id, name))}
        </div>
      );
    }
    return (
      <div style={{ padding: dropDownPadding }}>
        {this.renderSelectedText(selectedQuestionTypeItem.name, `Question Type - ${selectedQuestionTypeItem.name ? selectedQuestionTypeItem.name : '  -'}`)}
      </div>
    );
  }

  renderSubject(isContentEditable) {
    const { selectedSubjectItem } = this.state;
    if (isContentEditable) {
      const { subjects, newQuestion } = this.props;
      const filterItems = [];
      for (let i = 0; i < Object.keys(subjects).length; i += 1) {
        filterItems[i] = subjects[i];
      }
      return (
        <div style={{ padding: dropDownPadding }} onClick={newQuestion ? '' : () => this.props.showToast('Subject Cannot be Edited')}>
          {this.renderDropDown(selectedSubjectItem, filterItems, SELECT_SUBJECT,
            'Subjects', (id, name) => this.setState({
              selectedSubjectItem: { id, name },
              active: TEXT_CHANGES_TYPE.SUBJECT,
              selectedComprehension: {},
            }), !newQuestion)}
        </div>
      );
    }

    return (
      <div style={{ padding: dropDownPadding }}>
        {this.renderSelectedText(selectedSubjectItem.name, `Subject - ${selectedSubjectItem.name ? selectedSubjectItem.name : '  -'}`)}
      </div>
    );
  }

  renderComprehension(isContentEditable) {
    const { selectedComprehension, selectedSubjectItem } = this.state;
    const { comprehensionIds } = this.props;
    if (isContentEditable) {
      const filterItems = [];
      const subjectComprehensionIds = comprehensionIds[selectedSubjectItem.id] || [];
      subjectComprehensionIds.map((id) => {
        id && filterItems.push({ id, name: id });
      });
      return (
        <div style={{ padding: dropDownPadding }} onClick={filterItems.length === 0 ? () => { this.props.showToast('Select Subject With Comprehension Ids'); } : ''}>
          {this.renderDropDown(selectedComprehension, filterItems, SELECT_ID, 'Comprehension Question Id',
            (id, name) => this.setState({ selectedComprehension: id ? { id, name: `Comprehension Id - ${name}` } : {}, active: TEXT_CHANGES_TYPE.COMPREHENSION_QUESTION_ID }), false)}
        </div>
      );
    }
    return (
      <a
        href={selectedComprehension.name && `/comprehension_questions/edit/${selectedComprehension.id}`}
        target={selectedComprehension.name && '_blank'}
        style={{ textDecoration: 'none', cursor: selectedComprehension.name && 'pointer', color: StyleConstants.color.dark }}
      >
        <div style={{ padding: dropDownPadding }}>
          {this.renderSelectedText(selectedComprehension.name, selectedComprehension.name ? selectedComprehension.name : 'Comprehension Id -')}
        </div>
      </a>
    );
  }

  renderSubmitFooter() {
    const { newQuestion } = this.props;
    const submitText = newQuestion ? 'Create' : 'Update';
    return (
      <PreviewFooter
        submitText={submitText}
        editQuestion={this.newQuestion}
      />
    );
  }

  renderQuestionHeader(text) {
    return (
      <div style={{ margin: '10px 0px 15px' }}>
        <Text
          style={{
            color: StyleConstants.color.dark,
            fontSize: StyleConstants.textSize.subHeader,
          }}
          text={text}
        />
      </div>
    );
  }

  renderQuestionChanges(isContentEditable, type) {
    const { textChanges } = this.state;
    if (isContentEditable) {
      return this.editDisplay(type, type, TEXT_CHANGES_TYPE[type.toUpperCase()], textChanges);
    }
    return this.previewDisplay(type, type, textChanges);
  }

  renderText(isContentEditable, text, type, isImage = false) {
    return (
      <div style={{ padding: '10px 0' }}>
        {this.renderQuestionHeader(text)}
        {this.renderQuestionChanges(isContentEditable, type)}
        {isImage && this.renderSolutionSupprotingPicture(isContentEditable)}
      </div>
    );
  }

  renderQuestionView(isContentEditable) {
    const { selectedQuestionTypeItem } = this.state;
    const isComprehension = selectedQuestionTypeItem.id === QUESTION_TYPES.COMPREHENSION;
    const isNumerical = selectedQuestionTypeItem.id === QUESTION_TYPES.NUMERICAL;
    return (
      <div
        style={{
          width: '50%',
          border: StyleConstants.border.preview,
          background: !isContentEditable && '#F7F7F7 0% 0% no-repeat padding-box',
          backgroundColor: isContentEditable && StyleConstants.color.white,
          fontWeight: StyleConstants.textWeight.semiBold,
          fontSize: StyleConstants.textSize.subHeader,
          padding: 30,
          paddingBottom: 0,
        }}
      >
        {this.renderHeader(isContentEditable)}
        {this.renderQuestionType(isContentEditable)}
        {this.renderText(isContentEditable, 'Question Text', 'question_text')}
        {this.renderSupportingPicture(isContentEditable)}
        {this.renderSubject(isContentEditable)}
        {this.renderOption(isContentEditable)}
        {isNumerical && this.renderOtherTypeData(isContentEditable)}
        {isComprehension && this.renderComprehension(isContentEditable)}
        {this.renderText(isContentEditable, 'Solutions', 'solution_text', true)}
        {isContentEditable && this.renderSubmitFooter()}
      </div>
    );
  }

  renderData() {
    const { showLoader } = this.state;
    const { newQuestion } = this.props;
    if (showLoader) {
      return (<Loader />);
    }
    return (
      <div style={{ display: 'flex', margin: newQuestion ? 20 : 50 }}>
        {this.renderQuestionView(true)}
        {this.renderQuestionView(false)}
      </div>
    );
  }

  showUploadedText() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{
          margin: '5%', fontSize: StyleConstants.textSize.subHeader, color: StyleConstants.color.dark, textAlign: 'center',
        }}
        >
          Question Updated Successfully. Refresh the page to View the Updated Question
        </div>
        <div className="Button Success-Button" style={{ width: 100 }} onClick={() => this.setState({ showUpdateModal: false })}>Close</div>
      </div>
    );
  }

  render() {
    const { showUpdateModal } = this.state;
    const { newQuestion } = this.props;
    if (newQuestion) {
      return (
        this.renderData()
      );
    }
    return (
      <Layout
        headerText={fileConstants.contentsMenuOrder.TOOLS.label}
        currentPage={fileConstants.contentsMenuOrder.TOOLS.position}
      >
        <Modal isModalVisible={showUpdateModal} handleCloseModal={() => this.setState({ showUpdateModal: false })} header="Question Update Status">
          {this.showUploadedText()}
        </Modal>
        {this.renderData()}
      </Layout>
    );
  }
}

NewQuestion.propTypes = {
  questions: PropTypes.object,
  redirectReportPage: PropTypes.bool,
  questionTypes: PropTypes.object,
  comprehensionIds: PropTypes.object,
};

NewQuestion.defaultProps = {
  questions: {},
  subjects: {},
  redirectReportPage: false,
  questionTypes: {},
  comprehensionIds: {},
};

const mapStateToProps = ({ question }) => ({
  questions: question.questionData,
  redirectReportPage: question.redirectReportPage,
  subjects: question.subjects,
  questionTypes: question.questionTypes,
  comprehensionIds: question.comprehensionIds,
});
export default connect(mapStateToProps, {
  getQuestionsForEdit,
  showToast,
  updateInstituteQuestion,
  getInstituteSubjects,
  updateComprehensionQuestion,
  updateQuestionReducer,
})(NewQuestion);
