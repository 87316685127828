import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import { getComprehensionQuestions, updateQuestionReducer, showToast } from '../actions';
import { toastMessages } from '../shared/constants/fieldTypes';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import ComprehensionQuestion from './comprehensionQuestion';
import { Loader, DropDown, ComprehensionQuestionView } from './common';

const history = createBrowserHistory({
  forceRefresh: true,
});

const ITEMS_COUNT_PER_PAGE = 20;
const SELECT_TEXT = 'Select Subjects';
const TYPES = { view_questions: 'View Questions', create_question: 'Create Question' };

class comprehensionQuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.mathjaxDiv = null;
    const { type } = this.props;
    this.state = {
      selectedType: TYPES[type] || TYPES.view_questions,
      ViewQuestion: {},
    };
    this.renderPagination = this.renderPagination.bind(this);
  }

  componentDidMount() {
    const { selectedType } = this.state;
    const { type } = this.props;
    if (selectedType[type] !== TYPES.create_question) {
      this.props.getComprehensionQuestions().then(() => {
        this.renderMathJax();
      });
    }
  }

  renderMathJax() {
    window && window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  renderFlipper() {
    const { selectedType } = this.state;
    return (
      <div style={{
        display: 'flex', marginTop: 30, alignItems: 'center', justifyContent: 'space-between',
      }}
      >
        <div style={{ display: 'flex' }}>
          {
					Object.keys(TYPES).map((type, index) => {
					  const className = TYPES[type] === selectedType ? 'Button Primary-Button' : 'Button Secondary-Button';
					  const style = {
					    borderRadius: 0, borderLeft: (index !== 0) && 0, display: 'flex', padding: '0px 15px',
					  };
					  return (
  <div className={className} style={style} onClick={() => history.push(`/tools/comprehension_questions/${type}`)}>{TYPES[type]}</div>
					  );
					})
}
        </div>
        {selectedType === TYPES.view_questions && this.renderSubjectFilter()}
      </div>
    );
  }

  renderQuestionList() {
    const { questions, pageIndex } = this.props;
    if (questions.length === 0) {
      return (
        <div style={{
          marginTop: 150, display: 'flex', alignSelf: 'center', alignItems: 'center',
        }}
        >
          No Comprehension Questions Available For This Filter
        </div>
      );
    }
    return (
      <div style={{ marginTop: 25 }}>
        {questions && questions.map((question, index) => {
				  return (
  <div className="Card-View" style={{ margin: '5px 40px 0px' }}>
    <div
      className="Button Dark-Button"
      style={{
        width: 'fit-content', height: 'fit-content', padding: 4, marginLeft: 38,
      }}
    >
      Comprehension Id:&nbsp;
      {question.id}
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ComprehensionQuestionView
        canEdit
        question={question}
        currentIndex={((pageIndex - 1) * ITEMS_COUNT_PER_PAGE) + (index + 1)}
        removeCardView
      />
    </div>
  </div>
				  );
        })}
        {this.renderPagination()}
      </div>
    );
  }

  renderPagination() {
    const { pageIndex, questions, totalQuestionCount } = this.props;
    if (questions.length === 0) {
      return (<div />);
    }
    return (
      <div style={{
        display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
      }}
      >
        <Pagination
          activePage={pageIndex}
          itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
          totalItemsCount={totalQuestionCount}
          pageRangeDisplayed={5}
          onChange={(index) => {
					  window.scroll(0, 0);
					  this.props.updateQuestionReducer({ pageIndex: index });
					  this.props.getComprehensionQuestions(parseInt(index)).then(() => {
					    this.renderMathJax();
					  });
          }}
        />
      </div>
    );
  }

  handleSubjectChange() {
    const { enableFilterSearch, pageIndex } = this.props;
    if (enableFilterSearch) {
      this.props.updateQuestionReducer({ enableFilterSearch: false });
      this.props.getComprehensionQuestions(pageIndex).then(() => {
        this.renderMathJax();
      });
    } else {
      this.props.showToast(toastMessages.noFilterSelected);
    }
  }

  renderSubjectFilter() {
    const { subjects, enableFilterSearch, appliedFilters } = this.props;
    const selectedItem = appliedFilters.subject;
    const filterItems = [];
    const selected_text = selectedItem.id ? selectedItem.name : SELECT_TEXT;
    const length = (selected_text.length * 8) + 80;
    for (let i = 0; i < Object.keys(subjects).length; i += 1) {
      filterItems[i] = subjects[i];
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DropDown
          key={Object.keys(subjects).length}
          optionType="Subjects"
          selectedItem={selectedItem}
          onValueChange={(id, name) => this.props.updateQuestionReducer({ appliedFilters: { subject: { id: id || '', name } } }).then(() => {
					  this.props.updateQuestionReducer({ enableFilterSearch: true });
          })}
          height={StyleConstants.fieldHeight.small}
          optionsList={filterItems}
          defaultText={SELECT_TEXT}
          width={length}
        />
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{
					  width: '200px', margin: '10px 40px', cursor: 'pointer', opacity: enableFilterSearch ? 1 : 0.5,
          }}
          onClick={() => { this.handleSubjectChange(); }}
        >
          {' '}
          Submit
        </div>
      </div>
    );
  }

  renderData() {
    const { isLoading } = this.props;
    const { selectedType } = this.state;
    if (isLoading) {
      return (
        <Loader />
      );
    }
    return (
      <>
        {selectedType === TYPES.view_questions && this.renderQuestionList()}
        {selectedType === TYPES.create_question && <ComprehensionQuestion newQuestion />}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderFlipper()}
        {this.renderData()}
      </>
    );
  }
}

comprehensionQuestionList.propTypes = {
  isLoading: PropTypes.bool,
  comprehensiveQuestions: PropTypes.object,
  subjects: PropTypes.object,
  pageIndex: PropTypes.number,
  enableFilterSearch: PropTypes.bool,
  appliedFilters: PropTypes.object,
};

comprehensionQuestionList.defaultProps = {
  isLoading: false,
  comprehensiveQuestions: [],
  subjects: {},
  pageIndex: 1,
  enableFilterSearch: false,
  appliedFilters: {},
};
const mapStateToProps = ({ question }) => ({
  isLoading: question.isLoading,
  questions: question.comprehensiveQuestions,
  subjects: question.subjects,
  totalQuestionCount: question.totalQuestionCount,
  pageIndex: question.pageIndex,
  enableFilterSearch: question.enableFilterSearch,
  appliedFilters: question.appliedFilters,
});
export default connect(mapStateToProps, { getComprehensionQuestions, updateQuestionReducer, showToast })(comprehensionQuestionList);