import {
  UPDATE_TEST_DETAILS, UPDATE_DASHBOARD_DETAILS, UPDATE_COURSE_SUBJECT_LIST, UPDATE_SUBJECT_CHAPTER_LIST, UPDATE_CHAPTER_TOPIC_LIST, UPDATE_TOPIC_QUESTION_LIST,
} from '../actions';

const INITIAL_STATE = {
  testList: [],
  subjectList: [],
  recentTestReport: {},
  courses: [],
  dtpOperatorsBoundayValue: { Monthly: 300, Daily: 20, Weekly: 100 },
  dtpOpertorProductivity: {},
  durationFilters: [{ id: 0, name: 'Monthly' }, { id: 1, name: 'Weekly' }, { id: 2, name: 'Daily' }],
  durationFilterSelected: 0,
  questionHealthData: [],
  courseSelected: {},
  isLoading: false,
  subjects: [],
  chapters: [],
  topics: [],
  questions: [],
  courseSubjectMapping: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TEST_DETAILS:
      return { ...state, ...action.payload };
    case UPDATE_DASHBOARD_DETAILS:
      return { ...state, ...action.payload };
    case UPDATE_COURSE_SUBJECT_LIST:
      return { ...state, ...action.payload };
    case UPDATE_SUBJECT_CHAPTER_LIST:
      return { ...state, ...action.payload };
    case UPDATE_CHAPTER_TOPIC_LIST:
      return { ...state, ...action.payload };
    case UPDATE_TOPIC_QUESTION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
