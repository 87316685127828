import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';

import CheatsheetList from '../cheatsheetList';
import NewQuestion from '../newQuestion';
import ImportQuestions from '../importQuestions';
import AutoTagDifficulty from '../autoTagDifficulty';
import ComprehensionQuestionList from '../comprehensionQuestionList';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import Layout from '../layout';

const history = createBrowserHistory({
  forceRefresh: true,
});

const TOOLS_TYPES = {
  new_question: 'New Question',
  import_questions: 'Import Questions',
  cheatsheet: 'Cheatsheet',
  auto_tag_difficulty: 'Auto-tag Difficulty',
  comprehension_questions: 'Comprehension Questions',
};

class Tools extends PureComponent {
  constructor(props) {
    super(props);
    const { toolType } = props.match.params;
    this.state = {
      selectedType: toolType ? TOOLS_TYPES[toolType] : TOOLS_TYPES.new_question,
    };
  }

  render() {
    const { selectedType } = this.state;
    const { type, id } = this.props.match.params;
    const { edit } = this.props;
    const isNewQuestion = selectedType === TOOLS_TYPES.new_question;
    const isImportQuestion = selectedType === TOOLS_TYPES.import_questions;
    const isCheatsheetList = selectedType === TOOLS_TYPES.cheatsheet;
    const isAutoTagDifficulty = selectedType === TOOLS_TYPES.auto_tag_difficulty;
    const isComprehension = selectedType === TOOLS_TYPES.comprehension_questions;


    return (
      <Layout headerText={fileConstants.contentsMenuOrder.TOOLS.label} currentPage={fileConstants.contentsMenuOrder.TOOLS.position}>
        <div
          className="Simple-Card-View"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 30, fontFamily: StyleConstants.textFont.normal, fontSize: StyleConstants.textSize.subHeader,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              {Object.keys(TOOLS_TYPES).map((toolType, index) => {
                const selected = selectedType === TOOLS_TYPES[toolType];
                return (
                  <div
                    role="presentation"
                    key={`${toolType}`}
                    className={selected ? 'Button Primary-Button' : 'Button Secondary-Button'}
                    style={{
                      borderRadius: 0, borderLeft: (index !== 0) && 0, display: 'flex', padding: '0px 15px',
                    }}
                    onClick={() => history.push(`/tools/${toolType}`)}
                  >
                    {TOOLS_TYPES[toolType]}
                  </div>
                );
              })}
            </div>
          </div>
          {isNewQuestion && <NewQuestion newQuestion />}
          {isImportQuestion && <ImportQuestions />}
          {isCheatsheetList && <CheatsheetList />}
          {isAutoTagDifficulty && <AutoTagDifficulty />}
          {isComprehension && <ComprehensionQuestionList edit={edit} type={type} id={id} />}
        </div>
      </Layout>
    );
  }
}

Tools.propTypes = {

};

Tools.defaultProps = {

};

const mapStateToProps = ({ question }) => ({
  subjects: question.subjects,
});

export default connect(mapStateToProps, { })(Tools);
