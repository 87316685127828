import React from 'react';
import { renderMathjax } from '../helpers/Utils'

class OptionView extends React.Component {
  constructor(props) {
    super(props);
    this.refCallback = this.refCallback.bind(this);
    this.state = {
        maxWidthOption: 0,
        options: {},
      };
  }

  componentDidMount() {
    renderMathjax();
  }

  refCallback(element) {
    if (element) {
      this.elementRef = element;
      this.props.getRef(element);
    }
  }

  render() {
    const {
      text, image, image_width, optionIndex, highlightAnswer, marginRight, removeLeftBracket,
    } = this.props;
    if (image) {
      return (
        <div className="option-view" style={{ marginTop: 5, display: 'flex' }}>
          <span style={{ fontWeight: highlightAnswer ? 'bold' : 'normal' }}>
            {!removeLeftBracket && '('}
            {optionIndex}
            )&nbsp;
          </span>
          <div><img alt="" ref={this.refCallback} src={image} width={image_width} height="auto" /></div>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', marginTop: 5, marginRight }}>
        <span style={{ fontWeight: highlightAnswer ? 'bold' : 'normal' }}>
          {!removeLeftBracket && '('}
          {optionIndex}
          )&nbsp;&nbsp;&nbsp;
        </span>
        <span ref={this.refCallback} style={{ fontWeight: highlightAnswer ? 'bold' : 'normal', textAlign: 'left' }}>{text}</span> 
      </div>
    );
  }
}

OptionView.defaultProps = {
  getRef: () => {},
};
export default OptionView;
