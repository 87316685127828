import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stackedit from 'stackedit-js';
import ssmlCheck from 'ssml-check';
import {
  Modal, Text, ShowBrief, Loader, DropDown, TableModal,
} from '.';
import {
  addBrief, updateBrief, deleteBrief, updateBriefReducer,
  uploadImage, updateBriefAudio, showToast, getAudioForText,
  getAutoFormat, getFormula, updateBriefCursorPosition,
  getDiagram, updateBriefContentWithFormula, updateBriefContentWithDiagram,
} from '../../actions';
import {
  isMac, titleCase, getFormattedBriefContent,
} from '../../helpers/Utils';
import { toastMessages } from '../../shared/constants/fieldTypes';
import UploadImage from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

const Diff = require('diff');
const markdownToHTML = require('markdown-it')({ breaks: true, strict: false, html: true });
const mk = require('@iktakahiro/markdown-it-katex');

markdownToHTML.use(mk);

const MOBILE_VIEW_WIDTH = 325;
const EDIT_VIEW_WIDTH = (window.innerWidth - 250 - MOBILE_VIEW_WIDTH - 40 * 2);
const stackedit = new Stackedit();

class EditBrief extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteBriefModal: false,
      showUploadImageModal: false,
      showFormulaModal: false,
      showDiagramModal: false,
      showAudioModal: false,
      imageFile: '',
      playerStatus: 'paused',
      isTextCopied: false,
      isTitleFocused: true,
      screenHeight: global.innerHeight,
      isEdit: false,
    };
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.searchFormula = this.searchFormula.bind(this);
    this.searchDiagram = this.searchDiagram.bind(this);
    this.oldRef = React.createRef();
    this.newRef = React.createRef();
    this.onScrollOld = this.onScrollOld.bind(this);
    this.onScrollNew = this.onScrollNew.bind(this);
    this.handleCloseUploadImageModal = this.handleCloseUploadImageModal.bind(this);
    this.handleAutoFormatterModal = this.handleAutoFormatterModal.bind(this);
    this.handleCloseFormulaModal = this.handleCloseFormulaModal.bind(this);
    this.handleCloseDiagramModal = this.handleCloseDiagramModal.bind(this);
    this.updateBriefContentFormula = this.updateBriefContentFormula.bind(this);
    this.updateBriefContentDiagram = this.updateBriefContentDiagram.bind(this);
    this.handleCloseAudioModal = this.handleCloseAudioModal.bind(this);
    this.handlePlayButtonClick = this.handlePlayButtonClick.bind(this);
    this.handleAudioPlayOrUpdateButtonClick = this.handleAudioPlayOrUpdateButtonClick.bind(this);
    this.onTitleFocus = this.onTitleFocus.bind(this);
    this.onContentFocus = this.onContentFocus.bind(this);
  }

  
  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderDeleteBriefModal() {
    const { showDeleteBriefModal } = this.state;
    return (
      <Modal
        key={showDeleteBriefModal}
        isModalVisible={showDeleteBriefModal}
        handleCloseModal={this.handleCloseDeleteModal}
        height={130}
        width={600}
      >
        {this.deleteBrief()}
      </Modal>
    );
  }

  renderUploadImageModal() {
    const { briefMe: { imageURL } } = this.props;
    const { showUploadImageModal } = this.state;

    return (
      <Modal
        header="Upload Image"
        key={showUploadImageModal}
        isModalVisible={showUploadImageModal}
        handleCloseModal={this.handleCloseUploadImageModal}
        height={imageURL ? 380 : 330}
        width={imageURL ? 800 : 600}
        maxWidth={imageURL && 800}
      >
        {this.uploadImage()}
      </Modal>
    );
  }

  renderAutoFormatterModal() {
    const { showAutoFormatterModal, screenHeight } = this.state;

    return (
      <Modal
        header="Auto formatter"
        key={showAutoFormatterModal}
        isModalVisible={showAutoFormatterModal}
        handleCloseModal={this.handleAutoFormatterModal}
        height={screenHeight - 50}
        width={1300}
        maxWidth={1300}
      >
        {this.autoFormatter()}
      </Modal>
    );
  }

  renderFormulaModal() {
    const { briefMe: { selectedBriefId, briefCursorIndex }, formula: { formulas } } = this.props;
    const { showFormulaModal } = this.state;
    const tableHeaders = [
      'name',
    ];
    return (
      <TableModal
        header="Add Formula"
        key={showFormulaModal}
        isModalVisible={showFormulaModal && selectedBriefId}
        briefId={selectedBriefId}
        briefContentIndex={briefCursorIndex}
        tableHeaders={tableHeaders}
        tableValues={formulas}
        getSearchData={this.searchFormula}
        onAddButtonClick={this.updateBriefContentFormula}
        searchFieldRequired
        isAddButtonRequired
        height={600}
        width={425}
        handleCloseModal={this.handleCloseFormulaModal}
      />
    );
  }

  renderDiagramModal() {
    const { briefMe: { selectedBriefId, briefCursorIndex }, diagram: { diagrams } } = this.props;
    const { showDiagramModal } = this.state;
    const tableHeaders = [
      'name',
    ];
    return (
      <TableModal
        header="Add Diagram"
        key={showDiagramModal}
        isModalVisible={showDiagramModal && selectedBriefId}
        briefId={selectedBriefId}
        briefContentIndex={briefCursorIndex}
        tableHeaders={tableHeaders}
        tableValues={diagrams}
        getSearchData={this.searchDiagram}
        onAddButtonClick={this.updateBriefContentDiagram}
        searchFieldRequired
        isAddButtonRequired
        height={600}
        width={425}
        handleCloseModal={this.handleCloseDiagramModal}
      />
    );
  }

  renderAudioModal() {
    const {
      briefMe: {
        briefs, selectedBriefId, selectedIndex, title, content, timeToRead, audioURL, deprecated, metaData,
      },
    } = this.props;

    const { showAudioModal } = this.state;
    const header = 'Update Audio';
    const height = 650;
    const width = 900;
    const maxWidth = 900;

    if (selectedBriefId && briefs[selectedBriefId]) {
      const {
        title: briefTitle, content: briefContent, time_to_read: briefTimeToRead, audio_url: briefAudioURL, deprecated: briefDeprecated, polly_text: briefPollyText, meta_data: metaData,
      } = briefs[selectedBriefId];

      return (
        <Modal
          header={header}
          key={showAudioModal}
          isModalVisible={showAudioModal}
          handleCloseModal={this.handleCloseAudioModal}
          height={height}
          width={width}
          maxWidth={maxWidth}
        >
          {this.renderAudioChanges(selectedBriefId, briefTitle, briefContent, metaData, briefTimeToRead, briefAudioURL, briefDeprecated, selectedIndex, briefPollyText)}
        </Modal>
      );
    }

    return (
      <Modal
        header={header}
        key={showAudioModal}
        isModalVisible={showAudioModal}
        handleCloseModal={this.handleCloseAudioModal}
        height={height}
        width={width}
        maxWidth={maxWidth}
      >
        {this.renderAudioChanges(selectedBriefId, title, content, metaData, timeToRead, audioURL, deprecated, selectedIndex)}
      </Modal>
    );
  }

  deleteBrief() {
    const {
      briefMe: {
        selectedBriefId, title, selectedIndex, selectedBrief,
      },
    } = this.props;
    const width = 130;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text
          fontSize={StyleConstants.textSize.subHeader}
          color={StyleConstants.color.dark}
          style={{
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 550,
          }}
          text={`Are you sure you want to delete the brief ${title}?`}
        />
        <div className="modalButtonView">
          <div role="presentation" className="Button Secondary-Button" style={{ width, marginRight: 5 }} onClick={this.handleCloseDeleteModal}> Cancel </div>
          <div role="presentation" className="Button Primary-Button" style={{ width }} onClick={() => this.setState({ showDeleteBriefModal: false }, () => this.props.deleteBrief(selectedBriefId, selectedIndex, !!selectedBrief))}> Delete </div>
        </div>
      </div>
    );
  }

  copyToClipboard(event) {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.setState({ isTextCopied: true });
  }

  uploadImage() {
    const { imageFile, isTextCopied } = this.state;
    const { briefMe: { isUpdating, imageURL } } = this.props;

    if (isUpdating) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    if (imageFile) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {imageFile
              && (
                <div style={{
                  height: 'auto', width: 'fit-content', textAlign: 'center', margin: 'auto',
                }}
                >
                  <img
                    alt={imageFile}
                    src={URL.createObjectURL(imageFile)}
                    style={{
                      height: '150px', width: 'auto', marginTop: 10, borderRadius: 10,
                    }}
                  />
                  <div
                    className="Button Secondary-Button"
                    role="presentation"
                    style={{ margin: '20px 80px', width: 100 }}
                    onClick={() => this.setState({ imageFile: '', isTextCopied: false }, () => this.props.updateBriefReducer({ imageURL: '' }))}
                  >
                    Clear
                  </div>
                </div>
              )}
          </div>
          {!imageURL && <div role="presentation" className="Button Primary-Button" onClick={() => this.props.uploadImage(this.state.imageFile)}>Upload</div>}
          {imageURL && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.renderTitle('Image URL:')}
                {document.queryCommandSupported('copy') && <div role="presentation" className={`Button ${isTextCopied ? 'Secondary-Button' : 'Primary-Button'}`} style={{ width: 100 }} onClick={isTextCopied ? () => {} : (event) => this.copyToClipboard(event)}>{`${isTextCopied ? 'Copied' : 'Copy'}`}</div>}
              </div>
              <textarea
                readOnly
                ref={(textarea) => this.textArea = textarea}
                value={imageURL}
                style={{
                  marginTop: 10, overflow: 'scroll', maxHeight: 45, maxWidth: 740,
                }}
              />
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div
          role="presentation"
          style={{
            display: 'flex', flexDirection: 'column', borderRadius: 5, height: 220, border: `1px solid ${StyleConstants.color.mediumLight}`, backgroundColor: '#FFF', cursor: 'pointer',
          }}
          onClick={() => document.getElementById('image-input').click()}
        >
          <input
            style={{
              display: 'none',
            }}
            id="image-input"
            type="file"
            accept="image/*"
            onChange={(event) => this.setState({ imageFile: event.target.files[0] })}
          />
          <img src={UploadImage} alt="upload_image" style={{ marginTop: 80 }} />
          <Text style={{ color: StyleConstants.color.mediumLight, marginTop: 10, textAlign: 'center' }} text="Drag or browse the Diagram file to upload" />
        </div>
        <div style={{ display: 'flex' }}>
          <img src={info} alt="info" />
          <Text style={{ color: StyleConstants.color.mediumLight, padding: 8 }} text="Upload only .Jpg or .Png files" />
        </div>
      </>
    );
  }

  onScrollNew() {
    this.oldRef.current.scrollTop = this.newRef.current.scrollTop;
  }

  onScrollOld() {
    this.newRef.current.scrollTop = this.oldRef.current.scrollTop;
  }

  autoFormatter() {
    const { briefMe: { autoFormat: { isLoading, content: formattedContent }, content: orginalContent, metaData: { formulas, diagrams } } } = this.props;
    const { screenHeight, isEdit } = this.state;

    let isChanged = false;
    if (isLoading) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    const diff = Diff.diffChars(orginalContent, formattedContent, { newlineIsToken: true, ignoreWhitespace: true });

    return (
      <div>
        <div style={{
          padding: 15, backgroundColor: '#f1f1f1', margin: '15px 0px', borderRadius: 5, display: 'flex', height: screenHeight - 170,
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', fontWeight: 'bold', width: '48%',
          }}
          >
            <div style={{
              height: (screenHeight / 2) - 50, backgroundColor: 'white', marginBottom: 10, padding: 5,
            }}
            >
              <div style={{ color: 'red', fontSize: 16 }}>OLD</div>
              <div
                style={{
                  paddingRight: '1%', paddingTop: 10, overflow: 'scroll', height: (screenHeight / 2) - 150,
                }}
                className="brief-basic-details"
                onScroll={this.onScrollOld}
                ref={this.oldRef}
                dangerouslySetInnerHTML={{ __html: markdownToHTML.render(getFormattedBriefContent(orginalContent, formulas, diagrams)) }}
              />
            </div>
            <div style={{
              height: (screenHeight / 2) - 50, backgroundColor: 'white', padding: 5, marginBottom: 10,
            }}
            >
              <div style={{ color: 'green', fontSize: 16, fontWeight: 'bold' }}>NEW</div>
              <div
                style={{
                  paddingRight: '1%', paddingTop: 10, overflow: 'scroll', height: (screenHeight / 2) - 150,
                }}
                ref={this.newRef}
                className="brief-basic-details"
                onScroll={this.onScrollNew}
                dangerouslySetInnerHTML={{ __html: markdownToHTML.render(getFormattedBriefContent(formattedContent, formulas, diagrams)) }}
              />
            </div>
          </div>
          <div style={{ width: '48%', marginLeft: '2%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                role="presentation"
                className="Button Primary-Button"
                style={{ width: 120, marginBottom: 10 }}
                onClick={() => {
                  this.setState({ isEdit: !isEdit });
                }}
              >
                {isEdit ? 'View' : 'Edit'}
              </div>
            </div>
            {
              isEdit ? (
                <textarea
                  id="content-input"
                  style={{
                    marginBottom: 10, height: screenHeight - 250, width: '100%', borderRadius: 0,
                  }}
                  value={formattedContent}
                  placeholder="Description"
                  onChange={(event) => this.props.updateBriefReducer({ autoFormat: { isLoading: false, content: event.target.value } })}
                />
              ) : (
                <div
                  className="no-mathjax"
                  style={{
                    width: '100%', paddingLeft: '1%', paddingTop: 10, whiteSpace: 'break-spaces', overflow: 'scroll', backgroundColor: 'white', height: screenHeight - 250,
                  }}
                >
                  {diff.map((part) => {
                    let backgroundColor = '';
                    let color = '';
                    if (part.added) {
                      isChanged = true;
                      color = '#196619';
                      backgroundColor = '#ccffcc';
                      if (part.value === '\n') {
                        return (<span style={{ color, backgroundColor }}>{'Empty line added \n'}</span>);
                      } if (part.value === ' ') {
                        return (<span style={{ color, backgroundColor }}>Empty space added</span>);
                      } if (part.value === '\t') {
                        return (<span style={{ color, backgroundColor }}>{' Extra tab space added '}</span>);
                      }
                    } else if (part.removed) {
                      isChanged = true;
                      color = '#660000';
                      backgroundColor = '#ffcccc';
                      if (part.value === '\n') {
                        return (<span style={{ color, backgroundColor }}>{'Empty line removed \n'}</span>);
                      } if (part.value === ' ') {
                        return (<span style={{ color, backgroundColor }}>Empty space removed</span>);
                      } if (part.value === '\t') {
                        return (<span style={{ color, backgroundColor }}>{' Extra tab space removed '}</span>);
                      }
                    }
                    return (<span style={{ color, backgroundColor }}>{part.value}</span>);
                  })}
                </div>
              )
            }

          </div>

        </div>
        <div style={{ display: 'flex', placeContent: 'center' }}>
          {
            isChanged ? (
              <div
                role="presentation"
                className="Button Primary-Button"
                style={{ width: 120 }}
                onClick={() => { this.handleAutoFormatterModal(); this.props.updateBriefReducer({ content: formattedContent, isChanged: true }); }}
              >
                Use this
              </div>
            )
              : <div>{ isEdit ? '' : 'Already formatted to its best' }</div>
          }
        </div>

      </div>
    );
  }

  handlePlayButtonClick() {
    const audioInput = document.getElementById('audio-input');
    const { playerStatus } = this.state;

    if (playerStatus === 'paused' && audioInput) {
      audioInput.play();
    } else if (audioInput) {
      audioInput.pause();
    }
  }

  handleAudioPlayOrUpdateButtonClick(pollyText, isUpdateButtonClick, id = '', title = '', content = '') {
    const {
      briefMe: {
        selectedIndex, timeToRead, briefOrder, previousYearQuestionId, parentId,
      }, bmChapterId,
    } = this.props;

    if ((title === '' || content === '') && isUpdateButtonClick) {
      this.props.showToast(toastMessages.mandatoryFieldsNotFilled);
    } else {
      ssmlCheck.check(pollyText)
        .then((errors) => {
          if (errors) {
            this.props.showToast(toastMessages.invalidSyntax);
          } else if (isUpdateButtonClick && (id === null || id === '')) {
            this.props.addBrief(title, content, timeToRead, pollyText, selectedIndex, briefOrder, bmChapterId, previousYearQuestionId, parentId, true);
          } else if (isUpdateButtonClick) {
            this.props.updateBriefAudio(id, pollyText);
          } else {
            this.props.getAudioForText(id, pollyText, bmChapterId, selectedIndex).then(() => {
              this.handlePlayButtonClick();
            });
          }
        });
    }
  }

  playAudio(allowUpdate, briefAudioURL) {
    const { briefMe: { audioURL, isPlayButtonClicked } } = this.props;
    const audioUrlToPlay = isPlayButtonClicked && allowUpdate ? audioURL : briefAudioURL;

    if ((!allowUpdate || (allowUpdate && isPlayButtonClicked)) && audioUrlToPlay) {
      return (
        <audio
          id="audio-input"
          controls
          onPlay={() => this.setState({ playerStatus: 'playing' })}
          onEnded={() => this.setState({ playerStatus: 'paused' })}
          onPause={() => this.setState({ playerStatus: 'paused' })}
        >
          <source src={audioUrlToPlay} type="audio/mpeg" />
        </audio>
      );
    }
    return <></>;
  }

  playTheLatestAudio(pollyText, briefAudioURL, allowUpdate) {
    const { briefMe: { isPlayButtonClicked, audioURL, selectedBriefId } } = this.props;
    const audioUrlToPlay = isPlayButtonClicked && allowUpdate ? audioURL : briefAudioURL;

    if (allowUpdate && !isPlayButtonClicked) {
      this.handleAudioPlayOrUpdateButtonClick(pollyText, false, selectedBriefId);
    } else if (!allowUpdate && !audioUrlToPlay) {
      this.props.showToast(toastMessages.textNotChanged);
    } else {
      this.handlePlayButtonClick();
    }
  }

  renderAudioChanges(id, title, content, metaData, timeToRead, audioURL, deprecated, selectedIndex, briefPollyText = '<speak> Enter the text here </speak>') {
    const {
      briefMe: {
        isUpdating, pollyText,
      },
    } = this.props;

    const width = 400;
    const allowUpdate = (briefPollyText !== pollyText);
    const briefDetails = {
      id, title, content, timeToRead, audioURL, deprecated, pollyText, index: selectedIndex, width, briefQuestionsCount: '', briefPreviousYearQuestionsCount: '', messageToImproveConsistency: '', briefTags: [], printBriefs: false, displayAddButton: false, isAudioModal: true, showBorder: true, isVersionComparison: false, metaData,
    };

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0px' }}>
          <ShowBrief briefDetails={briefDetails} />
          <textarea style={{ width, maxHeight: 450 }} value={pollyText} placeholder="SSML Input" onChange={(event) => this.props.updateBriefReducer({ pollyText: event.target.value, isPlayButtonClicked: false })} />
        </div>
        <div style={{ display: 'flex', justifyContent: isUpdating ? 'center' : 'space-between', alignItems: 'center' }}>
          {!isUpdating && (
          <>
            <div
              role="presentation"
              className={`Button ${this.state.playerStatus === 'paused' ? 'Primary-Button' : 'Secondary-Button'}`}
              style={{ width: 100 }}
              onClick={() => this.playTheLatestAudio(pollyText, audioURL, allowUpdate)}
            >
              {' '}
              {this.state.playerStatus === 'paused' ? 'Play' : 'Pause'}
              {' '}
            </div>
            {!isUpdating && this.playAudio(allowUpdate, audioURL)}
          </>
          )}
          {isUpdating && this.showLoader({ marginTop: 20 })}
          {!isUpdating && (
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{ width: 100, opacity: allowUpdate ? 1 : 0.5, cursor: allowUpdate ? 'pointer' : 'not-allowed' }}
            onClick={allowUpdate ? () => this.handleAudioPlayOrUpdateButtonClick(pollyText, true, id, title, content) : () => {}}
          >
            {id === null || id === '' ? 'Create' : 'Update'}
          </div>
          )}
        </div>
      </>
    );
  }

  editContent(content) {
    let textContent = '';

    stackedit.openFile({
      content: {
        text: content,
      },
    });

    stackedit.on('fileChange', (file) => {
      textContent = file.content.text;
    });

    stackedit.on('close', () => {
      this.props.updateBriefReducer({ content: textContent, isChanged: true });
    });
  }

  onSaveButtonClick() {
    const {
      briefMe: {
        isBriefSelected, selectedIndex, content, title, timeToRead, pollyText, selectedBriefId, briefOrder, selectedBrief, selectedTags, previousYearQuestionId, parentId,
      }, bmChapterId,
    } = this.props;

    if (isBriefSelected) {
      this.props.updateBrief(selectedBriefId, title, content, timeToRead, bmChapterId, previousYearQuestionId, !!selectedBrief, selectedTags, parentId);
    } else {
      this.setState({ displayEditView: false }, () => {
        this.props.addBrief(title, content, timeToRead, pollyText, selectedIndex, briefOrder, bmChapterId, previousYearQuestionId, parentId, false, selectedTags);
        this.setState({ displayEditView: true });
      });
    }
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  renderText(text) {
    return <Text fontSize={StyleConstants.textSize.smallText} color={StyleConstants.color.dark} text={text} style={{ alignSelf: 'center' }} />;
  }

  removeBold(text) {
    return text.replaceAll('**', '');
  }

  handleKeyDown(event, keysPressed, text, isTitleFocused) {
    const { briefMe: { isChanged } } = this.props;
    const toggleBold = (isMac() && (keysPressed.Meta && event.keyCode === 66)) || (keysPressed.Control && event.keyCode === 66);
    const toggleHeading = (isMac() && (keysPressed.Meta && event.keyCode === 72)) || (keysPressed.Control && event.keyCode === 72);
    const toggleBullets = (isMac() && (keysPressed.Meta && event.keyCode === 85)) || (keysPressed.Control && event.keyCode === 85);
    const saveBrief = (isMac() && (keysPressed.Meta && event.keyCode === 83)) || (keysPressed.Control && event.keyCode === 83);
    let selectedText = text.substring(event.target.selectionStart, event.target.selectionEnd);

    if (saveBrief) {
      event.preventDefault();
      if (isChanged) {
        this.onSaveButtonClick();
      }
    }

    let textChanges = '';
    let newText = '';
    const bullets = ['* ', '- ', '+ '];
    const innerBullets = ['    * ', '    - ', '    + '];
    let bulletPoint = bullets.includes(selectedText.substring(0, 2)) ? selectedText.substring(0, 2) : '';
    bulletPoint = innerBullets.includes(selectedText.substring(0, 6)) ? selectedText.substring(0, 6) : bulletPoint;
    const isBold = selectedText.substring(bulletPoint.length, bulletPoint.length + 2) === '**' && selectedText.substring(selectedText.length - 2) === '**';
    if (toggleBold || toggleHeading) {
      if (bulletPoint.length > 0) {
        selectedText = selectedText.substring(2);
      }
    }
    if (event.key === 'Escape') {
      this.props.updateBriefReducer({
        displayEditView: false, selectedIndex: null, selectedBriefId: null, isBriefSelected: false,
      });
    } else if (toggleBold) {
      if (selectedText.substring(0, 4) === '### ') {
        selectedText = selectedText.substring(4);
      }
      selectedText = this.removeBold(selectedText);
      if (isBold) {
        textChanges = `${bulletPoint}${selectedText}`;
      } else {
        textChanges = `${bulletPoint}**${selectedText}**`;
      }
    } else if (toggleHeading) {
      if (isBold) {
        selectedText = this.removeBold(selectedText);
      }
      if (selectedText.substring(0, 4) === '### ') {
        textChanges = `${bulletPoint}${selectedText.substring(4)}`;
      } else {
        textChanges = `${bulletPoint}### ${selectedText}`;
      }
    } else if (toggleBullets) {
      if (bulletPoint.length > 0) {
        selectedText = selectedText.substring(2);
        bullets.map((bullet) => {
          selectedText = selectedText.replaceAll(`\n    ${bullet}`, '\n    ');
          selectedText = selectedText.replaceAll(`\n${bullet}`, '\n');
        });
        textChanges = selectedText;
      } else {
        selectedText = selectedText.replaceAll('\n', '\n* ');
        textChanges = `* ${selectedText}`;
      }
    }
    if (toggleBold || toggleHeading || toggleBullets) {
      let newChanges = {};
      let focusedInput = '';
      newText = `${text.substring(0, event.target.selectionStart)}${textChanges}${text.substring(event.target.selectionEnd)}`;
      if (isTitleFocused) {
        focusedInput = document.getElementById('title-input');
        newChanges = { title: newText, isChanged: true, briefCursorIndex: text.length };
      } else {
        focusedInput = document.getElementById('brief-content-input');
        newChanges = { content: newText, isChanged: true, briefCursorIndex: text.length };
      }
      focusedInput.selectionStart = 0;
      focusedInput.selectionEnd = text.length;
      document.execCommand('insertText', false, newText);
      this.props.updateBriefReducer(newChanges);
    }
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteBriefModal: false });
  }

  handleCloseFormulaModal() {
    this.setState({ showFormulaModal: false });
  }

  handleCloseDiagramModal() {
    this.setState({ showDiagramModal: false });
  }

  handleCloseUploadImageModal() {
    this.setState({ showUploadImageModal: false, isTextCopied: false });
  }

  handleAutoFormatterModal() {
    this.setState({ showAutoFormatterModal: false });
  }

  handleCloseAudioModal() {
    this.setState({ showAudioModal: false, playerStatus: 'paused' }, () => this.props.updateBriefReducer({ isPlayButtonClicked: false }));
  }

  searchFormula(text) {
    const { briefMe: { overallDetails: { selectedBmSubject: { id } } }, getFormula } = this.props;
    getFormula({ bmSubjectId: id, searchText: text });
  }

  searchDiagram(text) {
    const { briefMe: { overallDetails: { selectedBmSubject: { id } } }, getDiagram } = this.props;
    getDiagram({ bmSubjectId: id, searchText: text });
  }

  updateCursorPosition(event) {
    const briefCursorIndex = event.target.selectionStart;
    this.props.updateBriefCursorPosition(briefCursorIndex);
  }

  renderTagsDropDown(dropdownOptions) {
    return (
      <DropDown
        key={dropdownOptions.key}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        optionType="Tags"
        selectedItems={dropdownOptions.selectedItems}
        defaultText="Tags"
        onValueChange={(id, name) => this.props.updateBriefReducer({ selectedTags: this.getSelectedTags(id, name, dropdownOptions.selectedItems), isChanged: true })}
        optionsList={dropdownOptions.optionsList}
        dropDownContainerStyle={{ marginLeft: 30 }}
      />
    );
  }

  renderSingleSelectDropdown(dropdownOptions) {
    return (
      <DropDown
        key={dropdownOptions.key}
        selectedItem={dropdownOptions.selectedItem}
        optionsList={dropdownOptions.optionsList}
        optionType={dropdownOptions.optionType}
        defaultText={dropdownOptions.defaultText}
        width={dropdownOptions.width}
        height={dropdownOptions.height}
        onValueChange={dropdownOptions.onValueChange}
      />
    );
  }

  onTitleFocus() {
    this.setState({ isTitleFocused: true });
  }

  onContentFocus(event) {
    this.setState({ isTitleFocused: false });
    this.props.updateBriefCursorPosition(event.target.selectionStart);
  }

  showFormulaModal() {
    const { briefMe: { overallDetails: { selectedBmSubject: { id } } }, getFormula } = this.props;
    getFormula({ bmSubjectId: id }).then(() => {
      this.setState({ showFormulaModal: true });
    });
  }

  showDiagramModal() {
    const { briefMe: { overallDetails: { selectedBmSubject: { id } } }, getDiagram } = this.props;
    getDiagram({ bmSubjectId: id }).then(() => {
      this.setState({ showDiagramModal: true });
    });
  }

  editBrief() {
    const {
      briefMe: {
        isBriefSelected, content, title, timeToRead, deprecated, isChanged, selectedBriefId, isAdmin, selectedIndex, briefOrder, parentIds, briefOrderWithoutChildren,
        isUpdating, briefs, hasModifyBriefAccess, selectedBrief, briefTags, selectedTags, previousYearQuestionId, briefOrderWithIndex, parentId,
      }, bmChapterId, isBriefSearch,
    } = this.props;

    const { isTitleFocused } = this.state;
    let enableSaveButton = content && title && isChanged;
    enableSaveButton = timeToRead !== '' ? enableSaveButton && (timeToRead <= 30) : enableSaveButton;
    const width = EDIT_VIEW_WIDTH;
    const buttonWidth = 100;
    const inputStyle = { marginBottom: 20, borderRadius: 0, backgroundColor: '#FFF' };
    const tagsDropdownOptions = {
      key: selectedTags.length,
      width: 200,
      height: 35,
      optionsList: briefTags,
      selectedItems: selectedTags,
    };
    const ParentBriefOptions = {
      key: parentId,
      width: 200,
      height: 35,
      optionType: 'select brief',
      optionsList: briefOrderWithIndex,
      defaultText: 'Add Parent brief',
      selectedItem: !isBriefSearch && briefOrderWithIndex[briefOrderWithoutChildren.indexOf(parentId)] || null,
      onValueChange: (id) => {
        if (id === selectedIndex) {
          this.props.showToast('Brief cannot be child to itself');
        } else {
          const isChanged = parentId !== briefOrder[id];
          this.props.updateBriefReducer({ parentId: id !== null && id >= 0 && briefOrder.length > 0 ? briefOrder[id] : '', isChanged, parentIdChanged: isChanged });
        }
      },
    };

    const keysPressed = {};
    const text = isTitleFocused ? title : content;

    return (
      <div
        role="presentation"
        className="Card-View"
        style={{
          width, position: 'fixed', top: 0, bottom: 0, right: 0,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          {!isBriefSearch && (
          <div style={{ display: 'flex' }}>
            {this.renderText(`Position: ${selectedIndex + 1}`)}
            {parentIds.indexOf(briefOrder[selectedIndex]) >= 0 ? this.renderText('Parent Brief') : <div style={{ marginLeft: 15 }}>{this.renderSingleSelectDropdown(ParentBriefOptions)}</div>}
          </div>
          )}
          <div
            role="presentation"
            style={{
              height: 20, width: 20, border: '1px solid #000', borderRadius: 50, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            onClick={() => this.props.updateBriefReducer({
              displayEditView: false, selectedIndex: null, selectedBriefId: null, isBriefSelected: false,
            })}
          >
            <img src="/images/common/x-mark.svg" alt="close" style={{ height: 10 }} />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <input
            id="title-input"
            autoFocus
            onFocus={this.onTitleFocus}
            style={{ ...inputStyle, width: '95%', height: '35px' }}
            type="text"
            value={title}
            placeholder="Title"
            onChange={(event) => this.props.updateBriefReducer({ title: event.target.value, isChanged: true })}
            onKeyDown={(event) => {
              keysPressed[event.key] = true;
              this.handleKeyDown(event, keysPressed, text, isTitleFocused);
              if (!keysPressed.Meta) {
                this.updateCursorPosition(event);
              }
            }}
          />
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{ width: '4%', marginLeft: '1%', height: '35px' }}
            onClick={() => {
              if (title === '' || title === null) {
                this.props.showToast('Title is empty!');
              } else {
                const formattedTitle = title.replace(' (or) ', ' or ');
                this.props.updateBriefReducer({ title: titleCase(formattedTitle), isChanged: true });
              }
              this.props.showToast('Title formatted');
            }}
          >
            Aa
          </div>
        </div>
        <textarea
          id="brief-content-input"
          style={{
            marginBottom: 10, height: 500, borderRadius: 0, maxWidth: width - 40,
          }}
          onFocus={this.onContentFocus}
          value={content}
          placeholder="Description"
          onChange={(event) => {
            this.props.updateBriefReducer({ content: event.target.value, isChanged: true, briefCursorIndex: event.target.selectionStart });
          }}
          onClick={(event) => {
            this.updateCursorPosition(event);
          }}
          onKeyDown={(event) => {
            keysPressed[event.key] = true;
            this.handleKeyDown(event, keysPressed, text, isTitleFocused);
            if (!keysPressed.Meta) {
              this.updateCursorPosition(event);
            }
          }}
        />
        <div style={{
          display: 'flex', justifyContent: 'flex-end', color: StyleConstants.color.dark, marginBottom: 10,
        }}
        >
          {`${content.length} chars | ${(content.length > 0 ? content.trim().split(/\s+/).length : 0)} words`}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input style={{ width: 75, ...inputStyle }} type="number" min="1" max="30" step="1" value={timeToRead} placeholder="Time" onChange={(event) => this.props.updateBriefReducer({ timeToRead: event.target.value, isChanged: true })} />
          <input style={{ width: 170, ...inputStyle }} type="number" min="1" value={previousYearQuestionId} placeholder="Previous Year Qn Id" onChange={(event) => this.props.updateBriefReducer({ previousYearQuestionId: event.target.value, isChanged: true })} />
          {this.renderTagsDropDown(tagsDropdownOptions)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.setState({ showAudioModal: true }, () => briefs[selectedBriefId] && this.props.updateBriefReducer({ pollyText: briefs[selectedBriefId].polly_text }))}>
            Audio
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.setState({ showUploadImageModal: true })}>
            Add Image
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.showFormulaModal()}>
            Add Formula
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => this.showDiagramModal()}>
            Add Diagram
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth }} onClick={() => window.open(`/brief_me/browse?bmChapterId=${bmChapterId}&brief_id=${selectedBriefId}&show_versions=true`)}>
            Versions
          </div>
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{ width: buttonWidth }}
            onClick={() => {
              this.props.getAutoFormat(content);
              window && window.MathJax && window.MathJax.Hub.Queue();
              this.setState({ showAutoFormatterModal: true });
            }}
          >
            Format
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth, border: `1px solid ${StyleConstants.color.primary}` }} onClick={() => this.editContent(content)}>
            Markdown
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          {isBriefSelected && (
          <div style={{ display: 'flex' }}>
            {hasModifyBriefAccess && (
            <div
              role="presentation"
              className="Button Error-Secondary-Button"
              style={{ width: buttonWidth, marginRight: 15 }}
              onClick={() => this.props.updateBriefReducer({
                deprecated: !deprecated, selectedBriefId, toggleDeprecate: true, bmChapterId, isBriefSearched: !!selectedBrief,
              })}
            >
              {deprecated ? 'Undeprecate' : 'Deprecate'}
            </div>
            )}
            {isAdmin && (
            <div className="Button Error-Button" style={{ width: buttonWidth }} onClick={() => (isBriefSelected && this.setState({ showDeleteBriefModal: true }))}>
              Delete
            </div>
            )}
          </div>
          )}
          {isUpdating && this.showLoader({ marginRight: isBriefSelected && 40, marginLeft: !isBriefSelected && 40 })}
          {hasModifyBriefAccess && !isUpdating && (
          <div role="presentation" className="Button Primary-Button" style={{ width: buttonWidth, opacity: enableSaveButton ? 1 : 0.5, cursor: enableSaveButton ? 'pointer' : 'not-allowed' }} onClick={() => (enableSaveButton && this.onSaveButtonClick())}>
            {isBriefSelected ? 'Save' : 'Create'}
          </div>
          )}
        </div>
      </div>
    );
  }

  updateBriefContentFormula(briefId, briefContentIndex, selectedId) {
    const { updateBriefContentWithFormula } = this.props;
    updateBriefContentWithFormula(selectedId, briefId, briefContentIndex);
  }
  
  updateBriefContentDiagram(briefId, briefContentIndex, selectedId) {
    const { updateBriefContentWithDiagram } = this.props;
    updateBriefContentWithDiagram(selectedId, briefId, briefContentIndex);
  }

  render() {
    const {
      briefMe: {
        isLoading, isBriefSelected, selectedIndex, displayEditView, isAddBriefQuestion,
      },
    } = this.props;
    const {
      showUploadImageModal, showAudioModal, showAutoFormatterModal,
      showFormulaModal, showDiagramModal,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {displayEditView && (selectedIndex !== null) && !isAddBriefQuestion && this.editBrief()}
        {showUploadImageModal && this.renderUploadImageModal()}
        {showAutoFormatterModal && this.renderAutoFormatterModal()}
        {showFormulaModal && this.renderFormulaModal()}
        {showDiagramModal && this.renderDiagramModal()}
        {isBriefSelected && this.renderDeleteBriefModal()}
        {showAudioModal && this.renderAudioModal()}
      </>
    );
  }
}

EditBrief.propTypes = {
  briefMe: PropTypes.object.isRequired,
  formula: PropTypes.object.isRequired,
  diagram: PropTypes.object.isRequired,
  bmChapterId: PropTypes.number.isRequired,
  briefContentIndex: PropTypes.number.isRequired,
  briefId: PropTypes.number.isRequired,
  getAutoFormat: PropTypes.func.isRequired,
  addBrief: PropTypes.func.isRequired,
  updateBriefContentWithFormula: PropTypes.func.isRequired,
  updateBriefContentWithDiagram: PropTypes.func.isRequired,
  updateBrief: PropTypes.func.isRequired,
  deleteBrief: PropTypes.func.isRequired,
  updateBriefReducer: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  updateBriefAudio: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getAudioForText: PropTypes.func.isRequired,
};

EditBrief.defaultProps = {

};

const mapStateToProps = ({ briefMe, formula, diagram }) => ({
  briefMe,
  formula,
  diagram,
});

export default connect(mapStateToProps, {
  addBrief,
  updateBrief,
  deleteBrief,
  updateBriefReducer,
  uploadImage,
  updateBriefAudio,
  showToast,
  getAutoFormat,
  getAudioForText,
  getFormula,
  getDiagram,
  updateBriefCursorPosition,
  updateBriefContentWithFormula,
  updateBriefContentWithDiagram,
})(EditBrief);
