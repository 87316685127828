import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import StyleConstants from '../../shared/constants/styleConstants/styles';
import fileConstants from '../../shared/constants/fileConstants';
import { DropDown, Text, Loader } from '../common';
import { getDashBoardDetails, getDtpOperatorsProductivityDetails } from '../../actions';
import Layout from '../layout';
import { numberFormatterWithCommas } from '../../helpers/Utils';

class DashBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questionHealthHeadings: ['SUBJECT', 'TOTAL QUESTIONS', 'WITHOUT TOPIC TAGGING', 'WITHOUT DIFFICULTY TAGGING', 'WITHOUT PROOF READING', 'UNRESOLVED ERRORS'],
    };
  }

  componentDidMount() {
    this.props.getDashBoardDetails();
  }

  renderTable(headings, tableDatas, highlightIndex, boundaryValue, blockBoundaryValue) {
    return (
      <div>
        <table className="table table-striped table-hover" style={{ textAlign: 'center', marginBottom: 0, columnSpan: '10px' }}>
          <thead>
            <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontWeight: '800' }}>
              {headings.map((heading, columnIndex) => {
                return (
                  <th rowSpan="2" style={{ textAlign: columnIndex === 0 ? 'left' : 'right', padding: '12px', verticalAlign: 'middle' }}>
                    {' '}
                    {heading.toLocaleUpperCase()}
                    {' '}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tableData, index) => {
              const rowData = Object.keys(tableData);
              return (
                <tr style={{ fontSize: StyleConstants.textSize.subHeader, color: highlightIndex === index ? StyleConstants.textColor.primary : StyleConstants.textColor.secondary, fontWeight: highlightIndex === index ? '800' : '400' }}>
                  {
                    rowData.map((key, columnIndex) => {
                      return (
                        <td style={{ textAlign: columnIndex === 0 ? 'left' : 'right', padding: '12px', color: highlightIndex === index ? StyleConstants.textColor.primary : !blockBoundaryValue ? tableData[key] < boundaryValue ? StyleConstants.color.error : StyleConstants.textColor.secondary : StyleConstants.textColor.secondary }}>{numberFormatterWithCommas(tableData[key])}</td>);
                    })
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {
      courses, isLoading, dtpOpertorProductivity, durationFilterSelected, durationFilters, dtpOperatorsBoundayValue, courseSelected, questionHealthData,
    } = this.props;
    const { questionHealthHeadings } = this.state;
    const filterDuration = durationFilters[durationFilterSelected].name;
    return (
      <Layout headerText={fileConstants.contentsMenuOrder.DASHBOARD.label} currentPage={fileConstants.contentsMenuOrder.DASHBOARD.position}>
        {isLoading ? <Loader />
          : (
            <div
              className="Simple-Card-View"
              style={{
                display: 'flex', justifyContent: 'space-between', paddingTop: 10, flexDirection: 'column',
              }}
            >
              <div className="Card-View" style={{ margin: 20, padding: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                  <Text text="Question Health" color={StyleConstants.color.mediumDark} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.secondaryHeader} />
                  <DropDown
                    width={150}
                    optionType="Courses"
                    selectedItem={courseSelected}
                    defaultText="Course"
                    onValueChange={(id, name) => {
                      this.props.getDashBoardDetails({ id: id || '', name: name || '' });
                    }}
                    optionsList={courses}
                  />
                </div>
                {this.renderTable(questionHealthHeadings, questionHealthData, questionHealthData.length - 1, 10000, true)}
              </div>

              <div className="Card-View" style={{ margin: 20, padding: 30 }}>
                <div style={{
                  display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 30,
                }}
                >
                  <Text text="DTP Operator Productivity" color={StyleConstants.color.mediumDark} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.secondaryHeader} />
                  <DropDown
                    width={150}
                    optionType="Duration"
                    selectedItem={durationFilters[durationFilterSelected]}
                    onValueChange={(id) => { this.props.getDtpOperatorsProductivityDetails(id || 0); }}
                    optionsList={durationFilters}
                  />
                </div>
                {Object.keys(dtpOpertorProductivity).length > 0 && dtpOpertorProductivity[filterDuration] && this.renderTable(dtpOpertorProductivity[filterDuration].headings, dtpOpertorProductivity[filterDuration].datas, questionHealthData, questionHealthData.length - 1, dtpOpertorProductivity[filterDuration].datas.length - 1, dtpOperatorsBoundayValue[filterDuration], false)}
              </div>
            </div>
          )}
      </Layout>
    );
  }
}

DashBoard.propTypes = {
};

DashBoard.defaultProps = {
  dtpOpertorProductivity: {},
};

const mapStateToProps = ({ home }) => ({
  courses: home.courses,
  courseSelected: home.courseSelected,
  isLoading: home.isLoading,
  questionHealthData: home.questionHealthData,
  durationFilters: home.durationFilters,
  durationFilterSelected: home.durationFilterSelected,
  dtpOpertorProductivity: home.dtpOpertorProductivity,
  dtpOperatorsBoundayValue: home.dtpOperatorsBoundayValue,
});

export default connect(mapStateToProps, { getDashBoardDetails, getDtpOperatorsProductivityDetails })(DashBoard);
