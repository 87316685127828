import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from './baseModal';
import uploadIssuesImage from '../../shared/images/common/upload_issues.svg';
import info from '../../shared/images/common/info.svg';

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReportErrorModal: false,
      imageFile: '',
      imageUploaded: false,
      displayImage: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadImageFile = this.uploadImageFile.bind(this);
  }

  uploadImageFile(event) {
    const { option, getImage } = this.props;
    const { imageUploaded, displayImage } = this.state;
    this.setState({
      imageUploaded: !imageUploaded,
      imageFile: imageUploaded ? '' : event.target.files[0],
      displayImage: !displayImage,
    },
    () => {
      if (this.state.imageFile) {
        getImage(this.state.imageFile, option);
      } else {
        getImage('', option);
      }
    });
  }

  toggleModal() {
    const { showReportErrorModal } = this.state;
    this.setState({
      showReportErrorModal: !showReportErrorModal,
    });
  }

  handleCloseModal() {
    this.setState({ showReportErrorModal: false });
  }

  uploadImage() {
    const { clear } = this.props;
    const { displayImage, imageFile } = this.state;
    if (displayImage && !clear) {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {imageFile
              && (
                <div style={{
                  display: 'inline-block', height: 'auto', width: 'fit-content', textAlign: 'center',
                }}
                >
                  <img
                    alt={imageFile}
                    src={URL.createObjectURL(imageFile)}
                    style={{
                      height: '150px', padding: '5px', marginLeft: '0', width: 'auto', maxWidth: 480,
                    }}
                  />
                  <div
                    className="Button Secondary-Button"
                    role="presentation"
                    style={{ margin: '20px 30%' }}
                    onClick={this.uploadImageFile}
                  >
                    Clear
                  </div>
                </div>
              )}
          </div>
          <div role="presentation" className="Button Primary-Button" onClick={this.toggleModal}>Done</div>
        </>
      );
    }
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', margin: '0px 20px',
      }}
      >
        <div style={{
          borderRadius: '5px', height: '220px', textAlign: 'center', border: '1px solid #B1B1B1', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', textAlign: 'center',
        }}
        >
          <input
            style={{
              position: 'relative', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', cursor: 'pointer',
            }}
            type="file"
            accept="image/*"
            onChange={this.uploadImageFile}
          />
          <img
            src={uploadIssuesImage}
            alt=""
            style={{
              height: '26px', position: 'absolute', margin: '13% 43%', textAlign: 'center', width: '26px', opacity: 1, textAlign: 'center',
            }}
          />
          <p style={{
            color: '#898989', fontSize: '14', margin: '20% 22%', position: 'absolute', textAlign: 'center',
          }}
          >
            Drag or browse the Diagram file to upload
          </p>
        </div>
        <div style={{ display: 'flex', justifyItems: 'left' }}>
          <img
            src={info}
            alt=""
            style={{
              marginTop: '2%', marginBottom: '4%', height: '14px', width: '14px', opacity: 1,
            }}
          />
          <div style={{ fontSize: '12', color: '#B1B1B1', padding: '8px' }}>Upload only .Jpg or .Png files</div>
        </div>
      </div>
    );
  }

  render() {
    const { header, width } = this.props;
    const { showReportErrorModal } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <div
          role="presentation"
          className="Button Secondary-Button"
          style={{ width }}
          onClick={this.toggleModal}
        >
          {header}

        </div>
        <Modal center isModalVisible={showReportErrorModal} handleCloseModal={this.handleCloseModal} header={this.props.header} height="360px" width="536px" headerHeight="55px" headerMargin="0px 20px">
          <div>
            {this.uploadImage()}
          </div>
        </Modal>
      </div>
    );
  }
}

ImageUploader.propTypes = {
  getImage: PropTypes.func.isRequired,
  option: PropTypes.string,
  header: PropTypes.string,
  width: PropTypes.number,
  clear: PropTypes.bool,
};

ImageUploader.defaultProps = {
  option: '',
  header: '',
  width: '',
  clear: false,
};
export default connect()(ImageUploader);
