import {
  UPDATE_FORMULA, RESET_FORMULA,
} from '../actions';

const initialState = {
  appliedFilters: {
    subject: {},
    chapter: {},
    briefOperator: {},
    briefHealth: null,
    briefHealthSecondary: null,
  },
  subjects: [],
  formulas: [],
  selectedIndex: 0,
  selectedFormulaId: null,
  isFormulaSelected: false,
  name: '',
  equation: '',
  explanation: '',
  derivation: '',
  isFormulaSearch: false,
  displayEditView: false,
  isChanged: false,
  bmSubjectId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORMULA:
      return { ...state, ...action.payload };
    case RESET_FORMULA:
      const resetItem = {
        selectedIndex: 0,
        selectedFormulaId: null,
        isFormulaSelected: false,
        name: '',
        equation: '',
        explanation: '',
        derivation: '',
      };
      return { ...state, ...resetItem, ...action.payload };
    default:
      return state;
  }
}
