import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  BriefMeTabs, Loader, Text, BriefView,
} from '../common/index';
import {
  getBrief, updateBriefReducer,
} from '../../actions';

const history = createBrowserHistory({
  forceRefresh: true,
});

class BriefFindById extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
  }

  componentDidMount() {
    const { brief_id: briefId } = queryString.parse(window.location.search);
    if (briefId) {
      this.props.getBrief(briefId);
    }
  }

  renderTitle(text, underline = false) {
    return <Text style={{ textDecoration: underline && 'underline' }} fontSize={StyleConstants.textSize.title} color={StyleConstants.color.dark} text={text} />;
  }

  renderInputBox() {
    const { searchText } = this.state;
    const inputStyle = { backgroundColor: '#FFF', height: 40 };
    return (
      <>
        <style jsx>
          {`
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type=number] {
              -moz-appearance: textfield;
            }
          `}
        </style>
        <input
          style={inputStyle}
          type="number"
          value={searchText}
          placeholder="Find By Brief ID"
          onChange={(event) => this.setState({ searchText: event.target.value })}
        />
      </>
    );
  }

  render() {
    const {
      briefMe: {
        hasCreateSubjectAccess, isLoading, selectedBrief,
      },
    } = this.props;
    const { searchText } = this.state;
    const { brief_id: briefId } = queryString.parse(window.location.search);
    const enableSearchButton = searchText.trim().length > 0;

    if (isLoading) {
      return <Loader />;
    }

    if (briefId && Object.keys(selectedBrief).length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
          {this.renderTitle(`Brief is not present with ID = ${briefId}`)}
        </div>
      );
    }

    if (briefId) {
      return <BriefView selectedType="Find" />;
    }

    return (
      <>
        {hasCreateSubjectAccess && <BriefMeTabs selectedType="Find" />}
        <div style={{ display: 'flex', width: 350, justifyContent: 'space-between' }}>
          <div style={{ margin: '0px 40px', height: 40 }}>{this.renderInputBox(true, true)}</div>
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{
              width: 100, height: 40, opacity: enableSearchButton ? 1 : 0.5, cursor: enableSearchButton ? 'pointer' : 'not-allowed',
            }}
            onClick={enableSearchButton ? () => history.push(`/brief_me/find?brief_id=${searchText}`) : () => {}}
          >
            Search
          </div>
        </div>
      </>
    );
  }
}

BriefFindById.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasCreateSubjectAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ briefMe }) => ({
  briefMe,
});

export default connect(mapStateToProps, {
  getBrief, updateBriefReducer,
})(BriefFindById);
