import { combineReducers } from 'redux';
import GlobalReducer from './globalReducer';
import HomeReducer from './homeReducer';
import SearchReducer from './searchReducer';
import QuestionReducer from './questionReducer';
import SnackBarReducer from './snackBarReducer';
import CheatsheetListReducer from './cheatsheetListReducer';
import BriefMeReducer from './briefMeReducer';
import BriefsDictionaryReducer from './briefsDictionaryReducer';
import FormulaReducer from './formulaReducer';
import DiagramReducer from './diagramReducer';
import QuestionTaggingReducer from './questionTaggingReducer';

export default combineReducers({
  global: GlobalReducer,
  home: HomeReducer,
  search: SearchReducer,
  question: QuestionReducer,
  snackBar: SnackBarReducer,
  cheatsheetList: CheatsheetListReducer,
  briefMe: BriefMeReducer,
  briefsDictionary: BriefsDictionaryReducer,
  formula: FormulaReducer,
  diagram: DiagramReducer,
  questionTagging: QuestionTaggingReducer,
});
