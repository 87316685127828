module.exports = {
api: {
  url: `https://api.${process.env.REACT_APP_DOMAIN}`,
  ws_url: `wss://api.${process.env.REACT_APP_DOMAIN}/cable`,
  responseType: 'json',
  timeout: 40000,
  withCredentials: true,
},
cookie: {
  secure: true,
  subDomain: `content.${process.env.REACT_APP_DOMAIN}`,
  domainRegex: `.${process.env.REACT_APP_DOMAIN}`,
  accountsDomain: `accounts.${process.env.REACT_APP_DOMAIN}`,
  homeDomain: `www.${process.env.REACT_APP_DOMAIN}`,
  qbank: `qbank.${process.env.REACT_APP_DOMAIN}`,
},
"contentEngineAppVersionCode": "1.0.0",
"contact": "+91-63610 10101",
"email":"support@ideaboard.xyz",
"companyUrl": "www.ideaboard.xyz",
"maxPageContentWidth": 800,
"toastMessages": {
    "networkError": "Network Error,please try again later!",
    "thankYouResponse": "Thank you for your interest, we will get back to you soon!"
  }
}

