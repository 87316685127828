import {
  GET_LIST, CHECKPOINT_CHAPTER_LIST, UPDATE_QUICKPOINT_FILTER_DATA, GET_QUICKPOINT_FILTER_DATA, ADD_QUICKPOINTS, UPDATE_QUICKPOINTS, DELETE_QUICKPOINTS, ORDER_QUICKPOINTS,
} from '../actions';

const initialState = {
  quickpoints: [],
  appliedFilters: {
    course: {},
    subject: {},
    chapter: {},
    checkpoints: [],
  },
  courses: [],
  subjects: {},
  chapters: {},
  checkpoints: [],
  selectedChapter: '',
  selectedIndex: '',
  enableFilterSearch: false,
  isQuickpointSelected: false,
  isLoading: false,
  configuration: {},
  isSuccessfullyOrdered: true,
};

function addQuickpoint(oldQuickpoints, response) {
  oldQuickpoints.splice(response.selectedIndex, 0, response.data);
  return { quickpoints: oldQuickpoints };
}

function updateQuickpointData(oldQuickpoints, response) {
  const quickpointIndex = oldQuickpoints.findIndex(({ id, name }) => { return id === response.data.id; });
  oldQuickpoints[quickpointIndex] = response.data;
  return { quickpoints: oldQuickpoints };
}

function deprecateQuickpoint(oldQuickpoints, response) {
  return { quickpoints: oldQuickpoints.filter((quickpoint) => { return quickpoint.id !== response.id; }) };
}

function orderQuickpoints(oldQuickpoints, response) {
  const { fromIndex, toIndex } = response;
  if (toIndex === oldQuickpoints.length) {
    oldQuickpoints.push(oldQuickpoints[fromIndex]);
    oldQuickpoints.splice(fromIndex, 1);
  } else {
    oldQuickpoints[fromIndex] = oldQuickpoints.splice(toIndex, 1, oldQuickpoints[fromIndex])[0];
  }
  return { quickpoints: oldQuickpoints };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST: {
      return { ...state, ...action.payload };
    }
    case CHECKPOINT_CHAPTER_LIST: {
      return { ...state, ...action.payload };
    }
    case UPDATE_QUICKPOINT_FILTER_DATA: {
      return { ...state, ...action.payload };
    }
    case GET_QUICKPOINT_FILTER_DATA: {
      return { ...state, ...action.payload };
    }
    case ADD_QUICKPOINTS: {
      return { ...state, ...addQuickpoint(state.quickpoints, action.payload), ...action.payload };
    }
    case UPDATE_QUICKPOINTS: {
      return { ...state, ...updateQuickpointData(state.quickpoints, action.payload), ...action.payload };
    }
    case DELETE_QUICKPOINTS: {
      return { ...state, ...deprecateQuickpoint(state.quickpoints, action.payload), ...action.payload };
    }
    case ORDER_QUICKPOINTS: {
      return { ...state, ...orderQuickpoints(state.quickpoints, action.payload), ...action.payload };
    }
    default:
      return state;
  }
}
