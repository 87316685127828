import React, { PureComponent } from 'react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  forceRefresh: true,
});

const BRIEFME_TYPES = {
  dashboard: 'Dashboard',
  browse: 'Browse',
  permissions: 'Permissions',
  find: 'Find',
  findAndReplace: 'Find And Replace',
  subscriptConverter: 'Subscript Converter',
  dictionary: 'Dictionary',
  formula: 'Formula',
  diagram: 'Diagram',
  questionTagging: 'Question Tagging',
};

class BriefMeTabs extends PureComponent {
  render() {
    const { selectedType } = this.props;

    return (
      <div style={{ display: 'flex', margin: '20px 40px' }}>
        {Object.keys(BRIEFME_TYPES).map((type, index) => {
          const selected = selectedType === BRIEFME_TYPES[type];
          return (
            <div
              role="presentation"
              key={`${type}`}
              className={selected ? 'Button Primary-Button' : 'Button Secondary-Button'}
              style={{
                borderRadius: 0, borderLeft: (index !== 0) && 0, display: 'flex', padding: '0px 15px',
              }}
              onClick={() => history.push(`/brief_me/${type}`)}
            >
              {BRIEFME_TYPES[type]}
            </div>
          );
        })}
      </div>
    );
  }
}

export default BriefMeTabs;
