import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './common/baseModal';
import { sendErrorReported, showToast } from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { REPORT_ISSUE_TYPES } from '../shared/constants/fieldTypes'

class ReportError extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showThanksMessage: false,
      active: 0,
      showReportErrorModal: false,
      questionId: props.questionId ? props.questionId : '',
      value: '',
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.reportError = this.reportError.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  toggleModal() {
    const { questionId } = this.props;
      this.setState({
        showReportErrorModal: !this.state.showReportErrorModal,
        questionId,
      });
  }

  handleActive (activeIndex) {
    this.setState({ active : activeIndex === this.state.active ? 0 : activeIndex});
  }

  handleCommentChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  reportError() {
      if (this.state.active === 0 || (this.state.active === 4 && !this.state.value )) {
        if (this.state.active === 0) {
          this.props.dispatch(showToast("Atleast one option should be selected."));
        }
        this.props.dispatch(showToast("For 'Any other issues' kindly specify the problem so that we can fix it at the earliest."));
      }
      else{
      this.setState({ showReportErrorModal: false, value: '' });
      this.props.dispatch(sendErrorReported(this.state.questionId, this.state.active, this.state.value)).then((result)=> {
        if(result.success){
          this.handleCloseModal()
          setTimeout(() => {
            this.setState({ showThanksMessage: true });
          }, 100);
        }
      });
    }
  }

  handleCloseModal() {
    this.setState({ showReportErrorModal: false, showThanksMessage: false });
  }

  showThanksMessage() {
    return (
      <div>
        <p style={{margin:"5%", fontSize:"18px", fontFamily:"Raleway", color:"#535353"}}>
          Thank you for submitting the report. Issue will be rectified soon.
        </p>
        <div style={{textAlign:"center"}}>
          <div className="btn mb-5" style={{ backgroundColor: "#20CB90", color: "white", fontFamily:"Raleway", fontWeight: "bold", fontSize:"18px"}} onClick={this.handleCloseModal}>Close</div>
        </div>
      </div>
    );
  }

  renderReportError() {
    return (
      <div>
        <ul className="list-group cursor" style={{ margin: "2%" }}>
          {Object.keys(REPORT_ISSUE_TYPES).map(reportIssueKey => <li id="listItem" className='list-group-item mb-2' style={{ backgroundColor: this.state.active === reportIssueKey && StyleConstants.color.primary, color: this.state.active === reportIssueKey && 'white', cursor: 'pointer' }} onClick={() => this.handleActive(reportIssueKey)}>{REPORT_ISSUE_TYPES[reportIssueKey]}</li>)}
        </ul>
        <div className="col">
          <textarea rows="8" style={{ height: "50%", margin: '2%' }} cols="43" value={this.state.value} onChange={this.handleCommentChange} placeholder="Please share your valuable comments here..."></textarea>
        </div>
        <div className="d-flex flex-column">
          <div className="p-2 ml-2" style={{color:"#dddddd", textAlign:"left", marginTop:"-2%" }}>Question ID: {this.state.questionId}</div>
          {<div className="p-2 btn btn-lg" style={{ backgroundColor: StyleConstants.color.primary, color:"white", fontSize:"1vw", margin: "2%"}} onClick={() => this.reportError()}>Submit</div>}
        </div>
      </div>
    );
  }

  render() {
    const buttonWidth = 100;
    const {  showReportErrorModal, showThanksMessage } = this.state;
    return (
      <div>
        <Modal isModalVisible={showReportErrorModal} handleCloseModal={this.handleCloseModal} header={"Report Error"}>
          {this.renderReportError()}
        </Modal>

        <Modal isModalVisible={showThanksMessage} handleCloseModal={this.handleCloseModal} header={"Error Reported Successfully"}>
          {this.showThanksMessage()}
        </Modal>
        <div className="Button Error-Secondary-Button" style={{ width: buttonWidth }} onClick={() => this.toggleModal()}>Report Error</div>
      </div>
    );
  }
}

export default connect()(ReportError);
