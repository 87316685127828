import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import { showToast } from './snackBar';
import { updateCursorStatus } from './index';
import { toastMessages } from '../shared/constants/fieldTypes';

export const GET_BRIEFS = 'BriefMe/GET_BRIEFS';
export const ADD_BRIEFS = 'BriefMe/ADD_BRIEFS';
export const DELETE_DETAILS = 'BriefMe/DELETE_DETAILS';
export const UPDATE_BRIEFS = 'BriefMe/UPDATE_BRIEFS';
export const ORDER_BRIEFS = 'BriefMe/ORDER_BRIEFS';
export const DELETE_BRIEF = 'BriefMe/DELETE_BRIEF';
export const REPLACE_BRIEF_TEXT = 'BriefMe/REPLACE_BRIEF_TEXT';
export const TOGGLE_DEPRECATE = 'BriefMe/TOGGLE_DEPRECATE';
export const TOGGLE_CHAPTER_STATUS = 'BriefMe/TOGGLE_CHAPTER_STATUS';
export const TOGGLE_SUBJECT_STATUS = 'BriefMe/TOGGLE_SUBJECT_STATUS';
export const UPDATE_BRIEF_FILTER_DATA = 'BriefMe/UPDATE_BRIEF_FILTER_DATA';
export const ADD_DETAILS = 'BriefMe/ADD_DETAILS';
export const EDIT_DETAILS = 'BriefMe/EDIT_DETAILS';
export const UPDATE_AUDIO_DETAILS = 'BriefMe/UPDATE_AUDIO_DETAILS';
export const UPDATE_AUDIO_URL = 'BriefMe/UPDATE_AUDIO_URL';
export const UPDATE_BRIEF_ME_HEALTH_FILTER = 'BriefMe/UPDATE_BRIEF_ME_HEALTH_FILTER';
export const UPDATE_BRIEF_ME_FILTER_LIST = 'BriefMe/UPDATE_BRIEF_DASHBOARD_FILTER';
export const UPDATE_BRIEFS_OPERATOR_PRODUCTIVITY_FILTER = 'BriefMe/UPDATE_BRIEFS_OPERATOR_PRODUCTIVITY_FILTER';
export const UPDATE_FILTER_TAGS = 'BriefMe/UPDATE_FILTER_TAGS';
export const UPDATE_ACTIVE_FILTER = 'BriefMe/UPDATE_ACTIVE_FILTER';
export const APPEND_NEW_QUESTION = 'BriefMe/APPEND_NEW_QUESTION';
export const REMOVE_QUESTIONS = 'BriefMe/REMOVE_QUESTIONS';
export const CLEAR_IMAGE = 'BriefMe/CLEAR_IMAGE';

export const MAIN_FILTER_TYPES = {
  OVERALL: 1,
  COURSE: 2,
  SUBJECT: 3,
};

const history = createBrowserHistory({
  forceRefresh: true,
});

const updateBriefMeHealthDashboardFilter = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_BRIEF_ME_HEALTH_FILTER,
    payload,
  });
};

export const updateActiveDashboardFilter = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_FILTER,
    payload,
  });
};

const updateBriefMeOperatorsProductivityFilter = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_BRIEFS_OPERATOR_PRODUCTIVITY_FILTER,
    payload,
  });
};

export const secondaryCourseFilterData = (briefsHealthData, activeIndex, selectedIssueId = null) => async (dispatch, getState) => {
  dispatch(updateActiveDashboardFilter({ briefHealthSecondary: activeIndex }));
  const { briefMe: { courseNameForIssueTypeCheck } } = getState();
  const overAllBriefsHealthData = briefsHealthData.overall;
  const selectedCourse = overAllBriefsHealthData.courses_present[activeIndex];
  const briefHealthHeadings = ['Subject Name', 'Semester', 'Total Chapters', 'Total Briefs'];
  const boundaryValues = [-1, -1, 50, 200];
  const formattedData = [];
  const selectedCourseDetails = selectedIssueId && selectedCourse === courseNameForIssueTypeCheck ? overAllBriefsHealthData[selectedCourse][selectedIssueId] : overAllBriefsHealthData[selectedCourse];

  selectedCourseDetails.subjects_present.forEach((subjectName) => {
    const course = {};
    course.subjectName = subjectName;
    course.semester = selectedCourseDetails[subjectName].semester_name;
    course.totalChapters = selectedCourseDetails[subjectName].total_chapters;
    course.totalBriefs = selectedCourseDetails[subjectName].total_briefs;
    formattedData.push(course);
  });
  formattedData.push({
    subjectName: 'Total', activeSemesters: `${selectedCourseDetails.active_semesters} active`, noOfChapters: selectedCourseDetails.total_chapters, noOfBriefs: selectedCourseDetails.total_briefs,
  });
  dispatch({
    type: UPDATE_BRIEF_FILTER_DATA,
    payload: { formattedBriefsHealthData: formattedData, briefMeHealth: { headings: briefHealthHeadings, boundaryValues } },
  });
};

export const secondarySubjectFilterData = (briefsHealthData, activeIndex) => async (dispatch) => {
  dispatch(updateActiveDashboardFilter({ briefHealthSecondary: activeIndex }));
  const overAllBriefsHealthData = briefsHealthData.overall;
  const briefHealthHeadings = ['Chapter Name', 'Course', 'Semester', 'Total Briefs'];
  const boundaryValues = [-1, -1, -1, 20];
  const formattedData = [];
  const activeSubject = overAllBriefsHealthData.subjects_present[activeIndex];
  const chaptersPresent = Object.keys(briefsHealthData.subject[activeSubject]);
  const courses = [];
  let totalBriefs = 0;
  chaptersPresent.forEach((chapterName) => {
    const subject = {};
    subject.chapterName = chapterName;
    subject.courseName = briefsHealthData.subject[activeSubject][chapterName].course_name;
    subject.semester = briefsHealthData.subject[activeSubject][chapterName].semester_name;
    if (!courses.includes(briefsHealthData.subject[activeSubject][chapterName].course_name)) {
      courses.push(briefsHealthData.subject[activeSubject][chapterName].course_name);
    }
    subject.totalBriefs = briefsHealthData.subject[activeSubject][chapterName].total_briefs;
    totalBriefs += briefsHealthData.subject[activeSubject][chapterName].total_briefs;
    formattedData.push(subject);
  });
  formattedData.push({
    subjectName: `${Object.keys(formattedData).length} chapters`, courseName: `${courses.length} courses`, semester: '-', noOfBriefs: totalBriefs,
  });
  dispatch({
    type: UPDATE_BRIEF_FILTER_DATA,
    payload: { formattedBriefsHealthData: formattedData, briefMeHealth: { headings: briefHealthHeadings, boundaryValues } },
  });
};

export const formatBriefHealth = (filterType, briefsHealthData) => async (dispatch) => {
  const formattedData = [];
  let briefHealthHeadings = [];
  let boundaryValues = [];
  const briefHealthSecondary = [];
  const overAllBriefsHealthData = briefsHealthData.overall;
  if (filterType === MAIN_FILTER_TYPES.OVERALL) {
    dispatch(updateBriefMeHealthDashboardFilter(0));
    briefHealthHeadings = ['Course Name', 'Active Semesters', 'Subjects', 'Total Chapters', 'Total Briefs'];
    boundaryValues = [-1, -1, 10, 50, 200];
    overAllBriefsHealthData.courses_present.forEach((courseName) => {
      if (overAllBriefsHealthData[courseName]) {
        const courseMetaData = {};
        courseMetaData.courseName = courseName;
        courseMetaData.activeSemesters = overAllBriefsHealthData[courseName].active_semesters;
        courseMetaData.activeSubjects = overAllBriefsHealthData[courseName].subjects_present.length;
        courseMetaData.noOfChapters = overAllBriefsHealthData[courseName].total_chapters;
        courseMetaData.noOfBriefs = overAllBriefsHealthData[courseName].total_briefs;
        formattedData.push(courseMetaData);
      }
    });
    formattedData.push({
      subjectName: 'Total', activeSemesters: overAllBriefsHealthData.total_semesters, activeSubjects: `${overAllBriefsHealthData.subjects_present.length} unique`, noOfChapters: overAllBriefsHealthData.total_chapters, noOfBriefs: overAllBriefsHealthData.total_briefs,
    });
    dispatch({ type: UPDATE_FILTER_TAGS, payload: { briefHealthSecondary: [] } });
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { formattedBriefsHealthData: formattedData, briefMeHealth: { headings: briefHealthHeadings, boundaryValues } } });
  } else if (filterType === MAIN_FILTER_TYPES.COURSE) {
    dispatch(updateBriefMeHealthDashboardFilter(1));
    overAllBriefsHealthData.courses_present.forEach((courseName, index) => {
      briefHealthSecondary.push({ id: index + 1, name: courseName });
    });
    dispatch({
      type: UPDATE_FILTER_TAGS,
      payload: { briefHealthSecondary },
    });
    dispatch(secondaryCourseFilterData(briefsHealthData, 0));
    updateActiveDashboardFilter({ briefHealthSecondary: 0 });
  } else if (filterType === MAIN_FILTER_TYPES.SUBJECT) {
    dispatch(updateBriefMeHealthDashboardFilter(2));
    overAllBriefsHealthData.subjects_present.forEach((subjectName, index) => {
      briefHealthSecondary.push({ id: index + 1, name: subjectName });
    });
    dispatch({
      type: UPDATE_FILTER_TAGS,
      payload: { briefHealthSecondary },
    });
    dispatch(secondarySubjectFilterData(briefsHealthData, 0));
    updateActiveDashboardFilter({ briefHealthSecondary: 0 });
  }
  return true;
};

export const getBriefFilterData = (chapterID = 0) => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: true } });
  let urlParams = '?get_memberships_with_brief_permission=true';
  if (chapterID > 0) {
    urlParams += `&bm_chapter_id=${chapterID}`;
  }
  const response = await Api({
    method: 'get',
    url: `/briefs/filter_data${urlParams}`,
  });
  const payload = {};
  if (response.success) {
    payload.briefTags = response.brief_tags;
    payload.rating = response.rating;
    payload.isAdmin = response.is_admin;
    payload.subjects = response.subjects;
    payload.chapters = response.chapters;
    payload.hasCreateSubjectAccess = response.has_create_bm_subject_access;
    payload.hasModifyBriefAccess = response.has_modify_brief_access;
    payload.selectedChapterStatus = response.overall_details ? response.overall_details.selectedBmChapter.status : '';
    payload.membershipsWithPermission = response.memberships_with_brief_permission.map(({
      membership_id, name, permission, subject_ids,
    }) => {
      return {
        membershipID: membership_id, name, permission, subjectIDs: subject_ids,
      };
    });
    if (response.overall_details) {
      payload.overallDetails = response.overall_details;
      payload.changedChapterName = response.overall_details ? response.overall_details.selectedBmChapter.name : '';
    }
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  return response;
};

export const getBriefMeCourseSemesterDetails = () => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: true } });
  const response = await Api({
    method: 'get',
    url: '/briefs/get_courses_and_semesters',
  });
  let payload = {};
  if (response.success) {
    payload = {
      bmCourses: response.courses ? Object.values(response.courses) : [],
      semesters: response.semesters ? response.semesters : {},
    };
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  dispatch(getBriefFilterData());
};

export const getBriefMeSemesterSubjectList = (semesterId) => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: true } });
  const response = await Api({
    method: 'get',
    url: `/briefs/get_semester_bm_subjects?semester_id=${semesterId}`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      overallDetails: response.overall_details,
      bmSubjects: response.semester_bm_subject_distribution,
    };
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  dispatch(getBriefFilterData());
};

export const getBriefMeSubjectChapterList = (bmSubjectId) => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: true } });
  const response = await Api({
    method: 'get',
    url: `/briefs/get_bm_subject_chapters?bm_subject_id=${bmSubjectId}`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      overallDetails: response.overall_details,
      changedSubjectName: response.overall_details ? response.overall_details.selectedBmSubject.name : '',
      selectedSubjectStatus: response.overall_details ? response.overall_details.selectedBmSubject.status : '',
      bmChapters: response.bm_chapter_briefs_distribution,
    };
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  dispatch(getBriefFilterData());
  return response;
};

export const parentChildrenSeperations = (response) => async (dispatch) => {
  let parentIndex = 0;
  const payload = {};
  payload.parentIds = [];
  payload.briefOrderWithoutChildren = [];
  payload.briefOrderWithIndex = [];
  response.brief_order.map((briefId, index) => {
    if (!response.briefs[briefId].parent_id) {
      parentIndex += 1;
      payload.briefOrderWithIndex.push({
        id: index, name: `${parentIndex}.${response.briefs[briefId].title}`,
      });
      payload.briefOrderWithoutChildren.push(briefId);
    } else {
      payload.parentIds.push(response.briefs[briefId].parent_id);
    }
  });
  dispatch({
    type: GET_BRIEFS,
    payload,
  });
};

export const getBriefs = (chapterID, getInconsistentBriefs, issueType = '') => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: true } });
  const response = await Api({
    method: 'get',
    url: `/briefs/get_briefs_of_chapter?bm_chapter_id=${chapterID}&include_deprecated_briefs=true&get_inconsistent_briefs=${getInconsistentBriefs}&issue_type=${issueType}`,
  });
  const payload = {};
  if (response.success) {
    dispatch(parentChildrenSeperations(response));
    payload.briefs = response.briefs;
    payload.briefOrder = response.brief_order;
    dispatch({
      type: GET_BRIEFS,
      payload,
    });
  } else {
    dispatch(showToast(response.message));
  }
  payload.isLoading = false;
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  dispatch(getBriefFilterData(chapterID));
};

export const getBriefMeDashBoardDetails = (type, freshLoad) => async (dispatch, getState) => {
  const filterType = type.id;
  const { briefMe: { briefsHealthData } } = getState();
  if (freshLoad && !Object.keys(briefsHealthData).length) {
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: [{ isLoading: true }] });
  } else {
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    dispatch(updateBriefMeHealthDashboardFilter(0));
  }
  const response = await Api({
    method: 'get',
    url: '/briefs/brief_me_dashboard',
  });
  if (response.success) {
    dispatch(formatBriefHealth(filterType, response.briefs_health));
    const payload = {
      briefsHealthData: response.briefs_health,
      briefsOperatorProductivityData: response.briefs_operators_productivity,
      courseNameForIssueTypeCheck: response.course_name_for_issues_check,
      feedback: response.feedback,
    };
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  }
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isLoading: false } });
  dispatch(updateCursorStatus({ pointerType: 'default' }));
};

export const setBriefsOperatorsProductivityDetails = (type) => async (dispatch) => {
  dispatch(updateCursorStatus({ pointerType: 'progress' }));
  dispatch(updateBriefMeOperatorsProductivityFilter(type));
  dispatch(updateCursorStatus({ pointerType: 'default' }));
};

export function addSubject(name, semesterId) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/create_bm_subject',
      data: {
        name,
        semester_id: semesterId,
      },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: ADD_DETAILS,
          response,
        });
        dispatch(getBriefMeSemesterSubjectList(semesterId));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function addChapter(name, subjectID) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/create_bm_chapter',
      data: {
        name,
        bm_subject_id: subjectID,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        dispatch({
          type: ADD_DETAILS,
          payload,
        });
        dispatch(getBriefMeSubjectChapterList(subjectID));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function editSubjectName(name, subjectID) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/edit_bm_subject_name',
      data: {
        name,
        bm_subject_id: subjectID,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.name = name;
        payload.type = 'Subject';
        dispatch({
          type: EDIT_DETAILS,
          payload,
        });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function editChapterName(name, chapterID) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/edit_bm_chapter_name',
      data: {
        name,
        bm_chapter_id: chapterID,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        payload = response;
        payload.name = name;
        payload.type = 'Chapter';
        dispatch({
          type: EDIT_DETAILS,
          payload,
        });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function deleteChapter(chapterID, subjectID) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${chapterID}/delete_bm_chapter`,
    }).then((response) => {
      let payload = {};
      payload = response;
      payload.selectedChapterToDelete = { id: '', name: '' };
      if (response.success) {
        dispatch({
          type: DELETE_DETAILS,
          payload,
        });
        history.push(`/brief_me/browse?bmSubjectId=${subjectID}`);
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function getAudioForText(id, pollyText, bmChapterId, index) {
  return (dispatch) => {
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isUpdating: true } });
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    const data = {
      id,
      text: pollyText,
      chapter_id: bmChapterId,
      index,
    };
    return Api({
      method: 'post',
      url: '/briefs/get_audio_for_text',
      data,
    }).then((response) => {
      const payload = response;
      if (response.success) {
        dispatch({
          type: UPDATE_AUDIO_URL,
          payload,
        });
      }
      payload.isUpdating = false;
      dispatch(showToast(response.message));
      dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function updateBriefAudio(id, pollyText) {
  return (dispatch) => {
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isUpdating: true } });
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    const data = {
      polly_text: pollyText,
    };
    return Api({
      method: 'post',
      url: `/briefs/${id}/update_brief_audio`,
      data,
    }).then((response) => {
      const payload = response;
      payload.id = id;
      if (response.success) {
        dispatch({
          type: UPDATE_AUDIO_DETAILS,
          payload,
        });
      }
      payload.isUpdating = false;
      dispatch(showToast(response.message));
      dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function addBrief(title, content, timeToRead, pollyText, selectedIndex, briefOrder, chapterID, previousYearQuestionId, parentId, isPollyTextUpdated = false, selectedTags = []) {
  return (dispatch) => {
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isUpdating: true } });
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: '/briefs',
      data: {
        title,
        content,
        time_to_read: timeToRead,
        selected_index: selectedIndex,
        bm_chapter_id: chapterID,
        include_deprecated_briefs: true,
        brief_tags: JSON.stringify(selectedTags),
        previous_year_question_id: previousYearQuestionId,
        parent_id: parentId,
      },
    }).then((response) => {
      let payload = {};
      if (response.success) {
        dispatch(parentChildrenSeperations(response));
        payload = response;
        if (!isPollyTextUpdated) {
          payload.title = '';
          payload.content = '';
          payload.timeToRead = '';
          payload.selectedIndex = selectedIndex + 1;
          payload.isBriefSelected = false;
        }
        dispatch({
          type: ADD_BRIEFS,
          payload,
        });
        payload.isChanged = false;
      }
      if (isPollyTextUpdated) {
        dispatch(updateBriefAudio(response.new_brief_id, pollyText));
      } else {
        payload.isUpdating = false;
        dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
        dispatch(updateCursorStatus({ pointerType: 'default' }));
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function orderBriefs(fromIndex, toIndex, selectedChapterID) {
  return (dispatch) => {
    const data = {
      bm_chapter_id: selectedChapterID,
      from_index: fromIndex,
      to_index: toIndex,
    };
    dispatch({
      type: UPDATE_BRIEF_FILTER_DATA,
      payload: { isOrderBeingUpdated: false },
    });
    return Api({
      method: 'post',
      url: '/briefs/update_order?include_deprecated_briefs=true',
      data,
    }).then((response) => {
      const payload = response;
      if (response.success) {
        dispatch(parentChildrenSeperations(response));
        dispatch({ type: ORDER_BRIEFS, payload });
        payload.selectedIndex = null;
        payload.isBriefSelected = false;
        payload.isOrderBeingUpdated = true;
        dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updateBrief(id, title, content, timeToRead, chapterID, previousYearQuestionId, isBriefSearched = false, selectedTags = [], parentId) {
  return (dispatch, getState) => {
    const {
 briefMe: {
      selectedIndex, briefOrder, briefs, parentIdChanged,
    } 
} = getState();
    const data = {
      title,
      content,
      time_to_read: timeToRead,
      bm_chapter_id: chapterID,
      brief_tags: JSON.stringify(selectedTags),
      previous_year_question_id: previousYearQuestionId,
      parent_id: parentId,
    };
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { isUpdating: true } });
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: `/briefs/${id}/update`,
      data,
    }).then((response) => {
      let payload = {};
      payload = response;
      payload.id = id;
      payload.isBriefSearched = isBriefSearched;
      if (response.success) {
        if ((parentId || parentId === '') && parentIdChanged) {
          const fromIndex = selectedIndex;
          const potentialParentId = parentId || briefs[briefOrder[selectedIndex]].parent_id;
          let toIndex = briefOrder.indexOf(potentialParentId);
          if (toIndex !== briefOrder.length) {
            toIndex += 1;
            let index = toIndex;
            for (index; index < briefOrder.length; index += 1) {
              if (!briefs[briefOrder[index]].parent_id) {
                break;
              }
            }
            if (fromIndex < toIndex || toIndex === briefOrder.length || parentId === '') index -= 1;
            toIndex = index;
          }
          dispatch(orderBriefs(fromIndex, toIndex, chapterID));
        }
        dispatch({ type: UPDATE_BRIEFS, payload });
        payload.isBriefSelected = false;
        payload.selectedIndex = null;
        payload.removedPreviousYearQIds = [];
      } else {
        dispatch(showToast(response.message));
      }
      payload.isUpdating = false;
      dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function toggleSubjectStatus(id, status) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${id}/toggle_bm_subject_status`,
      data: {
        status,
      },
    }).then((response) => {
      const payload = { id, status };
      if (response.success) {
        dispatch({ type: TOGGLE_SUBJECT_STATUS, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function toggleChapterStatus(id, status) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${id}/toggle_bm_chapter_status`,
      data: {
        bm_chapter_id: id,
        status,
      },
    }).then((response) => {
      const payload = { id, status };
      if (response.success) {
        dispatch({ type: TOGGLE_CHAPTER_STATUS, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function deprecateBrief(id, deprecated, chapterID, isBriefSearched) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${id}/toggle_deprecate`,
      data: {
        bm_chapter_id: chapterID,
        deprecated,
      },
    }).then((response) => {
      const payload = { id, deprecated, isBriefSearched };
      if (response.success) {
        dispatch({ type: TOGGLE_DEPRECATE, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function deleteBrief(id, selectedIndex, isBriefSearched) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${id}/destroy`,
    }).then((response) => {
      const payload = { id, isBriefSearched };
      if (response.success) {
        dispatch({ type: DELETE_BRIEF, payload });
        payload.selectedIndex = selectedIndex;
        payload.isBriefSelected = false;
        dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export const updateBriefReducer = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  const {
    bmSubjectId, bmChapterId, selectedSubjectStatus, selectedChapterStatus, toggleDeprecate,
  } = payload;
  if (bmSubjectId && selectedSubjectStatus) {
    dispatch(toggleSubjectStatus(bmSubjectId, selectedSubjectStatus));
  } else if (bmChapterId && selectedChapterStatus) {
    dispatch(toggleChapterStatus(bmChapterId, selectedChapterStatus));
  }
  if (toggleDeprecate) {
    const { selectedBriefId, deprecated, isBriefSearched } = payload;
    dispatch(deprecateBrief(selectedBriefId, deprecated, bmChapterId, isBriefSearched));
  }
};

export function uploadImage(imageFile) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BRIEF_FILTER_DATA,
      payload: { isUpdating: true },
    });
    dispatch(updateCursorStatus({ pointerType: 'progress' }));
    return Api({
      method: 'post',
      url: '/briefs/upload_brief_images',
      attachment: imageFile,
      type: 'brief_image',
    }).then((response) => {
      const payload = response;
      if (response.success) {
        payload.imageURL = response.brief_image_url;
        dispatch(showToast(response.message));
      }
      payload.isUpdating = false;
      dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      dispatch(updateCursorStatus({ pointerType: 'default' }));
      return response;
    });
  };
}

export function removePermissions(index, membershipsWithPermission) {
  return (dispatch) => {
    const data = {
      permission_info: JSON.stringify(membershipsWithPermission.filter((detail, detailIndex) => { return detailIndex === index; })[0]),
    };
    return Api({
      method: 'post',
      url: '/briefs/remove_permissions',
      data,
    }).then((response) => {
      const payload = { membershipsWithPermission: membershipsWithPermission.filter((detail, detailIndex) => { return detailIndex !== index; }) };
      dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function updatePermissions(membershipsWithPermission, { newMembershipID, newPermission }, isNewInfoPresent) {
  let permissionInfo = membershipsWithPermission.length > 0 ? membershipsWithPermission.map(({ membershipID, permission, subjectIDs }) => { return { membershipID, permission: permission.name, subjectIDs: subjectIDs.map(({ id }) => { return id; }) }; }) : [];
  permissionInfo = isNewInfoPresent ? [...permissionInfo, { membershipID: parseInt(newMembershipID, 10), permission: newPermission.name, subjectIDs: [] }] : permissionInfo;

  return (dispatch) => {
    const data = {
      memberships_with_permission: JSON.stringify(permissionInfo),
    };
    return Api({
      method: 'post',
      url: '/briefs/update_permissions',
      data,
    }).then((response) => {
      if (response.success && isNewInfoPresent) {
        dispatch(getBriefFilterData());
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export const getVersionsDetails = (selectedBriefId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/briefs/${selectedBriefId}/get_versions_details`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      briefVersions: response.brief_versions,
    };
  }
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  return response;
};

export const getAutoFormat = (briefContent) => async (dispatch) => {
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { autoFormat: { isLoading: true, content: '' } } });
  const response = await Api({
    method: 'post',
    url: '/briefs/format_brief',
    data: {
      content: briefContent,
    },
  });
  let payload = {};
  if (response.success) {
    payload = {
      isLoading: false,
      content: response.formatted_brief_content,
    };
    dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload: { autoFormat: payload } });
  } else {
    dispatch(showToast(response.message || toastMessages.requestFailureMessage));
  }
  return response;
};

export const getVersionsComparison = (selectedBriefId, newerVersionId, olderVersionId = '', isCurrentBrief = false) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/briefs/${selectedBriefId}/get_versions_comparison?newer_version_id=${newerVersionId}&older_version_id=${olderVersionId}&is_current_brief=${isCurrentBrief}`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      newerBrief: response.newer_brief,
      olderBrief: response.older_brief,
      versionDifferences: response.version_differences,
      newerVersionCreator: response.newer_version_creator,
      olderVersionCreator: response.older_version_creator,
    };
  }
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  return response;
};

export const briefSearchByWord = (searchText, replacingText, bmSubjectId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/briefs/search?replacing_text=${replacingText}&bm_subject_id=${bmSubjectId}&find_and_replace_in_brief=true&search_text=${searchText.replace(/%/g, '')}`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      searchedBriefs: response.briefs,
    };
  }
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  return response;
};

export function replaceBriefText(briefData, searchText, replacingText) {
  const {
    id, is_title_matches: isTitleMatches, is_content_matches: isContentMatches,
  } = briefData;
  return (dispatch) => {
    return Api({
      method: 'post',
      url: `/briefs/${id}/replace_brief_text`,
      data: {
        search_text: searchText,
        replacing_text: replacingText,
        is_title_matches: isTitleMatches,
        is_content_matches: isContentMatches,
      },
    }).then((response) => {
      if (response.success) {
        const payload = {
          id,
          searchText,
          replacingText,
          isTitleMatches,
          isContentMatches,
        };
        dispatch({ type: REPLACE_BRIEF_TEXT, payload });
      }
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export const getBrief = (briefId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/briefs/${briefId}/get_brief`,
  });
  let payload = {};
  if (response.success) {
    payload = {
      selectedBrief: response.selected_brief,
      isAdmin: response.is_admin,
      hasCreateSubjectAccess: response.has_create_bm_subject_access,
      hasModifyBriefAccess: response.has_modify_brief_access,
    };
  }
  dispatch({ type: UPDATE_BRIEF_FILTER_DATA, payload });
  return response;
};

export function restoreVersion(briefId, versionId) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/update_brief_version',
      data: {
        id: briefId,
        selected_version_id: versionId,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function getBriefQuestions(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/briefs/${id}/get_brief_questions`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        briefQuestionList: response.brief_questions,
      };
      dispatch({
        type: UPDATE_BRIEF_FILTER_DATA,
        payload,
      });
    }
    return response;
  });
}

export function getBriefQuestionsForChapter(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/briefs/${id}/get_brief_questions_for_chapter`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        briefQuestionListForChapter: response.brief_question,
      };
      dispatch({
        type: UPDATE_BRIEF_FILTER_DATA,
        payload,
      });
    }
    return response;
  });
}

export function getBriefPreviousYearQuestions(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/briefs/${id}/get_brief_previous_year_questions`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        briefPreviousYearQuestionList: response.brief_previous_year_questions,
      };
      dispatch({
        type: UPDATE_BRIEF_FILTER_DATA,
        payload,
      });
    }
    return response;
  });
}

export function updateBriefQuestion(id, filters, isBriefSearched = false, imageFile = {}, removedImages = {}) {
  return (dispatch) => Api({
    method: 'post',
    url: '/briefs/update_brief_question',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'reported_image_file',
    data: {
      brief_question: JSON.stringify(filters),
      brief_id: id,
      removed_images: JSON.stringify(removedImages),
    },
  }).then((response) => {
    const payload = {
      id,
      textChanges: filters,
      isBriefSearched,
    };
    if (!(filters.id > 0)) {
      dispatch(getBriefQuestions(id));
      dispatch({
        type: APPEND_NEW_QUESTION,
        payload,
      });
    }
    dispatch(showToast(response.message));
    return response;
  });
}

export function removeBriefQuestion(briefId, questionId, isPreviousYearQuestionView = true, isBriefSearched = false) {
  return (dispatch) => Api({
    method: 'post',
    url: isPreviousYearQuestionView ? `/briefs/remove_brief_previous_year_question?brief_id=${briefId}` : '/briefs/remove_brief_question',
    data: { id: questionId },
  }).then((response) => {
    const payload = {
      briefId,
      questionId,
      isPreviousYearQuestionView,
      isBriefSearched,
      success: response.success,
    };
    if (response.success) {
      dispatch({
        type: REMOVE_QUESTIONS,
        payload,
      });
    }
    dispatch(showToast(response.message));
    return response;
  });
}

export function convertImage(image) {
  return () => Api({
    method: 'post',
    url: '/briefs/get_text_from_image',
    attachment: image,
    type: 'image_file',
    data: { image: image && URL.createObjectURL(image) },
  }).then((response) => {
    return response;
  });
}

export function updateBmChapterOrder(bmSubjectId, chapterOrder, sourceIndex, destinationIndex, droppedChapterName) {
  return (dispatch) => {
    return Api({
      method: 'post',
      url: '/briefs/update_chapter_order',
      data: {
        bm_subject_id: bmSubjectId,
        chapter_order: JSON.stringify(chapterOrder),
        source_index: sourceIndex,
        destination_index: destinationIndex,
        chapter_name: droppedChapterName,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      return response;
    });
  };
}

export function clearImageFile() {
  return (dispatch) => {
    const payload = {
      imageURL: '',
    }
    dispatch({
      type: CLEAR_IMAGE,
      payload,
    });
  };
}