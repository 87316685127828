import React from 'react';
import MathJax from 'react-mathjax2';
import { asciiForMathJax } from '../../helpers/Utils';
import { MATHJAX_SCRIPT, QUESTION_TEXT_TYPES } from '../../shared/constants/fieldTypes';

class PreviewDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.mathjaxDiv = null;
    this.previewDisplay = this.previewDisplay.bind(this);
    this.renderScrollHeight = this.renderScrollHeight.bind(this);
  }

  renderScrollHeight(inputType = '') {
    QUESTION_TEXT_TYPES.map((type) => {
      const selected = (type === inputType);
      return this.props.scrollheight(type, selected);
    });
  }

  previewDisplay(content) {
    return (
      <MathJax.Context
        input={asciiForMathJax()}
        onLoad={(event) => this.renderScrollHeight(event)}
        onError={() => {
          MathJax.Hub && MathJax.Hub.Queue(
            MathJax.Hub.Typeset(),
          );
        }}
        script={MATHJAX_SCRIPT}
        options={{
          asciimath2jax: {
            useMathMLspacing: true,
            delimiters: [['$$', '$$']],
            preview: 'none',
          },
        }}
      >
        <MathJax.Text text={content} />
      </MathJax.Context>
    );
  }

  render() {
    const { content } = this.props;
    return this.previewDisplay(content);
  }
}

export default PreviewDisplay;
